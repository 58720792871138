import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import {useCotizacion} from './cotizacion-context'
import Loading from "../../../../../components/Common/Loading";
import { useParams } from "react-router-dom";
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import Grid from '@material-ui/core/Grid';
import FormFieldTextUncontrolled from '../../../../../components/Common/FormFieldTextUncontrolled'
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import { v4 as uuidv4 } from 'uuid';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import IconButton from '@material-ui/core/IconButton';
import { TextField } from "@material-ui/core";

let propiedadesPlusMercancias = {
  urlData: "dic/diccionarios/grupomercancias",
  campoEtiqueta: "Etiqueta",
  campoId: "Id",
  required: false,
  name: "combogrupomercancia",
  disabled: false,
  label: "Plus",
  value: "",
  multiple: false,
  xs:2,
}

let propiedadesTiposMercancias = {
  urlData: "dic/diccionarios/tipomercancias",
  campoEtiqueta: "Id,Etiqueta",
  campoId: "Id",
  required: false,
  name: "combomercancia",
  disabled: false,
  label: "Clase",
  value: "",
  multiple: false,
  xs:2,
}

let propiedadesMercancias = {
  urlData: "dic/diccionarios/subtipomercancias/0",
  campoEtiqueta: "Etiqueta",
  campoId: "Id",
  required: false,
  name: "combosubtipomercancia",
  disabled: false,
  label: "Mercancía",
  value: "",
  multiple: false,
  xs:2,
}

let propiedadesMovimientos = {
  urlData: "dic/diccionarios/tipomovimientos",
  campoEtiqueta: "Etiqueta",
  campoId: "Id",
  required: false,
  name: "combo-movimiento",
  disabled: false,
  label: "Movimientos",
  value: "",
  multiple: false,
  xs:2,
}

let propiedadesJornadas = {
  urlData: "dic/diccionarios/listadojornadas",
  campoEtiqueta: "Etiqueta",
  campoId: "Id",
  required: true,
  name: "combo-jornadas",
  disabled: false,
  label: "Turno",
  value: "",
  multiple: false,
  xs:3,
}

let propiedadesNumBultos = {
  xs:1,
  required: false,
  label: "Nº Bultos",
  name: "nbultos",
  type: "number",    
  value: ''
}

let propiedadesNumKilos = {
  xs:1,
  required: false,
  label: "Toneladas",
  name: "nkilos",
  type: "number",    
  value: ''
}

let propiedadesHorasExtra = {
  xs:1,
  required: false,
  label: "Horas Extra",
  name: "hextra",
  type: "number",    
  value: ''
}

let propiedadesDescripcionMercancia = {
  xs:12,
  required: false,
  label: "Descripción trabajo",
  name: "descripcionMercancia",
  type: "text",    
  value: '',
  classes: 'd-none'
}

const FormularioCotizacionMano = ({index}) => {   
  let { id, modo } = useParams();
  const fechaActural = useRef('');
  const turnoActual = useRef('');
  const supervisorActual = useRef('');
  const horasExtra = useRef(0);

  const modoLectura = modo === "W" ? false : true
  const { control, handleSubmit, setValue } = useForm(); 
  const [turno, setTurno] = useState("")  
  const [empleados, setEmpleados] = useState([])
  const [jornadas, setJornadas] = useState([])
  const [openAlert, setOpenAlert] = useState(false);
  const [idManoActiva, setIdManoActiva] = useState(0);
  const [listadoMercancias, setListadoMercancias] = useState([])
   
    

    const {cotizacion, manosCotizacion, datosJornadas, setDatosJornadas, manosMercancia, setManosMercancia, 
      setCostesDirectos, setManosCotizacion, datosCotizacion,
      setDatosCotizacion, setDatosPresupuesto, setDatosFormulario} = useCotizacion();

    const handleChangeFecha = (valor) => {
      fechaActural.current = valor
    }

    const handleChangeSupervisor = (valor) => {
      supervisorActual.current = valor
    }

    let propiedadesFecha = {
      xs:2,
      required: true,
      disabled: false,
      label: "Fecha",
      name: "fechamano",
      id: "fechamano",
      type: "date",    
      value: '',
      onChangeValue: handleChangeFecha
    }

    let propiedadesSupervisor = {
      urlData: "dic/diccionarios/listadousuarios",
      campoEtiqueta: "nombre",
      campoId: "id",
      required: true,
      name: "combo-supervisor",
      disabled: false,
      label: "Supervisor",
      value: "",
      multiple: false,
      xs:3,
      onChangeValue: handleChangeSupervisor
    }

    const [isLoading, setIsLoading] = useState(false); 
    useEffect(() => {     
      setIsLoading(true);
      const fetchData = async () => {
        const respUsuarios = await axios(process.env.REACT_APP_API_URL + "dic/diccionarios/listadousuarios");    
        setEmpleados(respUsuarios.data);

        const respJornadas = await axios(process.env.REACT_APP_API_URL +  "dic/diccionarios/listadojornadas");    
        setJornadas(respJornadas.data);
      };
      fetchData();     

      setIsLoading(false);  
    },[])
   
    
    useEffect(() => {  
      setCostesDirectos(calculaCosteTotalManos())    
      let filas=[]            
      const datosMano = datosJornadas.filter(datoMano => datoMano.elementos.mano === index)      
      datosMano.forEach(datoJornada => {        
        let fila = {
          id: datoJornada.elementos.id ? datoJornada.elementos.id : uuidv4(),
          idPlus: datoJornada.elementos.plus ? datoJornada.elementos.plus.Id : null,
          idClaseMercancia: datoJornada.elementos.clase ? datoJornada.elementos.clase.Id : null,
          idTipoMercancia: datoJornada.elementos.mercancia ? datoJornada.elementos.mercancia.Id : null,
          idMovimiento: datoJornada.elementos.movimiento ? datoJornada.elementos.movimiento.Id : null,
          clase: datoJornada.elementos.clase ? datoJornada.elementos.clase.Etiqueta : datoJornada.elementos.descripcionMercancia ? datoJornada.elementos.descripcionMercancia : null,
          mercancia: datoJornada.elementos.mercancia ? datoJornada.elementos.mercancia.Etiqueta : null,
          movimiento: datoJornada.elementos.movimiento ? datoJornada.elementos.movimiento.Etiqueta : null,
          nbultos: datoJornada.elementos.bultos ? datoJornada.elementos.bultos : null,
          nkilos: datoJornada.elementos.kilos ? datoJornada.elementos.kilos : null,
          idRecurso: datoJornada.elementos.Id,
          recurso: datoJornada.elementos.Nombre,
          cantidad: datoJornada.elementos.cantidad,
          coste: datoJornada.elementos.Coste,
          total: parseFloat(datoJornada.elementos.Coste) * parseFloat(datoJornada.elementos.cantidad),
        }        
        filas.push(fila)
      })      
      setListadoMercancias(filas)
      return () => {}
    }, [datosJornadas])
    
    const {manoActiva, setManoActiva} = useCotizacion();
    useEffect(() => {    
      setManoActiva(index)   
    },[index, setManoActiva])
  
    useEffect(() => {    
      if(index){           
        manosCotizacion.forEach(element => { 
          if(element.Mano === index){            
            setIsLoading(true)    
            
            fechaActural.current =  element.Fecha 
            turnoActual.current = element.Turno
            supervisorActual.current = element.Supervisor     
            horasExtra.current = element.Horas_Extra || 0   
            
            let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "jornada")
            manoMercanciaTmp.push({nombre:"jornada", valor: element.Turno})
            setIdManoActiva(element.Id);
            setIsLoading(false) 
          }        
        });  
    }
    },[index, manosCotizacion]);

    const OrdenarManos = (manos) => {  
      const manosAux = JSON.parse(JSON.stringify(manos)); 
      manosAux.sort(function(a, b) {      
        if (a.Mano < b.Mano) return -1;
        if (a.Mano > b.Mano) return 1;
        return 0;
      });    
      return manosAux
    }
    
    const handleSubmitPost = async (datos) => {    

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = process.env.REACT_APP_API_URL +  'cotizacionesmanos';     

      if(id) datos["idCotizacion"] = id;    
      else datos["idCotizacion"] = cotizacion; 

      datos["idMano"] = manoActiva;        
      datos["hextra"] = horasExtra.current
        
      let rec = datosJornadas.filter(     
        (dato) => parseInt(dato.elementos.mano) === parseInt(index)
      )    
      datos["recursos"] = rec;   
                
      if (idManoActiva > 0) {        
        await axios
          .put(SERVER_URL + "/" + idManoActiva, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {
            if(!isNaN(response.data)){            
              setOpenAlert(true);
              setIdManoActiva(response.data)

              let manoAModificar = manosCotizacion.filter((manoCotizacion) => 
                  manoCotizacion.Id === response.data
              )            

              let manosANoModificar = manosCotizacion.filter((manoCotizacion) => 
              manoCotizacion.Id !== response.data
              )         

              manoAModificar = {           
                Fecha: datos.fechamano,
                Fk_Cotizacion: parseInt(datos.idCotizacion),
                Fk_Jornada: parseInt(datos['combo-jornadas']['Id']),
                Fk_Supervisor: parseInt(datos['combo-supervisor']['id']),
                Id: response.data,
                Jornada: datos['combo-jornadas']['Etiqueta'],
                Mano: datos.idMano,
                Numero_Empleados: null,
                Supervisor: {id: datos['combo-supervisor']['id'], nombre: datos['combo-supervisor']['nombre']},
                Turno: {Id: datos['combo-jornadas']['Id'], Etiqueta: datos['combo-jornadas']['Etiqueta']}, 
                HorasExtra: parseInt(datos['hextra']),
              }         
            
              const manosOrdenadas = OrdenarManos([...manosANoModificar, manoAModificar])
              setManosCotizacion(manosOrdenadas)
              //setManosCotizacion([...manosANoModificar, manoAModificar]) 
            }          
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          });
      } 
      else{       
        await axios
        .post(SERVER_URL, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {      
          if(!isNaN(response.data)){
            setOpenAlert(true);
            setIdManoActiva(response.data)                   
          
            const nuevaMano = {           
              Fecha: datos.fechamano,
              Fk_Cotizacion: parseInt(datos.idCotizacion),
              Fk_Jornada: parseInt(datos['combo-jornadas']['Id']),
              Fk_Supervisor: parseInt(datos['combo-supervisor']['id']),
              Id: response.data,
              Jornada: datos['combo-jornadas']['Etiqueta'],
              Mano: datos.idMano,
              Numero_Empleados: null,
              Supervisor: {id: datos['combo-supervisor']['id'], nombre: datos['combo-supervisor']['nombre']},
              Turno: {Id: datos['combo-jornadas']['Id'], Etiqueta: datos['combo-jornadas']['Etiqueta']}, 
              HorasExtra: parseInt(datos['hextra']),
            }         
            //setManosCotizacion([...manosCotizacion, nuevaMano])
            const manosOrdenadas = OrdenarManos([...manosCotizacion, nuevaMano])
            setManosCotizacion(manosOrdenadas)
            
          }
        })
        .catch((error) => {
          console.log("error " + error);
          console.log(error.response.data); // => the response payload 
          console.log(error.response.data.message)
        });
      }
    }

    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert(false);
    };  
    
    const handleChangeComboGrupoMercancias = (valor) => {    
      if(valor){      
        propiedadesPlusMercancias.value = valor 
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "plus")      
        manoMercanciaTmp.push({nombre:"plus", valor: valor})
        setManosMercancia(manoMercanciaTmp) 
      } 
      else{
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "plus")
        setManosMercancia(manoMercanciaTmp)
      }
    }  
    
    const handleChangeComboTipoMercancias = (valor) => { 
      
      if(valor){      
        if(valor.Id === 9998 || valor.Id === 9999) {
          propiedadesDescripcionMercancia.classes = ""
          //propiedadesDescripcionMercancia.value = ""
        }else{
          propiedadesDescripcionMercancia.classes = "d-none"
          propiedadesDescripcionMercancia.value = ""
        }
        propiedadesMercancias.urlData = 'dic/diccionarios/subtipomercancias/' + valor.Id   
        propiedadesTiposMercancias.value = valor
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "clase")
        manoMercanciaTmp.push({nombre:"clase", valor: valor})
        setManosMercancia(manoMercanciaTmp)
      }
      else{
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "clase")
        setManosMercancia(manoMercanciaTmp)
      }
    }

    const handleChangeComboMercancias = (valor) => {
      if(valor){
        propiedadesMercancias.value = valor
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "mercancia")
        manoMercanciaTmp.push({nombre:"mercancia", valor: valor})
        setManosMercancia(manoMercanciaTmp)        
      }
      else{
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "mercancia")
        setManosMercancia(manoMercanciaTmp)
      }
    }

    const handleChangeComboMovimientos = (valor) => {
      if(valor){
        propiedadesMovimientos.value = valor
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "movimiento")
        manoMercanciaTmp.push({nombre:"movimiento", valor: valor})
        setManosMercancia(manoMercanciaTmp)       
      }   
      else{
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "movimiento")
        setManosMercancia(manoMercanciaTmp)
      } 
    }

    const handleChangeNumMovs = (valor) => {
      if(valor){
        propiedadesNumBultos.value = valor
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "bultos")
        manoMercanciaTmp.push({nombre:"bultos", valor: valor})
        setManosMercancia(manoMercanciaTmp)  
      }
      else{
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "bultos")
        propiedadesNumBultos.value = ''
        setManosMercancia(manoMercanciaTmp)

      } 
    }

    const handleChangeNumKilos = (valor) => {   
      if(valor){
        propiedadesNumKilos.value = valor
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "kilos")
          manoMercanciaTmp.push({nombre:"kilos", valor: valor})
          setManosMercancia(manoMercanciaTmp)    
      }
      else{
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "kilos")
        propiedadesNumKilos.value = ''
        setManosMercancia(manoMercanciaTmp)      
      } 
    }

    const handleChangeHorasExtra = valor => {
      if(valor){
        propiedadesHorasExtra.value = valor
        horasExtra.current = valor
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "horasExtra")
          manoMercanciaTmp.push({nombre:"horasExtra", valor: valor})
          setManosMercancia(manoMercanciaTmp)    
      }
      else{
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "horasExtra")
        propiedadesHorasExtra.value = ''
        horasExtra.current = 0
        setManosMercancia(manoMercanciaTmp)      
      } 
    }

    const handleChangeComboJornadas = (valor) => {
      if(valor){
        turnoActual.current = valor
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "jornada")
          manoMercanciaTmp.push({nombre:"jornada", valor: valor})
          setManosMercancia(manoMercanciaTmp)    
          setTurno(valor)
      }
      else{
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "jornada")
        setManosMercancia(manoMercanciaTmp)
      } 
    }

    const handleChangeDescripcionMercancia = (valor) => {
        
      if(valor){
        propiedadesDescripcionMercancia.value = valor
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "descripcionMercancia")
          manoMercanciaTmp.push({nombre:"descripcionMercancia", valor: valor})
          setManosMercancia(manoMercanciaTmp)    
      }
      else{
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "descripcionMercancia")
        propiedadesDescripcionMercancia.value = ''
        setManosMercancia(manoMercanciaTmp)      
      }        
    }

    const calculaCosteTotalManos = (mano=0) => {
      let costeTotalMano = 0;  
      let ajusteCPE = 0;
      if(datosJornadas.length > 0 ){       
        datosJornadas.forEach(element => { 
          if(mano){
            if(mano === parseInt(element.elementos.mano)){
              let coste = parseFloat(element.elementos.esPresupuesto === "1" ? element.elementos.Importe : element.elementos.Coste);
              let cantidad = parseFloat(element.elementos.cantidad)
              costeTotalMano +=  coste * cantidad;     
              ajusteCPE += element.elementos.CategoriaPadre === "Mano de obra externa" ? parseFloat(element.elementos.Coste)*parseFloat(element.elementos.cantidad)*4/100 : 0
            } 
          }else{
            let coste = parseFloat(element.elementos.esPresupuesto === "1" ? element.elementos.Importe : element.elementos.Coste);
            let cantidad = parseFloat(element.elementos.cantidad)
            costeTotalMano +=  coste * cantidad;     
            ajusteCPE += element.elementos.CategoriaPadre === "Mano de obra externa" ? parseFloat(element.elementos.Coste)*parseFloat(element.elementos.cantidad)*4/100 : 0             
          }
        });  
      }
      return (costeTotalMano+ajusteCPE).toFixed(2)
    }

    const actualizaCostesMano = async () => {
      const datosManoActual = datosJornadas.filter(item => item.elementos.mano === index && item.elementos.EspecialidadCPE) 
      if(datosManoActual.length > 0){       
        
        let plus = manosMercancia.filter(item => item.nombre === "plus")      
        let clase = manosMercancia.filter(item => item.nombre === "clase")
        let mercancia = manosMercancia.filter(item => item.nombre === "mercancia")
        let movimiento = manosMercancia.filter(item => item.nombre === "movimiento")
        let bultos = manosMercancia.filter(item => item.nombre === "bultos")
        let kilos = manosMercancia.filter(item => item.nombre === "kilos")

        if(clase.length === 0 && (bultos.length === 0 || kilos.length === 0)){
          alert("Para reclacular los costes se debe rellenar al menos la Clase de mercancía y los bultos o toneladas")
          return
        }        
        
        let datos = {
            fecha: fechaActural.current,
            jornada: turnoActual.current,
            horasExtra: horasExtra.current,
            plus: plus.length > 0 ? plus[0].valor : null, 
            clase: clase.length > 0 ? clase[0].valor : null,
            mercancia: mercancia.length > 0 ? mercancia[0].valor : null, 
            movimiento: movimiento.length > 0 ? movimiento[0].valor : null, 
            bultos: bultos.length > 0 ? bultos[0].valor : null,
            kilos: kilos.length > 0 ? kilos[0].valor : null,
            mano: manoActiva,
            idManoActiva: idManoActiva,
            idOperativa: datosCotizacion.OperativaMercancia.Id,
            idCotizacion: id ? id : cotizacion
        }

        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL +  'cotizaciones/actualizarcostesmano'; 

        if (idManoActiva > 0) {        
          
          await axios
            .put(SERVER_URL, {
              headers: {
                "Content-Type": "application/json",
                Authorization: AuthStr,
              },
              datos,
            })
            .then((response) => {
              
              const elementosActualizados = response.data

              let datosManoActualizar = datosJornadas.filter(item => item.elementos.mano === index) 
              let datosOtrasManos = datosJornadas.filter(item => item.elementos.mano !== index) 
              
              elementosActualizados.forEach(element => {
                datosManoActualizar.forEach(elemento => {
                  if(parseInt(elemento.elementos.id) === parseInt(element.Id) && elemento.elementos.EspecialidadCPE){
                    elemento.elementos.Coste = element.Total
                    elemento.elementos.bultos = bultos.length > 0 ? bultos[0].valor : null
                    elemento.elementos.clase = clase.length > 0 ? clase[0].valor : null
                    elemento.elementos.idClaseMercancia = clase.length > 0 ? clase[0].valor.Id : null
                    elemento.elementos.idMovimiento = movimiento.length > 0 ? movimiento[0].valor.Id : null
                    elemento.elementos.idTipoMercancia = mercancia.length > 0 ? mercancia[0].valor.Id : null
                    elemento.elementos.idPlus = plus.length > 0 ? plus[0].valor.Id : null
                    elemento.elementos.kilos = kilos.length > 0 ? kilos[0].valor : null
                    elemento.elementos.mercancia = mercancia.length > 0 ? mercancia[0].valor : null
                    elemento.elementos.movimiento = movimiento.length > 0 ? movimiento[0].valor : null
                  }
                });
               
              });
              setDatosJornadas([...datosOtrasManos, ...datosManoActualizar])
              
            })
            .catch((error) => {
              console.log("error " + error);
              console.log(error.response.data); // => the response payload 
              console.log(error.response.data.message)
            });
        }
      }
    }
 
    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('es-ES', options2);  
    let costeTotalMano = 0; 
    costeTotalMano = numberFormat2.format(calculaCosteTotalManos(index))

    propiedadesMercancias.disabled = modoLectura
    propiedadesPlusMercancias.disabled = modoLectura
    propiedadesTiposMercancias.disabled = modoLectura
    propiedadesMovimientos.disabled = modoLectura
    propiedadesJornadas.disabled = modoLectura
    propiedadesSupervisor.disabled = modoLectura
    propiedadesNumBultos.disabled = modoLectura
    propiedadesNumKilos.disabled = modoLectura
    propiedadesFecha.disabled = modoLectura
    propiedadesDescripcionMercancia.disabled = modoLectura
    propiedadesHorasExtra.disabled = modoLectura

    setValue("fechamano", fechaActural.current)
    setValue("combo-jornadas", turnoActual.current)
    setValue("combo-supervisor", supervisorActual.current)
    setValue("hExtra", horasExtra.current)
    propiedadesHorasExtra.value = horasExtra.current

    let clase = manosMercancia.filter(item => item.nombre === "clase")
    if(clase.length > 0) propiedadesTiposMercancias.value = clase[0].valor

    let movimiento = manosMercancia.filter(item => item.nombre === "movimiento")
    if(movimiento.length > 0) propiedadesMovimientos.value = movimiento[0].valor 

    let kilos = manosMercancia.filter(item => item.nombre === "kilos")
    if(kilos.length > 0) propiedadesNumKilos.value = kilos[0].valor

    let bultos = manosMercancia.filter(item => item.nombre === "bultos")
    if(bultos.length > 0) propiedadesNumBultos.value = bultos[0].valor

    let pluses = manosMercancia.filter(item => item.nombre === "plus")
    if(pluses.length > 0) propiedadesPlusMercancias.value = pluses[0].valor
    
    let mercancia = manosMercancia.filter(item => item.nombre === "mercancia")
    if(mercancia.length > 0) propiedadesMercancias.value = mercancia[0].valor
    
    return (
      <>    
      {!isLoading && (
        <>
          {/* FORMULARIO */}
          <div className="">
            <form
              onSubmit={handleSubmit((datos) => {
              handleSubmitPost(datos);
              })}
              className="row"
            >
            <Grid container spacing={1} className="CPE1 mt-2">
              
            </Grid>

            <Grid container spacing={1} className="CPE1 mt-2">                   

                <FormFieldDate propiedades={propiedadesFecha} control={control}  />              
                <FormFieldCombo propiedades={propiedadesJornadas} control={control} onChangeValue={handleChangeComboJornadas} />
                <FormFieldTextUncontrolled propiedades={propiedadesHorasExtra} control={control} onChangeValue={handleChangeHorasExtra}/>
                <FormFieldCombo propiedades={propiedadesSupervisor} control={control}  />
                        
                {/* Coste Mano */}
                <div className="col-3 mt-4 mb-2 "> 
                  <span className="ml-1 costeManoRecursos float-right">
                      {costeTotalMano}
                  </span>
                  <span className="tituloRecursos mr-2 float-right">Coste Mano {index}                  
                  </span>               
                </div>

                <FormFieldCombo propiedades={propiedadesPlusMercancias} control={control} onChangeValue={handleChangeComboGrupoMercancias} />
                <FormFieldCombo propiedades={propiedadesTiposMercancias} control={control} onChangeValue={handleChangeComboTipoMercancias} />
                <FormFieldCombo propiedades={propiedadesMercancias} control={control} onChangeValue={handleChangeComboMercancias} />
                <FormFieldCombo propiedades={propiedadesMovimientos} control={control} onChangeValue={handleChangeComboMovimientos} />
                <FormFieldTextUncontrolled propiedades={propiedadesNumBultos} control={control} onChangeValue={handleChangeNumMovs}/>
                <FormFieldTextUncontrolled propiedades={propiedadesNumKilos} control={control} onChangeValue={handleChangeNumKilos}/>              
                <FormFieldTextUncontrolled propiedades={propiedadesDescripcionMercancia} control={control} onChangeValue={handleChangeDescripcionMercancia}/>                
                
                {modo === "W"  && 
                  <IconButton color="primary" aria-label="actualizar costes" onClick={() => {actualizaCostesMano()}}>
                    <AutorenewIcon />
                  </IconButton> 
                }       
                <TablaDatosMano listadoMercancias={listadoMercancias} />
                

              </Grid>

                {openAlert && 
                <AlertaMensaje 
                  mensaje={"¡Mano guardada correctamente!"} 
                  isOpen={openAlert} 
                  tipoMensaje="success"
                  cerrar={handleCloseAlert}
                />
                }  

                <div className="col-12 pt-2 mb-4">  
                  {modo === "W"  &&                   
                  <button 
                  disabled={modoLectura}
                  className="float-right MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation">
                    GRABAR
                  </button>
                  }                
                </div>
            </form>      
          </div>  
        </>
      )}
      <div>{isLoading && <Loading isLoading={isLoading} />}</div>

      </>
    );    
    
  };
  export default FormularioCotizacionMano;


  const TablaDatosMano = ({listadoMercancias}) => {
    let { id, modo } = useParams();
    const { datosJornadas, setDatosJornadas } = useCotizacion();
    //const [listadoMercancias, setListadoMercancias] = useState([])


    const columns = [
      { field: 'id', headerName: 'id', hide: true },
      { field: 'idPlus', headerName: 'Plus', width: 80, hide: true },
      { field: 'idClaseMercancia', headerName: 'idClaseMercancia', hide: true },
      { field: 'idTipoMercancia', headerName: 'idTipoMercancia', hide: true },
      { field: 'idMovimiento', headerName: 'idMovimiento', hide: true },
      { field: 'clase', headerName: 'Clase', width: 150 },
      { field: 'mercancia', headerName: 'Mercancía', width: 200, hide: true },
      { field: 'movimiento', headerName: 'Movimiento', width: 150 },
      { field: 'nbultos', headerName: 'Bultos', width: 90 },
      { field: 'nkilos', headerName: 'Toneladas', width: 90 },
      { field: 'idRecurso', headerName: 'idRecurso', hide: true },
      { field: 'recurso', headerName: 'Recurso', width: 180 },
      { field: 'cantidad', headerName: 'Cantidad',  type: 'number',            
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),
        width: 100, sortable: true, editable: modo !== "R" },
      { field: 'coste', headerName: 'Coste', width: 90, type: 'number' },
      { field: 'total', headerName: 'Total', width: 100, type: 'number' },
      { field: '', headerName: '', width: 80, 
          renderCell: (params) => (
                <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>
            ), 
      },
    ];

    const handleRemoveElement = (element) =>{  
      if( ( modo !== "R" ) && id > 0 ){   
        const nuevoListado2 = datosJornadas.filter(el => {  
          return !isNaN(element.id) && (parseInt(el.elementos.id) !== parseInt(element.id))
        })      
        setDatosJornadas(nuevoListado2)  
      }    
    }  

    const handleCellEditCommit = React.useCallback(
      ({ id, field, value }) => {           
        if (field === 'cantidad') {  
          let cantidad = 0
          let total = 0

          const updatedRows = listadoMercancias.map((row) => {
            if (row.id === id) {    
                cantidad = value
                total = cantidad * row.coste          

              return { ...row,  cantidad: value, total: total};
            }
            return row;
          });   

          
          let nuevoListado = JSON.parse(JSON.stringify(datosJornadas));
          nuevoListado.forEach(dato => {  
            if(dato.elementos.id === id){
              dato.elementos.cantidad = cantidad
              dato.elementos.total = total
            }
          });
          setDatosJornadas(nuevoListado)

        }
    })

    return (
      <Grid item xs={12} className="" style={{ display: 'flex', flexWrap: 'wrap',  height: 320, marginLeft: 0 }}>
        <DataGrid 
          rows={listadoMercancias} 
          columns={columns} 
          pageSize={100} 
          checkboxSelection = {false}
          hideFooter = {true}
          hideFooterPagination = {true}
          hideFooterRowCount = {true}
          hideFooterSelectedRowCount = {true}
          onCellEditCommit={handleCellEditCommit}          
        />     
       </Grid>  
    )            
  }