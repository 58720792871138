import React, { useState, useEffect } from "react";
import axios from "axios";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import "semantic-ui-css/semantic.min.css";
import MUIDataTable from "mui-datatables";
import { useParams } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles((theme) => ({ 
  linear: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const TablaProductosTarifa = ({ productosTabla, getListadoProductos, setListadoProductos }) => { 
  const classes = useStyles(); 
  const [DatosTabla, setDatosTabla] = useState(productosTabla);  
  const [loading, setLoading] = useState(false);
  const { id, modo } = useParams();

  const COLUMNS_PRODUCTOS = [
    {
      name: "idTarifaDetalle",
      label: "idTarifaDetalle",
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      }
    }, 
    {
      name: "idTarifa",
      label: "idTarifa",
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      }
    },  
        {
          name: "Id",
          label: "Id",
          options: {
            filter: false,
            sort: false,
            display: 'excluded',
          }
        },
        {
          name: "Operativa",
          label: "Operativa",
          options: {
           filter: true,
           sort: true,
          }
        },
        {
          name: "categoria",
          label: "Categoría",
          options: {
           filter: true,
           sort: true,
          }
        },
        {
         name: "Subcategoria",
         label: "Subcategoría",
         options: {
          filter: true,
          sort: true,
         }
        },
        {
          name: "codigo",
          label: "Código",
          options: {
           filter: true,
           sort: true,
          }
        },
        {
         name: "nombre",
         label: "Nombre Producto",
         options: {
          filter: true,
          sort: true,
         }
        },
        {
          name: "unidad_medida",
          label: "Medida",
          options: {
           filter: true,
           sort: true,
          }
        },
        {
            name: "periodicidad",
            label: "Periodicidad",
            options: {
             filter: true,
             sort: true,
            }
        },        
           {
            name: "tarifa",
            label: "Tarifa",
            options: {
             filter: true,
             sort: true,
              customBodyRender: (value, tableMeta, updateValue) => {                  
                return(                
                <FormControlLabel
                  value={value}
                  control={<TextField type="number" value={value} />}
                  onChange={event => {                    
                    updateValue(event.target.value)       
                    let newRow = tableMeta.rowData
                    newRow[tableMeta.columnIndex] = event.target.value                    
                    getListadoProductos(newRow)  

                    }}
                />
              );
            },
            }
           }, 
           { name: '', label: '', 
           options: {
            filter: false,
            sort: false,
             customBodyRender: (value, tableMeta, updateValue) => (
               <DeleteIcon onClick={() => handleRemoveElement(tableMeta.rowData)}/>
             )
           },},            
  ];

  const OPTIONS_PRODUCTOS = {
        filterType: 'checkbox',
        print: false,
        download: false,
        pagination: false,
        selectableRows: 'single',
        expandableRows: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        elevation:0,
  };

  const handleRemoveElement = async (element) =>{ 
   
    const elemenetosTemp = DatosTabla.filter(
      (dato) => parseInt(dato.Id) !== parseInt(element[2]) )
    
    setListadoProductos(elemenetosTemp)
    setDatosTabla(elemenetosTemp)    

  }  

  useEffect(() => {  
    setLoading(true)  
    if (modo === "R" || modo === "W") {
      const fetchData = async () => {              
        const respProducto = await axios( process.env.REACT_APP_API_URL + "tarifas/" + id);        
        if (respProducto.data) {   
          setDatosTabla(respProducto.data.productosTarifa); 
        }                
      };
      fetchData();
    }else{        
      setDatosTabla(...DatosTabla);      
    }   
  }, []);

  useEffect(() => {  
    setListadoProductos(DatosTabla) 
    setLoading(false)    
  }, [DatosTabla]);

  useEffect(() => {     
    setDatosTabla(productosTabla)    
  }, [productosTabla]);
  

  if (!loading){
    return (
      <>                 
        <div className="mt-4">
          <div className="rounded border border-formulario p1-form">
            <MUIDataTable
              title={""}
              className={"mt-1"}
              data={DatosTabla}
              columns={COLUMNS_PRODUCTOS}
              options={OPTIONS_PRODUCTOS} 
            />
          </div>
        </div>     
      </>
    )    
  }else{
    return (              
      <div className={classes.linear}>
          <CircularProgress />
      </div>
  );
  }
}

export default TablaProductosTarifa;