import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import { CircularProgress } from '@material-ui/core';
import FormularioTarifas from "./FormularioTarifas"
import FichaTarifas from "./FichaTarifas"
import MigasDePan  from "../../../../../components/Common/MigasDePan";
import SearchBar from "../../../../../components/Common/SearchBar";
import FormularioMultiple from "./FormularioMultiple"

const Tarifa = (props) => {
    let { id, modo } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [datos, setDatos] = useState();

    useEffect(() => {           
        if (isLoading && (modo === "R" || modo === "W")) {
          const fetchData = async () => {
            const respTarifa = await axios( process.env.REACT_APP_API_URL + "tarifas/" + id);
            if (respTarifa.data) {       
              setDatos(respTarifa.data);
              setIsLoading(false);
            }
          };
          fetchData();
        }  
        if (!modo) {      
          setIsLoading(false);
        }
        return () => {setDatos()}
    }, [isLoading, datos, modo, id]);

    const imprimirTarifa = async () => {
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        //http://localhost/mtmsystem/api/web/index.php/v1/tarifas/imprimirtarifa/11
        const SERVER_URL = process.env.REACT_APP_API_URL + "tarifas/imprimirtarifa/" + id;
        const res = await axios.get(
            SERVER_URL 
            /*, 
            { headers: {
                "Content-Type": "application/json",
                Authorization: AuthStr,
            }              
            },
            {params: { estado: ''}} 
            */
            );
        
        if(res.status === 200)
          window.open(res.data, "_blank")
    }    

    const botoneraAcciones = [    
        {
          etiqueta: modo === "R" ? "Editar tarifas" : "Cancelar",
          url: 
          typeof id === "undefined" ? `/ventas/productos/` :
          modo === "R" ? 
            `/ventas/productos/tarifa/${id}/W` : 
            `/ventas/productos/tarifa/${id}/R`,
          activo: true,
        },
        {
          etiqueta: "Guardar",
          form:'formulario-multiple',
          activo: modo === "R" ? false : true,
        },
        {
          etiqueta: "Imprimir",
          function: () => imprimirTarifa(),
          activo: modo === "R" ? true : false,
        },
    ];

    const urlsMigasdepan = [
      {titulo: "Tarifas", colorTexto: "texto-comercial", urlTo: "/ventas/productos"},
      {titulo: "Tarifa", colorTexto: "texto-comercial", urlTo: `/ventas/productos/tarifa/${id}/${modo}`},
    ]
    const codigo = typeof datos !== "undefined" ? datos.Codigo : "";
    
    return (
        <>
          <div className="col-8 col-main-home ">
            <SearchBar
              titulo="Ventas"
              claseCabecera = "bg-comercial"
              buscar={false}
              imagenCabecera={"4-2-3-productos.png"}
            />
            {!isLoading && (
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                    <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/>
                     
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">     
                      {modo === "R" &&  <FichaTarifas />}     
                      {modo !== "R" &&  <FormularioMultiple />}                           
                    </div>
                  </div>
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
            )}
            <div>{isLoading && <CircularProgress />}</div>
          </div>
        </>
      );
}
export default Tarifa;