import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import FormularioSalidaInicial from "./FormularioSalidaInicial";
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import LinearProgress from "@material-ui/core/LinearProgress";
import FormularioSalida from "./FormularioSalida";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },    
  }));

  const FormularioMaterialSalidas = ({index, tipoForm}) => {

    const [salida, setSalida] = useState([]) 
    const [campos, setCampos] = useState([]) 
    const { id, modo } = useParams();   
    const [listadoSalidas, setListadoSalidas] = useState([])
    const [ficheros, setFicheros] = useState([])
    const [nombresFicheros, setNombresFicheros] = useState([])
    const [openAlert, setOpenAlert] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const classes = useStyles();
    const methods = useForm();
    const { control, handleSubmit} = useForm();


    const handleFicheros = (files, filenames) => {      
      setFicheros(files)
      setNombresFicheros(filenames)
    }

    const handleListadoTabla=(valor) => {
     setListadoSalidas([...listadoSalidas,valor])
    }

    const handleRemoveElement = (element) =>{
      setListadoSalidas(element) 
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }  
    setOpenAlert(false);
  };  

  useEffect(() => {  
     
    setCampos([     
       {
        urlData: "dic/diccionarios/notasalidaalmacentipologia",
        campoEtiqueta: "Etiqueta",
        required: true,
        name: "tipologia",        
        label: "Tipologia",
        type: 'combo',
        value: '',
        multiple: false,
        xs: 4
       },
       {
        urlData: "ordenescompras",
        campoEtiqueta: "ordenCompra",
        required: true,
        name: "codigo",
        label: "Codigo",
        type: 'combo',
        value: '',
        multiple: false,
        xs: 4
       },
       {
        required: true,
        label: "Fecha",
        name: "fecha",
        type: "date",    
        value: '',
        xs: 4
       },
       {
        urlData: "dic/diccionarios/getdepartamentos",
        campoEtiqueta: "Etiqueta",
        required: true,
        name: "departamento",
        label: "Departamento",
        value: '',
        type:'combo',
        multiple: false,
        xs: 4
       },
       {
        urlData: "dic/diccionarios/notaentradaalmacenestado",
        campoEtiqueta: "Etiqueta",
        required: true,
        label: "Estado",
        name: "estado",
        type: 'combo',    
        value: '',
        multiple: false,
        xs: 4
       },
       {
        urlData: "dic/diccionarios/listadousuarios",
        campoEtiqueta: "nombre",
        required: true,
        name: "personal",
        
        label: "Personal",
        value: '',
        type:'combo',
        multiple: false,
        xs: 4
       },
       {
        campoEtiqueta: "observaciones",
        required: true,
        name: "observaciones",
        
        label: "Observaciones",
        value: '',
        multiple: false,
        type: 'textarea',
        lineas: 4,
        xs: 12
       },
       {
        required: false,
        label: "Documentacion",
        name: "filesupload",
        type: "filesupload",   
        tabla: "mtms_actividades_adjuntos",
        xs:12
       },
       {
        required: false,
        label: "Documentacion",
        name: "files",
        type: "files", 
       // ficheros: material.ficheros,    
        tabla: "mtms_actividades_adjuntos",
        modelo: "api\\modules\\v1\\models\\notasalida\\NotasalidaAlmacenAdjuntos",
        carpeta: "nota-salida-almacen",
        xs:12
       }
  

    ])

   const fetchData = async () => {
     setIsLoading(true) 
     if (modo === "W" || modo === "R") {
         const respSalida = await axios(
             `${process.env.REACT_APP_API_URL}salidamaterials/${id}`
         );                
         setSalida(respSalida.data) 
         setListadoSalidas(respSalida.data.materiales) 
     }
     setIsLoading(false);
 };    
 fetchData();       
  },[]);

  useEffect(() => {
    if (typeof salida !== "undefined") { 
      if(Object.keys(salida).length > 0){
        setIsLoading(true)  
        campos[0].value = salida.tipologia;
        //campos[1].value = salida.origen;
        if(salida.origen){
          if(salida.origen.Codigo){             
            campos[1].campoEtiqueta = "Codigo"
          }
        }   
        campos[1].value = salida.origen;
        campos[2].value = salida.fecha;
        campos[3].value = salida.departamento;
        campos[4].value = salida.estado;
        campos[5].value = salida.personal;           
        campos[6].value = salida.observaciones;
        campos[8].ficheros = salida.ficheros;
        setIsLoading(false)        
      }
    }
  }, [salida])

  const handleChangeTipologia = (valor) => { 
    let url="ordenescompras";
    let etiqueta="ordenCompra" 
    if(valor){
      switch(valor.Etiqueta){
        case "OPERACIÓN":
          url = "operaciones"
          etiqueta = "Codigo"
          break;
        case "INSTALACIÓN":
          url = "reparacions"
          etiqueta = "codigo"
          break;
        case "MAQUINARIA":
          url = "mantenimientos"
          etiqueta = "codigo"
          break;
        case "DEVOLUCIÓN":
          url = "ordenescompras"
          etiqueta = "ordenCompra"
          break;
        case "CONSUMO INTERNO":
          break;
        case "AJUSTE DE EXISTENCIAS":
          break;
        default: 
          url = "ordenescompras"
          etiqueta = "ordenCompra"     
      }       
    } 
    campos[1].urlData = url
    campos[1].campoEtiqueta = etiqueta    
  }

  const handleSubmitPost = async (datos) => {
    //setGrabandoFormulario(true);  
    let datosFormulario = {};
    datosFormulario.tipologia = datos.tipologia.Id
    datosFormulario.codigo = datos.codigo
    datosFormulario.fecha = datos.fecha
    datosFormulario.departamento = datos.departamento.Id
    datosFormulario.estado = datos.estado.Id
    datosFormulario.personal = datos.personal.id
    datosFormulario.observaciones = datos.observaciones
    datosFormulario.ficheros = ficheros
    datosFormulario.nombresFicheros = nombresFicheros
    //datosFormulario.ficherosadjuntos = datos.ficherosadjuntos
    //array de valores donde esten todos los variables de la tabla
    datosFormulario.listadotabla=listadoSalidas
    
  
    let user = JSON.parse(localStorage.getItem("user"));
    const AuthStr = "Bearer ".concat(user.access_token);
    const SERVER_URL = `${process.env.REACT_APP_API_URL}salidamaterials`
  
    
      if (modo==="W") {
        await axios
          .put(`${SERVER_URL}/${id}`, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datosFormulario,
          })
          .then((response) => {       
            if(!isNaN(response.data.Id)){
              if(parseInt(response.data.Id) === parseInt(id)){                       
                  setOpenAlert(true)
              }
            }
            })
            .catch((error) => {
              console.log("error " + error);
        });
      }
      else{
        await axios
          .post(SERVER_URL, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datosFormulario,
          })
          .then((response) => {       
            /*if(!isNaN(response.data.Id)){
              if(parseInt(response.data.Id) === parseInt(id)){                       
                  setOpenAlert(true)
              }
            }*/
            })
            .catch((error) => {
              console.log("error " + error);
        });
      }
  
  }


  return (
    <>    
      {!isLoading && (
      <>
        {/* FORMULARIO */}
        <div className={classes.root}>
            <FormProvider {...methods}>
              <form
                id='formulario-salida'
                onSubmit={handleSubmit((data) => {
                handleSubmitPost(data);
                })}
                className=""
              >
    
              <Grid className="borderFormulario" container spacing={1}> 
                  <FormFieldCombo propiedades={campos[0]} control={control} onChangeValue={handleChangeTipologia}/>
                  <FormFieldCombo propiedades={campos[1]} control={control} />
                  <FormFieldDate propiedades={campos[2]} control={control}/>
                  <FormFieldCombo propiedades={campos[3]} control={control}/>
                  <FormFieldCombo propiedades={campos[4]} control={control}/>
                  <FormFieldCombo propiedades={campos[5]} control={control}/>
                  <FormFieldTextArea propiedades={campos[6]} control={control}/>
                  <FormFieldFiles propiedades={campos[8]} control={control}/>
                  <FormFieldFileUpload propiedades={campos[7]} control={control} handleFicheros={handleFicheros}/>
              </Grid>
              </form>
              </FormProvider>
              <FormularioSalida listadotabla= {handleListadoTabla}/>
              <FormularioSalidaInicial listadoSalidas={listadoSalidas} ListadoSalidaFinal={handleRemoveElement} />
              {openAlert && 
              <AlertaMensaje 
                mensaje={"¡Parte guardado correctamente!"} 
                isOpen={openAlert} 
                tipoMensaje="success"
                cerrar={handleCloseAlert}
              />
              } 
        </div>  
      </>
      )}
        <div>
          {isLoading && 
            <div className={classes.linear}>
                  <LinearProgress />
            </div>
          }
        </div>
            </>
        )










  };

  export default FormularioMaterialSalidas;