import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useForm } from "react-hook-form";
import { StateContext } from '../../../../../context'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import {useOperacion} from './operacion-context'

import FormFieldSelectUncontrolled from '../../../../../components/Common/FormFieldSelectUncontrolled';
import FormFieldComboUncontrolled from '../../../../../components/Common/FormFieldComboUncontrolled';
import FormFieldTimeUncontrolled from '../../../../../components/Common/FormFieldTimeUncontrolled';
import FormFieldTextAreaUncontrolled from '../../../../../components/Common/FormFieldTextAreaUncontrolled';
import FormFieldNumberUncontrolled from '../../../../../components/Common/FormFieldNumberUncontrolled';

const useStyles = makeStyles((theme) => ({    
    paper: {
        width: "100%",
        padding: 10,
    },  
    celda:{
        padding: '0px !important',        
    },
    boton:{
        padding: 10
    },
    celdaDesplegable:{
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        paddingTop: '2px !important',
        paddingBottom: '2px !important',
        width: '25% !important'
    }

}));

  let propiedadesTipoMercancia = {    
    required: true,
    name: "tipoMercancia",
    disabled: false,
    label: "Tipo de mercancia",
    value: '',
    multiple: false,
    xs: 12,
    opciones: [{Id: 1, Etiqueta: "RODANTE"}, {Id: 2, Etiqueta: "VERTICAL"}],
    classes: 'mt-1'
  }

  let propiedadesHoraInicioRodante = {
    xs:12,
    required: false,
    label: "Hora inicio rodante",
    name: "horainiciorodante",
    type: "time",    
    value: ''
  }
  
  let propiedadesHoraFinRodante = {
    xs:12,
    required: false,
    label: "Hora fin rodante",
    name: "horafinrodante",
    type: "time",    
    value: ''
  }

  let propiedadesHorasExtraRodante = {
    xs:12,
    required: false,
    label: "Horas extra rodante",
    name: "horasextrarodante",
    type: "number",    
    value: 0
  }  
    
  let propiedadesClientes = {    
    required: true,
    name: "clientes",
    disabled: false,
    label: "Cliente",
    value: '',
    multiple: false,
    xs: 12,
    opciones: [],
    classes: 'mt-1'
  }

  let propiedadesRetrasosTDR = {    
    urlData: "dic/diccionarios/getlistadoretrasostdr",
    campoEtiqueta: "Etiqueta",
    campoDescripcion: "",
    required: false,
    name: "retrasostdr",
    disabled: false,
    label: "Retrasos",
    value: '',
    multiple: false,
    xs: 12,
    classes: 'mt-1'
  }

  let propiedadesHoraInicio = {
    xs:12,
    required: false,
    label: "Hora inicio retraso",
    name: "horainicio",
    type: "time",    
    value: ''
  }
  
  let propiedadesHoraFin = {
    xs:12,
    required: false,
    label: "Hora fin retraso",
    name: "horafin",
    type: "time",    
    value: ''
  }

  let propiedadesHorasExtra = {
    xs:12,
    required: false,
    label: "Horas extra retraso",
    name: "horasextra",
    type: "number",    
    value: 0
  }

  let propiedadesHoraInicio1 = {
    xs:12,
    required: false,
    label: "Hora inicio mano 1",
    name: "horainicio2",
    type: "time",    
    value: ''
  }
  
  let propiedadesHoraFin1 = {
    xs:12,
    required: false,
    label: "Hora fin mano 1",
    name: "horafin2",
    type: "time",    
    value: ''
  }

  let propiedadesHorasExtra1 = {
    xs:12,
    required: false,
    label: "Horas extra mano 1",
    name: "horasextra2",
    type: "number",    
    value: 0
  }

  let propiedadesHoraInicio2 = {
    xs:12,
    required: false,
    label: "Hora inicio mano 2",
    name: "horainicio2",
    type: "time",    
    value: ''
  }
  
  let propiedadesHoraFin2 = {
    xs:12,
    required: false,
    label: "Hora fin mano 2",
    name: "horafin2",
    type: "time",    
    value: ''
  }

  let propiedadesHorasExtra2 = {
    xs:12,
    required: false,
    label: "Horas extra mano 2",
    name: "horasextra2",
    type: "number",    
    value: 0
  }
  
  let propiedadesObservaciones = {
    xs:12, 
    required: false,
    label: "Observaciones",
    name: "observaciones",
    type: "textArea",    
    value: '',
    lineas: 3
  }

  let propiedadesNumManos = {
    xs:12,
    required: false,
    label: "Nº de manos",
    name: "nummanos",
    type: "number",    
    value: 1,
    min: 1,
    max: 2,
    step: 1,
    helperText: "El nº máximo de manos es 2"
  }

export default function FormularioTDROperacion({actualizaListado}) {
    
    const { state } = useContext(StateContext)
    const { documentosTDR, setDocumentosTDR } = useOperacion();
    const { operacion } = state
    const { id, modo } = useParams(); 
    const { control } = useForm();
    const classes = useStyles();     
    const [cliente, setCliente] = useState()     
    const [tipoOperativa, setTipoOperativa] = useState()
    const [numeroManos, setNumeroManos] = useState(1)

    const datosResumen = useRef({        
        Buque: '', Muelle: '', Fecha: '', 
        Jornada: '', HoraInicio: '', HoraFin: '', HorasExtra: 0,
        TipoMercancia: '', Carpeta: '', Cliente: '', Retraso:{}, Observaciones:'', HoraInicioRodante: '', HoraFinRodante: '', HorasExtraRodante: 0,
        HoraInicio1: '', HoraFin1: '', HorasExtra1: 0, HoraInicio2: '', HoraFin2: '', HorasExtra2: 0, numManos: 1
    });  

    const limpiaValoresCampos = () => {
      propiedadesTipoMercancia.value = ''
      propiedadesHoraInicioRodante.value = ''
      propiedadesHoraFinRodante.value = ''
      propiedadesHorasExtraRodante.value = ''
      propiedadesClientes.value = ''
      propiedadesRetrasosTDR.value = ''
      propiedadesHoraInicio.value = ''
      propiedadesHoraFin.value = ''
      propiedadesObservaciones.value = ''
      propiedadesHoraInicio.value = ''
      propiedadesHoraFin.value = ''
      propiedadesHorasExtra.value = ''
      propiedadesHoraInicio1.value = ''
      propiedadesHoraFin1.value = ''
      propiedadesHorasExtra1.value = ''
      propiedadesHoraInicio2.value = ''
      propiedadesHoraFin2.value = ''
      propiedadesHorasExtra2.value = ''
      propiedadesNumManos.value = ''
    }

    const limpiaValoresDatosResumen = () => {
      datosResumen.current.Buque = ''
      datosResumen.current.Muelle = ''
      datosResumen.current.Fecha = ''
      datosResumen.current.Jornada = ''
      datosResumen.current.HoraInicio = ''
      datosResumen.current.HoraFin = ''
      datosResumen.current.HorasExtra = 0
      datosResumen.current.TipoMercancia = ''
      datosResumen.current.Carpeta = ''
      datosResumen.current.Cliente = ''
      datosResumen.current.Retraso = {}
      datosResumen.current.Observaciones = ''
      datosResumen.current.HoraInicioRodante = ''
      datosResumen.current.HoraFinRodante = ''
      datosResumen.current.HorasExtraRodante = 0
      datosResumen.current.HoraInicio1 = ''
      datosResumen.current.HoraFin1 = ''
      datosResumen.current.HorasExtra1 = 0
      datosResumen.current.HoraInicio2 = ''
      datosResumen.current.HoraFin2 = ''
      datosResumen.current.HorasExtra2 = 0
      datosResumen.current.numManos = 0
    }

    useEffect(() => {     
      if(operacion){      
        let Clientes = [{Id: -1, Etiqueta: 'TODOS'}]
        operacion.CuentasCliente.forEach(cliente =>{
            Clientes.push({Id: cliente.Id, Etiqueta: cliente.Razon_Social, })
        })                
        propiedadesClientes.opciones = Clientes
        datosResumen.current.Buque = operacion.Buque[0] ? operacion.Buque[0].Nombre : ''
        datosResumen.current.Muelle = operacion.Localizacion[0] ? operacion.Localizacion[0].Etiqueta : ''
        datosResumen.current.Fecha = operacion.Fecha_Operativa
        datosResumen.current.Carpeta = operacion.carpetaPoseidon 
        if(propiedadesClientes.value)
            datosResumen.current.Cliente = propiedadesClientes.value
        
      } 

      return () => {
        limpiaValoresCampos();
        limpiaValoresDatosResumen();        
      }
    }, [operacion])

     
    const handleChangeComboClientes = (valor) => {      
        if(valor){
          let ClientesSinCodAgente = "";
          operacion.CuentasCliente.forEach(cliente =>{
            if( (cliente.CodAgente === "" && valor === cliente.Id) || (cliente.CodAgente === "" && valor === -1)){
               ClientesSinCodAgente += cliente.Razon_Social + " ";
            }
          })    
          if(ClientesSinCodAgente.length > 0 )
            alert(ClientesSinCodAgente + " no tiene(n) asigando elcampo CodAgente relacionado con Poseidón")
            datosResumen.current.Cliente = valor
            propiedadesClientes.value = valor
            setCliente(valor)  
        }        
    }
  
    const handleChangeComboRetrasos = (valor) => {      
        if(valor)
            datosResumen.current.Retraso = valor
    }
  
    const handleChangeHoraInicio = (nombre, valor) =>{
        if(valor)
            datosResumen.current.HoraInicio = valor
    }
  
    const handleChangeHoraFin = (nombre, valor) =>{
        if(valor)
            datosResumen.current.HoraFin = valor
    }    

    const handleChangeHoraInicio1 = (nombre, valor) =>{
      if(valor)
          datosResumen.current.HoraInicio1 = valor
    }

    const handleChangeHoraFin1 = (nombre, valor) =>{
      if(valor)
          datosResumen.current.HoraFin1 = valor
    }

    const handleChangeHorasExtra1 = (valor) => {    
      if(valor)
        datosResumen.current.HorasExtra1 = valor
    }

    const handleChangeHoraInicio2 = (nombre, valor) =>{
      if(valor)
          datosResumen.current.HoraInicio2 = valor
    }

    const handleChangeHoraFin2 = (nombre, valor) =>{
      if(valor)
        datosResumen.current.HoraFin2 = valor
    }

    const handleChangeHorasExtra2 = (valor) => {    
      if(valor)
        datosResumen.current.HorasExtra2 = valor
    }
  
    const handleChangeObservaciones = (valor) => {
      if(valor)
        datosResumen.current.Observaciones = valor
    }

    const handleChangeComboTipoMercancia = (valor) => {     
      if(valor){       
        propiedadesTipoMercancia.value = valor
        datosResumen.current.TipoMercancia = valor
        setTipoOperativa(valor)
      }
    }

    const handleChangeHoraInicioRodante = (nombre, valor) =>{
      if(valor)
          datosResumen.current.HoraInicioRodante = valor
   }

    const handleChangeHoraFinRodante = (nombre, valor) =>{    
        if(valor)
            datosResumen.current.HoraFinRodante = valor
    }

    const handleChangeHorasExtraRodante = (valor) => {    
      if(valor)
        datosResumen.current.HorasExtraRodante = valor
    }

    const handleChangeNumManos = (valor) => {
      if(valor){
        datosResumen.current.numManos = valor
        setNumeroManos(valor)
      }
    }
  
  const handleClick = async (e) => {
        
        let datos = datosResumen.current
        
        const user = JSON.parse(localStorage.getItem("user"));       
        datos.idOperacion = id
        datos.fechaOperacion = state.Fecha_Operativa
        const config = {
            headers: { Authorization: `Bearer ${user.access_token}` }
        };
        const bodyParameters = {
            datos: datos
        };
        
        let errores = "";
        if(datos.Cliente === "")
            errores += "Debe seleccionar al menos un cliente.\n"
        if(datos.Buque === "")
            errores += "La operativa no tiene buque asignado.\n"
        if (datos.Muelle === "")
            errores += "La operativa no tiene localización/muelle asignado.\n"
        
        if(errores){
            alert(errores)
            return;
        }

        let ClientesCodAgente = [];
        operacion.CuentasCliente.forEach(cliente =>{
          ClientesCodAgente.push(cliente.CodAgente);
        })  
        ClientesCodAgente = ClientesCodAgente.filter( (value, index) => ClientesCodAgente.indexOf(value) === index).sort();
        
        let ProductosCodAgente = [];
        operacion.listadoProductos.forEach(producto =>{
          ProductosCodAgente.push(producto.operador);
        })  
        ProductosCodAgente = ProductosCodAgente.filter( (value, index) => ProductosCodAgente.indexOf(value) === index).sort();

        //let difference = ClientesCodAgente.filter(x => !ProductosCodAgente.includes(x)).concat(ProductosCodAgente.filter(x => !ClientesCodAgente.includes(x))).filter(e => e).join(", ");
        let intersetctClientes = ClientesCodAgente.filter(x => !ProductosCodAgente.includes(x)).filter(e => e).join(", ");
        let intersectProductos = ProductosCodAgente.filter(x => !ClientesCodAgente.includes(x)).filter(e => e).join(", ");
        
        let mensajeAviso = "";
        if(intersetctClientes.length > 0)
          mensajeAviso += "Los siguientes agentes: '" + intersetctClientes + "' están incluídos como clientes en la operativa, pero no hay productos asignados a ellos.\n";

        if(intersectProductos.length > 0)
          mensajeAviso += "Para los siguientes agentes: '" + intersetctClientes + "' hay productos añadidos pero no están incluídos como clientes en la operativa.\n"

        if(mensajeAviso.length > 0)
          alert(mensajeAviso)
        
        
        try {          
            const respTdr = await axios.post(process.env.REACT_APP_API_URL + "operaciones/generatdr", bodyParameters, config); 
            if(respTdr.data){ 
              if(respTdr.data.length > 0){ 
                setDocumentosTDR(respTdr.data) 
              }   
            }else{
                //alert("El TDR que intenta emitir ya existe, debe eliminarlo para poder emitirlo.")
            }
        } catch (error) {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
            alert(error.response.data.message)
        }

             
  }
  
    return (   
        <>     
        {/* FORMULARIO PRINCIPAL */}
        <TableContainer component={Paper} className={classes.table}>
          <Table className={classes.table} aria-label="spanning table">
            <TableBody>
            <TableRow>
               
              <TableCell className={classes.celdaDesplegable} align="left">      
                <FormFieldSelectUncontrolled propiedades={propiedadesTipoMercancia} onChangeValue={handleChangeComboTipoMercancia}/>
              </TableCell>

              <TableCell className={classes.celdaDesplegable} align="left">      
                <FormFieldSelectUncontrolled propiedades={propiedadesClientes} onChangeValue={handleChangeComboClientes}/> 
              </TableCell>
              { tipoOperativa === 2 && (
              <TableCell className={classes.celdaDesplegable} align="left">  
                <FormFieldNumberUncontrolled propiedades={propiedadesNumManos} onChangeValue={handleChangeNumManos} />
              </TableCell>
              )}
              
            </TableRow>

            { tipoOperativa === 1 && (
              <TableRow>
                <TableCell className={classes.celdaDesplegable} align="left">      
                  <FormFieldTimeUncontrolled propiedades={propiedadesHoraInicioRodante} 
                  onChangeTime={handleChangeHoraInicioRodante} control={control}/>
                </TableCell>
                <TableCell className={classes.celdaDesplegable} align="left">      
                  <FormFieldTimeUncontrolled propiedades={propiedadesHoraFinRodante} 
                  onChangeTime={handleChangeHoraFinRodante}
                  />  
                </TableCell>                
                <TableCell className={classes.celdaDesplegable} align="left">  
                    <FormFieldNumberUncontrolled propiedades={propiedadesHorasExtraRodante} onChangeValue={handleChangeHorasExtraRodante} />
                </TableCell>  
                </TableRow>
              )}

              { tipoOperativa === 2 && numeroManos > 0 && (
                <TableRow>
                  <TableCell className={classes.celdaDesplegable} align="left">      
                    <FormFieldTimeUncontrolled propiedades={propiedadesHoraInicio1} 
                    onChangeTime={handleChangeHoraInicio1} control={control}/>
                  </TableCell>
                  <TableCell className={classes.celdaDesplegable} align="left">      
                    <FormFieldTimeUncontrolled propiedades={propiedadesHoraFin1} 
                    onChangeTime={handleChangeHoraFin1}
                    />  
                  </TableCell>
                  <TableCell className={classes.celdaDesplegable} align="left">  
                      <FormFieldNumberUncontrolled propiedades={propiedadesHorasExtra1} onChangeValue={handleChangeHorasExtra1} />
                  </TableCell>
                </TableRow>
              )}  

              { tipoOperativa === 2 && numeroManos > 1 && (
                <TableRow>
                  <TableCell className={classes.celdaDesplegable} align="left">      
                    <FormFieldTimeUncontrolled propiedades={propiedadesHoraInicio2} 
                    onChangeTime={handleChangeHoraInicio2} control={control}/>
                  </TableCell>
                  <TableCell className={classes.celdaDesplegable} align="left">      
                    <FormFieldTimeUncontrolled propiedades={propiedadesHoraFin2} 
                    onChangeTime={handleChangeHoraFin2}
                    />  
                  </TableCell>
                  <TableCell className={classes.celdaDesplegable} align="left">  
                      <FormFieldNumberUncontrolled propiedades={propiedadesHorasExtra2} onChangeValue={handleChangeHorasExtra2} />
                  </TableCell>
                </TableRow>
              )}  

            <TableRow>            
              <TableCell className={classes.celdaDesplegable} align="left">      
                <FormFieldComboUncontrolled propiedades={propiedadesRetrasosTDR} onChangeValue={handleChangeComboRetrasos} control={control}/>
              </TableCell>
              <TableCell className={classes.celdaDesplegable} align="left">      
                <FormFieldTimeUncontrolled propiedades={propiedadesHoraInicio} 
                onChangeTime={handleChangeHoraInicio} control={control}/>
              </TableCell>
              <TableCell className={classes.celdaDesplegable} align="left">      
                <FormFieldTimeUncontrolled propiedades={propiedadesHoraFin} 
                onChangeTime={handleChangeHoraFin}
                /> 
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.celdaDesplegable} colSpan={4} align="left">
                <FormFieldTextAreaUncontrolled propiedades={propiedadesObservaciones} onChangeValue={handleChangeObservaciones} />     
              </TableCell>
              </TableRow>   
              <TableRow>
              <TableCell className={classes.boton} colSpan={4} align="right"> 
                <button className="right MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation" disabled = {modo !== "W"} onClick={handleClick}>
                  EMITIR TDR
                </button>  
              </TableCell>
            </TableRow>   
            
            </TableBody>
          </Table>
        </TableContainer> 
             
        </>           
    );
}
