import React, { useState, useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldText from '../../../../../components/Common/FormFieldText'
import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import FormFieldNumber from '../../../../../components/Common/FormFieldNumber'
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import LinearProgress from "@material-ui/core/LinearProgress";

import { useStyles } from "../../../../../styles/styles";




const FormularioOperaciones = ({index, tipoForm}) => { 
  
  const [TesoreriaOperacion, setTesoreriaOperacion] = useState([]) 
  const [campos, setCampos] = useState([])
  const { id, modo } = useParams();
  //array para cargar los datos de los formularios correspondientes
  const [listadoVentas, setListadoVentas] = useState([])
 
  const [ficheros, setFicheros] = useState([])
  const [nombresFicheros, setNombresFicheros] = useState([])

  const [BaseImponible, setBaseImponible] = useState(0)


  const [IRPF, setIRPF] = useState(0)
  const [IGIC, setIGIC] = useState(0)

  const methods = useForm();
  
  const classes = useStyles();;
  const { control, handleSubmit} = useForm(); 
  //para el filtrado de categoria
  const [categoria, setCategoria] = useState(0);


  const [openAlert, setOpenAlert] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [IdOrigen, setIdOrigen]=useState("Operativa");  

  const [entidad, setEntidad] = useState("")

//variables para cambiar el origen
let url="";
let etiqueta="";

useEffect(()=>{
  if (typeof listadoVentas !== "undefined") { 
    if(Object.keys(listadoVentas).length > 0){
      setIsLoading(true)  
      campos[12].value = BaseImponible-(IRPF*BaseImponible/100)+(IGIC*BaseImponible/100);
      setIsLoading(false)  
    }
  }
},[IGIC,IRPF]) 

useEffect(()=>{
  if (typeof BaseImponible !== "undefined") { 
    if(Object.keys(listadoVentas).length > 0){
      setIsLoading(true)  
      campos[9].value =BaseImponible;
      campos[12].value = BaseImponible;
      setIsLoading(false)  
    }
  }
},[BaseImponible]) 


  const handleListadoTabla=(valor) => {
      setBaseImponible(BaseImponible+valor.precioTotal)
      setListadoVentas([...listadoVentas,valor])
  }

  const handleRemoveElement = (element) =>{
      setListadoVentas(element) 
  }

    
  const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert(false);
  };  

    
  const handleFicheros = (files, filenames) => {      
      setFicheros(files)
      setNombresFicheros(filenames)
  }
 
  const handleOperacion = (idO) =>{    
      setIdOrigen(idO)
  } 

    if (IdOrigen==="Operativa") {
      url="operaciones"
      etiqueta="Codigo"
    }
    if (IdOrigen==="Entrada") {
      url="almacenamientos/tiqueentrada"
      etiqueta="Codigo"
    }
    if (IdOrigen==="Salida") {
      url="almacenamientos/tiquesalida"
      etiqueta="Codigo"
    }
    
    const handleChangeIRPF=(valor) => {
      if (valor) {
        setIRPF(valor.Valor)
      }
      
    }
    const handleChangeIGIC=(valor) => {
      if (valor) {
        setIGIC(valor.Valor)
      }
    }

//esto es para rellenar la tabla con los productos 
    const handleChange=(valor) => {
          const fetchData = async () => {
            const respListadoVentas = await axios(process.env.REACT_APP_API_URL + url +"/"+ valor.Id);
            if (respListadoVentas.data) {              
              //setListadoVentas(respListadoVentas.data)
            }
          };
          fetchData();
      
    }


    const handleChangeCategoria=(valor) => {
      setCategoria(valor);
  }

  const handleChangeEntidad=(valor) => {
    if(valor){ setEntidad(valor.Entidad.replaceAll(" ", "_")) } 
    else{ setEntidad("") }}

    useEffect(() => {      
     setCampos([
       //0
        {
          urlData: "tesoreriacajas/getentidades",
          campoEtiqueta: "Entidad",
          required: true,
          name: "Entidad",
          disabled: false,
          label: "Entidad",
          type:'combo',
          value: '',
          multiple: false,
          xs: 6,
          onChangeValue:handleChangeEntidad
        },  
        //1      
        {
            urlData: "tesoreriacajas/getcuentas/todas",
            campoEtiqueta: "IBAN",
            required: true,
            name: "Cuenta",
            disabled: false,
            label: "Cuenta / Caja",
            type:'combo',
            value: '',
            multiple: false,
            xs: 6,
        },    
        //2    
        {            
            required: true,
            name: "Documento",
            disabled: false,
            label: "Documentos",
            type:'text',
            value: '',            
            xs: 4,
          },
       //3
        {
            urlData: "cuentas",
            campoEtiqueta: "Razon_Social",
            required: true,
            name: "Tercero",
            disabled: false,
            label: "Tercero",
            type:'combo',
            value: '',
            multiple: false,
            xs: 6,
          },
          //4
        {
            required: true,
            label: "Fecha Registro",
            name: "fechaRegistro",
            type: "date",    
            value: '',
            xs: 4
            },
            //5
          {
            required: true,
            name: "Importe",
            type:"number",
            label: "Importe con IGIC",
            value: "",
            xs: 6
        },
        //6
        {
            required: true,
            name: "Descripcion",
            label: "Descripcion",
            type: 'textarea',
            lineas:3,
            value: '',
            xs: 6,
        },
        //7
         {
            urlData: "tesoreriatipomovimientos",
            campoEtiqueta: "Etiqueta",
            required: true,
            name: "TipoMovimiento",
            disabled: false,
            label: "Movimiento",
            type:'combo',
            value: '',
            multiple: false,
            xs: 6,
        },
        //8
         {
            urlData: "tesoreriaconceptos",
            campoEtiqueta: "Etiqueta",
            required: true,
            name: "Concepto",
            disabled: false,
            label: "Concepto",
            type:'combo',
            value: '',
            multiple: false,
            xs: 6,
        },
        //9
         {
            urlData: "tesoreriacategorias/listadocategorias",
            campoEtiqueta: "Etiqueta",
            required: true,
            name: "Categoria",
            disabled: false,
            label: "Categoria",
            type:'combo',
            value: '',
            multiple: false,
            xs: 6,
        },
        //10
         {
            urlData: "tesoreriainstrumentos",
            campoEtiqueta: "Etiqueta",
            required: true,
            name: "Instrumento",
            disabled: false,
            label: "Instrumento",
            type:'combo',
            value: '',
            multiple: false,
            xs: 6,
        },
         //11
         {
            urlData: "tesoreriasubcategorias",
            campoEtiqueta: "Etiqueta",
            required: true,
            name: "Subcategoria",
            disabled: false,
            label: "Subcategoria",
            type:'combo',
            value: '',
            multiple: false,
            xs: 6,
        },
         //12
        {
            required: true,
            label: "Fecha Valor",
            name: "fechaValor",
            type: "date",    
            value: '',
            xs: 4
        },
        //13
        {
            required: true,
            name: "Igic",
            type:"number",
            label: "IGIC %",
            value: "",
            xs: 6,
        },
       //14
        {
            urlData: "dic/diccionarios/tesoreriatipologias",
            campoEtiqueta: "Etiqueta",
            required: true,
            name: "Tipologia",
            disabled: false,
            label: "Tipologia",
            type:'combo',
            value: '',
            multiple: false,
            xs: 6,
        },
        //15
        {
          required: true,
          name: "Observaciones",
          disabled: false,
          label: "Observaciones",
          value: '',
          type: 'textarea',
          lineas:3,
          xs: 6
        },
        //16
        {
          required: false,
          label: "Documentacion",
          name: "ficherosadjuntos",
          type: "filesupload",   
          tabla: "mtms_hoja_pedido_adjuntos",
          xs:6
        },
        //17
        {
          required: false,
          label: "Documentacion",
          name: "ficheros",
          type: "files", 
          ficheros:"",    
          tabla: "mtms_hoja_pedido_adjuntos",
          modelo: "api\\modules\\v1\\models\\TesoreriaOperacionesAdjuntos",
          carpeta: "tesoreriaoperacion",
          xs:12
        },
        //18
        {
          urlData: "dic/diccionarios/tesoreriaestados",
          campoEtiqueta: "Etiqueta",
          required: true,
          name: "Estado",
          disabled: false,
          label: "Estado",
          type:'combo',
          value: '',
          multiple: false,
          xs: 6,
      },
       
     ])

    const fetchData = async () => {
      setIsLoading(true) 
      if (modo === "W" || modo === "R") {
          const respTesoreriaOperacion = await axios(
              `${process.env.REACT_APP_API_URL}tesoreriaoperaciones/${id}`
          );      
          setTesoreriaOperacion(respTesoreriaOperacion.data)
      }
      setIsLoading(false);
      
  };    
  fetchData(); 
    
},[]);

 


//esta parte hace falta par cuando editas una operacion pendiente
useEffect(() => {
  if (typeof TesoreriaOperacion !== "undefined") { 
    if(Object.keys(TesoreriaOperacion).length > 0){
      setIsLoading(true)  
      campos[0].value = {"Entidad": TesoreriaOperacion.Entidad};
      campos[1].value = TesoreriaOperacion.CuentaBancaria;      
      campos[2].value = TesoreriaOperacion.Documento;
      campos[3].value = TesoreriaOperacion.Tercero;
      campos[4].value = TesoreriaOperacion.Fecha_Registro;
      campos[5].value = TesoreriaOperacion.Importe;           
      campos[6].value = TesoreriaOperacion.Descripcion;
      campos[7].value = TesoreriaOperacion.TipoMovimiento;
      campos[8].value = TesoreriaOperacion.Concepto;
      campos[9].value = TesoreriaOperacion.Categoria;
      campos[10].value = TesoreriaOperacion.Instrumento;
      campos[11].value = TesoreriaOperacion.Subcategoria;
      campos[12].value = TesoreriaOperacion.Fecha_Valor;
      campos[13].value = TesoreriaOperacion.Igic;
      campos[14].value = TesoreriaOperacion.Tipologia;
      campos[15].value = TesoreriaOperacion.Observaciones;
      //campos[16].ficheros = TesoreriaOperacion.ficheros;
      campos[17].ficheros = TesoreriaOperacion.ficheros;  
      campos[18].value = TesoreriaOperacion.Estado;
      setIsLoading(false)  
    }
  }
}, [TesoreriaOperacion])

const handleSubmitPost = async (datos) => {

  datos.ficheros=ficheros
  datos.nombresFicheros=nombresFicheros

  let user = JSON.parse(localStorage.getItem("user"));
  const AuthStr = "Bearer ".concat(user.access_token);
  const SERVER_URL = `${process.env.REACT_APP_API_URL}tesoreriaoperaciones`

    if (modo==="W") {
      await axios
        .put(`${SERVER_URL}/${id}`, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {       
          if(!isNaN(response.data)){
            if(parseInt(response.data) === parseInt(id)){                       
                setOpenAlert(true)
                setTimeout(function () {
                  window.location.reload();
                }, 500);
            }
          }
          })
          .catch((error) => {
            console.log("error " + error);
      });
    }
    else{
      await axios
        .post(SERVER_URL, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {       
          if(!isNaN(response.data)){
            //if(parseInt(response.data.Id) === parseInt(id)){                       
                setOpenAlert(true)
                setTimeout(function () {
                  window.location.reload();
              }, 500);
            //}
          }
          })
          .catch((error) => {
            console.log("error " + error);
      });
    }
}

if(Object.keys(campos).length > 0 ){
  if(entidad) { campos[1].urlData = `tesoreriacajas/getcuentas/${entidad}`; }
  else { campos[1].urlData = "tesoreriacajas/getcuentas/todas"; }
}

return (
<>    
  {!isLoading && (
  <>
    {/* FORMULARIO */}
    <div className={classes.root}>
     
      <FormProvider {...methods}>
        <form
          id='formulario-utilidades-Operaciones'
          onSubmit={handleSubmit((data) => {
          handleSubmitPost(data);
          })}
          className=""
        >
        <Grid className="borderFormulario" container spacing={1}>
          <FormFieldCombo propiedades={campos[0]} control={control}/>
          <FormFieldCombo propiedades={campos[1]} control={control} />
          <FormFieldText propiedades={campos[2]} control={control}/>
          <FormFieldDate propiedades={campos[4]} control={control}/>
          <FormFieldDate propiedades={campos[12]} control={control}/>


          <FormFieldCombo propiedades={campos[3]} control={control}/>
          <FormFieldNumber propiedades={campos[5]} control={control}/>
          <FormFieldCombo propiedades={campos[7]} control={control}/>
          <FormFieldCombo propiedades={campos[8]} control={control}/>
          <FormFieldCombo propiedades={campos[9]} control={control}/>
          <FormFieldCombo propiedades={campos[10]} control={control}/>
          <FormFieldCombo propiedades={campos[11]} control={control}/>
          
          <FormFieldNumber propiedades={campos[13]} control={control}/>
          <FormFieldCombo propiedades={campos[14]} control={control}/>
          <FormFieldCombo propiedades={campos[18]} control={control}/>
          <FormFieldTextArea propiedades={campos[6]} control={control}/>
          <FormFieldTextArea propiedades={campos[15]} control={control}/>

          <FormFieldFiles propiedades={campos[17]} control={control} ficheros={handleFicheros}/>
          <FormFieldFileUpload propiedades={campos[16]} control={control} handleFicheros={handleFicheros}/>              
        </Grid>        
        </form>
      </FormProvider>
          
          {openAlert && 
          <AlertaMensaje 
            mensaje={"Operación guardada correctamente!"} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
          } 
    </div>  
  </>
  )}
    <div>
      {isLoading && 
        <div className={classes.linear}>
              <LinearProgress />
        </div>
      }
    </div>
        </>
    )
};

export default FormularioOperaciones;