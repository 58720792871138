import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import { CircularProgress } from '@material-ui/core';
import FormularioProductos from "./FormularioProductos"
import FichaProductos from "./FichaProductos"
import MigasDePan  from "../../../../../components/Common/MigasDePan";
import SearchBar from "../../../../../components/Common/SearchBar";

const Portfolio = (props) => {
    let { id, modo } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [datos, setDatos] = useState();  

    useEffect(() => { 
        if (isLoading && (modo === "R" || modo === "W")) {           
          const fetchData = async () => {
            const respProducto = await axios( process.env.REACT_APP_API_URL + "productos/" + id);
            if (respProducto.data) {      
              setDatos(respProducto.data);
              setIsLoading(false);
            }
          };
          fetchData();
        }  
        if (!modo) {  
          setIsLoading(false);
        }
    }, [isLoading, datos, modo, id]);

    const botoneraAcciones = [    
        {
          etiqueta: modo === "R" ? "Editar Productos" : "Cancelar",
          url: 
          typeof id === "undefined" ? `/ventas/productos/` :
          modo === "R" ? 
            `/ventas/productos/portfolio/${id}/W` : 
            `/ventas/productos/portfolio/${id}/R`,
          activo: true,
        },
    ];

    const urlsMigasdepan = [
      {titulo: "Productos", colorTexto: "texto-comercial", urlTo: "/ventas/productos"},
      {titulo: "Producto", colorTexto: "texto-comercial", urlTo: `/ventas/productos/portfolio/${id}/${modo}`},
    ]

    const codigo = typeof datos !== "undefined" ? datos.Codigo : "";

    return (
        <>
          <div className="col-8 col-main-home ">
            <SearchBar
              titulo="Ventas"
              claseCabecera = "bg-produccion"
              buscar={false}
              imagenCabecera={"4-2-3-productos.png"}
            />
            {!isLoading && (
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                    <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/>
                      
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">   
                      {modo === "R" && 
                        <FichaProductos />}    
                      {modo !== "R" && 
                        <FormularioProductos />}   
                    </div>
                  </div>
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
            )}
            <div>{isLoading && <CircularProgress />}</div>
          </div>
        </>
      );
}
export default Portfolio;