import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";
import PasosCotizacion from "./PasosCotizacion"
import {useCotizacion} from './cotizacion-context'
import MigasDePan  from "../../../../../components/Common/MigasDePan";

const Cotizacion = (props) => {    
  let { id, modo } = useParams(); 

  const botoneraAcciones = [  
    /*  
        {
          etiqueta: "Crear Cotización",
          url: "/ventas/cotizaciones/cotizacion",            
          activo: true,
        },
    */
        {
          etiqueta: modo === "R" ? "Editar Cotización" : "Cancelar Edición",
          url: 
          typeof id === "undefined" ? `/ventas/cotizaciones/` :
          modo === "R" ? 
            `/ventas/cotizaciones/cotizacion/${id}/W` : 
            `/ventas/cotizaciones/cotizacion/${id}/R`,
          activo: true,
        },                    
  ]; 

  const urlsMigasdepan = [
    {titulo: "Cotizaciones", colorTexto: "texto-comercial", urlTo: "/ventas/cotizaciones"},
    {titulo: "Cotización", colorTexto: "texto-comercial", urlTo: `/ventas/cotizaciones/cotizacion/${id}/${modo}`},
  ]  

  const {
    datosCotizacion, setDatosCotizacion, setDatosDesplegables, setDatosFormulario, 
    setCostesDirectos, setDatosPresupuesto, setDatosJornadas, setManosCotizacion
  } = useCotizacion();

    
    useEffect(() => {      
      const fetchDataDesplegables = async () => {
        const respFormulario = await axios(
          process.env.REACT_APP_API_URL + "dic/diccionarios/formulariocrearpedidocliente"
        );         
        setDatosDesplegables({
          buques: respFormulario.data.buques,
          clientes: respFormulario.data.clientes,
          contactos: respFormulario.data.contactos,
          canales: respFormulario.data.canalSolicitud,
          muelles: respFormulario.data.localizaciones,
          operativas: respFormulario.data.tiposOperativas,            
          servicios: respFormulario.data.servicios,  
          categorias: respFormulario.data.categorias, 
        }); 
      }  
      fetchDataDesplegables()            
    },[]) 
  
    useEffect(() => {        
      if( (modo === "R" || modo === "W") && id > 0 ){        
        const fetchData = async () => {          
          const respPedido = await axios(
            `${process.env.REACT_APP_API_URL}cotizaciones/${id}`
          );          
          if (Object.keys(respPedido.data).length > 0) {    
            setDatosCotizacion(respPedido.data);                  
            setCostesDirectos(respPedido.data.costesDirectos)
            setDatosPresupuesto({
              Beneficio_Industrial: respPedido.data.Beneficio_Industrial,
              Costes_Indirectos: respPedido.data.Costes_Indirectos,
              Fecha_Presupuesto: respPedido.data.Fecha_Presupuesto,
              Descripcion: respPedido.data.Descripcion_Presupuesto,
              costesDirectos: respPedido.data.costesDirectos,
              precioportonelada: respPedido.data.PrecioPorTonelada,
              toneladaspresupuesto: respPedido.data.ToneladasPresupuesto,
              exclusiones: respPedido.data.Exclusiones,
            })            
            let notaPedido="";  
            if(respPedido.data.Fk_PedidoCliente.length > 0 ){
              notaPedido = respPedido.data.Fk_PedidoCliente[0].Codigo
            }            
            setDatosFormulario({
              Fecha_Solicitud: respPedido.data.Fecha_Solicitud,
              canal: respPedido.data.Fuente,
              Fk_Pedido_Cliente: respPedido.data.Id,
              cliente: respPedido.data.Cuenta,
              contacto: respPedido.data.Contacto,
              Referencia_Cliente: respPedido.data.Referencia_Cliente,
              Fecha_Operativa: respPedido.data.Fecha_Operativa,
              Fk_Localizacion: respPedido.data.Fk_Localizacion,
              Localizacion: respPedido.data.Localizacion,
              buque: respPedido.data.Buque,
              Descripcion: respPedido.data.Descripcion,              
              notapedido: notaPedido,           
              ficheros: respPedido.data.ficheros,
              Estado: respPedido.data.Estado,
              Cotizacion: respPedido.data.Cotizacion,
              OperativaMercancia: respPedido.data.OperativaMercancia,
              TotalAceptado: respPedido.data.TotalAceptado,
              TotalAceptadoTN: respPedido.data.TotalAceptadoTN
            })
          }
        }
        
        let respManosCotizacion;
        const fetchDataNumManos = async () => {                 
          respManosCotizacion = await axios(
            `${process.env.REACT_APP_API_URL}cotizaciones/manoscotizacion/${id}`
          )       
          if (Object.keys(respManosCotizacion.data).length > 0) {          
            let elementos = [];             
            respManosCotizacion.data.forEach(element => {            
              const fetchDataManos = async () => {                   
                const respRecursosManos = await axios(`${process.env.REACT_APP_API_URL}cotizacionesmanos/${element.Id}`);      
                if(respRecursosManos.data){   
                  respRecursosManos.data.recursos.forEach(element => {    
                    elementos.push({ elementos: element});          
                  });                  
                  setDatosJornadas( elementos );
                }
              }    
              fetchDataManos();
            })               
          }
          setManosCotizacion(respManosCotizacion.data);
        }     
         
        fetchData();     
        fetchDataNumManos(); 
      }   
    },[id])  
    
  const codCotizacion = datosCotizacion.Codigo
  return (
    <>        
      <div className="col-8 col-main-home ">            
        <SearchBar
          titulo="Ventas"
          claseCabecera = "bg-comercial"
          buscar={false}
          imagenCabecera={"4-2-2-cotizaciones.png"}
        />   
        <>
        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">
            <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codCotizacion}/>
              
            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
              <PasosCotizacion />
            </div>
          </div>
          <TableAcciones
            filaSeleccionada={-1}
            botonaccion={botoneraAcciones}
          />
        </div>
        </>       
      </div>     
    </>
  );
}

export default Cotizacion;