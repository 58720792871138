import React, { useState } from "react";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";



const FormularioAddRecursos = ({disabled, handleAddElemento}) => { 
    const [valores, setValores] = useState({
        tercero:'',
        recurso: '',
        cantidad: 0,
        coste: 0,
        total: 0
    })

    const handleClick = () => { 
        handleAddElemento(valores)
    }

    const handleValues = event => {        
        const { name, value } = event.target;
        
        let total = valores.coste*valores.cantidad

        if(name === "cantidad")
            total = value*valores.coste
        if(name === "coste")
            total = value*valores.cantidad

        setValores(prevState => ({
            ...prevState,
            [name]: value,
            total: total
        }));
    }

    return (
    <>  
    <Grid container spacing={0} className="mt-4 borderFormulario"> 
    <Grid item xs={12} className="mt-2" >OTROS GASTOS</Grid>
    
    <Grid item xs={12} className="mt-2" >        
        <TextField
            disabled = {disabled}
            required
            id="tercero"
            name="tercero"        
            label="Tercero"        
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleValues}
        >                                       
        </TextField>
    </Grid>

    <Grid item xs={12} className="mt-2" >
        <TextField
            disabled = {disabled}
            required
            id="recurso"
            name="recurso"        
            label="Recurso"        
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleValues}
        >                                       
        </TextField>
    </Grid>
    
    <Grid item xs={12} className="mt-2" >
        <TextField
            disabled = {disabled}
            required
            id="cantidad"
            name="cantidad"        
            label="Cantidad"        
            variant="outlined"
            size="small"
            type="number"
            fullWidth
            onChange={handleValues}
        >                                       
        </TextField>
    </Grid>

    <Grid item xs={12} className="mt-2" >
        <TextField
            disabled = {disabled}
            required
            id="coste"
            name="coste"        
            label="Coste (ud)"        
            variant="outlined"
            size="small"
            type="number"
            fullWidth
            onChange={handleValues}
        >                                       
        </TextField>
    </Grid>
    
    <Grid item xs={12} className="mt-2" >
        <TextField
            disabled = {true}            
            id="total"
            name="total"        
            label="Total"        
            variant="outlined"
            size="small"
            type="number"
            fullWidth
            value={valores.total}
            onChange={handleValues}
        >                                       
        </TextField>
    </Grid>
    
    <Grid item xs={12} className="mt-2" >
    <Button className="" variant="outlined" size="large" onClick={handleClick} disabled={disabled}>
          AÑADIR
          </Button> 
    </Grid>

    </Grid>  
    </>  
    )

}
export default FormularioAddRecursos;