import React, { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FiltroListado from "./FiltroListado";

const useStyles = makeStyles((theme) => ({    
       
    table: {
      minWidth: 700,
      marginTop: 30,
      marginBottom:30,
    },
    celda:{
      borderWidth: '0px',
      borderColor: '#000',
      borderStyle: 'solid',
      padding: '0px !important', 
      backgroundColor: 'white',
      fontSize: '8pt',
      color: '#505050', 
    },
    subcelda:{
        paddingLeft: '10px !important', 
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        backgroundColor: 'white',
        fontSize: '10pt',
        color: '#505050', 
      },
    celdaAlta:{
        borderWidth: '1px',
        borderColor: '#000',
        borderStyle: 'solid',
        padding: '0px !important',
        height: '10px',
        backgroundColor: 'white',
    },
    celdaAltaFechas:{
        /*borderWidth: '1px',*/
        borderColor: '#000',
        borderStyle: 'solid',
        padding: '0px !important',
        height: '10px',
        backgroundColor: 'white',
        fontSize: '12pt',
        color: '#505050', 
        borderTopWidth: '1px !important',
        borderLeftWidth: '0px !important',
        borderRightWidth: '0px !important',
        borderBottomWidth: '1px !important', 
        borderBottomStyle: 'solid !important',
        borderTopStyle: 'solid !important',     
    },
    celdaNegrita:{
      borderWidth: '1px',
      borderColor: '#000',
      borderStyle: 'solid',
      padding: '0px !important',
      fontWeight: 'bold',
      fontSize: '12pt',
      color: '#505050', 
    },
    textoNegrita: {fontWeight: 'bold'},
    textoRojo:{color: '#F00 !important'}
}));


const TablaPeriodificacion = ({urlFechas}) => {
    const classes = useStyles();

    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
   
    const zerofilledDay = ('00'+day).slice(-2);
    const zerofilledMonth = ('00'+month).slice(-2);

    const fechaFin = year + "-" + zerofilledMonth + "-" + zerofilledDay
    const fechaInicio = year + "-01-01"

    const urlInicio = "?fechainicial="+fechaInicio+"&fechafinal="+fechaFin
    
    const [cuentaExplotacionRequest, setCuentaExplotacionRequest] = useState({
        loading: true,
        datos: [],
        url: urlInicio,
        fechaInicio: fechaInicio,
        fechaFin: fechaFin
    })

    const { loading, url } = cuentaExplotacionRequest;

    useEffect(() => {
        
      if (loading) {
        const fetchData = async () => {     
          try{       
            const respPyG = await axios(process.env.REACT_APP_API_URL + "dic/diccionarios/tablaperiodificacion"+url);
           
            if (respPyG.data) {
                if(urlFechas){
                    
                    urlFechas(url)
                }
                setCuentaExplotacionRequest(prevCuentaExplotacion => {
                    return {
                        ...prevCuentaExplotacion,
                        loading: false,
                        datos: respPyG.data,
                    }
                })
            }
          }catch(e){
            setCuentaExplotacionRequest(prevCuentaExplotacion => {
                return {
                    ...prevCuentaExplotacion,
                    loading: false,
                    datos: null,
                    url: "",
                }
            })
           
          }
          //setIsLoading(false);
        };
        fetchData();
      }          
    }, [cuentaExplotacionRequest]); 

    const obtenCifra = (importe) => {
        var formatter = new Intl.NumberFormat('es-ES', {
            style: 'currency',
            currency: 'EUR',
          });
          
        if(importe) {
            return formatter.format(importe)
        }
        else return formatter.format(0); 
    }


    const handleUrlFecha = (valores) =>{  
        setCuentaExplotacionRequest(prevCuentaExplotacion => {
            return {
                ...prevCuentaExplotacion,
                loading: true,
                datos: null,
                url: valores.url,
                fechaInicio: valores.fechaInicio,
                fechaFin: valores.fechaFin
            }
        })
    }   
    
    let totales = {
        enero: 0,
        febrero: 0,
        marzo: 0,
        abril: 0,
        mayo: 0,
        junio: 0,
        julio: 0,
        agosto: 0,
        septiembre: 0,
        octubre: 0,
        noviembre: 0,
        diciembre: 0
    }

    
    if(cuentaExplotacionRequest.datos){
        cuentaExplotacionRequest.datos.forEach(element => {
           
            totales.enero += element.Enero;
            totales.febrero += element.Febrero;
            totales.marzo += element.Marzo;
            totales.abril += element.Abril;
            totales.mayo += element.Mayo;
            totales.junio += element.Junio;
            totales.julio += element.Julio;
            totales.agosto += element.Agosto;
            totales.septiembre += element.Septiembre;
            totales.octubre += element.Octubre;
            totales.noviembre += element.Noviembre;
            totales.diciembre += element.Diciembre;
        });
    }

    return (   
      <div className="mt-5">    
        {!loading && (
          <>
          <FiltroListado urlfecha={handleUrlFecha} fechaInicial={cuentaExplotacionRequest.fechaInicio} fechaFinal={cuentaExplotacionRequest.fechaFin}/>
          <TableContainer component={Paper}>
          <Table  className={classes.table} aria-label="spanning table">
              {/* CABECERA */}
              <TableHead>
                  <TableRow>
                      <TableCell className={classes.celdaAltaFechas} align="left" colSpan={1}>Proveedor</TableCell>
                      <TableCell className={classes.celdaAltaFechas} align="left" colSpan={1}>Nº Factura</TableCell>
                      <TableCell className={classes.celdaAltaFechas} align="right" colSpan={1}>Ene</TableCell>
                      <TableCell className={classes.celdaAltaFechas} align="right" colSpan={1}>Feb</TableCell>
                      <TableCell className={classes.celdaAltaFechas} align="right" colSpan={1}>Mar</TableCell>
                      <TableCell className={classes.celdaAltaFechas} align="right" colSpan={1}>Abr</TableCell>
                      <TableCell className={classes.celdaAltaFechas} align="right" colSpan={1}>May</TableCell>
                      <TableCell className={classes.celdaAltaFechas} align="right" colSpan={1}>Jun</TableCell>
                      <TableCell className={classes.celdaAltaFechas} align="right" colSpan={1}>Jul</TableCell>
                      <TableCell className={classes.celdaAltaFechas} align="right" colSpan={1}>Ago</TableCell>
                      <TableCell className={classes.celdaAltaFechas} align="right" colSpan={1}>Sep</TableCell>
                      <TableCell className={classes.celdaAltaFechas} align="right" colSpan={1}>Oct</TableCell>
                      <TableCell className={classes.celdaAltaFechas} align="right" colSpan={1}>Nov</TableCell>
                      <TableCell className={classes.celdaAltaFechas} align="right" colSpan={1}>Dic</TableCell>
                  </TableRow>
              </TableHead>

        {/* CUERPO rowSpan={2} colSpan={3}*/}
        <TableBody id="tablaPeriodificacion">
            {cuentaExplotacionRequest.datos && 
                cuentaExplotacionRequest.datos.map((elemento, index) => (
                <React.Fragment key={index}>
                <TableRow > 
                    <TableCell className={`${classes.celda} `} align="left" colSpan={1}>{elemento.Proveedor}</TableCell>
                    <TableCell className={`${classes.celda} `} align="left" colSpan={1}>{elemento.NumFactura}</TableCell>
                    <TableCell className={`${classes.celda} `} align="right" colSpan={1}>{obtenCifra(elemento.Enero)}</TableCell>
                    <TableCell className={`${classes.celda} `} align="right" colSpan={1}>{obtenCifra(elemento.Febrero)}</TableCell>
                    <TableCell className={`${classes.celda} `} align="right" colSpan={1}>{obtenCifra(elemento.Marzo)}</TableCell>
                    <TableCell className={`${classes.celda} `} align="right" colSpan={1}>{obtenCifra(elemento.Abril)}</TableCell>
                    <TableCell className={`${classes.celda} `} align="right" colSpan={1}>{obtenCifra(elemento.Mayo)}</TableCell>
                    <TableCell className={`${classes.celda} `} align="right" colSpan={1}>{obtenCifra(elemento.Junio)}</TableCell>
                    <TableCell className={`${classes.celda} `} align="right" colSpan={1}>{obtenCifra(elemento.Julio)}</TableCell>
                    <TableCell className={`${classes.celda} `} align="right" colSpan={1}>{obtenCifra(elemento.Agosto)}</TableCell>
                    <TableCell className={`${classes.celda} `} align="right" colSpan={1}>{obtenCifra(elemento.Septiembre)}</TableCell>
                    <TableCell className={`${classes.celda} `} align="right" colSpan={1}>{obtenCifra(elemento.Octubre)}</TableCell>
                    <TableCell className={`${classes.celda} }`} align="right" colSpan={1}>{obtenCifra(elemento.Noviembre)}</TableCell>
                    <TableCell className={`${classes.celda} `} align="right" colSpan={1}>{obtenCifra(elemento.Diciembre)}</TableCell>
                </TableRow>
                <TableRow><TableCell className={classes.celdaAlta} align="left" colSpan={15}></TableCell></TableRow>
                </React.Fragment>
            ))}
            <TableRow>
            <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>TOTALES</TableCell>
            <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="right" colSpan={1}>{obtenCifra(totales.enero)}</TableCell>
            <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="right" colSpan={1}>{obtenCifra(totales.febrero)}</TableCell>
            <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="right" colSpan={1}>{obtenCifra(totales.marzo)}</TableCell>
            <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="right" colSpan={1}>{obtenCifra(totales.abril)}</TableCell>
            <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="right" colSpan={1}>{obtenCifra(totales.mayo)}</TableCell>
            <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="right" colSpan={1}>{obtenCifra(totales.junio)}</TableCell>
            <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="right" colSpan={1}>{obtenCifra(totales.julio)}</TableCell>
            <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="right" colSpan={1}>{obtenCifra(totales.agosto)}</TableCell>
            <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="right" colSpan={1}>{obtenCifra(totales.septiembre)}</TableCell>
            <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="right" colSpan={1}>{obtenCifra(totales.octubre)}</TableCell>
            <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="right" colSpan={1}>{obtenCifra(totales.noviembre)}</TableCell>
            <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="right" colSpan={1}>{obtenCifra(totales.diciembre)}</TableCell>
            </TableRow>

      </TableBody>
        </Table>
            </TableContainer>
            </>
        )}
        {loading && (
          <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} /> 
        )} 
      </div>   
    )
}

export default TablaPeriodificacion;