import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";

import SearchBar from "./SearchBar";
import TableAcciones from "./TableAcciones";
import { CircularProgress } from '@material-ui/core';
import "semantic-ui-css/semantic.min.css";
import MUIDataTable from "mui-datatables";
import {TEXT_LABELS} from "../../configuraciones/conf"
import { botoneraAlmacen } from "../../containers/views/Main/Produccion/Operativas/BotonesConf";
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

let theme = createMuiTheme({
  overrides: {        
    MUIDataTableSelectCell: {
      root: {
        display: 'none'
      }
    },
    MUIDataTableToolbarSelect: {
      title: {
        display: 'none'
      }
    }
  }
});

const CuerpoTabla = ({
    titulo, 
    columnas, 
    urlDatos,

    }) => {     

    const [datos, setDatos] = useState([]); 
    const history = useHistory(); 
    const [isLoading, setIsLoading] = useState(false)


    const OPTIONS_COLUMNS = {
        filterType: 'checkbox',
        print: false,
        download: false,
        pagination: false,
        selectableRows: 'single',  
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        elevation:0,
        responsive: "vertical",
        setRowProps: (item, idx) => ({
          onDoubleClick: () => {      
          }
        }),
        textLabels: TEXT_LABELS
    };    

    useEffect(() => {
      setIsLoading(true)
      const getData = async () => {
        const fetchData = async () => {
          const respEmpresas = await axios(process.env.REACT_APP_API_URL + urlDatos);
          setDatos(respEmpresas.data); 
          
          setIsLoading(false)
        };
        fetchData();
      };
      getData();
    }, [urlDatos]);    

    return (
        <>                 
            <div className="bg-white p-10px">
              {/*
              <div className="p-1 bg-white mb-4">
                  <h6 className="texto-comercial" id="title">
                    {titulo}
                  </h6>                  
              </div>
              */}
              <div className="bg-white   overflow-auto overflow-hiddens table-responsive">
                { !isLoading &&
                  ( 
                    <ThemeProvider theme={theme}>
                  <MUIDataTable
                    title={""}
                    className={"mt-1 mb-2"}
                    data={datos}
                    columns={columnas}
                    options={OPTIONS_COLUMNS}
                  /></ThemeProvider>   )}
                  { isLoading &&
                    <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />
                  }                  
              </div>
            </div>
     
        </>
    )    
}

export default CuerpoTabla;