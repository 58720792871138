import React, { useState, useEffect, useRef } from "react";
import Grid from '@material-ui/core/Grid';

import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import FormFieldSwitch from '../../../../../components/Common/FormFieldSwitch'

const FormularioFacturaRecurrenteDatos = ({datosFactura, changeCliente, changeFicheros, control}) => {  
    
    const hayCliente = useRef(false);
    const Cliente = useRef({});
    const [esPlantilla, setEsPlantilla] = useState(Object.keys(datosFactura).length > 0 && datosFactura.plantilla === 1 ? true : false)
    const [IdOrigen, setIdOrigen]=useState("Operativa");

    const handleChangeCliente=(valor)=>{
        hayCliente.current = valor === null ? false : true   
        Cliente.current = valor     
        changeCliente(valor)   
    }
      
    const handleFicheros = (files, filenames) => {  
        changeFicheros(files, filenames)        
    }  

    const handleChangeEsPlantilla = (valor) => {  
        setEsPlantilla(valor) 
    }

    const handleChangeCop = (valor) => {
    }

    const valoresFactura = Object.keys(datosFactura).length > 0 || (Cliente.current && Object.keys(Cliente.current).length > 0)        
    hayCliente.current = valoresFactura ? true : false 

    let fechaFactura = "";
    if(valoresFactura)  
    {
        if (datosFactura.plantilla) 
            fechaFactura = datosFactura.FechaFactura
        else{
            fechaFactura = datosFactura.Codigo ? datosFactura.FechaFactura : ''
            //handleChangeCliente(datosFactura.Cliente)
        }
        if(Object.keys(datosFactura).length > 0)
            handleChangeCliente(datosFactura.Cliente)
    }
   
    let campos = [        
       //0
        {
            urlData: "cuentas/clientesrecurrentes",
            campoEtiqueta: "Nombre_Comercial",
            required: true,
            name: "Cliente",
            disabled: false,
            label: "Cliente",
            type:'combo',
            value: valoresFactura ? datosFactura.Cliente : "",
            multiple: false,
            xs: 4,
            onChangeValue: handleChangeCliente
        },
        //1
        {
            required: true,
            label: "Fecha Factura",
            name: "fechaFactura",
            type: "date",  
            disabled: !hayCliente.current,  
            value: valoresFactura ? fechaFactura : '', //datosFactura.FechaFactura : "",
            xs: 2
        },
        //2
        {
            urlData: "dic/diccionarios/facturaventasestados",
            campoEtiqueta: "Etiqueta",
            required: true,
            name: "Estado",
            disabled: !hayCliente.current,
            type:'combo',
            label: "Estado",
            value: valoresFactura ? datosFactura.Estado : "",
            multiple: false,
            xs: 3
        },
        //3   
        {
            required: false,
            disabled: !hayCliente.current,
            label: "Plantilla",
            name: "plantilla",
            type: "switch",    
            value: esPlantilla,
            xs:4           
        },  
        //4
        {
            urlData: "dic/diccionarios/facturaventasperiodicidad",
            campoEtiqueta: "Etiqueta",
            required: true,
            name: "Periodicidad",
            disabled: !esPlantilla,
            type:'combo',
            label: "Periodicidad",
            value: valoresFactura ? datosFactura.Periodicidad : "",
            multiple: false,
            xs: 2
        }, 
        //5
        {
            required: true,
            label: "Inicio periodo",
            name: "inicioPeriodo",
            type: "date", 
            disabled: !esPlantilla,   
            value: valoresFactura ? datosFactura.inicioPeriodo : "",
            xs: 3
        },
        //6
        {
            required: true,
            label: "Fin periodo",
            name: "finPeriodo",
            type: "date", 
            disabled: !esPlantilla,   
            value: valoresFactura ? datosFactura.finPeriodo : "",
            xs: 3
        },
        //7
        {
            required: false,
            name: "observaciones",
            disabled: !hayCliente.current,
            label: "Observaciones",
            value: valoresFactura ? datosFactura.Observaciones : "",
            type: 'textarea',
            lineas:3,
            xs: 6
        },
        //8
        {
            required: false,
            name: "textoFactura",
            disabled: !hayCliente.current,
            label: "Texto para factura",
            value: valoresFactura ? datosFactura.textoFactura : "",
            type: 'textarea',
            lineas:3,
            xs: 6
        },
        //9
        {
            required: false,
            label: "Documentacion",
            name: "ficherosadjuntos",
            type: "filesupload",   
            tabla: "mtms_hoja_pedido_adjuntos",
            ficheros: valoresFactura ? datosFactura.ficheros : "",
            xs:6
        },
        //10
        {
            required: false,
            label: "Documentacion",
            name: "ficheros",
            type: "files", 
            ficheros: valoresFactura ? datosFactura.ficheros : "",   
            tabla: "mtms_hoja_pedido_adjuntos",
            modelo: "api\\modules\\v1\\models\\facturaventa\\FacturaRecurrenteAdjuntos",
            carpeta: "FacturaRecurrente",
            xs:12
        },   
        //11
        {
            urlData: hayCliente.current ? `cuentas/listadooperaciones/${Cliente.current.Id}` : "cuentas/listadooperaciones/0",
            campoEtiqueta: "Codigo",
            required: false,
            name: "cop",
            label: "COP - Origen",
            disabled: !hayCliente.current,
            type:'combo',
            value: valoresFactura ? datosFactura.cop : "",
            multiple: false,
            xs: 3,
            onChangeValue: handleChangeCop
        },

    ]    
    
    const numCampos = Object.keys(campos).length 
    return (
    <>
    { numCampos > 0 && (
    <Grid className="borderFormulario" container spacing={1}>         
        <FormFieldCombo propiedades={campos[0]} control={control}/>
        <FormFieldDate propiedades={campos[1]} control={control}/>   
        <FormFieldCombo propiedades={campos[11]} control={control}/>       
        <FormFieldCombo propiedades={campos[2]} control={control}/> 

        <FormFieldSwitch propiedades={campos[3]} control={control} onChangeValue={handleChangeEsPlantilla}/>
        <FormFieldCombo propiedades={campos[4]} control={control}/>
        <FormFieldDate propiedades={campos[5]} control={control}/> 
        <FormFieldDate propiedades={campos[6]} control={control}/> 
        
        <FormFieldTextArea propiedades={campos[7]} control={control}/>
        <FormFieldTextArea propiedades={campos[8]} control={control}/>

        <FormFieldFiles propiedades={campos[10]} control={control} /*ficheros={ficheros}*//>
        <FormFieldFileUpload propiedades={campos[9]} control={control} handleFicheros={handleFicheros}/>                
    </Grid>    
    )}
    </>
    )
}

export default FormularioFacturaRecurrenteDatos;