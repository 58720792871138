import React, { useState, useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import FormFieldText from '../../../../../components/Common/FormFieldText'


import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import LinearProgress from "@material-ui/core/LinearProgress";

import FormFieldCombo from "../../../../../components/Common/FormFieldCombo";
import { useStyles } from "../../../../../styles/styles";



const FormularioInstrumentos = ({index, tipoForm}) => { 
 
  
  const [Instrumentos, setInstrumentos] = useState([]) 
  const [campos, setCampos] = useState([])
  const { id, modo } = useParams();

  const methods = useForm();
  
  const classes = useStyles();;
  const { control, handleSubmit} = useForm(); 


  const [openAlert, setOpenAlert] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);

    
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert(false);
    };  




    useEffect(() => { 

      setCampos([
        {
            required: true,
            name: "Codigo",
            disabled: false,
            label: "Codigo",
            type:'text',
            value: '',
            xs: 4,
          },
        {
            required: true,
            name: "Instrumento",
            disabled: false,
            label: "Instrumento",
            type:'text',
            value: '',
            xs: 4,
        },
        {
            urlData:"tesoreriatipomovimientos",
            etiqueta:"Etiqueta",
            required: true,
            name: "movimiento",
            disabled: false,
            label: "Tipo de movimiento",
            type:'combo',
            value: '',
            xs: 4,
          },
       
     ])
     

    const fetchData = async () => {
      setIsLoading(true) 
      if (modo === "W") {

          const resCajasBancos = await axios(
              //cambiar url cuando este el endpoint
              `${process.env.REACT_APP_API_URL}tesoreriainstrumentos/${id}`
          );                
          setInstrumentos(resCajasBancos.data)
          
      }
      setIsLoading(false);

  };    
  fetchData(); 
    
},[]);




   


//esta parte hace falta par cuando editas una operacion pendiente
useEffect(() => {
  if (typeof Instrumentos !== "undefined") { 
    if(Object.keys(Instrumentos).length > 0){
      setIsLoading(true)  
      campos[0].value = Instrumentos.Codigo;
      campos[1].value = Instrumentos.Etiqueta;
      campos[2].value = Instrumentos.TipoMovimiento;    
      setIsLoading(false) 
    }
  }
}, [Instrumentos])



const handleSubmitPost = async (datos) => {
 
  //cambiar los nombres de los campos dependiendo de como los mandes en el controlador
  datos.Codigo = datos.Codigo
  datos.Concepto = datos.Concepto

  let user = JSON.parse(localStorage.getItem("user"));
  const AuthStr = "Bearer ".concat(user.access_token);
  //cambiar el endpoint cuando este
  const SERVER_URL = `${process.env.REACT_APP_API_URL}tesoreriainstrumentos`

    if (modo==="W") {
      await axios
        .put(`${SERVER_URL}/${id}`, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {       
          if(!isNaN(response.data.Id)){
            if(parseInt(response.data.Id) === parseInt(id)){                       
                setOpenAlert(true)
            }
          }
          })
          .catch((error) => {
            console.log("error " + error);
      });
    }
    else{
      await axios
        .post(SERVER_URL, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {       
          /*if(!isNaN(response.data.Id)){
            if(parseInt(response.data.Id) === parseInt(id)){                       
                setOpenAlert(true)
            }
          }*/
          })
          .catch((error) => {
            console.log("error " + error);
      });
    }



}
  


return (
<>    
  {!isLoading && (
  <>
    {/* FORMULARIO */}
    <div className={classes.root}>
        <FormProvider {...methods}>
          <form
            id='formulario-Operaciones'
            onSubmit={handleSubmit((data) => {
            handleSubmitPost(data);
            })}
            className=""
          >
          <Grid className="borderFormulario" container spacing={1}> 
              
              <FormFieldText propiedades={campos[0]} control={control}/>
              <FormFieldText propiedades={campos[1]} control={control} />
              <FormFieldCombo propiedades={campos[2]} control={control}/>
          </Grid>        
          </form>
          </FormProvider>
          {openAlert && 
          <AlertaMensaje 
            mensaje={"¡Parte guardado correctamente!"} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
          }  
    </div>  
  </>
  )}
    <div>
      {isLoading && 
        <div className={classes.linear}>
              <LinearProgress />
        </div>
      }
    </div>
        </>
    )
};

export default FormularioInstrumentos;