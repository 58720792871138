import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from '@material-ui/core/Grid';

import FiltroListado from "../../CMO/FiltroListado";
import SearchBar from "../../../../../components/Common/SearchBar";
import RentabilidadTotal from "./RentabilidadTotal";
import RentabilidadXCategorias from "./RentabilidadXCategorias";
import RentabilidadXClientes from "./RentabilidadXClientes";
import TableAcciones from "../../../../../components/Common/TableAcciones";

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [year, month, day].join('-');
}
  
let date = new Date();
let firstDay = new Date(date.getFullYear(), 0, 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
firstDay = formatDate(firstDay);
lastDay = formatDate(lastDay);

const Ventas = () => {

    const [esDirectivo, setEsDirectivo]=useState(false); 
    const [url, setUrl] = useState(`?fechainicial=${firstDay}&fechafinal=${lastDay}`)

    let botoneraPresupuesto = [{
        etiqueta: "Imprimir",
        function: (e) => handlePrintReport(), 
        activo: true
    },]


    const handlePrintReport = () => { 
        
        //setIsLoading(true);
        const fetchData = async () => {
            try{
                const respdatos = await axios(`${process.env.REACT_APP_API_URL}informes/imprimeinformerentabilidad${url}`) 
                   
            if (respdatos.data) {
              
                if(respdatos.data.includes(".pdf"))
                    window.open(respdatos.data, "_blank")                
                //setIsLoading(false);
            }
            }catch(error){
                console.log(error)
            }
            };
            fetchData();      
    } 

    useEffect(() => { 
        const fetchData = async () => {  
            const user = JSON.parse(localStorage.getItem("user"));
            const respdatos = await axios(`${process.env.REACT_APP_API_URL}rrhhdatoslaborales/${user.id}`);   
            
            const datos = respdatos.data
            if(datos && datos.Puesto_Trabajo && datos.Puesto_Trabajo.Etiqueta && datos.Puesto_Trabajo.Etiqueta === "DIRECTIVO"){
                setEsDirectivo(true)
            }         
        };    
    fetchData();    
    }, [])

    const handleUrlFecha = (url) =>{        
        setUrl(url)     
    }
    
  return (          
    <div className="col-main-home ">      
    <SearchBar
        titulo="INFORME DE VENTAS"
        claseCabecera = "bg-comercial"
        buscar={false}
        imagenCabecera={"1-1-cuadro-de-mandos.png"}  
    />   

      <div className="bg-cuerpo p-10px">
        <div className="bg-white p-10px">   
          <div className="principal bg-cuerpo cuerpo-h overflow-auto overflow-hiddens table-responsive mx-auto">          

            {!esDirectivo && (<><h1>SIN ACCESO</h1></>)}

            {esDirectivo && (
                <>
                <FiltroListado urlfecha={handleUrlFecha} firstDay={firstDay} lastDay={lastDay} showPrintButton={false} />
                {/*
                <Grid container spacing={0}>
                    <Grid item xs={6} className="">
                        <RentabilidadTotal titulo={'Rentabilidad Bruta'} tituloCostes={'Costes Directos'} tituloMargen={'Margen Bruto'} urlInforme={'rentabilidadbruta'} filtroFechas={url} />
                    </Grid>
                    <Grid item xs={6} className="">
                        <RentabilidadTotal titulo={'Rentabilidad Neta'} tituloCostes={'Costes Totales'} tituloMargen={'Margen Neto'} urlInforme={'rentabilidadneta'} filtroFechas={url} />
                    </Grid>
                </Grid>
                */}

                <Grid container spacing={0}>
                    <Grid item xs={6} className="">
                        <RentabilidadXCategorias filtroFechas={url} />
                    </Grid>
                    <Grid item xs={6} className="">
                        <RentabilidadXClientes filtroFechas={url} />
                    </Grid>
                </Grid>                
                </>
            )}
          </div> 
        </div>

        <div className="bg-cuerpo">
          <div className="bg-white">
            <TableAcciones botonaccion={botoneraPresupuesto} />
          </div>

        </div>

      </div>
    </div> 
  );
}
export default Ventas;

 