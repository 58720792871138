import React, { useState, useEffect, useRef  } from 'react';
import axios from "axios";

import MUIDataTable from "mui-datatables";
import { TEXT_LABELS} from "../../../../../configuraciones/conf"
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import FormFieldFiles from "../../../../../components/Common/FormFieldFiles";
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import LinearProgress from "@material-ui/core/LinearProgress";
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";

import { useStyles } from '../../../../../styles/styles';

const OPTIONS_PINS = { 
  filter: false,
  print: false,
  download: false,
  pagination: false,
  selectableRows: 'single', 
  search: false,
  viewColumns: false,  
  expandableRows: true,
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
  expandableRowsHeader: false,
  expandableRowsOnClick: true,
  elevation:0,
  isRowExpandable: (dataIndex, expandedRows) => {
    // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
   
    if (expandedRows.data.length > 4 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0) return false;
    return true;
  },
  renderExpandableRow: (rowData, rowMeta) => {
    
    const colSpan = rowData.length; 
    return (
      <>
      <TableRow>
        <TableCell colSpan={2}>
          Cargo: {rowData[7]}
        </TableCell>
        <TableCell colSpan={2}>
          Seguro: {rowData[8]}
        </TableCell>
        <TableCell colSpan={2}>
          Fecha PIN: {rowData[9]}
        </TableCell>
        <TableCell colSpan={2}>
          Factura: {rowData[10]}
        </TableCell>
        <TableCell colSpan={2}>
          Expediente: {rowData[11]}
        </TableCell>        
      </TableRow>
      <TableRow>
      <TableCell colSpan={3}>
          Observaciones: {rowData[12]}
        </TableCell>
        <TableCell colSpan={3}>
          Solución: {rowData[13]}
        </TableCell>        
      </TableRow>
      <TableRow>
       <TableCell colSpan={colSpan}>
       <FormFieldFiles propiedades={{tabla:"", xs:6, ficheros:rowData[14]}}/>
        </TableCell>
      </TableRow>
      </>
    );
  },
  textLabels: TEXT_LABELS

};


function convertDate(inputFormat) {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat)
  return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
}

export default function ListadoPINOperacion({actualizarListado}) {
    
    const [listadoPin, setListadoPin] = useState([])

    const [isLoading, setIsLoading] = useState(false);
    const entidadEliminar = useRef(-1);
    const [abrirDialogo, setAbrirDialogo] = useState(false)

    const classes = useStyles();
   
    const COLUMNS_PINS = [    
      {
        name: "Numero_Pin",
        label: "Nº PIN",
        options: {     
        sort: true,
        }
      },
        {
          name: "Carpeta_Poseidon",
          label: "Carpeta Poseidón",
          options: {
            sort: true,
          }
        },    
        {
          name: "Cliente",
          label: "Cliente",
          options: {
          sort: true,     
          }
        },
        {
        name: "Fecha_Operacion",
        label: "Fecha Operacion",
        options: {
          sort: true,
        }
        },
        {
          name: "Empleado",
          label: "Empleado",
          options: {
          sort: true,
          }
        }, 
        {
          name: "Identificacion",
          label: "Identificación",
          options: {
          sort: true,
          }
        }, 
        {
          name: "Area",
          label: "Área",
          options: {
          sort: true,
          }
        },  
        {
          name: "Cargo",
          label: "Cargo",
          options: {
          sort: true,
          display: 'excluded'
          },
        },   
        {
          name: "Seguro",
          label: "Seguro",
          options: {
          sort: true,
          display: 'excluded'
          },
        },  
        {
          name: "Fecha_Pin",
          label: "Fecha PIN",
          options: {
          sort: true,
          display: 'excluded'
          },
        }, 
        {
          name: "Factura",
          label: "Factura",
          options: {
          sort: true,
          display: 'excluded'
          },
        },  
        {
          name: "Expediente",
          label: "Expediente",
          options: {
          sort: true,
          display: 'excluded'
          },
        }, 
        {
          name: "Observaciones",
          label: "Observaciones",
          options: {
          sort: true,
          display: 'excluded'
          },
        }, 
        {
          name: "Solucion",
          label: "Solucion",
          options: {
          sort: true,
          display: 'excluded'
          },
        }, 
        {
          name: "Adjuntos",
          label: "Adjuntos",
          options: {
          sort: true,
          display: 'excluded'
          },
        },
        {
          name: "Id",
          label: "Id",
          options: {  
          display: 'excluded'
          }
        },
        {
          name: "Eliminar",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => ( 
              <Tooltip title={"Editar"}>
                <IconButton className="" onClick={() => handleClickDelete(tableMeta.tableData[tableMeta.rowIndex])}>
                  <DeleteIcon className=""/>
                </IconButton>
              </Tooltip>                 
            )
          }
        }
    ];

    const handleClickDelete = (valor) => {
      entidadEliminar.current = valor.Id       
      setAbrirDialogo(true)
    };

    

    useEffect(() => { 
      const fetchData = async () => {     
        const respListadoPins = await axios(
          `${process.env.REACT_APP_API_URL}operacionespins`
        );    

        let listadoAux = []
        respListadoPins.data.map(listadoPin => {
          
          listadoAux.push({          
            Numero_Pin: listadoPin.Numero_Pin,
            Carpeta_Poseidon: listadoPin.Carpeta_Poseidon,
            Cliente: listadoPin['Cuenta'] ? listadoPin['Cuenta'][0]['Razon_Social'] : '',
            Fecha_Operacion: listadoPin.Fecha_Operacion ? convertDate(listadoPin.Fecha_Operacion) : '',
            Empleado: listadoPin['Empleado'].length > 0 ? listadoPin['Empleado'][0]['nombre'] : '',
            Identificacion: listadoPin['Identificador'].length > 0 ? listadoPin['Identificador'][0]['Etiqueta'] : '',
            Area: listadoPin['Area'].length > 0 ? listadoPin['Area'][0]['Etiqueta'] : '',
            Cargo: listadoPin['Cargo'],
            Seguro: listadoPin['Seguro'],
            Fecha_Pin: convertDate(listadoPin.Fecha_Pin),
            Factura: listadoPin['Numero_Factura'],
            Expediente: listadoPin['Numero_Expediente'],
            Observaciones: listadoPin['Observaciones'],
            Solucion: listadoPin['Solucion'],
            Adjuntos: listadoPin['Adjuntos'],
            Id: listadoPin.Id,
          })    
        })  
        setListadoPin(listadoAux)
      }
      fetchData();

      return () => {        
      }
    }, [actualizarListado])

    const handleCerrarDialogo = (valor) => {       
      setAbrirDialogo(false)
      if(valor){    
        const fetchData = async () => {  
          setIsLoading(true)
          let user = JSON.parse(localStorage.getItem("user"));
          const AuthStr = "Bearer ".concat(user.access_token);
          const SERVER_URL = process.env.REACT_APP_API_URL + 'operacionespins';
          await axios
          .delete(SERVER_URL + "/" + entidadEliminar.current, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },        
          })
          .then((response) => {    
            if(!isNaN(response.data)){
              
              if(parseInt(response.data) === parseInt(entidadEliminar.current)){
                entidadEliminar.current = -1 
                setIsLoading(false)
              }          
            } else{
              alert(response.data);  
              setIsLoading(false)
            }
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
            setIsLoading(false)
          });  
        }
        fetchData();
      }      
    }
    

    return (   
      <>
  { abrirDialogo && (<AlertaDialogo titulo={"¿Desea eliminar el parte de incidencias?"} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
    {!isLoading && (    
    <div className="rounded border border-formulario p-2">
      <MUIDataTable
        title={""}
        className={"mt-1 mt-2"}
        data={listadoPin}
        columns={COLUMNS_PINS}
        options={OPTIONS_PINS}
       />
      </div> 
      )}
    {isLoading && 
      <div className={classes.linear}>
            <LinearProgress />
      </div>
    }

    </>          
  );

}