import React from "react";

const getCosteTotal = (datosJornadas, mano) => {    
    let consumibles = []
    let herramientas = []
    let otros = []

    if(datosJornadas && datosJornadas.length > 0)
    {
        consumibles = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Consumible propio" && parseInt(datosJornada.elementos.mano) === parseInt(mano))
        herramientas = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Herramientas" && parseInt(datosJornada.elementos.mano) === parseInt(mano)) 
        otros = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Otros gastos" && parseInt(datosJornada.elementos.mano) === parseInt(mano))   
    }

    let costeTotal = 0;
    let costeTotalConsumibles = 0;
    let costeTotalHerramientas = 0;
    let costeTotalOtros = 0;
    let unidadesTotalConsumibles=0;
    let unidadesTotalHerramientas=0;
    let unidadesTotalOtros=0;

    consumibles.forEach(consumibe => {
        costeTotal += parseFloat(consumibe.elementos.Coste) * parseInt(consumibe.elementos.cantidad)
        costeTotalConsumibles += parseFloat(consumibe.elementos.Coste) * parseInt(consumibe.elementos.cantidad)
        unidadesTotalConsumibles += parseInt(consumibe.elementos.cantidad)
    }); 
    
    herramientas.forEach(herramienta => {
        costeTotal += parseFloat(herramienta.elementos.Coste) * parseInt(herramienta.elementos.cantidad)
        costeTotalConsumibles += parseFloat(herramienta.elementos.Coste) * parseInt(herramienta.elementos.cantidad)
        unidadesTotalConsumibles += parseInt(herramienta.elementos.cantidad)
    }); 

    otros.forEach(otro => {
        costeTotal += parseFloat(otro.elementos.Coste) * parseInt(otro.elementos.cantidad)
        costeTotalOtros += parseFloat(otro.elementos.Coste) * parseInt(otro.elementos.cantidad)
        unidadesTotalOtros += parseInt(otro.elementos.cantidad)
    });   

    return {
        costeTotal: costeTotal, 
        costeTotalConsumibles: costeTotalConsumibles,
        costeTotalHerramientas: costeTotalHerramientas,
        costeTotalOtros: costeTotalOtros,
        unidadesTotalConsumibles: unidadesTotalConsumibles,
        unidadesTotalHerramientas: unidadesTotalHerramientas, 
        unidadesTotalOtros: unidadesTotalOtros       
    }
}

const RecursosManoDetalleOtrosDesgloce = ({ 
    datosJornadas, mano }) => 
{  
    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('es-ES', options2);
    const costeTotalaAgrupado = getCosteTotal(datosJornadas, mano)
    
    const costeTotal = numberFormat2.format(costeTotalaAgrupado.costeTotal)    

    const consumibles = datosJornadas ? datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Consumible propio" && parseInt(datosJornada.elementos.mano) === parseInt(mano)) : []
    const herramientas = datosJornadas ? datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Herramientas" && parseInt(datosJornada.elementos.mano) === parseInt(mano)) : []   
    const otrosGastos = datosJornadas ? datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Otros gastos" && parseInt(datosJornada.elementos.mano) === parseInt(mano)) : []   
    
    const unidadesTotalConsumibles = costeTotalaAgrupado.unidadesTotalConsumibles;
    const unidadesTotalHerramientas = costeTotalaAgrupado.unidadesTotalHerramientas;
    const unidadesTotalOtrosGastos = costeTotalaAgrupado.unidadesTotalOtros;
    const TotalUnidades = unidadesTotalConsumibles + unidadesTotalHerramientas + unidadesTotalOtrosGastos;
    
    return (        
    <>
    <div className="col-12 d-flex overflow-auto justify-content-center mb-2">
        <div className="col-2 float-left">Empresa </div>
        <div className="col-1 float-right">N.º</div>
        <div className="col-5 float-left">Tipología</div>
        <div className="col-2 float-left text-right">Cantidad </div>
        <div className="col-2 float-right text-right">Costes</div>
    </div>

    {consumibles &&  consumibles.map((consumible, index) => (
        <div key={index} className="col-12 d-flex overflow-auto justify-content-center mt-1 mb-1">
            <div className="col-2 float-left">Gesport</div>
            <div className="col-1 float-right">{index+1}</div>
            <div className="col-5 float-left">{consumible.elementos.Nombre}</div>
            <div className="col-2 float-left text-right">{consumible.elementos.cantidad} </div>
            <div className="col-2 float-right text-right">{numberFormat2.format(consumible.elementos.Coste * consumible.elementos.cantidad)}</div>
        </div>
    ))}

    {herramientas &&  herramientas.map((herramienta, index) => (
        <div key={index} className="col-12 d-flex overflow-auto justify-content-center mt-1 mb-1">
            <div className="col-2 float-left">Externo</div>
            <div className="col-1 float-right">{consumibles.length + index+1}</div>
            <div className="col-5 float-left">{herramienta.elementos.Nombre}</div>
            <div className="col-2 float-left text-right">{herramienta.elementos.cantidad} </div>
            <div className="col-2 float-right text-right">{numberFormat2.format(parseFloat(herramienta.elementos.Coste) * parseInt(herramienta.elementos.cantidad))}</div>
        </div>
    ))}

    {otrosGastos &&  otrosGastos.map((otro, index) => (
        <div key={index} className="col-12 d-flex overflow-auto justify-content-center mt-1 mb-1">
            <div className="col-2 float-left">{otro.elementos.descripcionMercancia}</div>
            <div className="col-1 float-right">{consumibles.length + herramientas.length + index+1}</div>
            <div className="col-5 float-left">{otro.elementos.Nombre}</div>
            <div className="col-2 float-left text-right">{otro.elementos.cantidad} </div>
            <div className="col-2 float-right text-right">{numberFormat2.format(parseFloat(otro.elementos.Coste) * parseInt(otro.elementos.cantidad))}</div>
        </div>
    ))}

    <div className="col-12 d-flex overflow-auto justify-content-center mt-2 mb-2 ">                    
        <div className="col-5 float-left"></div>
        <div className="col-3 float-right text-right">Total</div>                        
        <div className="col-2 float-left text-right">{TotalUnidades} </div>
        <div className="col-2 float-left text-right">{costeTotal}</div>                    
    </div> 
    </>    
    )
}

export default RecursosManoDetalleOtrosDesgloce;