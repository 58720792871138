import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaProductos = () => {
    let { id } = useParams();
    const [productos, setProductos] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respProductos = await axios(`${process.env.REACT_APP_API_URL}productos/${id}`);    
          setProductos(respProductos.data)  
        };
        fetchData();  
    }, [id]);
    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(productos).length > 0){  
            setCampos([
            {
                classsLabel: "",
                label: "Operativa",
                value: productos.Operativas.length > 0 ? productos.Operativas[0].Etiqueta : '',
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Categoría",
                value: productos.Categoria.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Subcategoría",
                value: productos.SubCategoria ? productos.SubCategoria.Etiqueta : "",
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Nombre producto",
                value: productos.Nombre,
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Nombre producto (Inglés)",
                value: productos.NombreIngles,
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Estado",
                value: productos.Estado.Etiqueta,
                classText: classText,
                xs:4
            },
            
            {
                classsLabel: "",
                label: "Fecha de alta",
                value: productos.Fecha_Alta,
                classText: classText,
                xs:4
            }, 
            {
                classsLabel: "",
                label: "Unidad",
                value: productos.Unidad.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Periodicidad",
                value: productos.Periodicidad ? productos.Periodicidad.Etiqueta : "" ,
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "Tarifa",
                value: productos.Tarifa,
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "Extra coste",
                value: productos.extraCoste === 0 ? "NO" : "SÍ",
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "Cod. Poseidón",
                value: productos.codPoseidon,
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Observaciones",
                value: productos.Observaciones,
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            /*
            {                
                classsLabel: "",
                label: "Activo:",
                value: productos.Activo,
                classText: classText,
                xs:4
               },
            */
            {                
                 classsLabel: "",
                 label: "Archivos adjuntos:",
                 value: productos.ficheros,
                 classText: classText,
                 type: "file",
                 xs:4
                },
        ])
    }
},[productos]) 
return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    urlClick = {`/ventas/productos/${id}/W`}
    />
    </>
  )
}
export default FichaProductos;