import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Button from '@material-ui/core/Button';
import RecursosManoDetalleManoObraCabecera from "./RecursosManoDetalleManoObraCabecera"
import RecursosManoDetalleMaquinariaCabecera from "./RecursosManoDetalleMaquinariaCabecera"
import RecursosManoDetalleAuxiliarCabecera from "./RecursosManoDetalleAuxiliarCabecera"
import RecursosManoDetalleOtrosCabecera from "./RecursosManoDetalleOtrosCabecera"
import RecursosManoTotalCostes from "./RecursosManoTotalCostes"


const RecursosManoDetalle = ({ manos, datosJornadas, urlGenerarFicha = null}) => 
{   
    let { id } = useParams();

    const handlePrintDetalles = async () => {
        let datos=[];

        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + urlGenerarFicha + id;
        const res = await axios.get(
            SERVER_URL 
            /*, 
            { headers: {
                "Content-Type": "application/json",
                Authorization: AuthStr,
            }              
            },
            {params: { estado: ''}} 
            */
            );
        window.open(res.data, "_blank")
    }
    return(
    <>
        
        <div className="col-12 mt-4 d-flex overflow-auto justify-content-center">
            <span className="col-10 float-left"></span>
            <span className="col-2 float-right">
                {urlGenerarFicha && (
                <Button onClick={() => handlePrintDetalles()} color="primary">
                        IMPRIMIR
                </Button>
                )}
            </span>            
        </div>
        

        <RecursosManoDetalleManoObraCabecera 
            manos={manos} 
            datosJornadas={datosJornadas}                
        />

        <RecursosManoDetalleMaquinariaCabecera 
            manos={manos} 
            datosJornadas={datosJornadas}                
        />

        <RecursosManoDetalleAuxiliarCabecera 
            manos={manos} 
            datosJornadas={datosJornadas}                
        />

        <RecursosManoDetalleOtrosCabecera 
            manos={manos} 
            datosJornadas={datosJornadas}                
        />

        <RecursosManoTotalCostes 
            datosJornadas={datosJornadas}                
        />        
    </>
        
    )
}

export default RecursosManoDetalle;
