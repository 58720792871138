import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaTarifas = () => {
    let { id } = useParams();

    const [tarifas, setTarifas] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respTarifas = await axios(`${process.env.REACT_APP_API_URL}tarifas/${id}`);    
          setTarifas(respTarifas.data)  
        };
        fetchData();  
    }, [id]);

    const classText= "Texto14"

    useEffect(() => {      
        if(Object.keys(tarifas).length > 0){ 
            
            setCampos([
            {
                classsLabel: "",
                label: "Cliente",
                value: tarifas.Cliente.Razon_Social,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Fecha Alta",
                value: tarifas.Fecha_Alta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Estado",
                value: tarifas.Estado.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Título",
                value: tarifas.Titulo,
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Nota",
                value: tarifas.Nota,
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Observaciones",
                value: tarifas.Observaciones,
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {                
                 classsLabel: "",
                 label: "Archivos adjuntos:",
                 value: tarifas.ficheros,
                 classText: classText,
                 type: "file",
                 xs:12
            },            
            {                
                campoEtiqueta: ["Id", "Codigo", "Nombre", "Tarifa"], 
                classsLabel: "",
                label: "Productos:",
                value: tarifas.productosTarifa,
                classText: classText,
                type: "listadoProductos",                
                xs:12
           },
           
        ])
    }
    },[tarifas]) 

    return(
        <>
        <h6 className="texto-comercial" id="title">          
        </h6>
        <FichaEntidad
            campos = {campos}
            urlClick = {`/ventas/productos/tarifa/${id}/W`}
        />
        </>
    )
}
export default FichaTarifas;