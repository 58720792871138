import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller } from "react-hook-form";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useParams } from "react-router-dom";
//import AddButtonToolbar from "./AddButtonToolBar"
import {CAMPOS_EVENTOS} from './AgendaCalendarioConf'
import DialogForm from "./DialogForm"


export default function FormFieldComboArray({propiedades, onChangeValue=null, valor=null, control=null}) {

  const [open, setOpen] = useState(false);  
  const [openForm,setOpenForm] = useState(false);
  const [add,setAdd] = useState(false); 
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [selectedCategory, setSelectedCategory] = useState(propiedades.value);
  const { modo } = useParams();
  const [cerrarDialogo, setCerrarDialogo] = useState();
  const [campos, setCampos] =useState(CAMPOS_EVENTOS)

  useEffect(() => {   
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {

      if (active) {
          if(propiedades.options.length > 0 ){
            setOptions(Object.keys(propiedades.options).map((key) => propiedades.options[key]))
          } else {
            const etiquetas = propiedades.campoEtiqueta.split(',')
            const etiqueta = etiquetas[0];
            const sinDatos = [{ 
              Id: -1,
              [etiqueta]: "Sin datos"
            }]
            setOptions(sinDatos)
          }
      }
    })();
    return () => {
      active = false;
    };
  }, [loading, propiedades.options, propiedades.campoEtiqueta]);

  useEffect(() => {
    if (!open) {      
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    setSelectedCategory(propiedades.value)
  }, [propiedades.value])

  const renderInput = (params) => {  
    const multipleRequired = Array.isArray(selectedCategory) && selectedCategory.length === 0
    const singleRequired =  (selectedCategory === null || selectedCategory === "") && propiedades.required;
    const isRequired = (singleRequired || multipleRequired) && propiedades.required
    
    return (
    <TextField  
      {...params}
      label={propiedades.label}
      required={isRequired}
      variant="outlined"      
      size="small"
      fullWidth      
      InputProps={{
        ...params.InputProps,
        endAdornment: (
        <>
          {loading ? <CircularProgress color="inherit" size={20} /> : null}
          {params.InputProps.endAdornment}
        </>
      ),
    }}             
    />
    )
  }
  
  const handleChange = (v) => {  
    if(propiedades.onChangeValue) propiedades.onChangeValue(v)
    if(onChangeValue) onChangeValue(v)      
    setSelectedCategory(v)
  }  

  const getOptionLabel = (option) => {   
  
    let etiqueta = "";
    if(option){      
      const etiquetas = propiedades.campoEtiqueta.split(',')
      etiquetas.forEach(element => {
        etiqueta += option[element] + " " 
      });      
    }  
    return etiqueta  
  } 

  const getOptionSelected = (option) => {        
    const campoId = propiedades.campoId ? propiedades.campoId : "Id"
    
    if(!selectedCategory) return true;

    if(propiedades.multiple === true){
      const estaArray = selectedCategory.filter(selected => selected[campoId] == option[campoId])
      return estaArray.length > 0
    }   
    
    return option[campoId] == selectedCategory[campoId]
  }

  const getOptionDisabled = (option) => {
    const campoDesactivar = propiedades.campoDesactivar ? propiedades.campoDesactivar : "Desactivar"
    return option[campoDesactivar] === 0
  }
 
  const deshabilitado = (
    typeof(propiedades.disabled) === "undefined" ? 
    modo==="R" ?  true : false : propiedades.disabled
  )

  const setOpenModal = (valor) => {
  }

  const accion = () => {        
    setCerrarDialogo(true)
} 

const handleClose = () => {
  let camposAux = [...campos];
  setOpenForm(false);
};

  
  return (        
    <Grid item xs={propiedades.xs} className={propiedades.classes} >
    <Controller    
    name={propiedades.name}    
    control={control}    
    defaultValue={ selectedCategory }    
    render={({onChange, ...props}) => (             
          <Autocomplete           
            multiple = {propiedades.multiple}     
            id={'_'+propiedades.name}
            getOptionSelected={(option) => getOptionSelected(option)}
            getOptionDisabled={(option) => getOptionDisabled(option)}
            open={open}
            loadingText="Cargando..."
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}   
            loading={loading}
            options={options}
            noOptionsText={
            propiedades.DialogForm ? 
            <Button onMouseDown={(e)=>
              {
                setOpenForm(true)
              }}>Añadir registro</Button> : 
              "No hay datos disponibles"
            }
            getOptionLabel={getOptionLabel}
            renderInput={renderInput}
            disabled={deshabilitado}           
            
            onChange={(e, data) => {
              handleChange(data)              
              onChange(data)
              }}
            {...props}
          />        
      )}    
      />  
      <DialogForm
        title={"Añadir registro "}
        isOpen={openForm} 
        handleCloseDialog={handleClose}
        urlForm={propiedades.urlData}
        action={handleClose}
        campos={propiedades.DialogForm}
        formName={propiedades.formName}
        urlGoto={""}
        type="add"
        // value={value}
      />   
    </Grid>
    
      
    
  )
}