import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import axios from "axios";
import {usePlanificacion} from './planificacion-context'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 250,
    width: '45%',
    backgroundColor: '#e5e5e5',
    marginTop: 30,
    marginLeft: 30
  },
  paperPdf: {
    height: 250,
    width: '50%',
    backgroundColor: '#434343',
    marginTop: 30,
    marginLeft: 30,
    color: '#FFF',
    "&:hover, &:focus": {
      backgroundColor: '#606060',
    } 
  },
  
}));


const PlanificacionFTO = () => {
    let { id } = useParams();
    const { datosPlanificacion } = usePlanificacion(); 
    const classes = useStyles();

    const [pdfFto, setPdfFto] = useState(datosPlanificacion.FicheroFTO || '')    

    const handleClick = () => {            
        if(id){
        const fetchData = async () => {
            const respFacturaUnica = await axios( process.env.REACT_APP_API_URL + "planificaciones/generaftopdf/" + id);
            if (respFacturaUnica.data && respFacturaUnica.data.includes(".pdf")) {   
              setPdfFto(respFacturaUnica.data);              
            }else { alert("Ha ocurrido un error mientras se preparaba el pdf, revise los datos de la planificación.")}
          };
          fetchData();   
        }
    }

    const handleClickOpen = () => {    
        window.open(pdfFto, "_blank")
    }


    return (
    <Grid 
    className={classes.root} 
    container 
    direction="row"
    justifycontent="center"
    alignItems="center"  
    spacing={1}  
    >
        
        <Grid item xs={6} className="" >
          <Button className={classes.paper} variant="outlined" size="large" onClick={handleClick} disabled={false} >
          GENERAR FTO
          </Button>                         
        </Grid>   
        <Grid item xs={6} className="" >
            <Button className={pdfFto.length > 0 ? classes.paperPdf : classes.paper} variant="outlined" size="large" onClick={handleClickOpen} disabled={pdfFto.length === 0}>
            VER FTO
            </Button>                   
        </Grid>  
    </Grid>
    )
}
export default PlanificacionFTO;