import React,  { useState, useEffect }  from "react";
import { useParams } from "react-router-dom";
import {useCotizacion} from './cotizacion-context'
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'
import { convertDate } from "../../../../../tools/metodos";

const FichaCotizacion = () => {    
    let { id } = useParams();
    const {datosCotizacion, setDatosCotizacion/*, setCodigoCotizacion*/} = useCotizacion();
    const [campos, setCampos] = useState([])

    const classText= "Texto14"

    useEffect(() => {
      const fetchData = async () => {  
          
        const respPedido = await axios(
          `${process.env.REACT_APP_API_URL}cotizaciones/${id}`
        );

        if (Object.keys(respPedido.data).length > 0) {    
          setDatosCotizacion(respPedido.data);
          //setCodigoCotizacion(respPedido.data.Codigo)           
        }
      }
      fetchData()
        
      return () => {

      }
    }, [])

    useEffect(() => {  
      if(Object.keys(datosCotizacion).length > 0){
        let notaPedido="";  
        if(datosCotizacion.Fk_PedidoCliente.length > 0 ){
          notaPedido = datosCotizacion.Fk_PedidoCliente[0].Codigo          
        }
        
        setCampos([
          {
            classsLabel: "",
            label: "Fecha de solicitud:",
            value: convertDate(datosCotizacion.Fecha_Solicitud),
            classText: classText,
            xs:4
          },
          {
            classsLabel: "",
            label: "Canal de Solicitud:",
            value: datosCotizacion.Fuente.Etiqueta,
            classText: classText,
            xs:4
          },
          {
            classsLabel: "",
            label: "Nota de pedido:",
            value: notaPedido,
            classText: classText,
            xs:2
          },
          {
            classsLabel: "",
            label: "Referencia de cliente:",
            value: datosCotizacion.Referencia_Cliente,
            classText: classText,
            xs:2
          },
          {
            classsLabel: "",
            label: "",
            value: "",
            classText: classText,
            xs:12
          },
          {
            classsLabel: "",
            label: "Cliente:",
            value: datosCotizacion.Cuenta[0].Razon_Social,
            classText: classText,
            xs:4
          },
          {
            classsLabel: "",
            label: "Contacto:",
            value: datosCotizacion.Contacto[0] ? datosCotizacion.Contacto[0].Nombre.concat(" ", datosCotizacion.Contacto[0].Apellidos) : "",
            classText: classText,
            xs:4
          }, 
          {
            classsLabel: "",
            label: "Fecha de operativa:",
            value: datosCotizacion.Fecha_Operativa ? convertDate(datosCotizacion.Fecha_Operativa) : "",
            classText: classText,
            xs:4
          },         
          {
            classsLabel: "",
            label: "",
            value: "",
            classText: classText,
            xs:12
          },          
          {
            classsLabel: "",
            label: "Muelle:",
            value: datosCotizacion.Localizacion ? datosCotizacion.Localizacion.Etiqueta : "",
            classText: classText,
            xs:4
          }, 
          {
            classsLabel: "",
            label: "Buque:",
            value: datosCotizacion.Buque ? datosCotizacion.Buque.Nombre : "",
            classText: classText,
            xs:4
          }, 
          {
            classsLabel: "",
            label: "Operativa:",
            value: datosCotizacion.OperativaMercancia ? datosCotizacion.OperativaMercancia.Etiqueta : "",
            classText: classText,
            xs:4
          },
          {
            classsLabel: "",
            label: "",
            value: "",
            classText: classText,
            xs:12
          },           
          {
            classsLabel: "",
            label: "Enviado:",
            value: datosCotizacion.Estado.Etiqueta,
            classText: classText,
            xs:3
          },
          {
            classsLabel: "",
            label: "Resultado:",
            value: datosCotizacion.Cotizacion.Etiqueta,
            classText: classText,
            xs:3
          },  
          {
            classsLabel: "",
            label: "Total aceptado:",
            value: datosCotizacion.TotalAceptado ? datosCotizacion.TotalAceptado + " €" : "",
            classText: classText,
            xs:3
          },      
          {
            classsLabel: "",
            label: "Total aceptado (TN):",
            value: datosCotizacion.TotalAceptadoTN ? datosCotizacion.TotalAceptadoTN + " €/TN" : "",
            classText: classText,
            xs:3
          },        
          {
            classsLabel: "",
            label: "",
            value: "",
            classText: classText,
            xs:12
          },
          {
            classsLabel: "",
            label: "Descripcion:",
            value: datosCotizacion.Descripcion,
            classText: classText,
            xs:5
          },
          {
            classsLabel: "",
            label: "Archivos adjuntos:",
            value: datosCotizacion.ficheros,
            classText: classText,
            type: "file",
            xs:7
          },
        ])
      }
    },[datosCotizacion])    
    
    return(
      <FichaEntidad
      campos = {campos}
      urlClick = {`/ventas/cotizaciones/cotizacion/${id}/W`}
      />
    )    
  };
  export default FichaCotizacion;  