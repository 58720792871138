import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaVacaciones = () => {
    let { id } = useParams();

    const [vacaciones, setSeleccion] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respSeleccion = await axios(`${process.env.REACT_APP_API_URL}rrhhvacaciones/${id}`);    
          setSeleccion(respSeleccion.data)  
        };
        fetchData();  
    }, [id]);

    const classText= "Texto14"

    useEffect(() => {      
        if(Object.keys(vacaciones).length > 0){  
            let dias = 0
            let fechaInicio = new Date(vacaciones.Fecha_Inicio);        
            let fechaFin = new Date(vacaciones.Fecha_Fin);       
            dias = fechaFin.getDate() - fechaInicio.getDate() + 1;

            setCampos([
                {
                    classsLabel: "",
                    label: "Trabajador:",
                    value: vacaciones.Trabajador.nombre,
                    classText: classText,
                    xs:4
                },
                {
                    classsLabel: "",
                    label: "Departamento:",
                    value: vacaciones.Departamento.Etiqueta,
                    classText: classText,
                    xs:4
                },
                {
                    classsLabel: "",
                    label: "Validar",
                    value: vacaciones.Valida ? vacaciones.Valida.nombre : "",
                    classText: classText,
                    xs:4
                }, 
                
                {
                    classsLabel: "",
                    label: "Fecha Inicio:",
                    value: vacaciones.Fecha_Inicio,
                    classText: classText,
                    xs:4
                },
                {
                    classsLabel: "",
                    label: "Fecha Fin:",
                    value: vacaciones.Fecha_Fin,
                    classText: classText,
                    xs:4
                },
                {
                    classsLabel: "",
                    label: "Días:",
                    value: dias,
                    classText: classText,
                    xs:4
                },
                {
                    classsLabel: "",
                    label: "Observaciones:",
                    value: vacaciones.Observaciones,
                    classText: classText,
                    xs:12
                },            
                {                
                     classsLabel: "",
                     label: "Archivos adjuntos:",
                     value: vacaciones.ficheros,
                     classText: classText,
                     type: "file",
                     xs:4
                    },
                
            ])

    }
},[vacaciones]) 

return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    urlClick = {`/rrhh/gestionpersonal/vacaciones/${id}/W`}
    />
    </>
  )
}
export default FichaVacaciones;