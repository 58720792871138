import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const useAxios = (axiosParams) => {
    const [response, setResponse] = useState(undefined);    
    const Error = useRef('')
    const Loading = useRef(true)
    
    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source(); 
        if(axiosParams){
            axiosParams.cancelToken = source.token
        }        
        
        const fetchData = async (params) => {  
            try {         
                const result = await axios.request(params);
                setResponse(result.data);
             } catch( error ) { 
                Error.current = error             
                if (axios.isCancel(error)) {       
                    } else {
                        throw error;
                    }               
             } finally {  
                Loading.current = false
             }
        };
        
        if(!axiosParams.url.includes("undefined")){
            fetchData(axiosParams);        
        }
        
        return () => { 
            source.cancel();            
        } 

    }, []); // execute once only
    
    const error = Error.current
    const loading = Loading.current
    return { response, loading, error };
};