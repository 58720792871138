import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import { TextField } from "@material-ui/core";
import { DataGrid } from '@mui/x-data-grid';
import {useFacturaCompra} from './facturacompra-context'

const TablaFacturaCompra = ({listadoCompra,ListadoCompraFinal}) => { 
    const [listadoCompraInicial, setListadoCompraInicial] = useState([])  
    const { setListadoProductosFacturaCompra } = useFacturaCompra();  
    const { modo } = useParams();  
    
    const columns = [        
        { field: 'codigo', headerName: 'Código', width: 90, sortable: false },
        { field: 'descripcion', headerName: 'Descripción Artículo', width: 300, sortable: true, editable: true },
        { field: 'unidad', headerName: 'Unidad', width: 90, sortable: false },
        { field: 'cantidad', headerName: 'Cantidad',  type: 'number',            
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),          
        width: 90, sortable: false, editable: true },
        { field: 'precioUnidad', headerName: 'Precio UD', type: 'number',
        renderCell: (params) => ( <TextField disabled={true} type="number" value={params.value}  /> ),
        width: 100, sortable: false, editable: true },
        { field: 'igic', headerName: 'IGIC. %', width: 80, sortable: false, }, 
        
        { field: 'irpf', headerName: 'IRPF. %', width: 80, type: 'number', sortable: false },
        { field: 'baseImponible', headerName: 'Base Imponible', width: 100, type: 'number', sortable: false,
        valueFormatter: (params) => {           
            const baseImponible = parseFloat(params.row.cantidad) * parseFloat(params.row.precioUnidad)            
            const valueFormatted = baseImponible.toLocaleString();
            return `${valueFormatted} €`;
          },
        },
        { field: 'precioTotal', headerName: 'Total', width: 100, type: 'number', sortable: false,
        valueFormatter: (params) => {
            const valueFormatted = Number(params.value).toLocaleString();
            return `${valueFormatted} €`;
          },
        },        
        { field: 'centroCoste', headerName: 'Centro de Coste', width: 170, sortable: false },  
        { field: 'categoria', headerName: 'Categoría', width: 150, sortable: false }, 
        { field: '', headerName: '', width: 80, 
            renderCell: (params) => (
                  <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>
              ), sortable: false },
        
    ]; 
    
    const resetForm = () => {
        setListadoCompraInicial([])
        setListadoProductosFacturaCompra([])
    }

    useEffect(() => {        
        if(  typeof listadoCompra === 'undefined' ||
            (Array.isArray(listadoCompra) && listadoCompra.length === 0 )
        ){        
            resetForm()
        }else{
            setListadoCompraInicial(listadoCompra)
            setListadoProductosFacturaCompra(listadoCompra)
        }       
    },[listadoCompra])

    const handleRemoveElement = (element) =>{
        if (modo==="R") return;

        const elementoAEliminar = listadoCompraInicial.filter (el => {
            return el.id !== element.id
        })
        ListadoCompraFinal(elementoAEliminar)
    }   

    const handleCellEditCommit = React.useCallback(
        ({ id, field, value }) => {            

          if (field === 'descripcion') {                       
            const updatedRows = listadoCompraInicial.map((row) => {
              if (row.id === id) {               
                return { ...row,  descripcion: value};
              }
              return row;
            });              
            ListadoCompraFinal(updatedRows)         
          }

          if (field === 'cantidad') {                       
            const updatedRows = listadoCompraInicial.map((row) => {
              if (row.id === id) {               
                return { ...row,  cantidad: value, igicImporte: parseFloat(value*row.precioUnidad*row.igic/100), precioTotal: parseFloat(value*row.precioUnidad)};
              }
              return row;
            });              
            ListadoCompraFinal(updatedRows)         
          }

          if (field === 'precioUnidad') {                    
            const updatedRows = listadoCompraInicial.map((row) => {
              if (row.id === id) {
                return { ...row,  precioUnidad: value, igicImporte: parseFloat(value*row.cantidad*row.igic/100), precioTotal: parseFloat(value*row.cantidad)};
              }
              return row;
            });  
            ListadoCompraFinal(updatedRows)                   
          }  

          if (field === 'igicImporte') {              
            const updatedRows = listadoCompraInicial.map((row) => {
              if (row.id === id) {
                if(parseFloat(row.precioUnidad) !== 0) return row
                return { ...row,  igicImporte: value, precioTotal: parseFloat(value+(row.cantidad*row.precioUnidad))};
              }
              return row;
            });  
            ListadoCompraFinal(updatedRows)                   
          }  

        },
        [listadoCompraInicial],
    );
  return(        
        <Grid container  spacing={0} className="mt-2 ml-1">
            <Grid item xs={12} className="" style={{height: 230}}>
                <DataGrid 
                disableColumnMenu={true}
                disableColumnReorder={true}                
                rows={listadoCompraInicial} 
                columns={columns} 
                //pageSize={100} 
                checkboxSelection = {false}
                hideFooterPagination = {true}
                hideFooterRowCount = {true}
                hideFooterSelectedRowCount = {true}
                onCellEditCommit={handleCellEditCommit}
                /*
                components={{
                    Footer: CustomFooterStatusComponent,
                }}
                componentsProps={{
                    footer: { listadoCompraInicial },
                }}
                */
                />
            </Grid>
            {/*
            <Grid item xs={12} className="" style={{height: 40}}>
                <CustomFooterStatusComponent listadoCompraInicial={listadoCompraInicial} />
            </Grid>  
            */} 
        </Grid>        
    )
}
export default TablaFacturaCompra;