import React from "react";

import ConfigTablas from "../containers/views/Main/Configuracion/ConfigTablas";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthRoute from "../components/AuthRoute";
import { connect } from "react-redux";

//import ConfigTablas from "../containers/views/Main/Configuracion/ConfigTablas";
//Laypouts
import Main from "../containers/layouts/Main";
import MainCotizaciones from "../containers/layouts/MainCotizaciones";
import MainPlanningSemanal from "../containers/layouts/MainPlanningSemanal";
import MainPlanificaciones from "../containers/layouts/MainPlanificaciones";
import MainOperaciones from "../containers/layouts/MainOperaciones";
import MainFacturaCompra from "../containers/layouts/MainFacturasCompra"
import MainFacturaVenta from "../containers/layouts/MainFacturasVenta"
import MainAlmacenamiento from "../containers/layouts/MainAlmacenamiento";
//import MainAdmin from "../containers/layouts/MainAdmin";

// views
import Home from "../containers/views/Main/Home";
import Login from "../containers/views/Main/Login";
//import Cmo from "../containers/views/Main/Cmo";
import RecuperarPassword from "../containers/views/Main/RecuperarPassword";

//CMO
import CuadroDeMandos from "../containers/views/Main/CMO/CuadroDeMandos";

//COMERCIAL
//CRM
import { cuentas, cuenta, cuentamodo } from './crmRoutes'
import { oportunidades, oportunidad, oportunidadmodo } from './crmRoutes'
import { actvidades, actvidad, actvidadmodo } from './crmRoutes'
import { proyectos, proyecto, proyectomodo } from './crmRoutes'
import { buques, fichabuques, formulariobuques, formulariobuques2} from "./crmRoutes";
import { localizaciones, fichalocalizaciones, formulariolocalizaciones, formulariolocalizaciones2} from "./crmRoutes";

//VENTAS
import { pedidos, pedido, pedidomodo } from './ventasRoutes'
import { cotizaciones, cotizacion, cotizacionmodo } from './ventasRoutes'
import { productos, portfolio, portfoliomodo, tarifa, tarifamodo } from './ventasRoutes'

//CONTACTOS
import CrearContacto from "../containers/views/Main/Contactos/CrearContacto";

//PRODUCCION
//LOGISTICA
import Entradas from "../containers/views/Main/Produccion/Logistica/Entradas";
//import Almacen from "../containers/views/Main/Produccion/Logistica/Almacen";
import Salidas from "../containers/views/Main/Produccion/Logistica/Salidas";

/* NUEVO */
import Existencias from "../containers/views/Main/Produccion/Logistica/Existencias";

import Depositos from "../containers/views/Main/Produccion/Logistica/Depositos";
import Deposito from "../containers/views/Main/Produccion/Logistica/Deposito";
import Materiales from "../containers/views/Main/Produccion/Logistica/Materiales";
import Material from "../containers/views/Main/Produccion/Logistica/Material";
//import Listado from "../containers/views/Main/Produccion/Logistica/Listado";
/* NUEVO */

//OPERATIVAS
import PlanningSemanal from "../containers/views/Main/Produccion/Operativas/PlanningSemanal";
import Planificaciones from "../containers/views/Main/Produccion/Operativas/Planificaciones";
import Planificacion from "../containers/views/Main/Produccion/Operativas/Planificacion";
import Operaciones from "../containers/views/Main/Produccion/Operativas/Operaciones";
import Operacion from "../containers/views/Main/Produccion/Operativas/Operacion";
import Almacenamiento from "../containers/views/Main/Produccion/Operativas/Almacenamiento";

/* NUEVO */
import Almacenes from "../containers/views/Main/Produccion/Operativas/Almacenes";
import Piezas from "../containers/views/Main/Produccion/Operativas/Piezas";
import Inventario from "../containers/views/Main/Produccion/Operativas/AlmacenamientoInventario";
import FichaSubEntrada from "../containers/views/Main/Produccion/Operativas/FichaSubEntrada";
//import FichaAlmacen from "../containers/views/Main/Produccion/Operativas/FichaAlmacen";
import Transferir from "../containers/views/Main/Produccion/Operativas/Transferir";
/* NUEVO */

//POSTVENTA
//import Documentacion from "../containers/views/Main/Produccion/Postventa/Documentacion";
import Auditoria from "../containers/views/Main/Produccion/Postventa/Auditoria";
import Soportes from "../containers/views/Main/Produccion/Postventa/Soportes";
import Soporte from "../containers/views/Main/Produccion/Postventa/Soporte";


//SERVICIO
import Maquinaria from "../containers/views/Main/Produccion/Servicio/Maquinaria";
import Maquinarias from "../containers/views/Main/Produccion/Servicio/Maquinarias";
import Instalaciones from "../containers/views/Main/Produccion/Servicio/Instalaciones";
import Instalacion from "../containers/views/Main/Produccion/Servicio/Instalacion";
import FormularioMaquinaria from "../containers/views/Main/Produccion/Servicio/FormularioMaquinaria";
import PlanningMantenimientoPreventivo from "../containers/views/Main/Produccion/Servicio/PlanningMantenimientoPreventivo";
import Actuaciones from "../containers/views/Main/Produccion/Servicio/Actuaciones";
import InformeMaquinariaServicios from "../containers/views/Main/Produccion/Servicio/InformeMaquinariaServicios";

//GESTION
//ADMINISTRACIÓN
//import Compra from "../containers/views/Main/Gestion/Compras/Compra"
//import Compras from "../containers/views/Main/Gestion/Compras/Pedidos";

//GESTION VENTAS
import Ventas from "../containers/views/Main/Gestion/Facturas/Ventas";
import FacturaUnica from "../containers/views/Main/Gestion/Facturas/FacturaUnica";
import FacturaRectificativa from "../containers/views/Main/Gestion/Facturas/FacturaRectificativa";
import FacturaRecurrente from "../containers/views/Main/Gestion/Facturas/FacturaRecurrente";
import ExportacionContanet from "../containers/views/Main/Gestion/ExportacionContanet/Exportacion";

//COMPRAS
//import PedidosCompras from "../containers/views/Main/Gestion/Compras/Pedidos";
//import GestionCompras from "../containers/views/Main/Gestion/Compras/GestionCompras";
//COMPRAS
import GestionCompra from "../containers/views/Main/Gestion/Compras/Compra";
import HojasDePedidos from "../containers/views/Main/Gestion/Compras/HojasDePedidos";
import OrdenDeCompra from "../containers/views/Main/Gestion/Compras/OrdenDeCompra";
import FacturaDeCompra from "../containers/views/Main/Gestion/Compras/FacturaDeCompra";


//FACTURAS
//import FacturasCompra from "../containers/views/Main/Gestion/Facturas/Compras";
//import FacturasVenta from "../containers/views/Main/Gestion/Facturas/Ventas";

//FINANZAS
import GestionTesoreria from "../containers/views/Main/Gestion/Finanzas/Tesoreria";
import Periodificacion from "../containers/views/Main/Gestion/Finanzas/Periodificacion";
import Explotacion from "../containers/views/Main/Gestion/Finanzas/Explotacion";
import PeriodificacionFormulario from "../containers/views/Main/Gestion/Finanzas/PeriodificacionFormulario";

//import ControlGestion from "../containers/views/Main/Gestion/Finanzas/ControlGestion";
import PendientesFormulario from "../containers/views/Main/Gestion/Finanzas/PendientesFormulario";
import CajasBancos from "../containers/views/Main/Gestion/Finanzas/CajasBancos";
import Categorias from "../containers/views/Main/Gestion/Finanzas/Categorias";
import Conceptos from "../containers/views/Main/Gestion/Finanzas/Conceptos";
import Instrumentos from "../containers/views/Main/Gestion/Finanzas/Instrumentos";

//RRHH
import Talento from "../containers/views/Main/Gestion/RRHH/Talento";
import GestionPersonal from "../containers/views/Main/Gestion/RRHH/GestionPersonal";
//import SaludFormacion from "../containers/views/Main/Gestion/RRHH/SaludFormacion";
import Seleccions from "../containers/views/Main/Gestion/RRHH/Seleccions";
import Formacion from "../containers/views/Main/Gestion/RRHH/Formacion";
import RecMed from "../containers/views/Main/Gestion/RRHH/RecMed";
import Enfermedad from "../containers/views/Main/Gestion/RRHH/Enfermedad";
import Accidente from "../containers/views/Main/Gestion/RRHH/Accidente";
import RRHHdocs from "../containers/views/Main/Gestion/RRHH/RRHHdocs";
import Empleados from "../containers/views/Main/Gestion/RRHH/Empleados"
import Vacaciones from "../containers/views/Main/Gestion/RRHH/Vacaciones";
import Personal from "../containers/views/Main/Gestion/RRHH/Personal";
//CPE
import GestionCPE from "../containers/views/Main/Gestion/RRHH/GestionCPE";
import PersonalCPE from "../containers/views/Main/Gestion/RRHH/PersonalCPE";


//CUENTA PERSONAL
import MyAccount from "../containers/views/Main/myAccount/MyAccount";
import ConfigTabla from "../containers/views/Main/Configuracion/ConfigTabla";
import FormConfigTabla from "../containers/views/Main/Configuracion/FormConfigTabla";
import EditConfigTabla from "../containers/views/Main/Configuracion/EditConfigTabla";
import FichaUniforme from "../containers/views/Main/Gestion/RRHH/FichaUniforme";
import FormularioUniforme from "../containers/views/Main/Gestion/RRHH/FormularioUniforme";
import Uniforme from "../containers/views/Main/Gestion/RRHH/Uniforme";

//INFORMES
import InformeVentas from "../containers/views/Main/Gestion/Informes/Ventas";
import Rentabilidad from "../containers/views/Main/Gestion/Informes/Rentabilidad";
import General from "../containers/views/Main/Gestion/Informes/General";
import InformeMaquinaria from "../containers/views/Main/Gestion/Informes/InformeMaquinaria";

import MaintenanceModeComponent from "../components/Common/MaintenanceModeComponent";
import ResetPassword from "../containers/views/Main/ResetPassword";
//import MainMantenimiento from "../containers/layouts/MainMantenimiento";

export default connect(({ reducerApi }) => ({ reducerApi }), {
  
})((props) => {
  const { isAuthUser, maintenanceMode } = props.reducerApi;

    return(   
    <Router>
      <Switch> 
        
        <Route path={[
          "/ventas/cotizaciones/cotizacion",
          "/ventas/cotizaciones/cotizacion/:id/:modo"
        ]} exact>
          <MainCotizaciones>
            <Switch>
              {isAuthUser && maintenanceMode && <AuthRoute path="/" component={MaintenanceModeComponent} type="private" key={maintenanceMode}/>}
              {cotizacion}
              {cotizacionmodo}

            </Switch>
          </MainCotizaciones>
        </Route>

        <Route path={[
          "/operativas/planningsemanal",
        ]} exact>
          <MainPlanningSemanal>
            <Switch>
              {isAuthUser && maintenanceMode && <AuthRoute path="/" component={MaintenanceModeComponent} type="private" key={maintenanceMode}/>}
            
              <AuthRoute
                path="/operativas/planningsemanal"
                exact
                component={PlanningSemanal}
                type="private"
              />
            </Switch>
          </MainPlanningSemanal>
        </Route>

        <Route path={[
                "/servicio/planningmantenimiento",
              ]} exact>
                <MainPlanningSemanal>
                  <Switch>
                    {isAuthUser && maintenanceMode && <AuthRoute path="/" component={MaintenanceModeComponent} type="private" key={maintenanceMode}/>}
                  
                    <AuthRoute
                      path="/servicio/planningmantenimiento"
                      exact
                      component={PlanningMantenimientoPreventivo}
                      type="private"
                    />
                  </Switch>
                </MainPlanningSemanal>
              </Route>

        <Route path={[
          "/operativas/planificaciones/planificacion",
          "/operativas/planificaciones/planificacion/:id/:modo",
        ]} exact>
          <MainPlanificaciones>
            <Switch>
              {isAuthUser && maintenanceMode && <AuthRoute path="/" component={MaintenanceModeComponent} type="private" key={maintenanceMode}/>}
              {isAuthUser && <AuthRoute exact path="/" component={CuadroDeMandos} type="private" />}
              <AuthRoute
                path="/operativas/planificaciones/planificacion"
                exact
                component={Planificacion}
                type="private"
              />
              <AuthRoute
                path="/operativas/planificaciones/planificacion/:id/:modo"
                exact
                component={Planificacion}
                type="private"
              />
            </Switch>
          </MainPlanificaciones>
        </Route>

        <Route path={[
          "/operativas/operaciones/operacion",
          "/operativas/operaciones/operacion/:id/:modo"
        ]} exact>
          <MainOperaciones>
            <Switch>
              {isAuthUser && maintenanceMode && <AuthRoute path="/" component={MaintenanceModeComponent} type="private" key={maintenanceMode}/>}
              <AuthRoute
                path="/operativas/operaciones/operacion"
                exact
                component={Operacion}
                type="private"
              />
              <AuthRoute
                path="/operativas/operaciones/operacion/:id/:modo"
                exact
                component={Operacion}
                type="private"
              />
            </Switch>
          </MainOperaciones>          
        </Route>        

        <Route path={[
          "/administracion/compras/facturascompras",
          "/administracion/compras/facturascompras/:id/:modo"
        ]} exact>
          <MainFacturaCompra>
          <Switch>
            {isAuthUser && maintenanceMode && <AuthRoute path="/" component={MaintenanceModeComponent} type="private" key={maintenanceMode}/>}
              
             {/*Path Crear Factura de compra*/}
             <AuthRoute
                path="/administracion/compras/facturascompras"
                exact
                component={FacturaDeCompra}
                type="private"
              />
              {/*Path editar Factura de compra*/}
              <AuthRoute
                path="/administracion/compras/facturascompras/:id/:modo"
                exact
                component={FacturaDeCompra}
                type="private"
              />
              </Switch>
          </MainFacturaCompra>      

        </Route>

        <Route path={[
          "/administracion/ventas/facturasunicas",
          "/administracion/ventas/facturasunicas/:id/:modo",

          "/administracion/ventas/facturasrectificativas",
          "/administracion/ventas/facturasrectificativas/:id/:modo",

          "/administracion/ventas/facturasrecurrentes",
          "/administracion/ventas/facturasrecurrentes/:id/:modo"
        ]} exact>
        <MainFacturaVenta>             
          <Switch>
            {isAuthUser && maintenanceMode && <AuthRoute path="/" component={MaintenanceModeComponent} type="private" key={maintenanceMode}/>}
            {/*Path Crear Factura Unica*/}
            <AuthRoute
                path="/administracion/ventas/facturasunicas"
                exact
                component={FacturaUnica}
                type="private"
              />
              {/*Path editar Factura Unica*/}
              <AuthRoute
                path="/administracion/ventas/facturasunicas/:id/:modo"
                exact
                component={FacturaUnica}
                type="private"
              />

              {/*Path Crear Factura Rectificativa*/}
              <AuthRoute
                path="/administracion/ventas/facturasrectificativas"
                exact
                component={FacturaRectificativa}
                type="private"
              />
              {/*Path editar Factura Unica*/}
              <AuthRoute
                path="/administracion/ventas/facturasrectificativas/:id/:modo"
                exact
                component={FacturaRectificativa}
                type="private"
              />

              {/*Path Crear Factura Recurrente*/}
              <AuthRoute
                path="/administracion/ventas/facturasrecurrentes"
                exact
                component={FacturaRecurrente}
                type="private"
              />
              {/*Path editar Factura Unica*/}
              <AuthRoute
                path="/administracion/ventas/facturasrecurrentes/:id/:modo"
                exact
                component={FacturaRecurrente}
                type="private"
              />
          </Switch>
        </MainFacturaVenta>
        </Route>

        <Route path={[
          "/operativas/almacenamientos",
          "/operativas/almacenamientos/:id/:modo"
        ]} exact>
        <MainAlmacenamiento>
          <Switch>
              {isAuthUser && maintenanceMode && <AuthRoute path="/" component={MaintenanceModeComponent} type="private" key={maintenanceMode}/>}
            
              {/*Path formulario ver AlmacenamientoInventario*/}
              <AuthRoute
                path="/operativas/almacenamientos"
                exact
                component={Inventario}
                type="private"
              />
              {/*Path editar AlmacenamientoInventario*/}
              <AuthRoute
                path="/operativas/almacenamientos/:id/:modo"
                exact
                component={Inventario}
                type="private"
              />
            </Switch>
        </MainAlmacenamiento>        
        </Route>

        <Route>
          <Main>
            <Switch>              
              {isAuthUser && maintenanceMode && <AuthRoute path="/" component={MaintenanceModeComponent} type="private" key={maintenanceMode}/>}
              {isAuthUser && <AuthRoute exact path="/" component={CuadroDeMandos} type="private" />}
              {!isAuthUser && <AuthRoute exact path="/" component={Login} type="private" />}
              <AuthRoute exact path="/login" component={Login} type="guest" />
              <AuthRoute exact path="/recuperarpassword" component={RecuperarPassword} type="guest" />
              <AuthRoute exact path="/reset-password" component={ResetPassword} type="guest" />
              {/*<AuthRoute path="/" exact component={Home} type="guest" />*/}
              
              <AuthRoute path="/home" render={Home} type="private" />
              {/*
              <AuthRoute
                path="/CMO/CuadroDeMandos"
                exact
                component={CuadroDeMandos}
                type="private"
              />
              */}

              {cuentas}
              {cuenta}
              {cuentamodo}

              {buques}
              {fichabuques}
              {formulariobuques}
              {formulariobuques2}

              {localizaciones}
              {fichalocalizaciones}
              {formulariolocalizaciones}
              {formulariolocalizaciones2}

              {oportunidades}
              {oportunidad}
              {oportunidadmodo}

              {actvidades}
              {actvidad}
              {actvidadmodo}

              {proyectos}
              {proyecto}
              {proyectomodo}

              {pedidos}
              {pedido}
              {pedidomodo}

              {cotizaciones}

              {productos}
              {portfolio}
              {portfoliomodo}
              {tarifa}
              {tarifamodo}


              <AuthRoute
                path="/config/tablas"
                exact
                component={ConfigTablas}
                type="private"
              />

              <AuthRoute
                path="/config/tablas/:nombre_tabla"
                exact
                component={ConfigTabla}
                type="private"
              />

               <AuthRoute
                path="/config/tablas/:nombre_tabla/:modo"
                exact
                component={EditConfigTabla}
                type="private"
              />

               <AuthRoute
                path="/config/tablas/:nombre_tabla/:modo"
                exact
                component={EditConfigTabla}
                type="private"
              />

              <AuthRoute
                path="/contactos/contacto/:id/:modo"
                exact
                component={CrearContacto}
                type="private"
              />

              <AuthRoute
                path="/logistica/materials/entradas"
                exact
                component={Entradas}
                type="private"
              />
              <AuthRoute
                path="/logistica/materials/entradas/:id/:modo"
                exact
                component={Entradas}
                type="private"
              />
              <AuthRoute
                path="/logistica/materials/salidas"
                exact
                component={Salidas}
                type="private"
              />
              <AuthRoute
                path="/logistica/materials/salidas/:id/:modo"
                exact
                component={Salidas}
                type="private"
              />
              {/*
              <AuthRoute
                path="/logistica/almacen"
                exact
                component={Almacen}
                type="private"
              />
              */}
              <AuthRoute
                path="/logistica/salidas"
                exact
                component={Salidas}
                type="private"
              />
              {/* NUEVO */}
              <AuthRoute
                path="/logistica/existencias"
                exact
                component={Existencias}
                type="private"
              />
              <AuthRoute
                path="/logistica/materiales"
                exact
                component={Materiales}
                type="private"
              />
              <AuthRoute
                path="/logistica/depositos"
                exact
                component={Depositos}
                type="private"
              />
              {/* NUEVO */}
              {/*Path formulario crear Depositos*/}
              <AuthRoute
                path="/logistica/depositos/deposito"
                exact
                component={Deposito}
                type="private"
              />
              {/*Path editar Depositos*/}
              <AuthRoute
                path="/logistica/depositos/deposito/:id/:modo"
                exact
                component={Deposito}
                type="private"
              />
              {/*Path formulario crear Materiales*/}
              {/*
                <AuthRoute
                path="/logistica/materials/listado"
                exact
                component={Listado}
                type="private"
              />
              */}
              {/*Path editar Materiales*/}
              {/*
                <AuthRoute
                path="/logistica/materials/listado/:id/:modo"
                exact
                component={Listado}
                type="private"
              />
              */}

              <AuthRoute
                path="/operativas/planificaciones"
                exact
                component={Planificaciones}
                type="private"
              />
              <AuthRoute
                path="/operativas/operaciones"
                exact
                component={Operaciones}
                type="private"
              />

              {/* NUEVO */}
              {/*Path crear almacenes*/}
              <AuthRoute
                path="/operativas/almacenamiento/almacen"
                exact
                component={Almacenes}
                type="private"
              />
              {/*Path editar almacenes*/}
              <AuthRoute
                path="/operativas/almacenamiento/almacen/:id/:modo"
                exact
                component={Almacenes}
                type="private"
              />
              {/*Path formulario crear piezas*/}
              <AuthRoute
                path="/operativas/almacenamiento/piezas"
                exact
                component={Piezas}
                type="private"
              />
              {/*Path editar piezas*/}
              <AuthRoute
                path="/operativas/almacenamiento/piezas/:id/:modo"
                exact
                component={Piezas}
                type="private"
              />
              
              {/*Path Transferir*/}
              <AuthRoute
                path="/operativas/almacenamientos-transferir/:id"
                exact
                component={Transferir}
                type="private"
              />
              {/* NUEVO */}


              <AuthRoute
                path="/operativas/almacenamiento"
                exact
                component={Almacenamiento}
                type="private"
              />

              {/* NUEVO */}
              {/*Almacenamiento > Ver*/}
              <AuthRoute
                path="/operativas/FichaSubEntrada"
                exact
                component={FichaSubEntrada}
                type="private"
              />


              {/*Path formulario crear Depositos*/}
              <AuthRoute
                path="/logistica/depositos/deposito"
                exact
                component={Deposito}
                type="private"
              />
              {/*Path editar Depositos*/}
              <AuthRoute
                path="/logistica/depositos/deposito/:id/:modo"
                exact
                component={Deposito}
                type="private"
              />
              {/*Path formulario crear Materiales*/}
              <AuthRoute
                path="/logistica/materials/material"
                exact
                component={Material}
                type="private"
              />
              {/*Path editar Materiales*/}
              <AuthRoute
                path="/logistica/materials/material/:id/:modo"
                exact
                component={Material}
                type="private"
              />

              {/* NUEVO */}
              {/*
              <AuthRoute
                path="/postventa/documentacion"
                exact
                component={Documentacion}
                type="private"
              />
              */}
              <AuthRoute
                path="/postventa/soportes"
                exact
                component={Soportes}
                type="private"
              />
              <AuthRoute
                path="/postventa/soportes/soporte"
                exact
                component={Soporte}
                type="private"
              />
              <AuthRoute
                path="/postventa/soportes/soporte/:id/:modo"
                exact
                component={Soporte}
                type="private"
              />
              <AuthRoute
                path="/postventa/auditoria"
                exact
                component={Auditoria}
                type="private"
              />

              {/*Servicios*/}
              {/*Path Mantenimiento*/}
              <AuthRoute
                path="/servicio/maquinarias"
                exact
                component={Maquinarias}
                type="private"
              />
              {/*Path Crear Mantenimiento*/}
              <AuthRoute
                path="/servicio/maquinarias/maquinaria"
                exact
                component={Maquinaria}
                type="private"
              />
              {/*Path editar Mantenimiento*/}
              <AuthRoute
                path="/servicio/maquinarias/maquinaria/:id/:modo"
                exact
                component={Maquinaria}
                type="private"
              />
              {/*Path Crear Maquinaria*/}
              <AuthRoute
                path="/servicio/maquinarias/fichamaquinaria"
                exact
                component={FormularioMaquinaria}
                type="private"
              />
              {/*Path editar Maquinaria*/}
              <AuthRoute
                path="/servicio/maquinarias/fichamaquinaria/:id/:modo"
                exact
                component={FormularioMaquinaria}
                type="private"
              />
              {/*Path editar Actuaciones*/}
              <AuthRoute
                path="/servicio/maquinarias/actuaciones/:id/:modo"
                exact
                component={Actuaciones}
                type="private"
              />
              {/*Path Instalación*/}
              <AuthRoute
                path="/servicio/instalaciones"
                exact
                component={Instalaciones}
                type="private"
              />
              {/*Path Crear Instalación*/}
              <AuthRoute
                path="/servicio/instalaciones/instalacion"
                exact
                component={Instalacion}
                type="private"
              />
              {/*Path editar Instalación*/}
              <AuthRoute
                path="/servicio/instalaciones/instalacion/:id/:modo"
                exact
                component={Instalacion}
                type="private"
              />     

              <AuthRoute 
                path="/servicio/informemaquinaria" 
                exact
                component={InformeMaquinariaServicios} 
                type="private"
              />

              {/*Path Planning Mantenimiento Preventivo*/}
              {/*
              <AuthRoute
                path="/servicio/planningmantenimiento"
                exact
                component={PlanningSemanalMantenimientoPreventivo}
                type="private"
              />
              */}
              
              <AuthRoute
                path="/administracion/compras"
                exact
                component={GestionCompra}
                type="private"
              />
              {/*Path Crear Hoja de pedido*/}
              <AuthRoute
                path="/administracion/compras/hojaspedidos"
                exact
                component={HojasDePedidos}
                type="private"
              />
              {/*Path editar Hoja de pedido*/}
              <AuthRoute
                path="/administracion/compras/hojaspedidos/:id/:modo"
                exact
                component={HojasDePedidos}
                type="private"
              />
              {/*Path Crear Orden de compra*/}
              <AuthRoute
                path="/administracion/compras/ordenescompras"
                exact
                component={OrdenDeCompra}
                type="private"
              />
              {/*Path editar Orden de compra*/}
              <AuthRoute
                path="/administracion/compras/ordenescompras/:id/:modo"
                exact
                component={OrdenDeCompra}
                type="private"
              />                

              <AuthRoute
                path="/administracion/ventas"
                exact
                component={Ventas}
                type="private"
              />

              <AuthRoute
                path="/administracion/exportacion"
                exact
                component={ExportacionContanet}
                type="private"
              />
              {/*Path Crear Factura Unica*/}
              {/*
              <AuthRoute
                path="/administracion/ventas/facturasunicas"
                exact
                component={FacturaUnica}
                type="private"
              />
              */}
              {/*Path editar Factura Unica*/}
              {/*}
              <AuthRoute
                path="/administracion/ventas/facturasunicas/:id/:modo"
                exact
                component={FacturaUnica}
                type="private"
              />
              */}
              {/*Path Crear Factura Recurrente*/}
              {/*}
              <AuthRoute
                path="/administracion/ventas/facturasrecurrentes"
                exact
                component={FacturaRecurrente}
                type="private"
              />
              */}
              {/*Path editar Factura Unica*/}
              {/*
              <AuthRoute
                path="/administracion/ventas/facturasrecurrentes/:id/:modo"
                exact
                component={FacturaRecurrente}
                type="private"
              />
              */}
              <AuthRoute
                path="/finanzas/tesoreria"
                exact
                component={GestionTesoreria}
                type="private"
              />

              {/* PERIODIFICACIÓN */}
              <AuthRoute
                path="/finanzas/periodificacion"
                exact
                component={Periodificacion}
                type="private"
              />
              <AuthRoute
                path="/finanzas/periodificacion/:tipo/"
                exact
                component={PeriodificacionFormulario}
                type="private"
              />
              <AuthRoute
                path="/finanzas/periodificacion/:tipo/:id/:modo"
                exact
                component={PeriodificacionFormulario}
                type="private"
              />
              {/*
              <AuthRoute
                path="/finanzas/periodificacion/ingresos"
                exact
                component={PeriodificacionFormulario}
                type="private"
              />
              <AuthRoute
                path="/finanzas/periodificacion/ingresos/:id/:modo"
                exact
                component={PeriodificacionFormulario}
                type="private"
              />
              */}


              {/*Path Crear Operacion pendiente*/}
              <AuthRoute
                path="/finanzas/tesoreria/PendientesFormulario"
                exact
                component={PendientesFormulario}
                type="private"
              />
              <AuthRoute
                path="/finanzas/tesoreria/PendientesFormulario/:id/:modo"
                exact
                component={PendientesFormulario}
                type="private"
              />
              {/*Path Crear Operacion pendiente*/}
              <AuthRoute
                path="/finanzas/tesoreria/cajasbancos"
                exact
                component={CajasBancos}
                type="private"
              />
              {/*Path editar caja*/}
              <AuthRoute
                path="/finanzas/tesoreria/cajasbancos/:id/:modo"
                exact
                component={CajasBancos}
                type="private"
              />
              {/*Path Crear Categorias*/}
              <AuthRoute
                path="/finanzas/tesoreria/categorias"
                exact
                component={Categorias}
                type="private"
              />
              {/*Path Crear Categorias*/}
              <AuthRoute
                path="/finanzas/tesoreria/categorias/:id/:modo"
                exact
                component={Categorias}
                type="private"
              />
              {/*Path Crear Conceptos*/}
              <AuthRoute
                path="/finanzas/tesoreria/conceptos"
                exact
                component={Conceptos}
                type="private"
              />
               {/*Path Crear Conceptos*/}
               <AuthRoute
                path="/finanzas/tesoreria/conceptos/:id/:modo"
                exact
                component={Conceptos}
                type="private"
              />
              {/*Path Crear Instrumentos*/}
              <AuthRoute
                path="/finanzas/tesoreria/instrumentos"
                exact
                component={Instrumentos}
                type="private"
              />
              {/*Path Crear Instrumentos*/}
              <AuthRoute
                path="/finanzas/tesoreria/instrumentos/:id/:modo"
                exact
                component={Instrumentos}
                type="private"
              />
              <AuthRoute
                path="/finanzas/explotacion"
                exact
                component={Explotacion}
                type="private"
              />
              {/*
              <AuthRoute
                path="/finanzas/controlgestion"
                exact
                component={ControlGestion}
                type="private"
              />
              */}

              <AuthRoute
                path="/rrhh/talento/"
                exact
                component={Talento}
                type="private"
              />
              <AuthRoute
                path="/rrhh/talento/seleccions"
                exact
                component={Seleccions}
                type="private"
              />
              <AuthRoute
                path="/rrhh/talento/seleccions/:id/:modo"
                exact
                component={Seleccions}
                type="private"
              />
              <AuthRoute
                path="/rrhh/talento/formacion"
                exact
                component={Formacion}
                type="private"
              />
              <AuthRoute
                path="/rrhh/talento/formacion/:id/:modo"
                exact
                component={Formacion}
                type="private"
              />
              <AuthRoute
                path="/rrhh/talento/recmed"
                exact
                component={RecMed}
                type="private"
              />
              <AuthRoute
                path="/rrhh/talento/recmed/:id/:modo"
                exact
                component={RecMed}
                type="private"
              />
              <AuthRoute
                path="/rrhh/talento/enfermedad"
                exact
                component={Enfermedad}
                type="private"
              />
              <AuthRoute
                path="/rrhh/talento/enfermedad/:id/:modo"
                exact
                component={Enfermedad}
                type="private"
              />
              <AuthRoute
                path="/rrhh/talento/accidente"
                exact
                component={Accidente}
                type="private"
              />
              <AuthRoute
                path="/rrhh/talento/accidente/:id/:modo"
                exact
                component={Accidente}
                type="private"
              />
              <AuthRoute
                path="/rrhh/talento/documentacion"
                exact
                component={RRHHdocs}
                type="private"
              />
              <AuthRoute
                path="/rrhh/talento/documentacion/:id/:modo"
                exact
                component={RRHHdocs}
                type="private"
              />
              <AuthRoute
                path="/rrhh/talento/uniforme"
                exact
                component={Uniforme}
                type="private"
              />

              <AuthRoute
                path="/rrhh/talento/uniforme/:id/:modo"
                exact
                component={Uniforme}
                type="private"
              />
              
              <AuthRoute
                path="/rrhh/gestionpersonal"
                exact
                component={GestionPersonal}
                type="private"
              />
              <AuthRoute
                path="/rrhh/gestionpersonal/empleados"
                exact
                component={Empleados}
                type="private"
              />
              <AuthRoute
                path="/rrhh/gestionpersonal/empleados/:id/:modo"
                exact
                component={Empleados}
                type="private"
              />
              <AuthRoute
                path="/rrhh/gestionpersonal/vacaciones"
                exact
                component={Vacaciones}
                type="private"
              />
              <AuthRoute
                path="/rrhh/gestionpersonal/vacaciones/:id/:modo"
                exact
                component={Vacaciones}
                type="private"
              />
              <AuthRoute
                path="/rrhh/gestionpersonal/personal"
                exact
                component={Personal}
                type="private"
              />
              <AuthRoute
                path="/rrhh/gestionpersonal/personal/:id/:modo"
                exact
                component={Personal}
                type="private"
              />


              <AuthRoute
                path="/rrhh/gestioncpe"
                exact
                component={GestionCPE}
                type="private"
              />
              <AuthRoute
                path="/rrhh/gestioncpe/personalcpe"
                exact
                component={PersonalCPE}
                type="private"
              />
              <AuthRoute
                path="/rrhh/gestioncpe/personalcpe/:id/:modo"
                exact
                component={PersonalCPE}
                type="private"
              />

              {isAuthUser && <AuthRoute exact path="/informes/ventas" component={InformeVentas} type="private" />}
              {isAuthUser && <AuthRoute exact path="/informes/rentabilidad" component={Rentabilidad} type="private" />}
              {isAuthUser && <AuthRoute exact path="/informes/general" component={General} type="private" />}
              {isAuthUser && <AuthRoute exact path="/informes/maquinaria" component={InformeMaquinaria} type="private" />}
              
             
              {/*
               <AuthRoute
                path="/rrhh/talento/uniforme/:id/W"
                exact
                component={FormularioUniforme}
                type="private"
              />
                 <AuthRoute
                path="/rrhh/talento/uniforme/:id/R"
                exact
                component={FichaUniforme}
                type="private"
              />
              */}
              {/*
              <AuthRoute
                path="/rrhh/saludformacion"
                exact
                component={SaludFormacion}
                type="private"
              />
              */}

              <AuthRoute path="/my-account" exact component={MyAccount} type="private" />
            </Switch>
          </Main>

        </Route>

      </Switch>
    </Router>
  )
  
});