import { useForm, FormProvider } from "react-hook-form";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FormFieldText from '../../../../components/Common/FormFieldText'
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from "@material-ui/core";
import AlertaMensaje from "../../../../components/Common/AlertaMensaje";
import MUIDataTable from "mui-datatables";
import OpcionesConf from "./OpcionesConf";
import AddButtonToolbar from "../../../../components/Common/AddButtonToolBar";
import {TEXT_LABELS} from "../../../../configuraciones/conf"
//import TableAcciones from "../Common/TableAcciones";
import { Button } from "semantic-ui-react";
import { faChessQueen } from "@fortawesome/free-solid-svg-icons";
import FormFieldTextUncontrolled from "../../../../components/Common/FormFieldTextUncontrolled";
import { ChangeHistoryRounded } from "@material-ui/icons";


    
const useStyles = makeStyles((theme) => ({
  overY:{
    overflow: 'overflow'
  } ,  
  root: {
      flexGrow: 1,
      
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

const FormConfigTabla = ()=> {
    
      const [campos, setCampos] = useState([])
      const {nombre_tabla, modo } = useParams();
      const [datosTabla, setDatosTabla] = useState([])
      const changedValue = useRef(null);
      const methods = useForm();
      const classes = useStyles();
      const { control, handleSubmit} = useForm();
      const [openAlert, setOpenAlert] = React.useState(false);
      const [isLoading, setIsLoading] = useState(true);
        
        const handleCloseAlert = (event, reason) => {
          if (reason === 'clickaway') {
            return;
          }  
          setOpenAlert(false);
        };  
      
        const handleChange = (e) =>{
          changedValue.current = e;
        }

        const changeData = ()=>{
          const fetchData = async () => {
        
            const respDic = await axios( process.env.REACT_APP_API_URL + "dicconfigs/" + nombre_tabla);
           
            if (respDic.data) {              
              setDatosTabla(respDic.data[0]);
              setIsLoading(false);
            }
          }
          fetchData();

        }


    // aqui se recoge los datos de la tabla asi como el numemero de columnas
  useEffect(()=>{    
    if (isLoading) {
      const fetchData = async () => {
        
        const respDic = await axios( process.env.REACT_APP_API_URL + "dicconfigs/" + nombre_tabla);
       
        if (respDic.data) {              
          // setColumnas(respDic.data[1]);
          setDatosTabla(respDic.data[0]);
          setIsLoading(false);
        }
      }
      fetchData();
    
    }  

  },[datosTabla])
  
        
    
        useEffect(() => {      
          
         setCampos([

            {
                //
                name: "Id",
                label: "Id",
                required: false,
                disabled : true,
                options: {
                 filter: true,
                 sort: true,
                 type : "text",
                },
                xs: 6
            },
            
            {
              name: "Etiqueta",
              disabled: false,
              required: false,
              label: "Etiqueta",
              options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <FormFieldTextUncontrolled propiedades={
                      {
                        name: "Etiqueta",
                        disabled: false,
                        required: false,
                        label: "Etiqueta",
                        value: value,
                      }
                    }
                    onChangeValue={event =>{ handleChange(event)
                    updateValue(event)}}
                    />
                  ); 
                  }
              },
              xs: 6,
            },
            {
              name: "Activo",
              label: "Activo",
              options: {
               filter: true,
               sort: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <OpcionesConf
                    value={value}
                    index={tableMeta.columnIndex}
                    change={event => updateValue(event)}
                  />
                );
              },
              }
             },
             {
              name:"",
              label: "",
              options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                 <button className="btn-sm btn-accion" onClick={

                  
                  
                  async ()=>{

                  let datos = tableMeta.rowData;

                  datos.push(nombre_tabla);

                  let user = JSON.parse(localStorage.getItem("user"));
                  const AuthStr = "Bearer ".concat(user.access_token);
                  const SERVER_URL = `${process.env.REACT_APP_API_URL}`

                  await axios
                  .put(`${SERVER_URL}dicconfigs/${nombre_tabla}`, {
                    headers: {        
                      "Content-Type": "application/json",
                      Authorization: AuthStr,
                    },
                    datos
                    
                    
                  })
                  .then((response) => {       
                    if(!isNaN(response.data)){
                     
                     changeData();
                     setOpenAlert(true);

                    }
                    })
                    .catch((error) => {
                      console.log("error " + error);
                      console.log(error.response.data); // => the response payload 
                      console.log(error.response.data.message)
                });

                 }
                
                }>Guardar</button>
                   );
              },
              }
             }
         ])
    },[]);
    


    const CAMPOS_ADD = [
      {
        required: true,
        label: "Etiqueta",
        name: "Etiqueta",
        type: "text",    
        disabled: false, 
        value: '',                
        xs:6  
  }
];

    

    const OPTIONS_EDIT = {
      filterType: 'checkbox',
      print: false,
      selectableRowsHideCheckboxes: true,
      download: false,
      scrollbars: true,
      pagination: false,
      expandableRows: false,
      selectableRowsHeader: false,
      expandableRowsHeader: false,
      expandableRowsOnClick: false,
      elevation:0,
  
       customToolbar: () => {
        return (
          <AddButtonToolbar 
          title = "Formulario Dic"
          urlForm = "dicconfigs" 
          action = {()=>console.log("")}
          campos = {CAMPOS_ADD}
          toolTipTitle = "Añadir campo"
          type = "add"
          value = {nombre_tabla}
          />
          
        );
      },
      textLabels: TEXT_LABELS,
  };
    
    return (
    <>    
      {!isLoading && (
      <>
        {/* FORMULARIO */}
        <div className={classes.root}>
              
              <MUIDataTable
              options ={OPTIONS_EDIT}
              columns ={campos} 
              className={classes.overY}
              data ={datosTabla}
              />
 
              {openAlert && 
              <AlertaMensaje
                mensaje={"¡Parte guardado correctamente!"} 
                isOpen={openAlert} 
                tipoMensaje="success"
                cerrar={handleCloseAlert}
              />
              } 
        </div>  
      </>
      )}
        <div>
          {isLoading && 
            <div className={classes.linear}>
                  <LinearProgress />
            </div>
          }
        </div>
            </>
        )
    
    
}

export default FormConfigTabla; 