import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormFieldCombo from "../../../../../components/Common/FormFieldCombo";
import FormFieldNumber from "../../../../../components/Common/FormFieldNumber";
import FormFieldComboArray from "../../../../../components/Common/FormFieldComboArray";
import FormFieldText from "../../../../../components/Common/FormFieldText";

import { useForm } from "react-hook-form";

const propiedadesArticulo = {
    urlData: "materials",
    campoEtiqueta: "Nombre",
    campoId: "id",
    required: false,
    name: "descripcion",
    disabled: false,
    label: "Materiales",
    value: "",
    multiple: false,
    xs:4,
}

const propiedadesCantidad = {
    xs:2,
    required: false,
    label: "Cantidad",
    name: "cantidad",
    type: "number",    
    value: ''
}

const propiedadesPrecioUnidad = {
    xs:2,
    required: false,
    label: "Precio .Ud",
    name: "precio",
    type: "number",    
    value: ''
}

const propiedadesIgic = {
    urlData: "dic/diccionarios/igic",
    campoEtiqueta: "Etiqueta,Valor",
    campoId: "Id",
    required: false,
    name: "igic",
    disabled: false,
    label: "Igic",
    value: "",
    multiple: false,
    xs:3,
}

const propiedadesIrpf = {
    urlData: "dic/diccionarios/irpf",
    campoEtiqueta: "Etiqueta,Valor",
    campoId: "Id",
    required: false,
    name: "irpf",
    disabled: false,
    label: "Irpf",
    value: "",
    multiple: false,
    xs:3,
}

const propiedadesProveedor = {
    urlData: "cuentas/proveedores",
    campoEtiqueta: "Nombre_Comercial",
    campoId: "Id",
    required: false,
    name: "proveedor",
    disabled: false,
    label: "Proveedor",
    value: "",
    multiple: false,
    xs:3,
}

const propiedadesDeposito = {
    urlData: "depositos",
    campoEtiqueta: "nombre",
    campoId: "id",
    required: false,
    name: "deposito",
    disabled: false,
    label: "Almacén",
    value: "",
    multiple: false,
    xs:3,
}

const propiedadesServicio = {
    xs:4,
    required: false,
    label: "Servicio",
    name: "servicio",
    type: "rext",    
    value: ''
}

const propiedadesProducto = {
    xs:4,
    required: false,
    label: "Producto",
    name: "producto",
    type: "rext",    
    value: ''
}

const FormularioTablaHojaDePedidos = ({listadotabla}) => {

    const { control, setValue, getValues} = useForm();
    const [ tipoPedido, setTipoPedido ] = useState({Id: 'Materiales', Etiqueta: 'Materiales'})
    
    const handleChangeTipo = (valor) => {
        setTipoPedido(valor)
    }
    
    const propiedadesTipoHojaPedido = {
        campoEtiqueta: "Etiqueta",
        required: false,
        label: "Tipo de pedido",
        name: "tipologia",
        disabled: false,
        type: "comboArray", 
        options:[{Id: 'Servicios', Etiqueta: 'Servicios'}, {Id: 'Materiales', Etiqueta: 'Materiales'}, {Id: 'Productos', Etiqueta: 'Productos'}], 
        multiple:false,   
        value: tipoPedido,
        onChangeValue: handleChangeTipo,
        xs:2
    } 

    const handleClick = () => {  
        if(tipoPedido){

            const material = getValues("descripcion")
            const servicio = getValues("servicio") 
            const producto = getValues("producto")
            const cantidad = getValues("cantidad")
            const precio = getValues("precio")
            const proveedor = getValues("proveedor")
            const deposito = getValues("deposito")
            const igic = getValues("igic")
            const irpf = getValues("irpf")

            const esMaterial = (typeof material === "undefined" || material.Id === -1 ) ? false : true;
            const idMaterial = esMaterial === true ? material.id : -1
            const eanMaterial = esMaterial === true ? material.ean : (servicio ? 'servicio' : 'producto')

            const nombreMaterial = esMaterial === true ? material.Nombre : (servicio ? servicio : producto)
            const unidadMaterial = esMaterial === true ? material.tipoUnidad : ''

            let errores = 0;
            let erroresMsg = "";

            if(!tipoPedido) {errores++; erroresMsg += "Debe seleccionar un tipo de pedido\n";}
            if(!nombreMaterial) {errores++; erroresMsg += "Debe seleccionar un material producto, o servicio\n";}
            if(!cantidad) {errores++; erroresMsg += "Debe intriducir una cantidad\n";}
            if(!precio) {errores++; erroresMsg += "Debe intriducir un precio unitario\n";}
            if(!igic) {errores++; erroresMsg += "Debe seleccionar un tipo de IGIC\n";}        
            if(!deposito) {errores++; erroresMsg += "Debe seleccionar un centro de coste\n";}
            
            if(errores > 0){
                alert(erroresMsg)
                return;
            }

            listadotabla({
                id:uuidv4(),
                idArticulo:idMaterial,
                idProveedor:proveedor.Id,
                idDeposito:deposito.id,
                idIgic: igic ? igic.Id : null,
                idIrpf: irpf ? irpf.Id : null,
                codigo: eanMaterial,
                descripcion: nombreMaterial,
                unidad: unidadMaterial,
                cantidad:cantidad,
                precioUnidad:precio,
                precioTotal:cantidad*precio,
                proveedores:proveedor.Razon_Social,
                depositos:deposito.nombre,
                igic: igic ? igic.Valor : null,
                irpf: irpf ? irpf.Valor : null,
            })

            setValue("descripcion", "")
            setValue("cantidad", "")
            setValue("precio", "")
            setValue("igic", "")      
            setValue("irpf", "") 
            setValue("servicio", "")   
            setValue("deposito", "")   
        }
    }

    const esMaterial = tipoPedido && tipoPedido.Id === "Materiales" 
    const esServicio = tipoPedido && tipoPedido.Id === "Servicios"
    const esProducto = tipoPedido && tipoPedido.Id === "Productos"
    


    return (
    <>
    <Grid container  spacing={1} className="mt-2 borderFormulario">        
        
        <FormFieldComboArray propiedades={propiedadesTipoHojaPedido} control={control} />
        
        { esMaterial && (
        <FormFieldCombo propiedades={propiedadesArticulo} control={control}/> )}
        { esServicio && (
        <FormFieldText propiedades={propiedadesServicio} control={control}/>)}
        { esProducto && (
        <FormFieldText propiedades={propiedadesProducto} control={control}/>)}
        
        <FormFieldCombo propiedades={propiedadesProveedor} control={control}/>
        <FormFieldCombo propiedades={propiedadesDeposito} control={control}/>
    

        <FormFieldNumber propiedades={propiedadesCantidad} control={control}/> 
        <FormFieldNumber propiedades={propiedadesPrecioUnidad} control={control}/>
        <FormFieldCombo propiedades={propiedadesIgic} control={control}/>
        <FormFieldCombo propiedades={propiedadesIrpf} control={control}/>
        
        <Grid item xs={2} className="" >
        <Button className="" variant="contained" color="primary" component="span" onClick={handleClick}>
            AÑADIR
        </Button> 
        </Grid>

    </Grid>
    </>
    )
}
export default FormularioTablaHojaDePedidos;