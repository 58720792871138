import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
//Ficha entidad general para reutilizar en cada ficha
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaDeposito = () => {
    let { id } = useParams();

    const [Deposito, setDeposito] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respDeposito = await axios(`${process.env.REACT_APP_API_URL}depositos/${id}`);    
          setDeposito(respDeposito.data)  
        };
        fetchData();  
    }, [id]);
    //Recogemos la fecha por dia/mes+1/año
    const convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    } 

    //Imprimimos los datos 
    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(Deposito).length > 0){  
            setCampos([
            {
                classsLabel: "",
                label: "Fecha creación:",
                value: Deposito.fechaCreacion,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Codigo:",
                value: Deposito.codigo,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Nombre:",
                value: Deposito.nombre,
                classText: classText,
                xs:4
            },
            {
                //Cada 3 label con datos hacemos un salto de línea
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
                ///////////////////////////////////////////////////
            },
            {
                classsLabel: "",
                label: "Estado:",
                value: Deposito.estado.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Ficheros:",
                value: Deposito.ficheros,
                type: 'file',
                classText: classText,
                xs:6
            },
            {
                //Cada 3 label con datos hacemos un salto de línea
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
                ///////////////////////////////////////////////////
            },
            {
                classsLabel: "",
                label: "Observaciones:",
                value: Deposito.observaciones,
                classText: classText,
                xs:12
            },
         
            
        ])
    }
},[Deposito]) 

return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    urlClick = {`/logistica/depositos/${id}/W`}
    />
    </>
  )
}
export default FichaDeposito;