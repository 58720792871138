import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../components/Common/FormularioEntidad'
import Grid from '@material-ui/core/Grid';
import { connect } from "react-redux";
import { logout } from "../../../../actions/auth";

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioDatPersonales = ({reducerApi, logout}) => {
    const { id, modo } = useParams();
    const classes = useStyles(); 
    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [camposEdit,setCamposEdit] = useState([])  
    const [foto, setFoto] = useState([]) 
    const { user } = reducerApi;
    

    const forzarRecarga = () => {
        logout();
        //window.location.href = '/';
    }

    useEffect(() => { 

        setCampos([  
            {
                disabled: true,
                required: false,
                type: "avatar",
                src :foto,
                name: "avatar-perfil",
                width: 120,
                height: 120,
            },
            {
                type:"lineseparator",
                xs: 6,
            },
            {
                required: false,
                label: "Usuario",
                name: "idUsuario1",
                type: "text", 
                disabled: true,             
                value: user.username,
                xs:10,
                classes: "mt-5"
            },
            {
                type:"lineseparator",
                xs: 6,
            },
            {
                required: false,
                label: "Correo electronico",
                name: "email1",
                type: "text", 
                disabled: true,             
                value: user.email,         
                xs:10
            }              
        ])

        setCamposEdit([
            {
                disabled: false,
                required: false,
                type: "avatar",
                src :"",
                name: "avatar-perfil-edit",
                width: 120,
                height: 120,
            },
            {
                type:"lineseparator",
                xs: 6,
            },
            {
                required: false,
                label: "Usuario",
                name: "nombreUsuario",
                type: "text", 
                disabled: false,             
                value: '',
                xs:10,
                classes: "mt-5",
            },
            {
                type:"lineseparator",
                xs: 6,
            },
            {
                required: false,
                label: "Correo electronico",
                name: "emailUsuario",
                type: "text", 
                disabled: false,             
                value: '',
                xs:10
            } 
        ])

        const fetchData = async () => { 
            if (modo === "W" || modo === "R" || typeof(modo) === "undefined") {
                const resppersonal = await axios(
                    `${process.env.REACT_APP_API_URL}rrhhdatospersonales/propios/${user.id}`
                );          
                
                const foto = resppersonal.data 
                && Object.keys(resppersonal.data).length > 0  ? resppersonal.data.Usuario.foto : null  
    
                if(foto){
                    setFoto(foto)
                }
            }
            setDatosCargados(true);
    
        };    
        
        fetchData();      
    },[]);

    

    if(campos.length>0)
        campos[0].src = foto;

    if (datoscargados){ 
    
        return ( 
            <>
             <Grid container spacing ={3}>
                <Grid item xs={6} >
                <FormularioEntidad 
                urlGoto = ""
                campos={campos}
                clases={classes.form}
                type="read"
                />
                </Grid>
    
                <Grid item xs ={6} >
                <FormularioEntidad
                urlForm = "rrhhdatospersonales/changeaccessdata"
                urlGoto = ""
                campos={camposEdit}
                clases={classes.form}
                action={() => forzarRecarga()}
                type="add"
                />
                </Grid>
             </Grid>
            </>
        )
        }
        else {
        return (              
            <div className={classes.linear}>
                <CircularProgress />
            </div>
        );
        }
}

export default connect(({ reducerApi }) => ({ reducerApi }), {
    logout,
})(FormularioDatPersonales);

//export default FormularioDatPersonales;