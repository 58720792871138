import React, { useState, useEffect } from "react"
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import FormFieldTextUncontrolled from "../../../../../components/Common/FormFieldTextAreaUncontrolled";
import AlertaMensaje from "../../../../../components/Common/AlertaMensaje";

const FormularioExportacionGeneral = () => {

    const [openAlert, setOpenAlert] = useState({open: false, mensaje: "", tipo:"success"});
    const [datosContanet, setDatosContanet] = useState({})

    const fetchData = async () => {
        let user = JSON.parse(localStorage.getItem("user"));            
        const respData = await axios( process.env.REACT_APP_API_URL + "exportaciondatos/contanet");
        
        if (respData.data) {              
            setDatosContanet(respData.data); 
        }
    };

    useEffect(() => {    
        fetchData();
        return () => {setDatosContanet([])}
    }, [])     
    
    const [campoNumeroEmpresaContanet, setCampoNumeroEmpresaContanet] = useState({
        required: false,
        label: "Nº Empresa CONTANET",      
        name: "numempresacontanet",
        type: "text",  
        value: '',
        disabled : false,
        xs:3
    })

    const [campoIgicRepercutido, setCampoIgicRepercutido] = useState({
        required: false,
        label: "Cuenta IGIC Repercutido",      
        name: "cuentaigicrepercutido",
        type: "text",  
        value: '',
        disabled : false,
        xs:3
    })

    const [campoIgicSoportado, setCampoIgicSoportado] = useState({
        required: false,
        label: "Cuenta IGIC Soportado",      
        name: "cuentaigicsoportado",
        type: "text",  
        value: '',
        disabled : false,
        xs:3
    })

    useEffect(() => {
        if(datosContanet.parametros){
            const obj = JSON.parse(datosContanet.parametros);
            setCampoNumeroEmpresaContanet({...campoNumeroEmpresaContanet, value: obj.campoNumeroEmpresaContanet})   
            setCampoIgicRepercutido({...campoIgicRepercutido, value: obj.campoIgicRepercutido})
            setCampoIgicSoportado({...campoIgicSoportado, value: obj.campoIgicSoportado}) 
        }
        //return () => {setDatosContanet([])}
    }, [datosContanet]) 

    const handleChangeNumEmpresaContanet = (valor) => {
        setCampoNumeroEmpresaContanet({...campoNumeroEmpresaContanet, value: valor})        
    }

    const handleChangeIgicRepercutido = (valor) => {
        setCampoIgicRepercutido({...campoIgicRepercutido, value: valor})        
    }

    const handleChangeIgicSoportado = (valor) => {
        setCampoIgicSoportado({...campoIgicSoportado, value: valor})        
    }

    const actualizaDatos = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}${"exportaciondatos"}/${datosContanet.Id}`;
        
        let datos =  JSON.stringify({ 
            campoNumeroEmpresaContanet: campoNumeroEmpresaContanet.value, 
            campoIgicRepercutido: campoIgicRepercutido.value,
            campoIgicSoportado: campoIgicSoportado.value
        })

        axios
          .put(SERVER_URL, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => { 
            
            if(!isNaN(response.data)){       
              setOpenAlert({
                open : true,
                tipoMensaje : "success",
                mensaje : "Parámetros de Contanet actualizados correctamente"
              })    
              
            }else{
              setOpenAlert({
                open: true,
                tipoMensaje: "error",
                mensaje: "Ha ocurrido un error al actualizar los parámetros de Contanet"
              })
            }            
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          });
        
    }

    const handleClick = () => {  
        const mensaje = CompruebaValoresFormulario();
        
        if (mensaje.length > 0){
            setOpenAlert({open: true, mensaje: mensaje, tipo:"error"});
            return
        }

        actualizaDatos()
    }

    const CompruebaValoresFormulario = () => {
        let mensaje = "";

        if(campoNumeroEmpresaContanet.value.length !== 2)
            mensaje += "Debe introducir un nº de empresa de contanet con 2 dígitos.\n"

        if(campoIgicRepercutido.value.length !== 3)
            mensaje += "Debe introducir una cuenta de IGIC repercutido de 3 dígitos.\n"

        if(campoIgicSoportado.value.length !== 3)
            mensaje += "Debe introducir una cuenta de IGIC soportado de 3 dígitos.\n"

        return mensaje;    
    }

    const handleCloseAlert = (event, reason) => {  
        if (reason === 'clickaway') {
          return;
        }  
        setOpenAlert({open: false, mensaje: "", tipo:"success"});
    };

    return (
    <>
        <div className="mt-5">
        <Grid className="borderFormulario" container spacing={1}>
            <FormFieldTextUncontrolled propiedades={campoNumeroEmpresaContanet} onChangeValue={handleChangeNumEmpresaContanet}/>
            <FormFieldTextUncontrolled propiedades={campoIgicRepercutido} onChangeValue={handleChangeIgicRepercutido}/>
            <FormFieldTextUncontrolled propiedades={campoIgicSoportado} onChangeValue={handleChangeIgicSoportado}/>
        
            <Grid item xs={2} className="ml-4 mt-2" >
                <Button className="" variant="contained" color="primary" component="span" onClick={handleClick} disabled={false}>
                    GUARDAR
                </Button>                         
            </Grid>
        </Grid> 
        {openAlert.open && 
            <AlertaMensaje 
              mensaje={openAlert.mensaje} 
              isOpen={openAlert.open} 
              tipoMensaje={openAlert.tipo}
              cerrar={handleCloseAlert}
            />
          } 
        </div> 
    </>
    )
}
export default FormularioExportacionGeneral;