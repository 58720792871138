import React, {  useState, useEffect  } from "react";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({       
    input: {
        display: 'none',
    },

    formFileUpload: {
        /*height: '8rem',*/
        width: '28rem',
        maxWidth: '100%',
        textAlign: 'center',
        position: 'relative',
    },

    labelFileUpload: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: '2px',
        borderRadius: '1rem',
        borderStyle: 'dashed',
        borderColor: '#cbd5e1',
        backgroundColor: '#f8fafc',
      },

      uploadButton: {
        cursor: 'pointer',
        padding: '0.25rem',
        height: '50px',
        fontSize: '1rem',
        border: 'none',
        backgroundColor: 'transparent',
        '&:hover': {
            textDecorationLine: 'underline',
          },
      },

}));

const FormFieldFileUpload = ({ propiedades, control, handleFicheros = null, register = null }) => {
    console.log(propiedades)
    const classes = useStyles();
    const [ficheros, setFicheros] = useState([]);
    const [nombresFicheros, setNombresFicheros] = React.useState([]);
    const { id, modo } = useParams();

    useEffect(() => {
        if(handleFicheros){
            handleFicheros( ficheros, nombresFicheros )
        }
        return () => {
            
        }
    }, [nombresFicheros])

    const handleCapture = ({ target }) => {   
             
        for (let i=0; i<target.files.length; i++){  
          const fileReader = new FileReader();          
          fileReader.readAsDataURL(target.files[0]);
          fileReader.onload = (e) => {
            setFicheros(ficheros => [...ficheros, e.target.result] )
            setNombresFicheros(nombresFicheros => [...nombresFicheros, target.files[i].name] )                                  
            
          };
        }         
    }; 

    const handleDeleteNewFile = (index) => {    
        let elementos = JSON.parse(JSON.stringify(ficheros)); 
        let elementos2 = JSON.parse(JSON.stringify(nombresFicheros));       
        elementos.splice(index, 1)
        elementos2.splice(index, 1)
        setFicheros(elementos)
        setNombresFicheros(elementos2)      
    }

    const deshabilitado = (
        typeof(propiedades.disabled) === "undefined" ? 
        modo==="R" ?  true : false : propiedades.disabled
    )

    const handleDragEnter = (event) => {
        event.preventDefault();
        //dispatch({ type: 'AddToDropZone', inDropZone: true });
      };
    
      const handleDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
        //dispatch({ type: 'AddToDropZone', inDropZone: true });
      };

    const handleDrop = (event) => {
        event.preventDefault();
        let files = [...event.dataTransfer.files];
        let files_with_preview = [];
    
        files.map((file, index) => {
          file[`image_${index}`] = URL.createObjectURL(file);
          files_with_preview.push(file);
        });
    
        if (files) {
            files.forEach(element => {
                const fileReader = new FileReader();          
                fileReader.readAsDataURL(element);
                fileReader.onload = (e) => {
                    setFicheros(ficheros => [...ficheros, e.target.result] )
                    setNombresFicheros(nombresFicheros => [...nombresFicheros, element.name] )                                  
                    
                };
            });
        }
      };



return (  
<>             
    <Grid item xs={propiedades.xs} className="" >  
    <Paper variant="outlined" >
        { nombresFicheros && 
            nombresFicheros.map((option, index) => (                  
            <Chip 
                key={index}
                className="m-2"
                variant="outlined" 
                color="primary" 
                size="small" 
                onDelete={() => handleDeleteNewFile(index)} 
                label={option}
            />
        ))}
    </Paper>                
    </Grid>                               

    <div 
        id="fileuploaderdnd-container"
        className={`${propiedades.classButton} ${classes.formFileUpload} `}
        
        onDrop={(event) => handleDrop(event)}
        onDragOver={(event) => handleDragOver(event)}
        onDragEnter={(event) => handleDragEnter(event)}
        //className={propiedades.classButton}
        >     
        <input        
        accept="*"
        className={classes.input}
        id="contained-button-file"
        multiple
        onChange={handleCapture}
        type="file"
        name={propiedades.name}
        disabled={deshabilitado} //{modo==="R"?true:false}
        />
        <label id="label-file-upload" htmlFor="contained-button-file" className={ classes.labelFileUpload }>
            <div  className={classes.uploadButton}>
            <p>Arrastre sus ficheros<br/>o haga click aquí</p>
            {/*<button className={classes.uploadButton} disabled={deshabilitado}>Selecciónelos</button>*/}
            </div> 
        </label>
        {/*   
        <label htmlFor="contained-button-file" className="">
            <p>Arrastre y suelte </p>
            <p>sus ficheros aquí o</p>
            <Button disabled={deshabilitado} variant="contained" color="primary" component="span">
            Añadir archivos
            </Button>
        </label>
        */}
    </div>  
</>      
)
}
export default FormFieldFileUpload;