import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";
import { useHistory } from "react-router";
import { CircularProgress } from '@material-ui/core';

import FormularioOrdenDeCompra from "./FormularioOrdenDeCompra";
import MigasDePan  from "../../../../../components/Common/MigasDePan";

const OrdenDeCompra = (props) => {
    let { id, modo } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory(); 
    const [datos, setDatos] = useState();
    const [pdfFactura, setPdfFactura]= useState('');

    useEffect(() => {
      if (modo==="R") {
        history.push({
          pathname: `/administracion/compras/ordenescompras/${id}/W`,       
        });
      }
  },[])

    useEffect(() => {           
        if (isLoading && (modo === "R" || modo === "W")) {
          const fetchData = async () => {
            const respOrdenCompra = await axios( process.env.REACT_APP_API_URL + "ordenescompras/" + id);
            if (respOrdenCompra.data) {              
              setDatos(respOrdenCompra.data);
              setPdfFactura(respOrdenCompra.data.urlDocumento);
              setIsLoading(false);
            }
          };
          fetchData();
        }  
    
    
        if (!modo) {      
          setIsLoading(false);
        }
    }, [isLoading, datos, modo, id]);

    const generarPDF =  () => {    
      setIsLoading(true);
        const fetchData = async () => {
          const respFacturaUnica = await axios( process.env.REACT_APP_API_URL + "ordenescompras/generapdf/" + id);
          if (respFacturaUnica.data) {   
            setPdfFactura(respFacturaUnica.data);
            setIsLoading(false);
          }
        };
        fetchData();      
    }    



    const botoneraAcciones = [    
      {
        etiqueta: modo === "R" ? "Editar Compra" : "Cancelar",
        url: 
        typeof id === "undefined" ? `/administracion/compras` :
        modo === "R" ? 
          `/administracion/compras/ordenescompras/${id}/W` : 
          `/administracion/compras`,
        activo: true,
      },
      {
        etiqueta: "Guardar",
        form:'formulario-OrdenDeCompra',
        activo: modo === "R" ? true : true,
      },
      {
        etiqueta: "Generar documento",
        function: generarPDF,
        activo: modo === "R" ? false : true,
      },
      {        
        etiqueta: "Ver documento",
        file: pdfFactura ? pdfFactura : "",
        activo: pdfFactura ? true : false,        
      }
    ];

    const urlsMigasdepan = [
      {titulo: "Ordenes de compra", colorTexto: "texto-comercial", urlTo: "/administracion/compras"},
      {titulo: "Orden de compra", colorTexto: "texto-comercial", urlTo: `/administracion/compras/ordenescompra/${id}/${modo}`},
    ]

    const codigo = typeof datos !== "undefined" ? datos.codigo : "";

    return (
        <>
          <div className="col-8 col-main-home ">
            <SearchBar
              titulo="Administración"
              claseCabecera = "bg-gestion"
              buscar={false}
              imagenCabecera={"6-1-1-compras.png"}
            />
    
            {!isLoading && (
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                  <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/>
                  
    
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">  
                      
                      {modo !== "R" && 
                        <FormularioOrdenDeCompra />}   
                          
                    </div>
                  </div>
                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
            )}
            <div>{isLoading && <CircularProgress />}</div>
          </div>
        </>
      );

}
export default OrdenDeCompra;