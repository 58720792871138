import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormularioDatPersonales from "./FormularioDatPersonales";
import FormularioDatLaborales from "./FormularioDatLaborales";
import TipoDocumentacion from "./TipoDocumentacion";
import FormularioFicherosFormacion from "./FormularioFicherosFormacion";
import FormularioFicherosContrato from "./FormularioFicherosContrato";
import FormularioFicherosSeguridad from "./FormularioFicherosSeguridad";
import FormularioFicherosTiempo from "./FormularioFicherosTiempo";
import FormularioFicherosDocumentacionPersonal from "./FormularioFicherosDocumentacionPersonal";


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const TabsEmpleados = (props) => {
  const { id, modo } = useParams();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [idSubOperacion, setIdSubOperacion]=useState("Documentacion");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOperacion = (idO) =>{
    setIdSubOperacion(idO)
  } 

  useEffect(() => {

    },[value])

  return (
    <>
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Datos Personales" />

        {(modo === "W" || modo === "R") &&
        <Tab label="Datos Laborales" />}

        {(modo === "W" || modo === "R") &&  
        <Tab label="Documentación" />}
        
      </Tabs>
    </Paper>
    
    {value===0 && <FormularioDatPersonales />}

    {value===1 && <FormularioDatLaborales />}

    {value===2 && ( <TipoDocumentacion opera={handleOperacion}/> )}

    {value===2 && idSubOperacion==="Documentacion" && <FormularioFicherosDocumentacionPersonal />}

    {value===2 && idSubOperacion==="Formacion" && <FormularioFicherosFormacion />}

    {value===2 && idSubOperacion==="Contrato" && <FormularioFicherosContrato />}

    {value===2 && idSubOperacion==="Seguridad" && <FormularioFicherosSeguridad/>}

    {value===2 && idSubOperacion==="Tiempo" && <FormularioFicherosTiempo />}


</>
  );
}

export default TabsEmpleados;