import React, { useState, useEffect } from "react";
import axios from "axios";


import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import SearchBar from "../../../../../components/Common/SearchBar";
import FiltroListadoMaquinaria from "../../Gestion/Informes/FiltroListadoMaquinaria";
//import FiltroListadoMaquinaria from "./FiltroListadoMaquinaria";
import TableAcciones from "../../../../../components/Common/TableAcciones";
//import ListadoInformeMaquinaria from "./ListadoInformeMaquinaria";
import ListadoInformeMaquinaria from "../../Gestion/Informes/ListadoInformeMaquinaria";

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [year, month, day].join('-');
}

let date = new Date();
let firstDay = new Date(date.getFullYear(), 0, 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
firstDay = formatDate(firstDay);
lastDay = formatDate(lastDay);

const InformeMaquinariaServicios = () => {
    //const [esDirectivo, setEsDirectivo]=useState(false); 
    const [url, setUrl] = useState(`?fechainicial=${firstDay}&fechafinal=${lastDay}`)
    const [isLoading, setIsLoading] = useState(false)
    
    let botoneraPresupuesto = [{
        etiqueta: "Imprimir",
        function: (e) => handlePrintReport(), 
        activo: true
    },]

    const handlePrintReport = () => {
        const fetchData = async () => {
            setIsLoading(true);
            try{
                const respdatos = await axios(`${process.env.REACT_APP_API_URL}informes/imprimeinformemaquinaria${url}`)                
                if (respdatos.data) {
                    if(respdatos.data.includes(".pdf"))
                        window.open(respdatos.data, "_blank")                
                }
            }catch(error){
                console.log(error)
            }
            finally{
                setIsLoading(false);
            }
            };
            fetchData();      
    }
    /*
    useEffect(() => { 
        const fetchData = async () => {  
            //const user = JSON.parse(localStorage.getItem("user"));
            //const respdatos = await axios(`${process.env.REACT_APP_API_URL}rrhhdatoslaborales/${user.id}`);   
            //const datos = respdatos.data
            
            //if(datos && datos.Puesto_Trabajo && datos.Puesto_Trabajo.Etiqueta && datos.Puesto_Trabajo.Etiqueta === "DIRECTIVO"){
            //    setEsDirectivo(true)
            //}   
                 
        };    
    fetchData();    
    }, [])
    */

    const handleUrlFecha = (url) =>{
        setUrl(url)     
    }


    return (          
        <div className="col-main-home ">      
        <SearchBar
            titulo="INFORME DE MAQUINARIA"
            claseCabecera = "bg-produccion"
            buscar={false}
            imagenCabecera={"5-4-1-mantenimiento.png"}  
        />   
    
          <div className="bg-cuerpo p-10px">
            <div className="bg-white p-10px">   
              <div className="principal bg-cuerpo cuerpo-h overflow-auto overflow-hiddens table-responsive mx-auto">
                <FiltroListadoMaquinaria urlfecha={handleUrlFecha} firstDay={firstDay} lastDay={lastDay} showPrintButton={false} />
                    
                <Grid container spacing={0}>
                    <Grid item xs={12} className="">
                        {isLoading && (
                            <CircularProgress />
                        )}
                        {!isLoading && (
                            <ListadoInformeMaquinaria urlInforme={'maquinaria'} filtroFechas={url} />
                        )}                            
                    </Grid>
                </Grid>                    
                
              </div> 
            </div>
                     
            <div className="bg-cuerpo">
              <div className="bg-white">
                <TableAcciones botonaccion={botoneraPresupuesto} />
              </div>    
            </div>
           
    
          </div>
        </div> 
    );
}
export default InformeMaquinariaServicios;