import React, { useState, useEffect } from "react";
//import { DataGrid } from '@material-ui/data-grid';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
import { TextField } from "@material-ui/core";

const TablaPedidoProductos = ({listadoProductos,ListadoProductosFinal,actualizarDatos}) => {  
    const { id, modo } = useParams();
    const [listadoProductosInicial, setListadoProductosInicial] = useState([]) 

    const jornadas = [
        "J1 lunes a viernes 08:00 14:00",
        "J2 lunes a viernes 14:00 20:00",
        "J3 lunes a viernes 20:00 02:00",
        "J8 lunes a viernes 02:00 08:00",
        "J7 sábado 08:00 14:00",
        "J4 festivo 08:00 14:00",
        "J5 festivo 14:00 20:00",
        "J6 festivo 20:00 02:00",
        "J9 festivo 02:00 08:00"
    ]

  
    const columns = [ 
        { field: 'operativa', headerName: 'Operativa', width: 120 },
        { field: 'categoria', headerName: 'Categoría', width: 120 },
        { field: 'subcategoria', headerName: 'Subcategoría', width: 120 },
        { field: 'producto', headerName: 'Producto', width: 280 },//EAN 
        { field: 'cantidad', headerName: 'Cantidad', type: 'number', width: 120, editable: true },
        /*
        { field: 'cantidad', headerName: 'Cantidad',  type: 'number', 
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),          
        width: 100, sortable: false, editable: true },   
        */ 
        { field: 'operador', headerName: 'Operador', editable: true, width: 150 }, 
        { field: 'jornada', headerName: 'Jornada', type: 'singleSelect', 
            editable: true, width: 150, valueOptions: jornadas
        }, 

        { field: 'Eliminar', headerName: '', width: 30, 
            renderCell: (params) => {
                const disabled= modo === "W" && id ? false : true
                if(!disabled)
                    return <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>                
                return null;
            }, 
        },
        { field: 'Guardar', headerName: '', width: 30, 
            renderCell: (params) => {
                const disabled= modo === "W" && id ? false : true
                if(!disabled)
                    return <SaveIcon onClick={() => handleSaveElement(params.row)}/>                
                return null;
            }, 
        },
    ];

    const handleCellEditCommit = React.useCallback(
        ({ id, field, value }) => {
          if (field === 'cantidad') {                       
            const updatedRows = listadoProductosInicial.map((row) => {
              if (row.id === id) {  
                return { ...row,  cantidad: value};
              }
              return row;
            });     
            setListadoProductosInicial(updatedRows)         
          }

          if (field === 'jornada'){
            const updatedRows = listadoProductosInicial.map((row) => {
              if (row.id === id) {                  
                return { ...row,  jornada: value};
              }
              return row;
            });              
            setListadoProductosInicial(updatedRows)  
          }

          if (field === 'operador'){
            const updatedRows = listadoProductosInicial.map((row) => {
              if (row.id === id) {
                return { ...row,  operador: value};
              }
              return row;
            });
            setListadoProductosInicial(updatedRows)  
          }  
    }, [listadoProductosInicial])

    const handleSaveElement = (element) => {
        actualizarDatos(element)
    }
    
    const resetForm = () => {
        setListadoProductosInicial([])
    }

    useEffect(() => {
        //OBETENER LAS JORNADAS DESDE LA BBDD
    },[])

    useEffect(() => {
        if(  typeof listadoProductos === 'undefined' ||
            (Array.isArray(listadoProductos) && listadoProductos.length === 0 )
        ){        
            resetForm()
        }else{
            setListadoProductosInicial(listadoProductos)
        }
    },[listadoProductos])    

    const handleRemoveElement = (element) =>{        
        ListadoProductosFinal(element)
    }   

    let unidades = 0
    listadoProductosInicial.forEach(element => unidades += parseFloat(element.cantidad))

    return(
        <>        
        <Grid item xs={12} className="" style={{height: 420}}>
            <DataGrid 
                //disableColumnMenu={true}
                rows={listadoProductosInicial} 
                columns={columns}    
                //autoHeight = {true}   
                density = {'compact'}
                //pageSize={100} 
                //checkboxSelection = {false}
                //hideFooter = {false}
                //hideFooterPagination = {true}
                //hideFooterRowCount = {false}
                //hideFooterSelectedRowCount = {true}
                onCellEditCommit={handleCellEditCommit}
                components={{
                    Footer: () => <div style={{padding : 5, marginRight: 20, float: 'right', fontSize: 14}}>Cantidad: {unidades}</div>,
                }}
                />
        </Grid>
        </>
    )
}
export default TablaPedidoProductos;