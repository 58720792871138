import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'


const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioDatPersonales = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [personal, setPersonal] = useState([])  


    useEffect(() => { 
            setCampos([  
                {
                    urlData: "dic/diccionarios/rrhhsexos",
                    campoEtiqueta: "Etiqueta",
                    required: true,
                    label: "Sexo:",
                    name: "sexo",
                    disabled: modo === "R" ? true : false,
                    type: "combo",
                    value: "",              
                    xs:4
                },
                {
                    required: false,
                    label: "DNI",
                    name: "dni",
                    type: "text",
                    disabled: modo === "R" ? true : false,              
                    value: "" ,
                    xs:4
                },
                {
                    required: false,
                    label: "Activo",
                    name: "activo",
                    type: "select",
                    disabled: modo === "R" ? true : false,              
                    value: "" ,
                    opciones: [{Id: 0, Etiqueta: "Desactivado"}, {Id: 1, Etiqueta: "Activado"}],
                    xs:4
                },
                {
                    required: true,
                    label: "Nombre",
                    name: "nombre",
                    type: "text",
                    disabled: modo === "R" ? true : false,              
                    value: "" ,
                    xs:4
                },
                {
                    required: true,
                    label: "Primer Apellido",
                    name: "primerApellido",
                    type: "text",  
                    disabled: modo === "R" ? true : false,            
                    value: "" ,
                    xs:4
                },
                {
                    required: false,
                    label: "Segundo Apellido",
                    name: "segundoApellido",
                    type: "text",
                    disabled: modo === "R" ? true : false,              
                    value: "" ,
                    xs:4
                },
                {
                    required: false,
                    label: "¿Tiene hijos?",
                    name: "tieneHijos",
                    type: "switch", 
                    disabled: modo === "R" ? true : false,             
                    value: "" ,
                    xs:12
                }, 
                {
                    required: false,
                    label: "Número de hijos",
                    name: "numeroHijos",
                    type: "number", 
                    disabled: modo === "R" ? true : false,             
                    value: "" ,
                    xs:4
                }, 
                {
                    required: false,
                    label: "Dirección Particular",
                    name: "direccion",
                    type: "text",
                    disabled: modo === "R" ? true : false,              
                    value: "" ,
                    xs:4
                },
                {
                    required: false,
                    label: "Código Postal",
                    name: "cp",
                    type: "text",
                    disabled: modo === "R" ? true : false,              
                    value: "" ,
                    xs:4
                },
                {
                    required: false,
                    label: "Población",
                    name: "poblacion",
                    type: "text", 
                    disabled: modo === "R" ? true : false,             
                    value: "" ,
                    xs:4
                },                      
                {
                    required: false,
                    label: "Fecha Nacimiento",
                    name: "fechaNacimiento",                    
                    type: "date",           
                    multiple: false,
                    disabled: modo === "R" ? true : false,   
                    value: "",
                    xs:4
                },
                {
                    required: false,
                    label: "Lugar nacimiento",
                    name: "lugarNacimiento",
                    type: "text",
                    disabled: modo === "R" ? true : false,              
                    value: "" ,
                    xs:4
                },
                {
                    required: false,
                    label: "Nacionalidad",
                    name: "nacionalidad",
                    type: "text",
                    disabled: modo === "R" ? true : false,              
                    value: "" ,
                    xs:4
                },
                {
                    urlData: "dic/diccionarios/rrhhestadosciviles",
                    campoEtiqueta: "Etiqueta",
                    required: false,
                    label: "Estado Civil",
                    name: "estadoCivil",                    
                    type: "combo",
                    disabled: modo === "R" ? true : false,
                    value: "",              
                    xs:4
                },    
                {
                    required: false,
                    label: "Teléfono particular",
                    name: "telefono",
                    type: "text", 
                    disabled: modo === "R" ? true : false,             
                    value: "" ,
                    xs:4,
                    hidden: false
                },
                {
                    required: true,
                    label: "Email",
                    name: "Email",
                    type: "text",
                    disabled: modo === "R" ? true : false,              
                    value: "" ,
                    xs:4
                },
                {
                    required: false,
                    label: "Persona contacto",
                    name: "personaContacto",
                    type: "text", 
                    disabled: modo === "R" ? true : false,             
                    value: "" ,
                    xs:4
                },
                {
                    required: false,
                    label: "Teléfono persona contacto",
                    name: "telefonoContacto",
                    type: "text", 
                    disabled: modo === "R" ? true : false,             
                    value: "" ,
                    xs:4
                },        

                {
                    required: false,
                    label: "Observaciones:",
                    name: "observaciones",
                    type: "textArea",
                    lineas: 4,  
                    disabled: modo === "R" ? true : false,
                    value: "",                
                    xs:12
                },
                {
                    required: false,
                    label: "Id Usuario",
                    name: "idUsuario",
                    type: "text", 
                    disabled: modo === "R" ? true : false,             
                    value: "" ,
                    classes: "d-none",
                    xs:4
                },              
        ])

        const fetchData = async () => { 
            if (modo === "W" || modo === "R") {
                const resppersonal = await axios(
                    `${process.env.REACT_APP_API_URL}rrhhdatospersonales/${id}`
                );                
                setPersonal(resppersonal.data)  
            }
            setDatosCargados(true);  
        };    
        fetchData();       
    },[modo]);

    useEffect(() => {            
    if (typeof personal !== "undefined") {        
      if(Object.keys(personal).length > 0){  
        setDatosCargados(false)
        campos[0].value = personal.Sexo;
        campos[1].value = personal.DNI || '';
        campos[2].value = personal.Activo;
        campos[3].value = personal.Nombre;
        campos[4].value = personal.Primer_Apellido;
        campos[5].value = personal.Segundo_Apellido || '';
        campos[6].value = personal.Tiene_Hijos;
        campos[7].value = personal.Numero_Hijos || '';
        campos[8].value = personal.Direccion || '';
        campos[9].value = personal.CP || '';
        campos[10].value = personal.Poblacion || '';
        campos[11].value = personal.Fecha_Nacimiento || '';
        campos[12].value = personal.Lugar_Nacimiento || '';
        campos[13].value = personal.Nacionalidad || '';
        campos[14].value = personal.Estado_Civil || '';
        campos[15].value = personal.Telefono || '';
        campos[16].value = personal.Email;
        campos[17].value = personal.Persona_Contacto || '';
        campos[18].value = personal.Telefono_Contacto  || '';
        campos[19].value = personal.Observaciones || ''; 
        campos[20].value = personal.Usuario.id; 
        localStorage.setItem('idUsuario', campos[20].value) 
        setDatosCargados(true)  
      }
  }
    },[personal])

    const modoFormulario = modo === "R" ? "view" : ( modo === "W" ? "edit" : "add")
    if (datoscargados){     
    return (  
        <FormularioEntidad 
        urlForm = "rrhhdatospersonales"
        campos={campos}
        type={modoFormulario}
        urlGoto="/rrhh/gestionpersonal/empleados/"
        />               
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }
}
export default FormularioDatPersonales;