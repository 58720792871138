import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioDepositos = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [Deposito, setDeposito] = useState([]) 


    const classDiv3 = "col-3 mt-2 mb-2"

    useEffect(() => { 

        setCampos([ 
            //0          
            {
                required: true,
                label: "Fecha creación:",
                name: "fechaCreacion",
                type: "date",  
                value: '',
                xs:3
            },
            {
            //Falta urlData 
            //1
                urlData: "",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Código:",
                name: "codigo",
                disabled: true,
                type: "text",           
                multiple:false,   
                value: '',
                xs:3
            },
            //2
            {
                required: true,
                label: "Nombre:",
                name: "nombre",
                type: "text",  
                value: '',
                xs:3
            },
            //3
            {
                //Falta urlData 
                urlData: "dic/diccionarios/estadosdeposito",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Estado:",
                name: "estado",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: '',
                xs:3
            },
            //4
            {
                required: false,
                label: "Observaciones:",
                name: "observaciones",
                type: "textArea",
                lineas: 4,                
                value: '',
                xs:12
            },   
            //5
            {
                classButton: classDiv3,
                required: false,
                label: "Documentacion",
                name: "ficherosadjuntos",
                type: "filesupload",   
                tabla: "mtms_actividades_adjuntos",
                xs:12
            },   
            //6 
            {
                required: false,
                label: "Documentacion",
                name: "ficherosremotos",
                type: "files", 
                ficheros: Deposito.ficheros,    
                tabla: "mtms_actividades_adjuntos",
                modelo: "api\\modules\\v1\\models\\depositos\\DepositoAdjuntos",
                carpeta: "almacenes",
                xs:12
            },
             
            
        ])

        const fetchData = async () => { 
            if (modo === "W") {
                const respDeposito = await axios(
                    `${process.env.REACT_APP_API_URL}depositos/${id}`
                );                
                setDeposito(respDeposito.data)  
            }
            setDatosCargados(true);
    
        };    
        fetchData(); 

    },[]);

    useEffect(() => {            
        if (typeof Deposito !== "undefined") {        
            if(Object.keys(Deposito).length > 0){      
                setDatosCargados(false)
                campos[0].value = Deposito.fechaCreacion;
                campos[1].value = Deposito.codigo;
                campos[2].value = Deposito.nombre;
                campos[3].value = Deposito.estado;
                campos[4].value = Deposito.observaciones;
                //campos[5].value = Deposito.ficherosadjuntos;
                campos[6].ficheros = Deposito.ficheros;  
                setDatosCargados(true)  
            }
        }
    },[Deposito])

    if (datoscargados){
    return (    
        <FormularioEntidad 
            urlForm = "depositos"
            urlGoto = "/logistica/depositos/deposito/"
            campos={campos}
        /> 
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }
}


export default FormularioDepositos;