import React, { useState, useEffect, useContext  } from 'react';

import { StateContext } from '../../../../../context'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useForm } from "react-hook-form";
import axios from "axios";

import { CircularProgress } from '@material-ui/core';

import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import { useParams } from "react-router-dom";


const useStyles = makeStyles((theme) => ({    
    paper: {
      width: "100%",
      padding: 10,
    },    
  }));

  let propiedadesFicheros = {
    xs:6,
    required: false,
    label: "",
    name: "filesupload",
    type: "filesupload", 
    //ficheros: datosFormulario.ficheros,    
    tabla: "mtms_operaciones_adjuntos"
  }

  let propiedadesAdjuntos = {
    xs:6,
    required: false,
    label: "",
    name: "files",
    type: "files", 
    //ficheros: datosFormulario.ficheros,    
    tabla: "mtms_operaciones_adjuntos",
    modelo: "api\\modules\\v1\\models\\operaciones\\OperacionesAdjuntos",
    carpeta: "operaciones\\adjuntos",
  }

export default function FormularioDOPOperacion({actualizaListado}) {
    const {state, dispatch} = useContext(StateContext)
    const { operacion } = state
    const classes = useStyles();  
    const [ficheros, setFicheros] = useState([])
    const [nombresFicheros, setNombresFicheros] = useState([])
    const { control, handleSubmit} = useForm();
    const { id } = useParams();
    const [operaciones, setOperaciones] = useState(null);
    const [isLoading, setIsLoading] = useState(false); 

    useEffect(() => { 
      if (operacion) setOperaciones(operacion)
    }, [operacion])

    const handleSubmitPost = async (datos) => {  

      datos.idOperacion = id
      datos.ficheros = ficheros
      datos.nombresFicheros = nombresFicheros   

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}operaciones/documentacionadjunta`
      setIsLoading(true)   

      await axios
      .post(SERVER_URL, {
        headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
        },
        datos,
      })
      .then((response) => {  
        if(!isNaN(response.data)){
          setFicheros([])
          setNombresFicheros([])
          actualizaListado(response.data)
        }
        setIsLoading(false)   

        })
        .catch((error) => {
          console.log("error " + error);
          setIsLoading(false)   

    });

    }

    const handleFicheros = (files, filenames) => {      
      setFicheros(files)
      setNombresFicheros(filenames)
    }
    
    return (       
      
    <Paper elevation={0} className={classes.paper} >
    
    <form
        onSubmit={handleSubmit((datos) => {
            handleSubmitPost(datos);
        })}
        className="row ml-2"
    >
    
        
    { !isLoading &&
    (    
      <Grid className="" container spacing={1}>
        <FormFieldFiles propiedades={propiedadesFicheros} control={control} ficheros={handleFicheros}/>
        <FormFieldFileUpload propiedades={propiedadesAdjuntos} control={control} handleFicheros={handleFicheros}/>
        <button style={{marginLeft: 20, maxHeight:33}} className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary ">
          GUARDAR
        </button>  
      </Grid>
    )}
    { isLoading &&
      <CircularProgress size={24} style={{height: '3vh', marginLeft: 15, position: 'relative', top: 4}} />
    }  
    
    </form>
    </Paper>            
  );

}