import React from "react";
import { connect } from "react-redux";
import Agenda from './Agenda';
import {useFacturaCompra} from '../../containers/views/Main/Gestion/Compras/facturacompra-context'
import Grid from '@material-ui/core/Grid';

function CustomFooterStatusComponent(props) {      
    if (props.length === 0) return null;
    let unidades = 0
    let igic = 0
    let irpf = 0
    let baseImponibleTotal = 0
    let precioTotal = 0
    
    props.listadoCompraInicial.forEach(element => {  
        unidades += parseFloat(element.cantidad)        
        const elIgic = Number.parseFloat(element.igic, 10);
        const elIrpf = Number.parseFloat(element.irpf, 10);
        const baseImponible =  Number.parseFloat(element.cantidad,10) * Number.parseFloat(element.precioUnidad, 10);

        if (element.igic){
            igic += parseFloat(element.igicImporte); //(parseFloat(element.precioUnidad) * parseFloat(element.cantidad) * parseFloat(elIgic))/100            
        }
        if (element.irpf){
            irpf += (parseFloat(element.precioUnidad) * parseFloat(element.cantidad) * parseFloat(elIrpf))/100            
        }
        precioTotal += parseFloat(element.precioTotal)
        baseImponibleTotal += parseFloat(baseImponible)
    })

    const claseSpanL = "p-0 font-weight-bold h6 text-left w-100"
    const claseSpanR = "p-0 font-weight-bold h6 text-right w-100"
    const claseGrid = "badge badge-info mt-1 mb-1"
  
    return (
        <Grid container className={claseGrid} spacing={2} direction="row" justify="center" alignItems="center">         

            <Grid item xs={7} >
                <span className={claseSpanL}>Unidades:</span>            
            </Grid> 
            <Grid item xs={5} >
                <span className={claseSpanR}>{unidades}</span>            
            </Grid> 
            <Grid item xs={7} >
                <span className={claseSpanL}>Base imponible:</span>
            </Grid> 
            <Grid item xs={5} >
                <span className={claseSpanR}>{baseImponibleTotal.toFixed( 2 )} €</span>
            </Grid> 
            <Grid item xs={7} >
                <span className={claseSpanL}>IGIC:</span>
            </Grid> 
            <Grid item xs={5} >
                <span className={claseSpanR}>{igic.toFixed( 2 )} €</span>
            </Grid> 
            <Grid item xs={7} >
                <span className={claseSpanL}>IRPF:</span>
            </Grid> 
            <Grid item xs={5} >
                <span className={claseSpanR}>{irpf.toFixed( 2 )} €</span>
            </Grid>
            <Grid item xs={7} >
                <span className={claseSpanL}>Total:</span>
            </Grid> 
            <Grid item xs={5} >
                <span className={claseSpanR}>{parseFloat(precioTotal - irpf).toFixed( 2 )} €</span>
            </Grid>  

        </Grid>
    );
} 
export { CustomFooterStatusComponent };

const MenuDerFacturaCompra = (props) => {
    const {listadoProductosFacturaCompra} = useFacturaCompra();
    const { isAuthUser } = props.reducerApi;      

    if (isAuthUser) {
        return (
          <>
          <div className="col col-menu-der p-0 pl-2 pr-2">
          <Agenda />
            <div className="col p-0 pl-0 pr-0 col-menu-der-recursos">     
                {/*COSTES*/}
                <div className="bg-cuerpo p-10px mt-2">
                    <div className="bg-white p-10px">
                        <Grid item xs={12} className="" /*style={{height: 40}}*/>
                            <CustomFooterStatusComponent listadoCompraInicial={listadoProductosFacturaCompra} />
                        </Grid>   
                    </div>
                </div>
            </div>
          </div>
          </>
          )
        } else {
            return  null
        }
    }

export default connect(({ reducerApi }) => ({ reducerApi }))(MenuDerFacturaCompra);
