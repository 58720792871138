import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import DialogForm from "./DialogForm"
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';
import { CircularProgress } from '@material-ui/core';
import {CAMPOS_EVENTOS} from './AgendaCalendarioConf'
 
const useStyles = makeStyles((theme) => ({
    
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightRegular,
      marginTop: '3px !important',
      color: '#505050'
    },    

    root: {
        flexGrow: 1,
    },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

    accordionDetails: {
        padding: '8px 8px 8px !important',           
    },
    calendario: { width: "100% !important", height: "330px !important"}

}));

const AgendaCalendario = (props) => {
    const classes = useStyles();   
    const [eventos, setEventos] = useState([])       
    const [isLoading, setIsLoading] = useState(true)
    const [open, setOpen] = useState(false);
        
    const tipoFormulario = useRef('add'); 
    const idEvento = useRef(null);  
      
    const [campos, setCampos] = useState(CAMPOS_EVENTOS)

    const fetchData = async () => {
        let user = JSON.parse(localStorage.getItem("user"));            
        const urlEventosUsuarios = process.env.REACT_APP_API_URL + "agendaeventosusuarios/" + user.id

        await axios 
        .get(urlEventosUsuarios)
        .then((response) => {
            setEventos(response.data);
        })
        .catch((error) => {
            setEventos([]); 
        })
        .finally(() => {
            setIsLoading(false)
        })
    };

    useEffect(() => {    
        fetchData();
        return () => {}
    }, [])   
    

    const handleEventClick = (info) => { // bind with an arrow function  
        const evento = eventos.filter(e => parseInt(e.id) === parseInt(info.event.id))  
        let camposAux = [...campos];
        
        if(evento.length > 0)
        {            
            const numeroDeDestinatarios = evento[0].destinatariosArray.length
            let destinatarios = []

            for(let i=0;i<numeroDeDestinatarios; i++){
                destinatarios[i] = evento[0].destinatariosArray[i]
            }
            
            camposAux[0].value = evento[0].title
            camposAux[1].value = evento[0].localizacion
            camposAux[2].value = evento[0].start.substr(0,10)
            camposAux[3].value = evento[0].horaInicio
            camposAux[4].value = evento[0].end.substr(0,10)
            camposAux[5].value = evento[0].horaFin
            camposAux[6].value = destinatarios[0]
            camposAux[7].value = destinatarios[1] || null
            camposAux[8].value = destinatarios[2] || null
            camposAux[9].value = destinatarios[3] || null
            camposAux[10].value = evento[0].descripcion  
            camposAux[12].ficheros = evento[0].ficheros 
            tipoFormulario.current = "edit" 
            idEvento.current = info.event.id
            setCampos(camposAux)
            setOpen(true);
        }        
    }

    const renderEventContent = (info) => {
        //console.log('renderEventContent', info)
    }

    const handleDateSelect = (info) => {
        //console.log(info)
    }

    const handleEvents = (info) => {
        //console.log('handleEvents', info)
    }
    
    const accion = () => {      
        handleClose()  
    }  

    const handleClose = () => {
        //console.log("handleClose")
        let camposAux = [...campos];        
        camposAux[0].value = ""
        camposAux[1].value = ""
        camposAux[2].value = ""
        camposAux[3].value = ""
        camposAux[4].value = ""
        camposAux[5].value = ""
        camposAux[6].value = []
        camposAux[7].value = ""
        camposAux[8].value = ""  
        tipoFormulario.current = "add" 
        idEvento.current = null
        fetchData();
        setCampos(camposAux)
        setOpen(false);
    };   

    return (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
        >
            <span id="title-image" className="float-left mr-2 ">
            <img
                src={`${process.env.REACT_APP_IMAGES}2-2-eventos.png`}
                width="24"
                height="24"
                alt=""
                loading="lazy"
            />
            </span> 
            <Typography className={classes.heading}>Calendario</Typography>
        </AccordionSummary>
         {!isLoading && (
            <AccordionDetails className={classes.accordionDetails} >     
          
            <FullCalendar
                plugins={[listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                height= {500}
                aspectRatio= {2}                
                expandRows= {true}
                firstDay= {1}     

                customButtons= {{
                    myCustomButton: {
                        text: '+',
                        click: function() {
                            setOpen(true)                            
                        }
                    }
                }}

                footerToolbar={{
                    center: 'listWeek dayGridMonth myCustomButton'
                }}

                buttonText = {{
                    today:    'hoy',
                    month:    'mes',
                    week:     'semana',
                    day:      'día',
                    list:     'lista'
                }}

                initialView='dayGridMonth'
                locale= "es-ES"
                editable={true}
                eventStartEditable={true}
                eventResizableFromStart={true}
                eventDurationEditable={true}
                noEventsContent={"No hay eventos que mostrar"}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                initialEvents={eventos} // alternatively, use the `events` setting to fetch from a feed
                select={handleDateSelect}
                eventContent={renderEventContent} // custom render function
                eventClick={handleEventClick}
                eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                // you can update a remote database when these fire:
                eventAdd={function(){console.log()}}
                eventChange={function(){console.log()}}
                eventRemove={function(){console.log()}}

                moreLinkContent= {function(arg) {                             
                    let italicEl = document.createElement('i')                
                    italicEl.innerHTML = '+ ' + arg.num + ' más'
                    let arrayOfDomNodes = [ italicEl ]
                    return { domNodes: arrayOfDomNodes }                
                }
                }
            /> 
            
            <DialogForm 
                title={"Formulario Evento"}
                isOpen={open} 
                handleCloseDialog={handleClose}
                urlForm={"agendaeventos"}
                action={accion}
                campos={campos}
                urlGoto={""}
                type={tipoFormulario.current}
                value={idEvento.current}
            />

            </AccordionDetails>
        )}
        {isLoading && (
        <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} /> 
        )} 
    </Accordion> 
    )

}
export default AgendaCalendario;