import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";
import { CircularProgress } from '@material-ui/core';
import FormularioAlmacenamiento from "./FormularioAlmacenamiento";
import MigasDePan  from "../../../../../components/Common/MigasDePan";
import { useAlmacenamiento } from "./almacenamiento-context";

const Inventario = (props) => {
    let { id, modo } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [datos, setDatos] = useState();
    const [idSubOperacion, setIdSubOperacion]=useState(localStorage.getItem('idSubOpAlmacenamiento') || "Inventario"); 
    const { listadoMaterialesAlmacenamiento, setListadoMaterialesAlmacenamiento } = useAlmacenamiento(); 
    const [pdfFactura, setPdfFactura]= useState('');

    
    const generarPDF =  () => {    
      setIsLoading(true);
        const fetchData = async () => {
          
          const respFacturaUnica = await axios( process.env.REACT_APP_API_URL + "almacenamientos/generapdf/" + id);
          if (respFacturaUnica.data) {   
            
            setPdfFactura(respFacturaUnica.data);
            setIsLoading(false);
          }
        };
        fetchData();      
    }    

    useEffect(() => {      
        setIsLoading(true)
    },[props.location.action])
    

    useEffect(() => {   
        if (isLoading && (modo === "R" || modo === "W")) {
          const fetchData = async () => {
            const respAlmacen = await axios( process.env.REACT_APP_API_URL + "almacenamientos/" + id);
            if (respAlmacen.data) {       
              setListadoMaterialesAlmacenamiento(respAlmacen.data.listadomateriales);
              setDatos(respAlmacen.data);
              setIsLoading(false);
            }
          };
          fetchData();
        } 
        if (!modo) {      
          setIsLoading(false);
        }
    }, [isLoading, modo, id]);

    const botoneraAcciones = [    
        {
          etiqueta: modo === "R" ? "Editar" : "Cancelar",
          url: 
          typeof id === "undefined" ? `/operativas/almacenamiento/` :
          modo === "R" ? 
            `/operativas/almacenamientos/${id}/W` : 
            `/operativas/almacenamientos/${id}/R`,
          activo: true,
        },
        {
          etiqueta: "Guardar",
          form:'formulario-almacenamiento',
          activo: modo === "R" ? false : true,
        },
        {
          etiqueta: "Generar documento",
          function: generarPDF,
          activo: modo === "R" ? false : true,
        },
        {        
          etiqueta: "Ver documento",
          file: pdfFactura ? pdfFactura : "",
          activo: pdfFactura ? true : false,        
        }
    ];

    const urlsMigasdepan = [
      {titulo: "Almacenamientos", colorTexto: "texto-comercial", urlTo: "/operativas/almacenamiento"},
      {titulo: `${idSubOperacion}`, colorTexto: "texto-comercial", urlTo: `/operativas/almacenamientos/${id}/${modo}`},
    ]

    const codigo = typeof datos !== "undefined" ? datos.codigo : "";
 
    return (
        <>
          <div className="col-8 col-main-home ">
            <SearchBar
              titulo="Operativas"
              claseCabecera = "bg-produccion"
              buscar={false}
              imagenCabecera={"5-2-3-almacenamiento.png"}
            />
    
            {!isLoading && (
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                    <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/> 
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">   
                        <FormularioAlmacenamiento datos={datos}/>                             
                    </div>
                  </div>                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
            )}
            <div>{isLoading && <CircularProgress />}</div>
          </div>
        </>
      );

}
export default Inventario;