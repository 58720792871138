import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import {usePlanificacion} from '../../containers/views/Main/Produccion/Operativas/planificacion-context'
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { equipoprincipalpropio, equipoauxiliarpropio, consumible, manoObraPropia, depositos } from './imagenesRecursos';
import { herramientas, otroGastos, equipoprincipalexterno, equipoauxiliarexterno } from './imagenesRecursos';
import { manoobraexterna, categoriasPresupuesto } from './imagenesRecursos';
import Agenda from './Agenda';
import AlertaMensaje from './AlertaMensaje'
import FormularioAddRecursos from "./FormularioAddRecursos";
import { v4 as uuidv4 } from 'uuid';

const extractColumn = (recursos, categoriaPadre) => recursos.map(x=>x[categoriaPadre]);
 
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightRegular,
  },
  input: {
    display: 'none',
  },
}));


const MenuDerPlanificaciones = (props) => {
  let { modo } = useParams(); 
  const { isAuthUser } = props.reducerApi;
  const { control, handleSubmit } = useForm(); 
  const [recursoSeleccionado, setRecursoSeleccionado] = useState(-1)
  //const classes = useStyles();    
  const [recursos, setRecursos] = useState([])
  const [categorias, setCategorias] = useState();
  const [ subcategorias, setSubcategorias] = useState();
  const {pasoActivo} = usePlanificacion();   
  const {manosPlanificacion, datosJornadas, setDatosJornadas, datosMercancia, manosMercancia, setManosMercancia, datosFormulario} = usePlanificacion();
  const [images, setImages] = useState([]);
  const {manoActiva} = usePlanificacion();
  const [filaActiva, setFilaActiva] = useState(-1)
  const [elementosCategoria, setElementosCategoria] = useState([])
  const [proveedores, setProveedores] = useState([])  
  const [openAlert, setOpenAlert] = useState(false);
  const [costeCPE, setCosteCPE] = useState({})
  const elementoFormuario = useRef(); 

  const handleSubmitPost = async (datos) => {
  } 

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }  
    setOpenAlert(false);
  };

  const handleChange = (event) => {    
    elementoFormuario.current = null
    setRecursoSeleccionado(event.target.value)    
    setElementosCategoria([])
    setFilaActiva(-1)  

    switch(categorias[event.target.value]){
      case "Equipo principal propio":
        setImages(equipoprincipalpropio);
        break;
      case "Equipo auxiliar propio":
        setImages(equipoauxiliarpropio);
        break;
      case "Consumible propio":
        setImages(consumible);
        break;
      case "Mano de obra propia":
        setImages(manoObraPropia);
        break;
      case "Mano de obra externa":
        setImages(manoobraexterna);
        break;
      case "Equipo principal externo":
        setImages(equipoprincipalexterno);
          break;
        case "Herramientas":
          setImages(herramientas);
          break;
        case "Otros gastos":
          setImages(otroGastos);
          break;
        case "Equipo auxiliar externo":
          setImages(equipoauxiliarexterno);
          break;
        case "Depósito":
          setImages(depositos);
          break; 

      default:
        setImages([]);
        break;
    }
  }  

  const [isLoading, setIsLoading] = useState(false); 
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const respRecursos = await axios(process.env.REACT_APP_API_URL + "dic/diccionarios/listadorecursos");    
            
      setRecursos(respRecursos.data)      
      
      const respProveedores = await axios(process.env.REACT_APP_API_URL + "cuentas/proveedores");   
      setProveedores(respProveedores.data)  
      
      setCategorias([...new Set(extractColumn(respRecursos.data, 'CategoriaPadre'))]) 
      setSubcategorias([...new Set(extractColumn(respRecursos.data, 'Categoria'))])
            
    };
    fetchData();
    setIsLoading(false);  
  },[setIsLoading, setSubcategorias])

  useEffect(() => {
 
     
      let datosJ = []
     
      for (let i=0; i<datosJornadas.length; i++){
       
        datosJ[i] = JSON.parse(JSON.stringify(datosJornadas[i])); //datosJornadas[i]
        if(parseInt(datosJornadas[i].elementos.Id) === parseInt(costeCPE.Id) &&
        parseInt(datosJornadas[i].elementos.mano) === parseInt(manoActiva)){
         
          datosJ[i].elementos.Coste = costeCPE.Coste
       
        }
      }
      
      setDatosJornadas(datosJ)
    
    return () => {}
  }, [costeCPE])

  const handleClickAddElemento = (elemento, index) => { 
     
    let disabled = (pasoActivo !== 2 || modo === "R")
   
    if (disabled) return;
       

    if(elemento.EspecialidadCPE){ 
    

      let faltanCampos = 3     
        
      manosMercancia.forEach(campo => {
        if (campo.nombre === "clase" || campo.nombre === "movimiento") 
          faltanCampos--      
      })
        
      for (let campo of manosMercancia) {
        if (campo.nombre === "bultos" || campo.nombre === "kilos"){
          faltanCampos--
          break
        }
      }
        
      if (manosMercancia.length === 0 || faltanCampos > 0 ){
        setOpenAlert(true)      
        return
      } 

      const plus = manosMercancia.filter(el => el.nombre === 'plus')
      const clase = manosMercancia.filter(el => el.nombre === 'clase')
      const mercancia = manosMercancia.filter(el => el.nombre === 'mercancia')
      const movimiento = manosMercancia.filter(el => el.nombre === 'movimiento')
      const bultos = manosMercancia.filter(el => el.nombre === 'bultos')
      const kilos = manosMercancia.filter(el => el.nombre === 'kilos')

    
      const datosManoActiva = manosPlanificacion.filter(el => el.Mano === manoActiva)
      const jornada = datosManoActiva[0].Turno
      const operativa = datosManoActiva[0].TipoOperativa.Id
      const modalidad = datosManoActiva[0].ModalidadTrabajo
      const horasExtra = datosManoActiva[0].Horas_Extra
      
      elemento.plus = plus.length > 0 ? plus[0].valor : null
      elemento.clase = clase.length > 0 ? clase[0].valor : null
      elemento.mercancia = mercancia.length > 0 ? mercancia[0].valor : null
      elemento.movimiento = movimiento.length > 0 ? movimiento[0].valor : null
      elemento.bultos = bultos.length > 0 ? bultos[0].valor : null
      elemento.kilos = kilos.length > 0 ? kilos[0].valor : null

      const getCosteCPE = async () => {
        
        const calculoCostesUrl = 'planificaciones/calculocostescpe'
        const user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}${calculoCostesUrl}`;
         
        const datos = {
          especialidadCPE: elemento.EspecialidadCPE,
          plus: plus.length > 0 ? plus[0].valor : null,
          clase: clase.length > 0 ? clase[0].valor : null,
          mercancia: mercancia.length > 0 ? mercancia[0].valor : null,
          movimiento: movimiento.length > 0 ? movimiento[0].valor : null,
          bultos: bultos.length > 0 ? bultos[0].valor : null,
          kilos: kilos.length > 0 ? kilos[0].valor : null,
          jornada: jornada,
          idOperativa: operativa,
          modalidad: modalidad,
          hextra: horasExtra
          
        } 

        await axios
            .put(SERVER_URL, { 
                headers: {        
                    "Content-Type": "application/json",
                    Authorization: AuthStr,
                  },                                             
              datos,
            })
            .then((response) => {  
              setCosteCPE({Id: elemento.Id, Coste: response.data.Total.toFixed(2), Clase: clase[0].valor})              
            })
            .catch((error) => {
              console.log("error " + error);
              console.log(error.response.data); // => the response payload 
              console.log(error.response.data.message)
            });
      }      
      getCosteCPE()    

    
       
    }else{
      setCosteCPE({Id: elemento.Id, Coste: elemento.Coste, Clase: null}) 
    }

    let existe = false;
    let numelementos=1;    

    const manoMercanciaTmp = manosMercancia.filter(item => item.nombre === "descripcionMercancia") 
    const descripcionMercancia = manoMercanciaTmp.length > 0 ? manoMercanciaTmp[0].valor : ""    
    

    if(datosJornadas.length === 0){ 
      elemento.cantidad=numelementos  
      elemento.mano =  manoActiva;
      elemento.esPresupuesto= 0;    
      elemento.descripcionMercancia = descripcionMercancia         
      setDatosJornadas([...datosJornadas, { elementos: elemento } ])
      return;
    }

    let posicionElementoEncontrado = 0;
    if(datosJornadas.length > 0){
      
      for (let i=0; i<datosJornadas.length; i++){  
      
        if(
          parseInt(elemento.Id) === parseInt(datosJornadas[i].elementos.Id) && 
          parseInt(manoActiva) === parseInt(datosJornadas[i].elementos.mano) /*&&
          (elemento.clase && datosJornadas[i].elementos && elemento.clase.Id === datosJornadas[i].elementos.clase.Id)*/
          ){ 
          if (typeof datosJornadas[i].elementos.cantidad !== 'undefined'){
            numelementos++            
          }
          else{
            numelementos = 1;
          }          
          existe = true 
          break;   
        }
        posicionElementoEncontrado++;                
      }
    }
    
    if(existe){   
      let elementoEncontrado=datosJornadas[posicionElementoEncontrado]
      elementoEncontrado.elementos.cantidad++      
      elementoEncontrado.descripcionMercancia = descripcionMercancia 
      let elementos = [...datosJornadas]
      elementos.splice(posicionElementoEncontrado, 1,elementoEncontrado);  
      setDatosJornadas(elementos)
    }
    
    if(!existe){
      elemento.cantidad=numelementos;   
      elemento.descripcionMercancia = descripcionMercancia
      elemento.mano =  manoActiva;   
      elemento.esPresupuesto= 0;
      setDatosJornadas([...datosJornadas, { elementos: elemento } ])
    }  
  }

  const handleClickRemoveElemento = (elemento, index) => {  
    let disabled = (pasoActivo !== 2 || modo === "R")
    if (disabled) return;

    let elementos = JSON.parse(JSON.stringify(datosJornadas));    
    
    if(elementos.length > 0){
      for (let i=0; i<elementos.length; i++){  
       
        if( 
          parseInt(manoActiva) === parseInt(elementos[i].elementos.mano) && 
          parseInt(elemento.Id) === parseInt(elementos[i].elementos.Id) &&
          parseInt(elementos[i].elementos.esPresupuesto) === 0){ 
          elementos[i].elementos.cantidad = parseInt(elementos[i].elementos.cantidad);
          elementos[i].elementos.cantidad--    
          setCosteCPE({Id: elementos[i].elementos.Id, Coste: elementos[i].elementos.Coste, Cantidad: elementos[i].elementos.cantidad, Clase: elementos[i].elementos.clase})
          if(elementos[i].elementos.cantidad  <= 0){    
            elementos.splice(i, 1);
          }
        }
      }
    }    
    setDatosJornadas(elementos)    
  }

  const handleClick = (image, index) => {     
    let disabled = (pasoActivo !== 2 || modo === "R")
    if (disabled) return;

    let elementosFormulario = recursos.filter((recurso) => image.grupo.includes(recurso.Codigo_Externo) && recurso.esFormulario === "1")
    if(elementosFormulario.length > 0)
      elementoFormuario.current = elementosFormulario[0]
    else elementoFormuario.current = null

    setElementosCategoria(recursos.filter((recurso) => image.grupo.includes(recurso.Codigo_Externo) && recurso.esFormulario === "0"))
    setFilaActiva(index)
  } 

  const addOtrosGastos = (otrosGastos) => {  

    let nuevoElemento = {     
      id: uuidv4(), 
      IdCategoria: 35,
      IdCategoriaPadre: 34,
      mano: manoActiva,
      esPresupuesto: 0, 
      idRecurso: 70,
      Id: 70,
      recurso: otrosGastos.recurso,
      cantidad: parseFloat(otrosGastos.cantidad),
      Coste: parseFloat(otrosGastos.coste),
      total: parseFloat(otrosGastos.total),
      descripcionMercancia: `${otrosGastos.tercero} ${otrosGastos.recurso}`,
      Nombre: otrosGastos.recurso,
      Categoria: 'Otros',
      CategoriaPadre: 'Otros gastos'
    }
    
    setDatosJornadas([...datosJornadas, { elementos: nuevoElemento } ])      
  }

  if (isAuthUser) {
    return (
      <>
      <div className="col col-menu-der p-0 pl-2 pr-2">
      <Agenda />
      <div className="col p-0 pl-0 pr-0 col-menu-der-recursos">     

      <div className="bg-cuerpo p-10px mt-2">
      <div className="bg-white p-10px menu-der">
      <div className="p-1 bg-white mb-3 cuerpo-menu-der">

      {/* DESPLEGABLE RECURSOS */}
      <form
          onSubmit={handleSubmit((datos) => {
          handleSubmitPost(datos);
          })}
            className="row"
          >        
          <div className="col-12 mt-4 mb-2  ">   
            <TextField
              disabled = {pasoActivo !== 2 || modo === "R"}
              required
              id="recursos"
              name="recursos"
              select
              label="Recursos"
              value={recursoSeleccionado}
              onChange={handleChange}
              variant="outlined"
              size="small"
              fullWidth
            >
            <MenuItem key={-1} value={-1}>              
                    {"Seleccionar recurso"}
                  </MenuItem>
              {categorias && 
                categorias.map((categoria, index) => (
                  <MenuItem key={index} value={index}>              
                    {categoria}
                  </MenuItem>
                ))
              }                 
              </TextField>
          </div> 
          {openAlert && 
                    <AlertaMensaje 
                      mensaje={"Debe rellenar los campos de la mano para poder calcular los costes"} 
                      isOpen={openAlert} 
                      tipoMensaje="error"
                      cerrar={handleCloseAlert}
                    />
                    }  
      </form>          
     

      <div className="row mx-auto">    
        {images &&        
          images.map((image, index) => (          
            <div key={index} className="col-4 no-gutters pl-2 pr-2 mb-2 mt-2">
              <img
                key={index}
                className={filaActiva===index?'imagen-cotizacion-activa':'imagen-cotizacion'}
                src={image.url}
                width="80"
                height="80"
                alt={image.title}                   
                onClick={() => handleClick(image, index)}   
              />
              <Typography className="text-break" variant="body2" color="textSecondary" component="p">
                {image.title}
              </Typography>
            </div>             
          ))
        }     
      </div>

      <div className="row mx-auto">  
        {
          elementosCategoria && 
          elementosCategoria.map(( elemento, index) => (
            <React.Fragment key={index}>
              <div className="col-9 no-gutters pl-2 pr-2 mb-2 mt-2">           
                {elemento.Nombre}
              </div>
              <div className="col-3 no-gutters pl-2 pr-2 mb-2 mt-2">           
                <RemoveCircleOutlineIcon onClick={() => handleClickRemoveElemento(elemento, index)}   />
                <AddCircleOutlineIcon onClick={() => handleClickAddElemento(elemento, index)}   />            
              </div>
            </React.Fragment>            
          ))
        }
      </div>   
      <div className="row mx-auto">                   
        { elementoFormuario.current && (                   
          <FormularioAddRecursos 
          disabled={pasoActivo !== 2 || modo === "R"} 
          handleAddElemento={addOtrosGastos} />
        )}                  
                </div> 


     </div>  

    </div>
    </div>
    </div>
    </div>
      </>
    );
  } else {
        return null;
  }    
}
export default connect(({ reducerApi }) => ({ reducerApi }))(MenuDerPlanificaciones);


/*

//const [nombresFicheros, setNombresFicheros] = React.useState([]);
  //const [ficheros, setFicheros] = React.useState([]);  
  //const [proveedorSeleccionado, setProveedorSeleccionado] = useState()

const handleChangeProveedor = (event) => {    
    setProveedorSeleccionado(event.target.value)
  }
  
  const handleSubmitPostPresupuesto = async (datos) => {    
    const presupuesto = {
      Categoria: elementosCategoria[0].Categoria,
      CategoriaPadre: elementosCategoria[0].CategoriaPadre,
      Codigo_Externo: elementosCategoria[0].Codigo_Externo,
      Coste: datos.importe,
      Descripcion: datos.descripcion,      
      Id: elementosCategoria[0].Id,
      IdCategoria: elementosCategoria[0].IdCategoria,
      IdCategoriaPadre: elementosCategoria[0].IdCategoriaPadre,
      Imagen: elementosCategoria[0].Imagen,
      Nombre: elementosCategoria[0].Nombre,      
      cantidad: datos.cantidad,
      mano:  manoActiva,
      codigopresupuesto: datos.codigopresupuesto,
      proveedor: proveedorSeleccionado,
      ficheros: ficheros,
      nombres: nombresFicheros,
      recursoSeleccionado: recursoSeleccionado,
      esPresupuesto: 1
    }    

    if(datosJornadas.length === 0){      
      setDatosJornadas([...datosJornadas, { elementos: presupuesto } ])
      return;
    }

    let existe = false;    
    
    if(!existe){        
      presupuesto.mano =  manoActiva;   
      setDatosJornadas([...datosJornadas, { elementos: presupuesto } ])
    }      
  } 

//let fecha = new Date();
  //let meses = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
  //let fechaTexto = fecha.getDate()+" "+ meses[fecha.getMonth()] + " " + fecha.getFullYear();
  
  const handleCapture = ({ target }) => {
    setFicheros([])
    setNombresFicheros([])

    for (let i=0; i<target.files.length; i++){
      const fileReader = new FileReader();          
      fileReader.readAsDataURL(target.files[i]);
      fileReader.onload = (e) => {
        setFicheros(ficheros => [...ficheros, e.target.result] )
        setNombresFicheros(nombresFicheros => [...nombresFicheros, target.files[i].name] )                      
      };          
    } 
};

let muestroPresupuesto = false;
  elementosCategoria.forEach(
    element => {      
      let encontrados = (categoriasPresupuesto.filter(
      elemento => elemento === parseInt(element.Codigo_Externo)))      
      muestroPresupuesto = encontrados.length > 0 ? true : false;
    }
  );

const handleDeleteNewFile = (index) => {    
    let elementos = JSON.parse(JSON.stringify(ficheros)); 
    let elementos2 = JSON.parse(JSON.stringify(nombresFicheros));       
    elementos.splice(index, 1)
    elementos2.splice(index, 1)
    setFicheros(elementos)
    setNombresFicheros(elementos2)      
  }
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  }, 
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', 
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  */
  /*
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  */
  /*
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  root2: {
    width: 90,
    minWidth: 90,
    maxWidth: 90, 
    padding: 5,
    flex:1,

  },
  media: {
    height: 90,
  },
  root3: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },  
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  */
/*
{muestroPresupuesto && 1===0 && 
                  <form
                    onSubmit={handleSubmit((datos) => {
                    handleSubmitPostPresupuesto(datos);
                    })}
                    className="row mx-auto"
                    >    

                      <div className="col-12 mt-2 mb-1  ">   
                        <TextField
                          disabled = {pasoActivo !== 2 || modo === "R"}
                          required
                          id="proveedor"
                          name="proveedor"
                          select
                          label="Proveedor"
                          value={proveedorSeleccionado}
                          onChange={handleChangeProveedor}
                          variant="outlined"
                          size="small"
                          fullWidth
                        >
                        <MenuItem key={-1} value={-1}> {"Seleccionar proveedor"} </MenuItem>
                        {proveedores && 
                          proveedores.map((proveedor, index) => (
                            <MenuItem key={proveedor.Id} value={proveedor.Id}>              
                              {proveedor.Razon_Social}
                            </MenuItem>
                          ))
                        }                 
                        </TextField>
                      </div> 

                       
                      <div className="col-12 mt-2 mb-1  ">                
                        <Controller
                          as={TextField}
                          control={control}
                          id="descripcion"
                          name="descripcion"
                          label="Descripción"
                          variant="outlined"
                          multiline
                          rows={2}
                          size="small"
                          fullWidth
                          defaultValue=""
                        />
                      </div> 

                      
                      <div className="col-6 mt-2 mb-1  ">
                        <Controller
                          as={TextField}
                          type="number"
                          control={control}
                          id="cantidad"
                          name="cantidad"
                          label="Cantidad"
                          variant="outlined"
                          size="small"
                          fullWidth
                          defaultValue=""
                        />
                      </div>

                      
                      <div className="col-6 mt-2 mb-1  ">
                        <Controller
                          as={TextField}
                          type="number"
                          control={control}
                          id="importe"
                          name="importe"
                          label="Importe"
                          variant="outlined"
                          size="small"
                          fullWidth
                          defaultValue=""
                        />
                      </div>
                      
                      
                      <div className="col-12 mt-2 mb-1  ">
                        <Controller
                          as={TextField}                                     
                          control={control}
                          id="codigopresupuesto"
                          name="codigopresupuesto"
                          label="Código presupuesto"
                          variant="outlined"
                          size="small"
                          fullWidth
                          defaultValue=""
                        />
                      </div>
                      
                          
                      <div className="col-12 mt-2 mb-1  ">
                        <Paper variant="outlined" >
                        { nombresFicheros && nombresFicheros.map((option, index) => (                  
                          <Chip 
                            key={index}
                            className="m-2"
                            variant="outlined" 
                            color="primary" 
                            size="small" 
                            onDelete={() => handleDeleteNewFile(index)} 
                            label={option}
                            style={{
                              width: '90%',
                            }}
                          />
                        ))}
                        </Paper>                
                      </div>

                      <div className="col-12 mt-2 mb-1">     
                        <input
                          accept="*"
                          className={classes.input}
                          id="contained-button-file"
                          multiple
                          onChange={handleCapture}
                          type="file"
                          name="ficherosadj"
                        />
                        <label htmlFor="contained-button-file" className="">
                          <Button variant="contained" color="primary" component="span">
                            Añadir archivos
                          </Button>
                        </label>
                      </div>        

                      <button className="float-right MuiButtonBase-root MuiButton-root MuiButton-contained2 MuiButton-containedPrimary2 MuiButton-disableElevation">
                        <img
                          key={'presupuestos'}              
                          src={'/imagenes/presupuesto.png'}              
                          alt={"Solicitar presupuesto a proveedor"}                  
                        /> 
                      </button>              
                      </form>  
                }

            <div key={index} className="col-12 no-gutters pl-2 pr-2 mb-2 mt-2">           
            {elemento.Nombre}
            <RemoveCircleOutlineIcon onClick={() => handleClickRemoveElemento(elemento, index)}   />
            <AddCircleOutlineIcon onClick={() => handleClickAddElemento(elemento, index)}   />
            </div>
        
const equipoprincipalpropio = [
  {
    url: '/cotizacion/1111_Grua_Portico.jpg',
    title: 'Grúa Pórtico',
    width: '100%',
    categoria: "Grúas",
    categoriaPadre: "Equipo principal propio",
    grupo: '1111'
  },
  {
    url: '/cotizacion/1112_Grua_RTG.jpg',
    title: 'Grúa apiladora (RTG)',
    width: '100%',
    categoria: "Grúas",
    categoriaPadre: "Equipo principal propio",
    grupo: '1112'
  },
  {
    url: '/cotizacion/1113_Reach_Stacker.jpg',
    title: 'Reach Stacker',
    width: '100%',
    categoria: "Grúas",
    categoriaPadre: "Equipo principal propio",
    grupo: '1113'

  },
  {
    url: '/cotizacion/1121_mafi.jpg',
    title: 'Cabeza Tractora (Mafi)',
    width: '100%',
    categoria: "Máquinas",
    categoriaPadre: "Equipo principal propio",
    grupo: '1121'

  },
  {
    url: '/cotizacion/1122_Trompo.jpg',
    title: 'Plataforma Portacontenedores',
    width: '100%',
    categoria: "Máquinas",
    categoriaPadre: "Equipo principal propio",
    grupo: '1122'
  },
  {
    url: '/cotizacion/1123_Carretilla_Elevadora.jpg',
    title: 'Carretilla eléctrica elevadora',
    width: '100%',
    categoria: "Máquinas",
    categoriaPadre: "Equipo principal propio",
    grupo: '1123'
  },
]
*/
/*
const equipoauxiliarpropio = [
  {
    url: '/cotizacion/0000_sin_imagen.jpg',
    title: 'Apiladora',
    width: '100%',
    categoria: "Elevación",
    categoriaPadre: "Equipo auxiliar propio",
    grupo: '1211'
    
  },
  {
    url: '/cotizacion/0000_sin_imagen.jpg',
    title: 'Transpaleta eléctrica',
    width: '100%',
    categoria: "Elevación",
    categoriaPadre: "Equipo auxiliar propio",
    grupo: '1222'
    
    
  },
  {
    url: '/cotizacion/0000_sin_imagen.jpg',
    title: 'Transpaleta manual',
    width: '100%',
    categoria: "Otros",
    categoriaPadre: "Equipo auxiliar propio",
    grupo: '1221'
  },
  {
    url: '/cotizacion/0000_sin_imagen.jpg',
    title: 'Vehículos',
    width: '100%',
    categoria: "Vehículos",
    categoriaPadre: "Equipo auxiliar propio",
    grupo: '1231,1232,1233,1234'
    
  },
]
*/
/*
const consumible = [
  {
    url: '/cotizacion/1311_Consumible_Amarre.jpg',
    title: 'Amarre',
    width: '100%',
    categoria: "Amarre",
    categoriaPadre: "Consumible",
    grupo: '1311'
  },
  {
    url: '/cotizacion/0000_sin_imagen.jpg',
    title: 'Fijación / Protección',
    width: '100%',
    categoria: "Fijación/Protección",
    categoriaPadre: "Consumible",
    grupo: '1321'
  }, 
]
*/
/*
const manoObraPropia = [
   {
    url: '/cotizacion/1411_Jefe_Operaciones.jpg',
    title: 'Jefe de operaciones',
    width: '100%',
    categoria: "Operaciones",
    categoriaPadre: "Mano obra propia",
    grupo: '1411'
  },
  {    
    url: '/cotizacion/1412_Operario.jpg',
    title: 'Operario',
    width: '100%',
    categoria: "Operaciones",
    categoriaPadre: "Mano obra propia",
    grupo: '1412'
  },  
]
*/
/*
const herramientas = [
  {
    url: '/cotizacion/1551_Herramientas.jpg',
    title: 'Herramientas',
    width: '100%',
    categoria: "Operaciones",
    categoriaPadre: "Herramientas",
    grupo: '1511'
  },
]
*/
/*
const otroGastos = [
  {
    url: '/cotizacion/1611_Otros_Gastos.jpg',
    title: 'Transporte',
    width: '100%',
    categoria: "Transporte",
    categoriaPadre: "Otros gastos",
    grupo: '1611,1612,1613'
  },
  {
    url: '/cotizacion/1611_Otros_Gastos.jpg',
    title: 'Otros gastos',
    width: '100%',
    categoria: "Otros",
    categoriaPadre: "Otros gastos",
    grupo: '1614'
  },
]
*/
/*
const equipoprincipalexterno = [
  {
    url: '/cotizacion/2111_Grua_Movil_Telescopica.jpg',
    title: 'Cabeza tractora',
    width: '100%',
    categoria: "Grúas",
    categoriaPadre: "Equipo principal externo",
    grupo: '2111'
    
  },
  {
    url: '/cotizacion/2121_Carretilla_electrica_elevadora.jpg',
    title: 'Máquinas',
    width: '100%',
    categoria: "Máquinas",
    categoriaPadre: "Equipo principal externo",
    grupo: '2121'
  },  
]
*/
/*
const equipoauxiliarexterno = [
  {
    url: '/cotizacion/2211_Plataforma_elevadora_articulada.jpg',
    title: 'Elevación',
    width: '100%',
    categoria: "Elevación",
    categoriaPadre: "Equipo auxiliar externo",
    grupo: '2211'
  },
]
*/
/*
const manoobraexterna = [
  {
    url: '/cotizacion/2311_Capataz_General.jpg',
    title: 'Mando',
    width: '100%',
    categoria: "Mando",
    categoriaPadre: "Mano de obra externa",
    grupo: '2311,2312,2313'
  },

  {
    url: '/cotizacion/2321_Transtainero.jpg',
    title: 'Oficial',
    width: '100%',
    categoria: "Oficial",
    categoriaPadre: "Mano de obra externa",
    grupo: '2321,2322,2323,2324,2325'
  },  
  {
    url: '/cotizacion/2331_Especialista.jpg',
    title: 'Especialista',
    width: '100%',
    categoria: "Especialista",
    categoriaPadre: "Mano de obra externa",
    grupo: '2331'
  },  
  {
    url: '/cotizacion/2341_Mano_Minima.jpg',
    title: 'Mano mínima',
    width: '100%',
    categoria: "Mano mínima",
    categoriaPadre: "Mano de obra externa",
    grupo: '2341'
  }, 
]
*/

/*
{
    url: '/cotizacion/carretillero_ajeno.jpg',
    title: 'Carretillero ajeno',
    width: '100%',
  },
  {
    url: '/cotizacion/especialista_ajeno.jpg',
    title: 'Especialista ajeno',
    width: '100%',
  },
  {
    url: '/cotizacion/gruero_ajeno.jpg',
    title: 'Gruero ajeno',
    width: '100%',
  },
  {
    url: '/cotizacion/oficial_ajeno.jpg',
    title: 'Oficial',
    width: '100%',
  },
  {
    url: '/cotizacion/picheador_ajeno.jpg',
    title: 'Picheador',
    width: '100%',
  },
  {
    url: '/cotizacion/reach_stacker_ajeno.jpg',
    title: 'Reach stacker',
    width: '100%',
  },
  {
    url: '/cotizacion/transtainero_ajeno.jpg',
    title: 'Transtainero',
    width: '100%',
  },
  {
    url: '/cotizacion/trompista_ajeno.jpg',
    title: 'Trompista',
    width: '100%',
  },
  {
    url: '/cotizacion/manipulador_pesca_ajeno.jpg',
    title: 'Manipulador pesca ajeno',
    width: '100%',
  },
  {
    url: '/cotizacion/mano_minima.jpg',
    title: 'Mano mínima',
    width: '100%',
  },
  {
    url: '/cotizacion/maquinista_elevador_ajeno.jpg',
    title: 'Maquinista elevador',
    width: '100%',
  },
  {
    url: '/cotizacion/maquinista_grua_ajeno.jpg',
    title: 'Maquinista grúa',
    width: '100%',
  },
*/
/*
const materiales = [
  {
    url: '/cotizacion/materiales.jpg',
    title: 'Materiales',
    width: '100%',
  },  
];
*/