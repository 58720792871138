import React, { useState, useEffect } from "react";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import {columnsAlmacenes, columnsPiezas,columnsAlmacenamiento, columnsRecepcion, columnsDespacho} from './AlmacenamientoConf'
import {botoneraAlmacen,botoneraAlmacenamiento, botoneraPiezas, botoneraRecepcion} from './BotonesConf'
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import Pasos from "../../../../../components/Common/Pasos";
import SearchBar from "../../../../../components/Common/SearchBar";
import DialogForm from "../../../../../components/Common/DialogForm";
import Axios from "axios";

let camposDialogo = [
  {
    required: true,
    label: "Fecha de Salida:",
    name: "fechaSalida",
    type: "date",    
    value: "",
    xs:8
},
{
  required: false,
  label: "",
  name: "almacenamiento",
  type: "text",    
  disabled: false, 
  value: "",                
  xs:4,
  classes: 'd-none',
  hidden: false,
}
]

const Almacenamiento = (props) => {
  const [idOperacion, setIdOperacion]=useState(parseInt(localStorage.getItem('idOpAlmacenamiento')) || 0);
  const [idSubOperacion, setIdSubOperacion]=useState(localStorage.getItem('idSubOpAlmacenamiento') || "Inventario");    
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  const [datosFilaSeleccionada, setDatosFilaSeleccionada] = useState();
  const [open, setOpen] = useState(false);  
  const [campos, setCampos] = useState(camposDialogo)
  const [cerrarDialogo, setCerrarDialogo] = useState();
  const [recargar, setRecargar] = useState(false);
  

  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }

  const verTabla = (idOperacion) => {    
    localStorage.setItem('idOpAlmacenamiento', idOperacion); 
    setIdOperacion(idOperacion)
  }  

  const handleOperacion = (idO) =>{
    localStorage.setItem('idSubOpAlmacenamiento', idO); 
    setIdSubOperacion(idO)
  }   

  const botoneraDespacho = [
    
    {
       etiqueta: "Imprimir",
       function: () => imprimirTiqueSalida(),
       activo: false,
     },  
     
   ];

   const imprimirTiqueSalida = () => 
   {
    if(datosFilaSeleccionada && datosFilaSeleccionada.Codigo_Salida){
      
      const fetchData = async () => {
       
        const respTiqueSalida = await Axios( process.env.REACT_APP_API_URL + "almacenamientos/generapdftiquesalida?tiquesalida=" + datosFilaSeleccionada.Codigo_Salida);
        if (respTiqueSalida.data) {   
        
          window.open(respTiqueSalida.data, "_blank")
        }
      };
      fetchData();      
    }
   }
  
  let columnasCuerpoTabla = columnsAlmacenamiento;
  let urlDatosCuerpoTabla = "almacenamientos";
  let urlDoubleClickCuerpoTabla = null //"/operativas/almacenamientos/";
  let columnIdCuerpoTabla = "idPieza";
  let botonaccionCuerpoTabla = botoneraAlmacenamiento;  
  let filtroToolbar = true
  let filtroToolbarOpciones = ['Inventario', 'Recepción', 'Despacho']
  
  if(idOperacion === 0){ // ALMACENAMINENTO
    if(idSubOperacion === "Recepción"){
      botonaccionCuerpoTabla= botoneraRecepcion
      columnasCuerpoTabla = columnsRecepcion
      urlDatosCuerpoTabla = "almacenamientos/recepciones"
      urlDoubleClickCuerpoTabla = "/operativas/almacenamientos/"
      columnIdCuerpoTabla="id"      
    }

    if(idSubOperacion === "Despacho"){
      botonaccionCuerpoTabla= botoneraDespacho 
      columnasCuerpoTabla = columnsDespacho
      urlDatosCuerpoTabla = "almacenamientos/despachos"
      urlDoubleClickCuerpoTabla = null //"almacenamientos/"
      columnIdCuerpoTabla="id"      
    }
  }  

  if(idOperacion === 1 ){ //PIEZAS
    botonaccionCuerpoTabla= botoneraPiezas 
    columnasCuerpoTabla = columnsPiezas
    urlDatosCuerpoTabla ="piezas"
    urlDoubleClickCuerpoTabla = "almacenamiento/piezas/"
    columnIdCuerpoTabla="id"
    filtroToolbar = false
    filtroToolbarOpciones=[]
  }

  if(idOperacion === 2 ){ //DEPÓSITOS
    botonaccionCuerpoTabla= botoneraAlmacen 
    columnasCuerpoTabla = columnsAlmacenes
    urlDatosCuerpoTabla = "almacens"
    urlDoubleClickCuerpoTabla = "almacenamiento/almacen/"
    columnIdCuerpoTabla = "id"
    filtroToolbar = false
    filtroToolbarOpciones=[]
  }         
  
  const accion = () => {        
    setCerrarDialogo(true)
  } 

  const handleClose = () => {    
    setOpen(false);
};

const getDatosFila = (fila) => {  
  setDatosFilaSeleccionada(fila)
  camposDialogo[1].value = fila.id  
}

  useEffect(() => { 
    if(props.location.action){   
      setOpen(false);   
      setRecargar(!recargar)
    }
  },[props.location.action])
  
  return (           
      <div className="col-8 col-main-home ">            
        <SearchBar
          titulo="Operativas"
          claseCabecera = "bg-produccion"
          buscar={false}
          imagenCabecera={"5-2-3-almacenamiento.png"}
        />   
        
        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">
            <div className="p-1 bg-white mb-3">
              <h6 className="texto-comercial float-left" id="title">
                Almacenamiento
              </h6>
            </div>
            <hr className="m-0 bg-comercial mb-1" />
            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
             
             <Pasos pasos={['Almacenamiento', 'Piezas', 'Depósitos']} idPasoActivo={idOperacion} changePasoActivo={verTabla} />
                 
              <>
                <CuerpoTablaPiezas
                  columnas = {columnasCuerpoTabla}
                  urlDatos = {urlDatosCuerpoTabla}
                  urlDoubleClick = {urlDoubleClickCuerpoTabla}
                  columnId = {columnIdCuerpoTabla}
                  getFilaSeleccionada={marcarFila}
                  filtroToolbar={filtroToolbar}
                  filtroToolbarOpciones = {filtroToolbarOpciones}
                  filtroToolbarValor = {idSubOperacion}
                  filtroToolbarChangeValor={handleOperacion}
                  getDatosFilaSeleccionada={getDatosFila}
                  recargar={recargar}
                  filtroEjercicio = {true}
                /> 
                <DialogForm 
                  title={"Asignar salida"}
                  isOpen={open} 
                  handleCloseDialog={handleClose}
                  urlForm={"almacenamientos/settiquesalida"}
                  action={accion}
                  campos={campos}
                  urlGoto={"almacenamiento"}
                  type={"add"}
                  //value={value}
                />                
              </>
                   
            </div>
          </div>

          <div className="bg-cuerpo">
            <div className="bg-white">
              <TableAcciones                
                filaSeleccionada={filaSeleccionada}                
                botonaccion={botonaccionCuerpoTabla}
              />            
            </div>
          </div>

        </div>
          
      </div>   
    
  );
}
export default Almacenamiento;