import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
import FichaTransferir from "./FichaTransferir";
import FormularioTransferir from "./FormularioTransferir";

const Transferir = (props) => {  
    let { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [datos, setDatos] = useState();

    useEffect(() => {     
        setIsLoading(true)
    },[props.location.action])

    useEffect(() => {        
        if (isLoading ) {
          const fetchData = async () => {
            const respAlmacen = await axios( process.env.REACT_APP_API_URL + "almacenamientos/" + id);
            if (respAlmacen.data) {    
              setDatos(respAlmacen.data);              
              setIsLoading(false);
            }
          };
          fetchData();
        } 
    }, [isLoading, datos, id]);

    const botoneraAcciones = [    
        {
          etiqueta:"Cancelar",
          url: 
          typeof id === "undefined" ? `/operativas/almacenamiento/` :
            `/operativas/almacenamiento/`,
          activo: true,
        },
    ];

    const codigo = typeof datos !== "undefined" ? datos.Codigo : "";    
    return (
        <>
          <div className="col-8 col-main-home ">   
            {!isLoading && (
              <>
                <div className="bg-cuerpo p-10px">
                    <div className="bg-white p-10px">
                        <div className="p-1 bg-white mb-4">
                            <h6 className="texto-produccion float-left" id="title">
                                Transferir
                            </h6>
                            <h6 className="texto-produccion float-right" id="title">
                                {codigo}
                            </h6>
                        </div>
        
                        <hr className="m-0 bg-comercial mb-4" />
                        <Grid className="cuerpo-h" container spacing={3}>
                          <Grid item xs={6}>
                            <h6 className="texto-produccion float-left" id="title">
                                Origen
                            </h6>
                            <div className="principal bg-white pr-2 overflow-auto overflow-hiddens table-responsive">
                                <FichaTransferir datos={datos}/>      
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <h6 className="texto-produccion float-left" id="title">
                                Destino
                            </h6>
                            <div className="principal bg-white pr-2 overflow-auto overflow-hiddens table-responsive">
                                <FormularioTransferir datos={datos}/>  
                            </div>
                          </Grid>
                        </Grid>
                    </div>
                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
            )}
            <div>{isLoading && <CircularProgress />}</div>
          </div>
        </>
      );

}
export default Transferir;