import React, { useState, useRef } from "react";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import axios from "axios"
import {columnsFacturasTodas, columnsFacturasUnicas, columnsFacturasRecurrentes, columnsPlantillasRecurrentes, columnsFacturasRectificativas } from './ColumnasConf'

import { CircularProgress } from '@material-ui/core';

import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import FiltroListado from "./FiltroListado";
import Pasos from "../../../../../components/Common/Pasos";
import SearchBar from "../../../../../components/Common/SearchBar";
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";

const GestionVentas = (props) => {     
  const [idOperacion, setIdOperacion]=useState(parseInt(localStorage.getItem('idOVentas')) || 0);
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  const [tipoFacturaRecurrente, setTipoFacturaRecurrente]=useState(localStorage.getItem('idTipoFacturaRecurrente') || "Facturas");
  const [url, setUrl] = useState("")
  const [openAlert, setOpenAlert] = useState(false);
  const facturaEliminar = useRef(-1);
  const [abrirDialogo, setAbrirDialogo] = useState(false)
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }  
    setOpenAlert(false);
  };  

  const handleFilteredData = (data) => {    
    const uniqueFilters = [...new Set(data)];
    setFilteredData(uniqueFilters);
  };

  const botoneraFacturaUnica = [
    {
      etiqueta: "Editar",
      url: '/administracion/ventas/facturasunicas/filaSeleccionada/W',
      activo: false,
    }, 

    {
      etiqueta: "Eliminar",
      function: (e) => handleClickEliminarFactura(e),
      activo: false,
    }, 

    {
      etiqueta: "Crear",
      url: '/administracion/ventas/facturasunicas',
      activo: true,
    }, 
  ];

  const botoneraFacturaRectificativa = [
    {
      etiqueta: "Editar",
      url: '/administracion/ventas/facturasrectificativas/filaSeleccionada/W',
      activo: false,
    }, 

    {
      etiqueta: "Eliminar",
      function: (e) => handleClickEliminarFactura(e),
      activo: false,
    }, 

    {
      etiqueta: "Crear",
      url: '/administracion/ventas/facturasrectificativas',
      activo: true,
    }, 
  ];

  const botoneraFacturaRecurrente = [
    {
      etiqueta: "Generar facturas recurrentes",
      function: () => generarFacturasRecurrentes(),
      activo: true,
    }, 
    
    {
      etiqueta: "Editar",
      url: '/administracion/ventas/facturasrecurrentes/filaSeleccionada/W',
      activo: false,
    }, 
  
    {
      etiqueta: "Eliminar",
      function: (e) => handleClickEliminarFactura(e),
      activo: false,
    }, 
  
    {
      etiqueta: "Crear",
      url: '/administracion/ventas/facturasrecurrentes',
      activo: true,
    }, 
  ];

  const botoneraPlantillaRecurrente = [
    {
      etiqueta: "Editar",
      url: '/administracion/ventas/facturasrecurrentes/filaSeleccionada/W',
      activo: false,
    }, 
    {
      etiqueta: "Generar factura",
      function: (e) => generarFactura(e),
      activo: true,
    }, 

    {
      etiqueta: "Eliminar",
      function: (e) => handleClickEliminarFactura(e),
      activo: false,
    }, 

    {
      etiqueta: "Crear",
      url: '/administracion/ventas/facturasrecurrentes',
      activo: true,
    }, 
  ];

  const botoneraTodasFacturas = [
    {
      etiqueta: "Generar Excel",
      function: (e) => handleClickGenerarExcel(e),
      activo: true,
    }, 
  ]

  const handleClickEliminarFactura = (valor) => { 
    facturaEliminar.current = valor       
    setAbrirDialogo(true)
  } 

  const handleClickGenerarExcel = (valor) => {
    const urlGeneraExcel = "dic/diccionarios/generalistadofacturasenexcel"
    const fetchData = async () => {
      setIsLoading(true)
      
      const respExcelFacturas = await axios(process.env.REACT_APP_API_URL + urlGeneraExcel + url + filteredData.join(''));
      if (respExcelFacturas.data) {
        if(respExcelFacturas.status === 200)       
          window.open(respExcelFacturas.data, "_blank") 
      }
      setIsLoading(false)
    };
    fetchData(); 
  }
  
  const generarFactura = async (e) => {
    
    let user = JSON.parse(localStorage.getItem("user"));
    const AuthStr = "Bearer ".concat(user.access_token);
    const SERVER_URL = `${process.env.REACT_APP_API_URL}facturasrecurrentes/generaunicafacturarecurrente/${e}`
    const datos = {}

    await axios(SERVER_URL)
    .then((response) => {  
      //if(!isNaN(response.data)){
        if(response.data === true){        
          setOpenAlert(true)
          setTimeout(function () {
            window.location.reload();   
          }, 500);
        }
      //}             
    })
    .catch((error) => {
      console.log("error " + error);
    });
  }

  const generarFacturasRecurrentes = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    const AuthStr = "Bearer ".concat(user.access_token);
    const SERVER_URL = `${process.env.REACT_APP_API_URL}facturasrecurrentes/generafacturasrecurrentes`
    const datos = {}
    await axios
      .post(SERVER_URL, {
        headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
        },
        datos,
      })
      .then((response) => {  
        if(!isNaN(response.data)){
          if(response.data === 0){        
            setOpenAlert(true)
            setTimeout(function () {
              window.location.reload();   
            }, 2000);
          }
        }             
      })
      .catch((error) => {
        console.log("error " + error);
      });
  } 

  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }

  const verTabla = (idOperacion) => { 
    localStorage.setItem('idOVentas', idOperacion);   
    setIdOperacion(idOperacion)
  }  

  const handleOperacion = (idO) =>{  
    localStorage.setItem('idTipoFacturaRecurrente', idO);     
    setTipoFacturaRecurrente(idO)    
  }  

 const handleUrlFecha = (url) =>{  
   setUrl(url)     
  }

  let columnasCuerpoTabla = columnsFacturasUnicas; 
  let urlDoubleClickCuerpoTabla = "ventas/facturasunicas/";
  let columnIdCuerpoTabla = "Id";
  let urlDatos = "facturasunicas" + url
  let urlDatosEliminar = "facturasunicas"
  let botonaccionCuerpoTabla = botoneraFacturaUnica;
  let footer = [
    {campo: 'Base_Imponible', titulo: 'Base Imponible'},
      {campo: 'Total_Impuestos', titulo: 'Impuestos'},
      {campo: 'IRPF', titulo: 'Irpf'},
      {campo: 'Total_Factura', titulo: 'Total'},
  ]

  if(idOperacion === 1 ){
    botonaccionCuerpoTabla= botoneraFacturaRectificativa 
    columnasCuerpoTabla = columnsFacturasRectificativas
    urlDatos = "facturasrectificativas" + url
    urlDatosEliminar = "facturasrectificativas"
    urlDoubleClickCuerpoTabla = "ventas/facturasrectificativas/"
    columnIdCuerpoTabla="Id"    
  }

  if(idOperacion === 2 ){    
    tipoFacturaRecurrente === "Facturas" ? botonaccionCuerpoTabla= botoneraFacturaRecurrente : botonaccionCuerpoTabla= botoneraPlantillaRecurrente
    tipoFacturaRecurrente === "Facturas" ? columnasCuerpoTabla = columnsFacturasRecurrentes : columnasCuerpoTabla = columnsPlantillasRecurrentes
    tipoFacturaRecurrente === "Facturas" ? urlDatos = "facturasrecurrentes/listadofacturasrecurrentes" : urlDatos = "facturasrecurrentes/listadoplantillasrecurrentes"
    urlDatos += url
    urlDatosEliminar = "facturasrecurrentes"
    urlDoubleClickCuerpoTabla = "ventas/facturasrecurrentes/"
    columnIdCuerpoTabla="Id"
  }

  if(idOperacion === 3 ){
    botonaccionCuerpoTabla= botoneraTodasFacturas 
    columnasCuerpoTabla = columnsFacturasTodas
    urlDatos = "dic/diccionarios/listadocompletofacturas" + url
    urlDatosEliminar = ""
    urlDoubleClickCuerpoTabla = "ventas/facturasunicas/"
    columnIdCuerpoTabla="Id"    
  }

  const handleCerrarDialogo = (valor) => {       
    setAbrirDialogo(false)
    if(valor){    
      const fetchData = async () => {  
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + urlDatosEliminar + "/" + facturaEliminar.current;
        
        await axios
        .delete(SERVER_URL, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },        
        })
        .then((response) => {   
          if(!isNaN(response.data)){           
            if(response.data === parseInt(facturaEliminar.current)){
              facturaEliminar.current = -1 
              setTimeout(function () {
                window.location.reload();   
              }, 1000);
            }          
          }
        })
        .catch((error) => {
          facturaEliminar.current = -1 
          console.log("error " + error);
        });  
      }
      fetchData();
    }      
  }

  let filtroToolbar = false
  let filtroToolbarOpciones = []

  if(idOperacion === 2 ){
    filtroToolbar = true
    filtroToolbarOpciones = ['Facturas', 'Plantillas']
  }

  return (           
      <div className="col-8 col-main-home ">            
        <SearchBar
          titulo="Administración"
          claseCabecera = "bg-gestion"
          buscar={false}
          imagenCabecera={"6-1-2-facturas-ventas.png"}
        />   
        
        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">
            <div className="p-1 bg-white mb-3">
              <h6 className="texto-gestion float-left" id="title">
                Facturas de venta
              </h6>
            </div>
            <hr className="m-0 bg-gestion mb-1" />
            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
            { abrirDialogo && (<AlertaDialogo titulo={"¿Desea eliminar la factura seleccionada?"} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
             <Pasos pasos={['Únicas', 'Rectificativas', 'Recurrentes', 'Todas']} idPasoActivo={idOperacion} changePasoActivo={verTabla} />

             <FiltroListado urlfecha={handleUrlFecha}/>
            
             <CuerpoTablaPiezas
                columnas = {columnasCuerpoTabla}
                urlDatos = {urlDatos}
                urlDoubleClick = {urlDoubleClickCuerpoTabla}
                columnId = {columnIdCuerpoTabla}
                getFilaSeleccionada={marcarFila}

                filtroToolbar={filtroToolbar}
                filtroToolbarOpciones = {filtroToolbarOpciones}
                filtroToolbarValor = {tipoFacturaRecurrente}
                filtroToolbarChangeValor={handleOperacion}
                customFooter={footer} 
                filtroEjercicio = {true}

                onFilter={handleFilteredData}
              /> 
             
             
            </div>
          </div>

          <div className="bg-cuerpo">
            <div className="bg-white">
            { !isLoading && ( 
              <TableAcciones                
                filaSeleccionada={filaSeleccionada}                
                botonaccion={botonaccionCuerpoTabla}
              />    
            )}        
              { isLoading &&
                <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />
             }  
            </div>
          </div>
          {openAlert && 
          <AlertaMensaje 
            mensaje={"Factura guardada correctamente!"} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
          }     

        </div>
          
      </div>   
    
  );
}
export default GestionVentas;