import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Grid from '@material-ui/core/Grid';
import axios from "axios";
import {usePlanificacion} from './planificacion-context'
import { useParams } from "react-router-dom";

import FormFieldDateUncontrolled from '../../../../../components/Common/FormFieldDateUncontrolled'
import FormFieldComboUncontrolled from '../../../../../components/Common/FormFieldComboUncontrolled'
import FormFieldTimeUncontrolled from '../../../../../components/Common/FormFieldTimeUncontrolled'
import Button from '@material-ui/core/Button';
import FormFieldTextUncontrolled from "../../../../../components/Common/FormFieldTextUncontrolled";

const FormularioPlanificacionManoDatos = ({index, setAlert}) => {
    let { id, modo } = useParams();
    const { control } = useForm();
    const [idManoActiva, setIdManoActiva] = useState(0); 
    const [fechaMano, setFechaMano] = useState()
    const [turno, setTurno] = useState('')  
    const [horaInicio, setHoraInicio] = useState('')
    const [horaFin, setHoraFin] = useState('')
    const [supervisor, setSupervisor] = useState('')  
    const [empleadosOperativa, setEmpleadosOperativa] = useState([]) 
    const [modalidadTrabajo, setModalidadTrabajo] = useState('')
    const [tipoOperativa, setTipoOperativa] = useState('')
    const [horasExtra, setHorasExtra] = useState(0)
    const {manoActiva} = usePlanificacion();
    const {planificacion, manosPlanificacion, 
        setDatosJornadas, setManosPlanificacion} = usePlanificacion();
    

    const OrdenarManos = (manos) => {  
      const manosAux = JSON.parse(JSON.stringify(manos)); 
      manosAux.sort(function(a, b) {      
        if (a.Mano < b.Mano) return -1;
        if (a.Mano > b.Mano) return 1;
        return 0;
      });    
     
      return manosAux
    }
      
    useEffect(() => {       
        if(index){
            manosPlanificacion.forEach(element => {
                if(element.Mano === index){            
                  setHoraInicio(element.HoraInicio)
                  setHoraFin(element.HoraFin)
                  setFechaMano(element.Fecha)
                  setSupervisor(element.Supervisor) 
                  setTurno(element.Turno)      
                  setIdManoActiva(element.Id);
                  setEmpleadosOperativa(element.empleados)   
                  setModalidadTrabajo(element.ModalidadTrabajo)       
                  setTipoOperativa(element.TipoOperativa)
                  setHorasExtra(element.Horas_Extra || 0)
                }        
            });  
        }
    },[index, manosPlanificacion]);    


    const handleSaveMano = async (e) => {      
      e.preventDefault();
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + 'planificacionesmanos';
        
        let datos={}
    
        if(id)
          datos["idPlanificacion"] =  id;    
        else
          datos["idPlanificacion"] = planificacion; 
    
        datos["idMano"] = manoActiva
        datos["jornada"] = turno;
        datos["supervisor"] = supervisor; 
        datos["empleados"] = empleadosOperativa; 
        datos["fechaMano"] = fechaMano; 
        datos["horaInicio"] = horaInicio; 
        datos["horaFin"] = horaFin; 
        datos["modalidad"] = modalidadTrabajo; 
        datos["tipoOperativa"] = tipoOperativa;   
        datos["hextra"] = horasExtra
    
        if (idManoActiva > 0) { 
          
          await axios
            .put(SERVER_URL + "/" + idManoActiva, {
              headers: {
                "Content-Type": "application/json",
                Authorization: AuthStr,
              },
              datos,
            })
            .then((response) => {
              
              if(!isNaN(response.data)){                
                setAlert(true);                
                //fetchDataNumManos();       
                setIdManoActiva(response.data)

                let manoAModificar = manosPlanificacion.filter((manoPlanificacion) => 
                  manoPlanificacion.Id === response.data
                )

                let manosANoModificar = manosPlanificacion.filter((manoPlanificacion) => 
                  manoPlanificacion.Id !== response.data
                )

                let nuevoEMpleados 
                datos.empleados.forEach((empleado) => {
                  nuevoEMpleados = {id: empleado.id, nombre: empleado.nombre}
                })
               
                
                 manoAModificar = {                         
                  Fecha: datos.fechaMano,
                  Fk_Jornada: parseInt(datos.jornada.Id),
                  Fk_Planificacion: parseInt(datos.idPlanificacion),
                  Fk_Supervisor: datos['supervisor'] ? parseInt(datos['supervisor']['id']) : null,
                  HoraFin: datos.horaFin,
                  HoraInicio: datos.horaInicio,
                  Id: response.data,
                  Jornada: datos['jornada']['Etiqueta'],
                  Mano: datos.idMano,
                  ModalidadTrabajo: datos.modalidad,
                  Supervisor: datos.supervisor,
                  TipoOperativa: datos.tipoOperativa,
                  Turno: datos.jornada,
                  empleados: empleadosOperativa, //nuevoEMpleados, 
                  Horas_Extra: horasExtra, // parseInt(datos['hextra']),                 
                }     
                const manosOrdenadas = OrdenarManos([...manosANoModificar, manoAModificar])
                setManosPlanificacion(manosOrdenadas)
                //setManosPlanificacion([...manosANoModificar, manoAModificar])
                
              }              
            })
            .catch((error) => {
              console.log("error " + error);
              console.log(error.response.data); // => the response payload 
              console.log(error.response.data.message)
            });
        } 
        else{     
          await axios
          .post(SERVER_URL, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {  
            if(!isNaN(response.data)){
              //fetchDataNumManos(); 
              setIdManoActiva(response.data)
              setAlert(true);
              let nuevoEMpleados
                datos.empleados.forEach((empleado) => {
                  nuevoEMpleados = {id: empleado.id, nombre: empleado.nombre}
                })
                

                const nuevaMano = {                         
                  Fecha: datos.fechaMano,
                  Fk_Jornada: parseInt(datos.jornada.Id),
                  Fk_Planificacion: parseInt(datos.idPlanificacion),
                  Fk_Supervisor: parseInt(datos['supervisor']['id']),
                  HoraFin: datos.horaFin,
                  HoraInicio: datos.horaInicio,
                  Id: response.data,
                  Jornada: datos['jornada']['Etiqueta'],
                  Mano: datos.idMano,
                  ModalidadTrabajo: datos.modalidad,
                  Supervisor: datos.supervisor,
                  TipoOperativa: datos.tipoOperativa,
                  Turno: datos.jornada,
                  empleados: empleadosOperativa, //nuevoEMpleados,  
                  Horas_Extra: horasExtra, // parseInt(datos['hextra']),                
                }     
                const manosOrdenadas = OrdenarManos([...manosPlanificacion, nuevaMano])
                setManosPlanificacion(manosOrdenadas)
                //setManosPlanificacion([...manosPlanificacion, nuevaMano])    
            }
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          });
        }
    }

    const handleChangeFechaPedido = (valor) => { 
        setFechaMano(valor)       
    }

    const handleJornada = (valor) => {
      if(valor){
        setTurno(valor)
        onChangeTime ('horainicio', valor.Hora_Inicio)
        onChangeTime ('horafin', valor.Hora_Fin)
        
      }
        
    }
    
    const onChangeTime = (nombre, valor) => {
      
        //let d = new Date()
        let hours = valor.substring(0, 2);
        let minutes = valor.substring(3, 5);
       

        const hora = hours + ":" + minutes    
        switch(nombre){
            case 'horainicio':
                setHoraInicio(hora)
                break;
            case 'horafin':
                setHoraFin(hora)
                break;
            default:
        }  
    }
    
    const handleSupervisor = (valor) => {
        setSupervisor(valor)
    }
    
    const handleEmpleados = (valor) => {
        setEmpleadosOperativa(valor)
    }
    
    const handleModalidad = (valor) => {
        setModalidadTrabajo(valor)
    }
    
    const handleTipoMercancia = (valor) => {
        setTipoOperativa(valor)
    }

    const handleChangeHorasExtra = valor => {
      if(valor){
        setHorasExtra(valor)         
      }
      else{       
        setHorasExtra(0)
      } 
    }

   const desahabilitado = modo === "W" && id ? false : true   

    let propiedadesFecha = {
        required: true,
        label: "Fecha",
        name: "fechamano",
        type: "date",    
        value: fechaMano,
        disabled: desahabilitado,
        xs: 2
    }

    let propiedadesJornadas = {
        urlData: "dic/diccionarios/listadojornadas",
        campoEtiqueta: "Etiqueta",
        required: true,
        name: "turno",
        disabled: desahabilitado,
        label: "Turno",
        value: turno,
        multiple: false,
        xs: 5
    }

    let propiedadesHoraInicio = {
        xs:2,
        required: true,
        label: "Hora inicio",
        name: "horainicio",
        type: "time",    
        classes: "",
        disabled: desahabilitado,
        value: horaInicio
    }

    let propiedadesHoraFin = {
        xs:2,
        required: true,
        label: "Hora fin",
        name: "horafin",
        type: "time",  
        classes: "",  
        disabled: desahabilitado,
        value: horaFin
    }

    let propiedadesSupervisor = {
        urlData: "dic/diccionarios/listadosupervisoresoperativas",
        campoEtiqueta: "nombre",
        campoId: "id",
        required: false,
        name: "supervisor",
        disabled: desahabilitado,
        label: "Supervisor",
        value: supervisor,
        multiple: false,
        xs: 4
    }

    let propiedadesEmpleados = {
        urlData: "dic/diccionarios/listadoempleadosoperativas",
        campoEtiqueta: "nombre",
        campoId: "id",
        required: false,
        name: "empleados",
        disabled: desahabilitado,
        label: "Empleados",
        value: empleadosOperativa,
        multiple: true,
        xs: 8
    }

    let propiedadesModalidad = {
        urlData: "dic/diccionarios/modalidadestrabajo",
        campoEtiqueta: "Etiqueta",
        campoDescripcion: "Descripcion",
        required: true,
        name: "combomodalidad",
        disabled: desahabilitado,
        label: "Modalidad de trabajo",
        value: modalidadTrabajo,
        multiple: false,
        xs:6,
    }

    let propiedadesOperativas = {
        urlData: "dic/diccionarios/operativasmercancias",
        campoEtiqueta: "Id,Etiqueta",
        required: true,
        name: "combooperativasmercancias",
        disabled: desahabilitado,
        label: "Operativa",
        value: tipoOperativa,
        multiple: false,
        xs:6,
    } 

    let propiedadesHorasExtra = {
      xs:1,
      required: false,
      label: "Horas Extra",
      name: "hextra",
      type: "number",    
      value: horasExtra
    }    

    return(
        <Grid container spacing={1} className="borderFormulario CPE mt-2">
          <FormFieldDateUncontrolled propiedades={propiedadesFecha} onChangeValue={handleChangeFechaPedido} />
          <FormFieldComboUncontrolled propiedades={propiedadesJornadas} onChangeValue={handleJornada} control={control}/>
          <FormFieldTimeUncontrolled propiedades={propiedadesHoraInicio} onChangeTime={onChangeTime} />
          <FormFieldTimeUncontrolled propiedades={propiedadesHoraFin} onChangeTime={onChangeTime}/>
          <FormFieldTextUncontrolled propiedades={propiedadesHorasExtra} control={control} onChangeValue={handleChangeHorasExtra}/>
          
          <div className="col-12 mt-4"></div>
          <FormFieldComboUncontrolled propiedades={propiedadesSupervisor} onChangeValue={handleSupervisor} control={control}/>
          <FormFieldComboUncontrolled propiedades={propiedadesEmpleados} onChangeValue={handleEmpleados} control={control}/>
          <div className="col-12 mt-4"></div>
          <FormFieldComboUncontrolled propiedades={propiedadesModalidad} onChangeValue={handleModalidad} control={control}/>
          <FormFieldComboUncontrolled propiedades={propiedadesOperativas} onChangeValue={handleTipoMercancia} control={control}/>
          <div className="col-12 mt-4">  
            <Button className="float-right MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation" 
            variant="contained" disabled={desahabilitado} onClick={(e) => handleSaveMano(e)}>Guardar datos</Button>
          </div>
        </Grid>
    )
}

export default FormularioPlanificacionManoDatos;


  /*
    const fetchDataNumManos = async () => {                 
        const respManosPlanificacion = await axios(
            `${process.env.REACT_APP_API_URL}planificaciones/manosplanificacion/${id}`
        )                 
        setManosPlanificacion(respManosPlanificacion.data);
        
        if (Object.keys(respManosPlanificacion.data).length > 0) {          
            let elementos = [];  
            respManosPlanificacion.data.forEach(element => {            
                const fetchDataManos = async () => {                            
                const respRecursosManos = await axios(`${process.env.REACT_APP_API_URL}planificacionesmanos/${element.Id}`);                 
                  
                if(respRecursosManos.data){
                    respRecursosManos.data.recursos.forEach(element => {    
                        elementos.push({ elementos: element});          
                    });
                 }
                 
                  //respRecursosManos.data.presupuestos.forEach(element => {  
                  //  elementos.push({ elementos: element});          
                  //});
                                    
                setDatosJornadas( elementos );
                }      
                fetchDataManos();
            })      
        }
    }  
    */