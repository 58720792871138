import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioFormacion = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [formacion, setformacion] = useState([]) 


    const classDiv3 = "col-3 mt-2 mb-2"

    useEffect(() => { 

        setCampos([      
            //0     
            {
                required: true,
                label: "Fecha Inicio:",
                name: "fechaInicio",
                disabled: false,
                type: "date",           
                multiple:false,   
                value: "",
                xs:4
            },
            //1
            {
                required: true,
                label: "Fecha Fin:",
                name: "fechaFin",
                disabled: false,
                type: "date",           
                multiple:false,   
                value: "",
                xs:4
            },
            //2
            {
                urlData: "dic/diccionarios/estadosformacionrrhh",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Estado:",
                name: "estado",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            },
            //3
            {
                required: true,
                label: "Horario:",
                name: "horario",
                type: "text",    
                disabled: false, 
                value: "",                
                xs:4
            },
            //4
            {
                required: true,
                label: "Lugar:",
                name: "lugar",
                type: "text",    
                disabled: false, 
                value: "",                
                xs:4
            },
            //5
            {
                required: true,
                label: "Entidad:",
                name: "entidad",
                disabled: false,
                type: "text",
                value: "",              
                xs:4
            },
            //6
            {
                required: true,
                label: "Curso Formacion:",
                name: "nombreCurso",
                disabled: false,
                type: "text",           
                multiple: false,   
                value: "",
                xs:4
            },
            //7
            {
                urlData: "dic/diccionarios/listadoempleadosactivos",
                campoEtiqueta: "nombre",
                campoId:"id",
                required: true,
                label: "Asistentes",
                name: "asistentes",
                disabled: false,
                type: "combo",           
                multiple:true,   
                value: [],
                xs:8
            },
            //8
            {
                required: true,
                label: "Observaciones:",
                name: "observaciones",
                type: "textArea",    
                disabled: false, 
                value: "",  
                lineas: 4,              
                xs:12
            },   
            //9
            {
                classButton: classDiv3,
                required: false,
                label: "Documentacion",
                name: "ficherosadjuntos",
                type: "filesupload",   
                tabla: "mtms_rrhh_formacion_adjuntos",
                xs:12
            },    
            //10        
            {
                required: false,
                label: "Documentacion",
                name: "ficherosremotos",
                type: "files", 
                ficheros: formacion.ficheros,    
                tabla: "mtms_rrhh_formacion_adjuntos",
                modelo: "api\\modules\\v1\\models\\rrhh\\RRHHFormacionAdjuntos",
                carpeta: "rrhh//formacion",
                xs:12
            },               
    ])

    const fetchData = async () => { 
        if (modo === "W") {
            const respformacion = await axios(
                `${process.env.REACT_APP_API_URL}rrhhformacions/${id}`
            );                
            setformacion(respformacion.data)  
        }
        setDatosCargados(true);
  
    };    
    fetchData();       
},[]);

useEffect(() => {            
    if (typeof formacion !== "undefined") {        
      if(Object.keys(formacion).length > 0){              
        setDatosCargados(false)
        campos[0].value = formacion.Fecha_Inicio;
        campos[1].value = formacion.Fecha_Fin;
        campos[2].value = formacion.Estado;
        campos[3].value = formacion.Horario;
        campos[4].value = formacion.Lugar;
        campos[5].value = formacion.Entidad;
        campos[6].value = formacion.Nombre_Curso;
        campos[7].value = formacion.asistentes;
        campos[8].value = formacion.Observaciones;            
        campos[10].ficheros = formacion.ficheros;

        setDatosCargados(true)  
      }
  }
  },[formacion])
if (datoscargados){
    return ( 
        <FormularioEntidad 
        urlForm = "rrhhformacions"
        urlGoto = "/rrhh/talento/formacion/"
        campos={campos}
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }
}
export default FormularioFormacion;