import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));
  

const FormularioPedido = ({getCodigo}) => {
    const { id, modo } = useParams();
    const classes = useStyles();
    const { /*control, handleSubmit,*/ setValue } = useForm();  
    const [campos, setCampos] = useState([])

    const [pedido, setPedido] = useState();
    const [datosSeleccionados, setDatosSeleccionados] = React.useState({
      buque: '',
      cliente: '',
      contacto: '',
      canal: '',
      muelle: '',
      operativa: [],
      servicios: [],
      presupuesto: false,
    })

    const [datosdesplegables, setDatosDesplegables] = useState({
      buques: null,
      clientes: null,
      contactos: null,
      canales: null,
      muelles: null,
      operativas: null,
      servicios: null
    });

    const [datoscargados, setDatosCargados] = useState(false);  
    const [clientes, setClientes] = useState("")
    const [contacto, setContacto] = useState("")  

  
    useEffect(() => { 
      if (!datoscargados) {
        const fetchData = async () => {     
          const respFormulario = await axios(
            `${process.env.REACT_APP_API_URL}dic/diccionarios/formulariocrearpedidocliente`
          );

          setDatosDesplegables({
            buques: respFormulario.data.buques,
            clientes: respFormulario.data.clientes,
            contactos: respFormulario.data.contactos,
            canales: respFormulario.data.canalSolicitud,
            muelles: respFormulario.data.localizaciones,
            operativas: respFormulario.data.tiposOperativas,            
            servicios: respFormulario.data.servicios,  
            categorias: respFormulario.data.categorias,          
          });
          
  
          if (modo === "W") {

            const respPedido = await axios(
              `${process.env.REACT_APP_API_URL}pedidoclientes/${id}`
            ); 

            if (Object.keys(respPedido.data).length > 0) {  
              getCodigo(respPedido.data.Codigo) 

              setValue("fechasolicitud", respPedido.data.Fecha_Solicitud);
              setValue("descripcion", respPedido.data.Descripcion);
              setValue("referenciacliente", respPedido.data.Referencia_Cliente);
              setValue("fechasoperativa", respPedido.data.Fecha_Servicio)
              
              let idsOperativas = respPedido.data.OperativasCliente.map((s) => {
                return s.Id;
              });

              let idsServicios = respPedido.data.ServiciosCliente.map((s) => {
                return s.Id;
              });

              setDatosSeleccionados({                
                buque: respPedido.data.Fk_Buque,
                cliente: respPedido.data.Fk_Cuenta,
                contacto: respPedido.data.Fk_Contacto,
                canal: respPedido.data.Fk_Fuente,
                muelle: respPedido.data.Fk_Localizacion,
                operativa: idsOperativas,
                servicios: idsServicios,                
                presupuesto: !!respPedido.data.Presupuesto
              })

              setPedido(respPedido.data);
            }
          }

          else{   
            setPedido({
              Presupuesto:false
            })
          }
          setDatosCargados(true);
        };  
        fetchData();
      }
    },[datosSeleccionados, datoscargados, id, modo, setValue]);
          
    const classDiv3 = "col-3 mt-2 mb-2"

    const handleChangeCuenta = (cuentas) => {
      if (cuentas){
        setClientes(cuentas.Id)
      }
      else{setClientes("")}
    }   

    useEffect(() => {  
      if (typeof pedido !== "undefined") { 
        if(Object.keys(pedido).length > 0){   
          const resultado = !pedido.Resultado || pedido.Resultado === 0 ? {Id: 0, Etiqueta: "No confirmado"} : {Id: 1, Etiqueta: "Confirmado"}
          setCampos([
            {
              required: true,
              label: "Fecha de solicitud",
              name: "fechasolicitud",
              type: "date",    
              value: pedido.Fecha_Solicitud ? pedido.Fecha_Solicitud : "",
              xs:4
            },
            {
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Canal de solicitud",
              name: "canal",
              disabled: false,
              type: "comboArray", 
              options:datosdesplegables.canales, 
              multiple:false,   
              value: pedido.Fuente ? pedido.Fuente : '',
              xs:4
            }, 
            {
              required: false,
              label: "Tiene presupuesto",
              name: "presupuesto",
              type: "switch",              
              value: pedido.Presupuesto ? pedido.Presupuesto : "",
              xs:4
            },
            {
              urlData: "cuentas/clientes",
              campoEtiqueta: "Nombre_Comercial",
              required: true,
              label: "Cliente",
              name: "cliente",
              disabled: false,
              type: "combo",           
              multiple:false,   
              value: pedido.Cuenta ? pedido.Cuenta : '',
              xs:4,
              onChangeValue: handleChangeCuenta
            }, 
            {
              urlData: "contactos",
              campoEtiqueta: "Nombre",
              required: false,
              label: "Contacto",
              name: "contacto",
              disabled: false,
              type: "combo",           
              multiple:false,   
              value: pedido.Contacto ? pedido.Contacto : '',
              xs:4,

              urlDataDialogForm: "contactos",
              DialogForm: [
                {
                  required: false,
                  disabled: true,
                  label: "Cuenta:",
                  name: "Fk_Cuenta",
                  type: "number",    
                  value: '',
                  xs:6,
                  classes: 'd-none'
                }, 
                {
                  required: true,
                  disabled: false,
                  label: "Nombre:",
                  name: "Nombre",
                  type: "text",    
                  value: "",
                  xs:6
                }, 
                {
                  required: false,
                  disabled: false,
                  label: "Apellidos:",
                  name: "Apellidos",
                  type: "text",    
                  value: "",
                  xs:12
                }, 
                {
                  required: false,
                  disabled: false,
                  label: "Departamento:",
                  name: "Departamento",
                  type: "text",    
                  value: "",
                  xs:6
                },
                {
                  required: false,
                  disabled: false,
                  label: "Cargo:",
                  name: "Cargo",
                  type: "text",    
                  value: "",
                  xs:6
                },
                {
                  required: false,
                  disabled: false,
                  label: "Móvil:",
                  name: "Movil",
                  type: "text",    
                  value: "",
                  xs:6
                }, 
                {
                  required: false,
                  disabled: false,
                  label: "Email:",
                  name: "Email",
                  type: "text",    
                  value: "",
                  xs:12
                }, 
                {
                  required: false,
                  disabled: false,
                  label: "Fecha de Nacimiento:",
                  name: "FechaNacimiento",
                  type: "text",    
                  value: "",
                  xs:6
                },
                {
                  required: false,
                  disabled: false,
                  label: "Linkedin:",
                  name: "Linkedin",
                  type: "text",    
                  value: "",
                  xs:6
                },
                {
                  required: true,
                  disabled: false,
                  label: "Teléfono:",
                  name: "Telefono",
                  type: "text",    
                  value: "",
                  xs:6
                },
                {
                  required: false,
                  disabled: false,
                  label: "Añadir a agenda personal:",
                  name: "agendapersonal",
                  type: "switch",    
                  value: "",
                  xs:6
                },
              ]
            },
            {
              required: false,
              label: "Referencia de Cliente",
              name: "referenciacliente",
              type: "text",              
              value: pedido.Referencia_Cliente ? pedido.Referencia_Cliente : "",
              xs:4
            },
            {
              required: false,
              label: "Fecha de operativa",
              name: "fechaoperativa",
              type: "date",    
              value: pedido.Fecha_Servicio ? pedido.Fecha_Servicio : "",
              xs:4
            },
            {
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Muelle",
              name: "muelle",
              disabled: false,
              type: "comboArray", 
              options:datosdesplegables.muelles, 
              multiple:false,   
              value: pedido.Localizacion ? pedido.Localizacion : '',
              xs:4
            }, 
            {
              campoEtiqueta: "Nombre",
              required: false,
              label: "Buque",
              name: "buque",
              disabled: false,
              type: "combo", 
              //options:datosdesplegables.buques, 
              multiple:false,   
              value: pedido.Buque ?  pedido.Buque : '',
              xs:4,
              
              urlData: "buques",
              DialogForm: [
              {                
                required: true,
                label: "Nombre:",
                name: "Nombre",
                disabled: false,
                type: "text",
                value: "",   
                xs:12
              }]
              
            }, 
            /*
            {
              campoEtiqueta: "Etiqueta",
              required: true,
              label: "Operativa",
              name: "operativa",
              disabled: false,
              type: "comboArray", 
              options:datosdesplegables.operativas, 
              multiple:true,   
              value: pedido.OperativasCliente ? pedido.OperativasCliente : [],
              xs:4
            }, 
            {
              campoEtiqueta: "Etiqueta",
              required: true,
              label: "Servicios a realizar",
              name: "servicios",
              disabled: false,
              type: "comboArray", 
              options:datosdesplegables.servicios, 
              multiple:true,   
              value: pedido.ServiciosCliente ? pedido.ServiciosCliente : [],
              xs:8
            }, 
            {
              required: false,
              label: "Toneladas Carga",
              name: "toneladascarga",
              type: "number", 
              value: pedido.Toneladas_Carga ? pedido.Toneladas_Carga : "",
              xs:2             
            },
            {
              required: false,
              label: "Toneladas Descarga",
              name: "toneladasdescarga",
              type: "number", 
              value: pedido.Toneladas_Descarga ? pedido.Toneladas_Descarga : "",
              xs:2           
            },            
            {
              required: false,
              label: "Movimientos Carga",
              name: "movimientoscarga",
              type: "number", 
              value: pedido.Movimientos_Carga ? pedido.Movimientos_Carga : "",
              xs:2             
            },
            {
              required: false,
              label: "Movimientos Descarga",
              name: "movimientosdescarga",
              type: "number", 
              value: pedido.Movimientos_Descarga ? pedido.Movimientos_Descarga : "",
              xs:2             
            },
            {
              required: false,
              label: "Mov. Remociones",
              name: "movimientosremociones",
              type: "number", 
              value: pedido.Movimientos_Remociones ? pedido.Movimientos_Remociones : "",
              xs:2             
            },
            */
            {
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Resultado",
              name: "resultado",
              disabled: false,
              type: "comboArray", 
              options: [{Id: 0, Etiqueta: "No confirmado"}, {Id: 1, Etiqueta: "Confirmado"}], 
              multiple:false,   
              value: resultado,
              xs:4,                
            },
            {
              required: false,
              label: "Descripción",
              name: "descripcion",
              type: "textArea", 
              lineas: 4,             
              value: pedido.Descripcion ? pedido.Descripcion : "",
              xs:8
            }, 
            {
              classButton: classDiv3,
              required: false,
              label: "",
              name: "filesupload",
              type: "filesupload",                 
              tabla: "mtms_pedidos_clientes_adjuntos",
              xs:7
            },            
            {
              required: false,
              label: "",
              name: "files",
              type: "files", 
              ficheros: pedido.ficheros ? pedido.ficheros : "",    
              tabla: "mtms_pedidos_clientes_adjuntos",
              modelo: "api\\modules\\v1\\models\\pedidocliente\\PedidoClienteAdjuntos",
              carpeta: "pedidoscliente",
              xs:5
            }, 
          ])
        }
    }
    },[pedido, datosdesplegables])
    
    useEffect(() => { 
      if (typeof campos !== "undefined") {        
          if(Object.keys(campos).length > 0){
            setDatosCargados(false)
            if(clientes) {
              campos[4].urlData = "cuentas/contactos/" + clientes
              campos[4].DialogForm[0].value = clientes
              campos[4].value = ""
              //setCampos(campos)
            }
            else {
              campos[4].value = ""
              campos[4].urlData = "contactos" 
              campos[4].DialogForm[0].value = ""
              //setCampos(campos)
            }
            setDatosCargados(true)
        }
      }
    },[clientes])   
    

    if (datoscargados)
      return (     
        <>     
        <FormularioEntidad 
          urlForm = "pedidoclientes"
          urlGoto = "/ventas/pedidos/pedido/"
          campos={campos}
        />    
       
        </>
      );
    else {
      return (
        <>
          <div className={classes.linear}>
            <LinearProgress />
          </div>
        </>
      );
    }
  };
  export default FormularioPedido;