import React, { useState, useRef } from "react";
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";
import axios from "axios";
import Loading from "../../../../../components/Common/Loading";
import SearchBar from "../../../../../components/Common/SearchBar";
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import TableAcciones from "../../../../../components/Common/TableAcciones";

import {COLUMNS_COTIZACIONES} from './CotizacionesConf'

const Cotizaciones = () => {
  const [isLoading, setIsLoading] = useState(false); 
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);

  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }  

  const actualizarEstado = (estado) => {
    setIsLoading(estado)
  }

  return (
    <div className="col-8 col-main-home ">            
      <SearchBar
        titulo="Ventas"
        claseCabecera = "bg-comercial"
        buscar={false}
        imagenCabecera={"4-2-2-cotizaciones.png"}
      />   
      
      <div className="bg-cuerpo p-10px">
        <Body seleccionarFila={marcarFila} isLoading={isLoading}/>
        <Footer filaSeleccionada={filaSeleccionada} seleccionarFila={marcarFila} actualizarEstado={actualizarEstado} />
      </div>
    </div>
  )  
};

export default Cotizaciones;

const Body = ({seleccionarFila, isLoading}) => {
  const [tipoCotizacion, setTipoCotizacion]=useState(localStorage.getItem('idTipoCotizacion') || "Abiertas");    
  
  const title = 'Cotizaciones'
  const urlDoubleClick = "/ventas/cotizaciones/cotizacion/"
  const filtroToolbar = true
  const filtroToolbarOpciones = ['Abiertas', 'Confirmadas', 'Todas']
  const urlDatos = tipoCotizacion === "Abiertas" ? "cotizaciones?estado=1" : (tipoCotizacion === "Confirmadas" ? "cotizaciones?estado=2" : "cotizaciones");
  const footer = [{campo: 'Importe', titulo: 'Importe'},]

  const marcarFila = (fila) => {
    seleccionarFila(fila)
  }  

  const handleOperacion = (idO) =>{  
    localStorage.setItem('idTipoCotizacion', idO);     
    setTipoCotizacion(idO)
  }  

  return (
    <div className="bg-white p-10px">
      <div className="p-1 bg-white mb-3">
        <h6 className="texto-comercial float-left" id="title">{title}</h6>
      </div>
      <hr className="m-0 bg-comercial mb-1" />
      <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
          {!isLoading && ( 
            <CuerpoTablaPiezas
              columnas = {COLUMNS_COTIZACIONES}
              urlDatos = {urlDatos}
              urlDoubleClick = {urlDoubleClick}
              columnId = "Id"
              getFilaSeleccionada={marcarFila}
              filtroToolbar={filtroToolbar}
              filtroToolbarOpciones = {filtroToolbarOpciones}
              filtroToolbarValor = {tipoCotizacion}
              filtroToolbarChangeValor={handleOperacion}
              customFooter={footer}   
              filtroEjercicio = {true}
            /> 
          )}
          <div>{isLoading && <Loading isLoading={isLoading} />}</div>
           
          </div>
    </div>
  )

}

const Footer = ({filaSeleccionada, seleccionarFila, actualizarEstado }) => {
  const [abrirDialogo, setAbrirDialogo] = useState(false)
  const cotizacionEliminar = useRef(-1);
  const mensajeErrorEliminacion = "No se ha podido eliminar la cotización"

  const handleCerrarDialogo = (valor) => {       
    setAbrirDialogo(false)
    if(valor){    
      const fetchData = async () => {  
        actualizarEstado(true)
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + 'cotizaciones';
        await axios
        .delete(SERVER_URL + "/" + cotizacionEliminar.current, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },        
        })
        .then((response) => {
          if(!isNaN(response.data)){
            if(response.data === cotizacionEliminar.current){
              cotizacionEliminar.current = -1 
            }          
          }else{
            alert(mensajeErrorEliminacion)
          }
        })
        .catch((error) => {
          console.log("error " + error);
          
          alert(mensajeErrorEliminacion)
        })
        .finally( () => {
          actualizarEstado(false)
          seleccionarFila(-1)
        })
      }
      fetchData();
    }      
  }

  const handleClickEliminarCotizacion = (valor) => {  
    cotizacionEliminar.current = valor       
    setAbrirDialogo(true)
  }  

  const BOTONERA_ACCIONES = [
    { etiqueta: "Crear Cotización", url: "/ventas/cotizaciones/cotizacion", activo: true },
    {
      etiqueta: "Editar Cotización",
      url: `/ventas/cotizaciones/cotizacion/filaSeleccionada/W`,
      activo: false,
    },    
    {
      etiqueta: "Eliminar",   
      function: (e) => handleClickEliminarCotizacion(e),
      activo: false,
    },      
    /*
    {
      etiqueta: "Crear Carpeta Producción",
      url: `/operativas/cuentas/crearcarpetaproduccion/filaSeleccionada`,
      activo: false,
    },
    */
  ];

  return (
    <div className="bg-cuerpo">
      <div className="bg-white">
      { abrirDialogo && (<AlertaDialogo titulo={"¿Desea eliminar la cotización?"} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
        <TableAcciones                
          filaSeleccionada={filaSeleccionada}                
          botonaccion={BOTONERA_ACCIONES}
        />            
      </div>
    </div>
  )
}