import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioUniforme = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos,setCampos] = useState([])  
    const [seleccion, setseleccion] = useState([]) 

    const classDiv3 = "col-3 mt-2 mb-2"

    

  useEffect(() => { 

    setCampos([      
            //0     
              {
                  urlData: "dic/diccionarios/listadoempleadosactivos",
                  campoEtiqueta: "nombre",
                  required: true,
                  label: "Trabajador:",
                  name: "trabajador",
                  disabled: false,
                  type: "combo",
                  value: "",              
                  xs:4
              },
              //1
              {
                  urlData: "dic/diccionarios/categoriasuniforme",
                  campoEtiqueta: "Etiqueta",
                  required: true,
                  label: "Categoria:",
                  name: "Fk_Categoria_Uniforme",
                  disabled: false,
                  type: "combo",
                  value: "",              
                  xs:4
              },
              //2
              {
                  urlData:"dic/diccionarios/elementosuniforme",
                  campoEtiqueta: "Etiqueta",
                  required: true,
                  label: "Elemento",
                  name: "Fk_Elemento_Uniforme",
                  disabled: false,
                  type: "combo",           
                  multiple:false,   
                  value: "",
                  xs:4
              },
              //3
              {
                  required: true,
                  label: "Fecha:",
                  name: "fecha",
                  disabled: false,
                  type: "date",           
                  multiple:false,   
                  value: "",
                  xs:4
              },
              //4
              {
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Activo",
                name: "Activo",
                type: "comboArray", 
                options:[{Id: '1', Etiqueta: 'Activo'}, {Id: '0', Etiqueta: 'Inactivo'}], 
                multiple:false,   
                value: "",
                xs:4
              },   
               //5
               {
                required: true,
                label: "Unidades:",
                name: "cantidad",
                disabled: false,
                type: "number",           
                multiple:false,   
                value: "",
                xs:4
            },
              //6
              {
                  required: true,
                  label: "Observaciones:",
                  name: "observaciones",
                  type: "textArea",    
                  disabled: false, 
                  value: "",
                  lineas:4,               
                  xs:12
                  
              },
            //7
              {
                  classButton: classDiv3,
                  required: false,
                  label: "Documentacion",
                  name: "ficherosadjuntos",
                  type: "filesupload",   
                  tabla: "mtms_rrhh_uniformes_adjuntos",
                  xs:12
                },  
                //8          
                {
                  required: false,
                  label: "Documentacion",
                  name: "ficherosremotos",
                  type: "files", 
                  ficheros: seleccion.ficheros,    
                  tabla: "mtms_rrhh_uniformes_adjuntos",
                  modelo: "api\\modules\\v1\\models\\rrhh\\RrhhUniformesAdjuntos",
                  carpeta: "rrhh//uniformes",
                  xs:12
                },
              
              
          
    ])

    const fetchData = async () => { 
      //setDatosCargados(false)
      if (modo === "W") {
        const respseleccion = await axios(
          `${process.env.REACT_APP_API_URL}rrhhuniformes/${id}`
        );
        
        setseleccion(respseleccion.data);
        
      }
      setDatosCargados(true);
    }
    fetchData();

    //setDatosCargados(true);
          
  },[]);

  useEffect(() => {  
           
    if (typeof seleccion !== "undefined") {        
      if(Object.keys(seleccion).length > 0){ 

        const options= [{Id: 1, Etiqueta: 'Activo'}, {Id: 0, Etiqueta: 'Inactivo'}];
        const activo = options.filter((element)=>element.Id === seleccion.Activo);
        setDatosCargados(false);
      
       
        campos[0].value = seleccion.Fk_trabajador;
        campos[1].value = seleccion.Fk_Categoria_Uniforme;
        campos[2].value = seleccion.Fk_Elemento_Uniforme;
        campos[3].value = seleccion.Fecha;
        campos[4].value = activo[0];
        campos[5].value = seleccion.Cantidad;
        campos[6].value = seleccion.Observaciones;            
        campos[8].ficheros = seleccion.ficheros;

        setDatosCargados(true)  
      }
      
  }
  },[seleccion])

  
  if (datoscargados){
    return ( 
    <>
      <FormularioEntidad 
        urlForm = "rrhhuniformes"
        urlGoto = "/rrhh/talento/uniforme/"
        campos={campos}
        type={ Object.keys(seleccion).length > 0 ? "edit" : "add" }
      /> 
    </>       
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}


export default FormularioUniforme;