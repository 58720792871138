import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import LinearProgress from "@material-ui/core/LinearProgress";
import {useCotizacion} from './cotizacion-context'
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'
import DialogImportData from "../../../../../components/Common/DialogImportData";

const useStyles = makeStyles((theme) => ({   
  linear: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const FormularioCotizacion = () => {  
    
    const { id } = useParams();    
    const classes = useStyles();    
    
    const { datosCotizacion, datosDesplegables } = useCotizacion();
    
    const [datoscargados, setDatosCargados] = useState(false);
    const [open, setOpen] = useState(false);  
    const [clientes, setClientes] = useState("") 
    const [resultado, setResultado] = useState("")

    const handleChangeCuenta = (cuentas) => {
      if (Array.isArray(cuentas)){
        setClientes(cuentas.map((cuenta) => cuenta.Id).join("_"))
      }
    }  
    
    const handleClickOpen = () => { 
      setOpen(true);
    };     

    const classDiv3 = "col-3 mt-2 mb-2"
    const [campos, setCampos] = useState([])  
    
    useEffect(() => {                 
      if(Object.keys(datosDesplegables).length > 0){          
        const aceptado = datosCotizacion.Cotizacion && datosCotizacion.Cotizacion.Id === 1 ? true : false        
       
        let notaPedido="";  

        if(Object.keys(datosCotizacion).length > 0){
          if( datosCotizacion.Fk_PedidoCliente.length > 0 ){
            notaPedido = datosCotizacion.Fk_PedidoCliente[0].Codigo
          }
        }
        
        setResultado(datosCotizacion.Cotizacion)    
        setCampos([
          //0
          {
            required: true,            
            label: "Fecha de solicitud",
            name: "fechasolicitud",
            type: "date",    
            value: datosCotizacion.Fecha_Solicitud ? datosCotizacion.Fecha_Solicitud : "",
            xs:4
          },
          //1
          {
            campoEtiqueta: "Etiqueta",
            required: true,
            label: "Canal de solicitud",
            name: "canal",
            disabled: false,
            type: "comboArray", 
            options:datosDesplegables.canales, 
            multiple:false,   
            value: datosCotizacion.Fuente ? datosCotizacion.Fuente : '',
            xs:4
          },    
          //2       
          {
            required: true,
            label: "Nota de pedido",
            name: "notapedido",
            type: "text",  
            disabled: true,            
            value: notaPedido ? notaPedido : "",
            xs:2
          },
          //3
          {
            required: false,
            label: "Ref. de Cliente",
            name: "referenciacliente",
            type: "text",              
            value: datosCotizacion.Referencia_Cliente ? datosCotizacion.Referencia_Cliente : "",
            xs:2
          },
          //4
          {
            urlData: "cuentas/clientes",
            campoEtiqueta: "Nombre_Comercial",
            required: true,
            label: "Cliente",
            name: "nombreComercial",
            disabled: false,
            type: "combo",           
            multiple:true,   
            value: datosCotizacion.Cuenta ? datosCotizacion.Cuenta : [],
            xs:4,
            onChangeValue: handleChangeCuenta
          },  
          //5      
          {
            urlData: "contactos",
            campoEtiqueta: "Nombre",
            campoId: "Id",
            required: false,
            label: "Contacto",
            name: "contacto",
            disabled: false,
            type: "combo",           
            multiple:true,   
            value: datosCotizacion.Contacto ? datosCotizacion.Contacto : [],
            xs:4,

            urlDataDialogForm: "contactos",
              DialogForm: [
                {
                  required: false,
                  disabled: true,
                  label: "Cuenta:",
                  name: "Fk_Cuenta",
                  type: "number",    
                  value: '',
                  xs:6,
                  classes: 'd-none'
                }, 
                {
                  required: true,
                  disabled: false,
                  label: "Nombre:",
                  name: "Nombre",
                  type: "text",    
                  value: "",
                  xs:6
                }, 
                {
                  required: false,
                  disabled: false,
                  label: "Apellidos:",
                  name: "Apellidos",
                  type: "text",    
                  value: "",
                  xs:12
                }, 
                {
                  required: false,
                  disabled: false,
                  label: "Departamento:",
                  name: "Departamento",
                  type: "text",    
                  value: "",
                  xs:6
                },
                {
                  required: false,
                  disabled: false,
                  label: "Cargo:",
                  name: "Cargo",
                  type: "text",    
                  value: "",
                  xs:6
                },
                {
                  required: false,
                  disabled: false,
                  label: "Móvil:",
                  name: "Movil",
                  type: "text",    
                  value: "",
                  xs:6
                }, 
                {
                  required: false,
                  disabled: false,
                  label: "Email:",
                  name: "Email",
                  type: "text",    
                  value: "",
                  xs:12
                }, 
                {
                  required: false,
                  disabled: false,
                  label: "Fecha de Nacimiento:",
                  name: "FechaNacimiento",
                  type: "text",    
                  value: "",
                  xs:6
                },
                {
                  required: false,
                  disabled: false,
                  label: "Linkedin:",
                  name: "Linkedin",
                  type: "text",    
                  value: "",
                  xs:6
                },
                {
                  required: true,
                  disabled: false,
                  label: "Teléfono:",
                  name: "Telefono",
                  type: "text",    
                  value: "",
                  xs:6
                },
                {
                  required: false,
                  disabled: false,
                  label: "Añadir a agenda personal:",
                  name: "agendapersonal",
                  type: "switch",    
                  value: "",
                  xs:6
                },
              ]

          },        
          //6
          {
            required: false,
            label: "Fecha de operativa",
            name: "fechadeoperativa",
            type: "date",    
            value: datosCotizacion.Fecha_Operativa ? datosCotizacion.Fecha_Operativa : "",
            xs:4
          },
          //
          {
            campoEtiqueta: "Etiqueta",
            required: false,
            label: "Muelle",
            name: "muelle",
            disabled: false,
            type: "comboArray", 
            options:datosDesplegables.muelles, 
            multiple:false,   
            value: datosCotizacion.Localizacion ? datosCotizacion.Localizacion : '',
            xs:4
          },     
          //8     
          {
            campoEtiqueta: "Nombre",
            required: false,
            label: "Buque",
            name: "buque",
            disabled: false,
            type: "combo", 
            //options:datosDesplegables.buques, 
            multiple:false,   
            value: datosCotizacion.Buque ?  datosCotizacion.Buque : '',
            xs:4,
            urlData: "buques",
              DialogForm: [
              {                
                required: true,
                label: "Nombre:",
                name: "Nombre",
                disabled: false,
                type: "text",
                value: "",   
                xs:12
              }]
          },
          //9
          {
            urlData: "dic/diccionarios/operativasmercancias",
            campoEtiqueta: "Id,Etiqueta",
            campoId: "Id",
            required: true,
            label: "Operativa",
            name: "operativamercancias",
            disabled: false,
            type: "combo",           
            multiple:false,   
            value: datosCotizacion.OperativaMercancia ? datosCotizacion.OperativaMercancia : "",
            xs:4
          },
          //10
          {
            campoEtiqueta: "Etiqueta",
            campoId: "Id",
            required: false,
            label: "Enviado",
            name: "estado",
            disabled: false,
            type: "comboArray",
            options: [{Id: 2, Etiqueta: 'No'}, {Id: 1, Etiqueta: 'Sí'}, {Id: 0, Etiqueta: ''}], 
            value: datosCotizacion.Estado ? datosCotizacion.Estado : "",
            multiple:false,
            xs:3
          }, 
          //11       
          {
            campoEtiqueta: "Etiqueta",
            required: false,
            label: "Resultado",
            name: "resultado",
            disabled: false,
            type: "comboArray",
            options: [{Id: 2, Etiqueta: 'Rechazado'}, {Id: 1, Etiqueta: 'Aceptado'}], 
            multiple:false,   
            value: datosCotizacion.Cotizacion ? datosCotizacion.Cotizacion : "",                        
            xs:3,
            onChangeValue: handleChangeResultado
          }, 
          //12  
          {
            required: false,
            label: "Total aceptado",
            name: "totalpresupuestoaceptado",
            type: "number",              
            value: datosCotizacion.TotalAceptado ? datosCotizacion.TotalAceptado : "",
            xs:3,
            classes: aceptado ? "" : "d-none"
          },  
          //13     
          {
            required: false,
            label: "Total aceptado (TN)",
            name: "totalpresupuestoaceptadotn",
            type: "number",              
            value: datosCotizacion.TotalAceptadoTN ? datosCotizacion.TotalAceptadoTN : "",
            xs:3,
            classes: aceptado ? "" : "d-none"
          },       
          //14
          {
            required: true,
            label: "Descripción",
            name: "descripcion",
            type: "textArea", 
            lineas: 4,             
            value: datosCotizacion.Descripcion ? datosCotizacion.Descripcion : "",
            xs:12
          }, 
          //15
          {
            classButton: classDiv3,
            required: false,
            label: "",
            name: "filesupload",
            type: "filesupload", 
            //ficheros: pedido.ficheros,    
            tabla: "mtms_cotizaciones_adjuntos",
            xs:8
          },    
          //16        
          {
            required: false,
            label: "",
            name: "files",
            type: "files", 
            ficheros: datosCotizacion.ficheros ? datosCotizacion.ficheros : "",    
            tabla: "mtms_cotizaciones_adjuntos",
            modelo: "api\\modules\\v1\\models\\cotizaciones\\CotizacionesAdjuntos",
            carpeta: "cotizaciones",
            xs:6
          },
          //17
          {    
            required: false,
            label: "",
            name: "listadoProductos",
            type: "text",    
            disabled: false, 
            value: datosCotizacion.listadoProductos ? datosCotizacion.listadoProductos : "",                
            xs:4,
            classes: 'd-none',
            hidden: false,
        },
        ])  
        if (Array.isArray(datosCotizacion.Cuenta)){
          setClientes(datosCotizacion.Cuenta.map((cuenta) => cuenta.Id).join("_"))
        }       

      } 
    },[datosCotizacion, datosDesplegables])
   

    useEffect(() => {  
      if(Object.keys(datosDesplegables).length > 0){        
        setDatosCargados(true)
      }
    },[datosDesplegables])
         
    const handleChangeResultado = ( valor ) => {
      setResultado(valor)
    } 

    useEffect(() => {  
      if (typeof campos !== "undefined") { 
          if(Object.keys(campos).length > 0){  
            if(clientes) {
              campos[5].urlData = "cuentas/contactos/" + clientes
              campos[5].DialogForm[0].value = clientes
            }
            else {
              campos[5].urlData = "contactos"  
              campos[5].value = ""
              campos[5].DialogForm[0].value = ""
            }
        }        
      }      
    },[clientes])

    const handleClosePedido = () => {
      setOpen(false);
    };

    const handleNotaPedido = (notapedido) => { 
      if (Object.keys(notapedido).length > 0) {
        setDatosCargados(false)   
        campos[0].value =  notapedido.Fecha_Solicitud
        campos[1].value =  notapedido.Fuente ? notapedido.Fuente : ""
        campos[2].value =  notapedido.Codigo
        campos[3].value =  notapedido.Referencia_Cliente ? notapedido.Referencia_Cliente : ""
        campos[4].value =  [notapedido.Cuenta]
        campos[5].value =  notapedido.Contacto ? [notapedido.Contacto] : []
        campos[6].value =  notapedido.Fecha_Servicio ? notapedido.Fecha_Servicio : ""
        campos[7].value =  notapedido.Localizacion ? notapedido.Localizacion : ""
        campos[8].value =  notapedido.Buque ? notapedido.Buque : ""
        campos[14].value =  notapedido.Descripcion ? notapedido.Descripcion : ""
        campos[17].value =  notapedido.listadoProductos ? notapedido.listadoProductos : ""
        if (Array.isArray([notapedido.Cuenta])){
          const clientePC = [notapedido.Cuenta].map((cuenta) => cuenta.Id).join("_")
          setClientes(clientePC)
        }  
        setDatosCargados(true)        
      }; 
    }
    
    if(Object.keys(campos).length > 0){ 
      const clase = resultado && resultado.Id === 1 ? '' : 'd-none'     
      campos[12].classes = clase   
      campos[13].classes = clase       
    }
    
   
    if (datoscargados)
      return (
        <>  
        {typeof id === 'undefined' && (  
        <div className="row justify-content-md-center">            
          <Button 
            className="mx-auto mt-3 mb-3 boton-secundario"
            variant="contained" 
            disableElevation
            size="small"
            onClick={handleClickOpen}              
          >
          Cargar nota de pedido
          </Button>              
        </div> 
        )}   

        <FormularioEntidad 
          urlForm = "cotizaciones"
          urlGoto = "/ventas/cotizaciones/cotizacion/"
          campos={campos}
        />  

        {open && (
        <DialogImportData
          isOpen = {open}
          handleCloseDialog = {handleClosePedido}
          url_datos = "pedidoclientes/getpedidosnoimportadosencotizaciones"
          titulo = "Cargar Pedido de cliente" 
          descripcion="Seleccione el pedido de cliente que desee importar para cargarla en el formulario."
          tituloSelect="Código de pedido de cliente"
          SetDatos = {handleNotaPedido}
        />)} 
                     
        </>
      );
    else {
      return (
        <>
          <div className={classes.linear}>
            <LinearProgress />
          </div>
        </>
      );
    }
  };

  export default FormularioCotizacion;