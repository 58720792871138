import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import FormularioOperacionGesportEmpleados from './FormularioOperacionGesportEmpleados'
import FormularioOperacionGesportMaquinaria from './FormularioOperacionGesportMaquinaria'
import FormularioOperacionGesportConsumible from './FormularioOperacionGesportConsumible'
import FormularioOperacionGesportEscolta from './FormularioOperacionGesportEscolta'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import FormFieldDateUncontrolled from '../../../../../components/Common/FormFieldDateUncontrolled'
import FormFieldTextUncontrolled from '../../../../../components/Common/FormFieldTextUncontrolled'

import { StateContext } from '../../../../../context'
import { getItems } from "../../../../../tools/metodos";

let propiedadesNumParte = {
    required: false,
    name: "numeroparte",
    disabled: false,
    label: "Nº Parte",
    value: "",
    xs:2,
}

let propiedadesFechaParteGesport = {
    xs:3, 
    required: false,
    label: "Fecha Parte",
    name: "fechapartegesport",
    type: "date",    
    value: ''
}

const FormularioOperacionGesport = () => { 
    let { id } = useParams(); 
    const {state, dispatch} = useContext(StateContext)
    const { operacion } = state

    const [listadoRecursosGesport, setListadoRecursosGesport] = useState([])
    
    const [refrescar, setRefrescar] = useState(false)
    const [value, setValue] = React.useState('empleado');

    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: 'idRecurso', headerName: 'idRecurso', hide: true },
        { field: 'recurso', headerName: 'Recursos', width: 90 },
        { field: 'nombre', headerName: 'Nombre', width: 190 },
        { field: 'cantidad', headerName: 'Cantidad', width: 90 },
        { field: 'coste', headerName: 'Coste', width: 90 },
        { field: 'total', headerName: 'Total', width: 90 },
        { field: 'horainicio', headerName: 'Hora inicio', width: 100 },
        { field: 'horafin', headerName: 'Hora fin', width: 90 },
        { field: '', headerName: '', width: 120, 
            renderCell: (params) => (
                  <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>
              ), },
    ];

    const resetForm = () => {
        propiedadesNumParte.value = ''
        propiedadesFechaParteGesport.value = ''
        setListadoRecursosGesport([])
    }

    useEffect(() => {
        if(  typeof operacion.listadoGesport === 'undefined' ||
            (Array.isArray(operacion.listadoGesport) && operacion.listadoGesport.length === 0 )
        ){        
            resetForm()
        }else{
            setListadoRecursosGesport(operacion.listadoGesport)
        }
       
    },[operacion.listadoGesport])

    const handleRemoveElement = (element) =>{
        const elementoAEliminar = listadoRecursosGesport.find(el => {
            return el.id === element.id
        })
        dispatch({type: 'REMOVE_LISTADO_GESPORT', payload: elementoAEliminar})
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleChangeNumParte = (valor) => {
        propiedadesNumParte.value=valor
        actualizaPedidoGesport()
        setRefrescar(!refrescar)
    }

    const handleChangeFechaParte = (valor) => {
        propiedadesFechaParteGesport.value=valor
        actualizaPedidoGesport()
        setRefrescar(!refrescar)
    }

    const actualizaPedidoGesport = () => {
        dispatch({type: "ADD_PEDIDO_GESPORT", 
        payload: {
            numParte: propiedadesNumParte.value,
            fechaParte: propiedadesFechaParteGesport.value
        }})
    }

    const handleCleanForm = () => { 
        dispatch({type: "CLEAR_LISTADO_GESPORT"})     
    }

    const handleSubmitForm = async () => {
        
        let continuar = true
        //AVISAR SI EXISTE EL PARTE
        const SERVER_URL_PARTES = `${process.env.REACT_APP_API_URL}operaciones/existepartegesport?partegesport=${operacion.pedidoGesport ? operacion.pedidoGesport.numParte : null}`
        let items = await getItems(SERVER_URL_PARTES);
        if(isNaN(items)){
            let text = "Este número de parte ya está en el sistema en las siguientes operaciones:\n" + items + "\n¿Desea Continuar?";
            if (window.confirm(text) == true) {
                continuar = true;
            } else {
                continuar = false
            }
        }
        if (!continuar) return;

        let datosFormulario = {};

        datosFormulario.numparte = operacion.pedidoGesport ? operacion.pedidoGesport.numParte : null
        datosFormulario.fechaparte = operacion.pedidoGesport ? operacion.pedidoGesport.fechaParte : null
        datosFormulario.listadoGesport = operacion.listadoGesport
        datosFormulario.tipoForm = "gesport"

        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}operaciones/${id}`

        await axios
        .put(SERVER_URL, {
        headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
        },
        datosFormulario,
        })
        .then((response) => {       
        if(!isNaN(response.data)){
            if(parseInt(response.data) === parseInt(id)){      
            operacion.actualizarDetalle = true                 
                dispatch({type: "UPDATE_OPERACION", 
                payload: "gesport",
                })
                alert("Parte guardado correctamente")
                //setOpenAlert(true)
            }
        }
        })
        .catch((error) => {
            console.log("error " + error);
        });
        
    }

    
    return(
        <>
        <Grid container spacing={1} className="GESPORT mt-2 borderFormulario">
            
            
            <FormFieldTextUncontrolled propiedades={propiedadesNumParte} onChangeValue={handleChangeNumParte} />
            <FormFieldDateUncontrolled propiedades={propiedadesFechaParteGesport} onChangeValue={handleChangeFechaParte} />
            
            <Grid item xs={6} className="ml-4 " >
                <FormControl component="fieldset">
                    <RadioGroup row aria-label="position" name="position" value={value} onChange={handleChange} >
                        <FormControlLabel value="empleado" control={<Radio color="primary" />} label="Empleado" />
                        <FormControlLabel value="maquinaria" control={<Radio color="primary" />} label="Maquinaria" />
                        <FormControlLabel value="consumible" control={<Radio color="primary" />} label="Consumible" />
                        <FormControlLabel value="escolta" control={<Radio color="primary" />} label="Escolta" />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
        
        <Grid container  spacing={1} className="mt-2 borderFormulario">
            <Grid item xs={12} className="" >
                {value === "empleado" && 
                    <FormularioOperacionGesportEmpleados />
                }
                {value === "maquinaria" && 
                    <FormularioOperacionGesportMaquinaria />
                    }
                {value === "consumible" && 
                    <FormularioOperacionGesportConsumible />
                }
                {value === "escolta" && 
                    <FormularioOperacionGesportEscolta />
                }
            </Grid>

            <Grid item xs={12} className="" style={{height: 280}}>
                <DataGrid 
                    rows={listadoRecursosGesport} 
                    columns={columns} 
                    pageSize={100} 
                    checkboxSelection = {false}
                    hideFooter = {true}
                    hideFooterPagination = {true}
                    hideFooterRowCount = {true}
                    hideFooterSelectedRowCount = {true}
                />
            </Grid>
        </Grid>
        <Grid container  spacing={1} className="">
        <Grid item xs={8}></Grid>
         <Grid item xs={2}>
            <Button className="float-right" variant="contained" color="primary" 
                onClick={() => handleCleanForm() }>
                CANCELAR
            </Button>
        </Grid>   

         <Grid item xs={2}>
            <Button className="float-right" variant="contained" color="primary" 
                onClick={() => handleSubmitForm() }>
                GUARDAR
            </Button>
        </Grid> 
        </Grid>  
         
        </>
    )
}
export default FormularioOperacionGesport;

/*
<FormFieldText propiedades={propiedadesNumParte} control={control} />
            <FormFieldDate propiedades={propiedadesFechaParteGesport} control={control} />

            */