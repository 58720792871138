import { apiRequest } from "../actions/api";
import { LOGIN, RECOVERYPASS } from "../actions/auth";

const SERVER_URL = `${process.env.REACT_APP_API_URL_ROOT}users`;

export const appMiddleware = () => (next) => (action) => {
  
  next(action);
  switch (action.type) {
    case LOGIN: {
      next(
        apiRequest({
          url: `${SERVER_URL}/login?username=${action.payload.username}&password=${action.payload.password}`,
          method: "POST",
          data: action.payload,
        })
      );
      break;
    }
    case RECOVERYPASS: {
      next(
        apiRequest({
          url: `${SERVER_URL}/recoverypass/${action.payload.email}`,
          method: "GET",
          data: action.payload,
        })
      );
      break;
    }
    default:
      break;
  }
};
