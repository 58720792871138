import React, { useState, useEffect, useContext } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { useParams } from "react-router-dom";

const FormFieldAutoCompleteUncontrolled = ({propiedades, onChangeValue}) => {
    const [open, setOpen] = useState(false)
    const [selectedValue, setSelectedValue] = useState(propiedades.value)
    const [valores, setValores] = useState([]);
    const loading = open && valores.length === 0;
    const { modo } = useParams();

    useEffect(() => { 
      setSelectedValue(propiedades.value)
    },[])

    useEffect(() => {  
      
      let active = true;
      if (!loading) {
        //return undefined;
      }

      if(propiedades.value === null){
        setSelectedValue("")
      } else{
        setSelectedValue(propiedades.value)
      }
      
      (async () => {   
        const respClientes = await axios(process.env.REACT_APP_API_URL + propiedades.urlData);      
        if (active) {
            if(respClientes.data.length > 0 ){
              setValores(Object.keys(respClientes.data).map((key) => respClientes.data[key]))
            } else {
              const etiquetas = propiedades.campoEtiqueta.split(',')
              const etiqueta = etiquetas[0];
              const sinDatos = [{ 
                Id: -1,
                [etiqueta]: "Sin datos"
              }]
              setValores(sinDatos)
            }
        }
      })();

      return () => {
        active = false;
      };
    }, [loading, propiedades]);


    const getOptionLabel = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedades.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const deshabilitado = (
      typeof(propiedades.disabled) === "undefined" ? 
      modo==="R" ?  true : false : propiedades.disabled
    )

    const getOptionSelected = (option, value) => {    
      
      const campoId = propiedades.campoId ? propiedades.campoId : "Id"

      if(!selectedValue) return true;

      if(propiedades.multiple === true){
        const estaArray = selectedValue.filter(selected => selected[campoId] === option[campoId])
        return estaArray.length > 0
      }
      
      return option[campoId] === selectedValue[campoId]

      
      /*
        if(value){
            if( option.id === value.Id ) 
            {
                setSelectedValue(value)
            }
            return option.id === value.Id
        } 
        
        return true
        */
    }

    const renderInput = (params) => {    
      const multipleRequired = Array.isArray(selectedValue) && selectedValue.length === 0
      const singleRequired = selectedValue === "" && propiedades.required;
      const isRequired = (singleRequired || multipleRequired) && propiedades.required
      
      return (
          <TextField  
          {...params}
              label={propiedades.label}
              required={isRequired}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
          }}             
          />
      )
    }

    const handleChange = (e, v) => { 
        if(onChangeValue) onChangeValue(v)    
        if(propiedades.onChangeValue) propiedades.onChangeValue(v)    
        setSelectedValue(v)

    }

  
    return (        
      <Grid item xs={propiedades.xs} className="">
        <Autocomplete
          multiple = {propiedades.multiple}  
          id={propiedades.name}
          name={propiedades.name}
          getOptionLabel= { getOptionLabel }
          getOptionSelected={(option, value) => getOptionSelected(option, value)}
          open={open}
          loadingText="Cargando..."
          onOpen={() => { setOpen(true); }}
          onClose={() => { setOpen(false); }}   
          loading={loading}
          label={propiedades.label}        
          renderInput={renderInput}
          disabled={deshabilitado}
          onChange={(e, v, r) => handleChange(e, v, r)}  
          options={valores}
          //options={valores.sort((a, b) => b.nombreCompleto > a.nombreCompleto)}
          //groupBy={(option) => option.nombreCompleto }
          value={selectedValue}
          style={{  marginLeft: 0 }}
        />            
      </Grid>
    )
}

export default FormFieldAutoCompleteUncontrolled;