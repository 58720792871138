import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
const TablaPresupuestoCotizacionProductos = ({listadoProductos,ListadoProductosFinal,actualizarDatos}) => {  
    const { id, modo } = useParams();
    const [listadoProductosInicial, setListadoProductosInicial] = useState([])    

  
    const columns = [ 
      
        { field: 'TextoOperativa', headerName: 'Descripción', width: 350, editable: true },

        { field: 'ImporteOperativa', headerName: 'Importe', type: 'number', width: 90, editable: true },
      
        { field: 'Eliminar', headerName: '', width: 30, 
            renderCell: (params) => {
                const disabled= modo === "W" && id ? false : true
                if(!disabled)
                    return <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>                
                return null;
            }, 
        },

        { field: 'Guardar', headerName: '', width: 30, 
            renderCell: (params) => {
                const disabled= modo === "W" && id ? false : true
                if(!disabled)
                    return <SaveIcon onClick={() => handleSaveElement(params.row)}/>                
                return null;
            }, 
        },
    ];

    const handleCellEditCommit = React.useCallback(
        ({ id, field, value }) => {

        if (field === 'descripcion') {                       
            const updatedRows = listadoProductosInicial.map((row) => {
                if (row.id === id) {  
                    return { ...row,  descripcion: value};
                }
                  return row;
            });     
            setListadoProductosInicial(updatedRows)   
        }  

          if (field === 'importe') {                       
            const updatedRows = listadoProductosInicial.map((row) => {
              if (row.id === id) {  
                return { ...row,  importe: value};
              }
              return row;
            });     
            setListadoProductosInicial(updatedRows)    
        }     
          
    }, [listadoProductosInicial])

    const handleSaveElement = (element) => {
        actualizarDatos(element)
    }
    
    const resetForm = () => {
        setListadoProductosInicial([])
    }

    useEffect(() => {
        //OBETENER LAS JORNADAS DESDE LA BBDD
    },[])

    useEffect(() => {
        if(  typeof listadoProductos === 'undefined' ||
            (Array.isArray(listadoProductos) && listadoProductos.length === 0 )
        ){        
            resetForm()
        }else{
            setListadoProductosInicial(listadoProductos)
        }
    },[listadoProductos])    

    const handleRemoveElement = (element) =>{        
        ListadoProductosFinal(element)
    }   

    let unidades = 0
    listadoProductosInicial.forEach(element => {
        unidades += parseFloat(element.ImporteOperativa)
    })


    return(
        <>        
        <Grid item xs={12} className="" style={{height: 250}}>
            <DataGrid 
                //rowHeight={150}
                disableColumnMenu={true}
                rows={listadoProductosInicial} 
                columns={columns}    
                //autoHeight = {true}   
                density = {'compact'}
                //pageSize={100} 
                //checkboxSelection = {false}
                //hideFooter = {true}
                //hideFooterPagination = {true}
                //hideFooterRowCount = {true}
                //hideFooterSelectedRowCount = {true}
                onCellEditCommit={handleCellEditCommit} 
                components={{
                    Footer: () => <div style={{padding : 5, marginRight: 20, float: 'right', fontSize: 14}}>{unidades}</div>,
                }}               
                />
        </Grid>
        </>
    )
}
export default TablaPresupuestoCotizacionProductos;