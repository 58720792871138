import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldText from '../../../../../components/Common/FormFieldText'
import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import BotonesMantenimiento from "./BotonesMantenimiento"
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from '@material-ui/core/styles';
import FormularioMantenimientoMateriales from "./FormularioMantenimientoMateriales";
import FormularioMantenimientoEmpleados from "./FormularioMantenimientoEmpleados";
import FormularioMantenimientoInicial from "./FormularioMantenimientoInicial";
import FormFieldComboArray from "../../../../../components/Common/FormFieldComboArray";
import { getDateToday } from "../../../../../tools/metodos";
import { useHistory } from "react-router";
import FormularioMantenimientoProveedor from "./FormularioMantenimientoProveedor";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },  
}));

const FormularioMantenimiento = ({index, tipoForm}) => { 
 
  const [mantenimiento, setMantenimiento] = useState([]) 
  const [campos, setCampos] = useState([]) 
  const { id, modo } = useParams();
  const [listadoMateriales, setListadoMateriales] = useState([])
  const [tipoFormulario, setTipoFormulario]=useState('empleado')
  const history = useHistory(); 
  const methods = useForm();

  const classes = useStyles();;
  const { control, handleSubmit} = useForm();  

  const [ficheros, setFicheros] = useState([])
  const [nombresFicheros, setNombresFicheros] = useState([])   

  const [openAlert, setOpenAlert] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);  
  const [fecha, setFecha] = useState(getDateToday())


  const handleListadoTabla=(valor) => {    
    setListadoMateriales([...listadoMateriales,valor])
  }

  const handleRemoveElement = (element) =>{
    setListadoMateriales(element) 
  }    
 
  const handleFicheros = (files, filenames) => {      
    setFicheros(files)
    setNombresFicheros(filenames)
  }

  useEffect(() => {      
      
    setCampos([
      //0
      {
        urlData: "dic/diccionarios/listadoresponsablesservicios",
        campoEtiqueta: "nombre",
        required: true,
        name: "encargado",
        disabled: modo === "R" ? true : false,
        type:'combo',
        label: "Responsable",
        value: '',
        multiple: false,
        xs: 4
      },      
      //1
      {
        urlData: "",
        required: true,
        name: "elemento",
        disabled: true,
        label: "Área trabajo",
        type: 'text',
        value: 'Maquinaria',
        multiple: false,
        xs: 4
      },
      //2
      {
        required: true,
        label: "Fecha",
        name: "fecha",
        disabled: modo === "R" ? true : false,
        type: "date",    
        value: fecha,
        xs: 4
      }, 
      //3
      //Cambiar por listado de maquinarias
      {
        urlData: "dic/diccionarios/listadomaquinariapropia",
        campoEtiqueta: "Nombre",
        required: true,
        name: "areatrabajo",
        disabled: modo === "R" ? true : false,
        label: "Elemento",
        type:'combo',
        value: '',
        multiple: false,
        xs: 4
      },
      //4
      {
        campoEtiqueta: "Etiqueta",
        required: true,
        label: "Tipología",
        name: "tipologia",
        disabled: modo === "R" ? true : false,
        type: "comboArray", 
        options:[{Id: 'Mantenimiento', Etiqueta: 'Mantenimiento'}, {Id: 'Preventivo', Etiqueta: 'Preventivo'}, {Id: 'Reparación', Etiqueta: 'Reparación'}, {Id: 'Repostaje', Etiqueta: 'Repostaje'}, {Id: 'Varios', Etiqueta: 'Varios'}], 
        multiple:false,   
        value: '',
        xs:4
      },     
      //5     
      {
        urlData: "dic/diccionarios/serviciosestados",
        campoEtiqueta: "Etiqueta",
        required: true,
        name: "estado",
        disabled: modo === "R" ? true : false,
        type:'combo',
        label: "Estado",
        value: '',
        multiple: false,
        xs: 4
      },
      //6
      {
        required: true,
        name: "observaciones",
        disabled: modo === "R" ? true : false,
        label: "Observaciones",
        value: '',
        type: 'textarea',
        lineas:3,
        xs: 12
      },
      //7
          {
            required: false,
            label: "Documentacion",
            name: "ficherosadjuntos",
            type: "filesupload",   
            tabla: "mtms_actividades_adjuntos",
            disabled: modo === "R" ? true : false,
            //xs:3
          },
          //8
          {
            required: false,
            label: "Documentacion",
            name: "ficherosremotos",
            type: "files", 
            ficheros: mantenimiento.ficheros,    
            tabla: "mtms_actividades_adjuntos",
            modelo: "api\\modules\\v1\\models\\mantenimientos\\MantenimientoAdjuntos",
            carpeta: "mantenimiento",
            disabled: modo === "R" ? true : false,
            //xs:3
          } 
    ])

    const fetchData = async () => {
      setIsLoading(true) 
      if (modo === "W" || modo === "R") {
          const respMantenimiento = await axios(
              `${process.env.REACT_APP_API_URL}mantenimientos/${id}`
          );                
          setMantenimiento(respMantenimiento.data)           
      }
      setIsLoading(false);
    };    

    fetchData();  

  },[]);
    
useEffect(() => {
  if (typeof mantenimiento !== "undefined") { 
    if(Object.keys(mantenimiento).length > 0){
      setIsLoading(true)  
      campos[0].value = mantenimiento.encargado;
      campos[1].value = mantenimiento.elemento;
      campos[2].value = mantenimiento.fecha;
      campos[3].value = mantenimiento.areatrabajo;
      campos[4].value = {Id: mantenimiento.tipologia, Etiqueta: mantenimiento.tipologia};
      campos[5].value = mantenimiento.estado;           
      campos[6].value = mantenimiento.observaciones;
      //campos[7].ficheros = mantenimiento.ficheros;
      campos[8].ficheros = mantenimiento.ficheros;
      setListadoMateriales(mantenimiento.listadotabla)
      setIsLoading(false)        
    }
  }
}, [mantenimiento])

const handleSubmitPost = async (datos) => {  

  let datosFormulario = {};
  datosFormulario.elemento = datos.elemento
  datosFormulario.fecha = datos.fecha
  datosFormulario.areasTrabajo = datos.areatrabajo.Id  
  datosFormulario.responsable = datos.encargado.id
  datosFormulario.estado = datos.estado.Id
  datosFormulario.observaciones = datos.observaciones
  datosFormulario.ficheros=ficheros
  datosFormulario.tipologia = datos.tipologia.Id
  datosFormulario.nombresFicheros=nombresFicheros
  datosFormulario.listadotabla=listadoMateriales  

  let user = JSON.parse(localStorage.getItem("user"));
  const AuthStr = "Bearer ".concat(user.access_token);
  const SERVER_URL = `${process.env.REACT_APP_API_URL}mantenimientos`

  
    if (modo==="W") {
      await axios
        .put(`${SERVER_URL}/${id}`, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datosFormulario,
        })
        .then((response) => {  
          if(!isNaN(response.data)){
            if(parseInt(response.data) === parseInt(id)){                       
                setOpenAlert(true)
                setTimeout(function () {
                  window.location.reload();   
              }, 2000);
                
            }
          }
          })
          .catch((error) => {
            console.log("error " + error);
            //console.log(error.response.data); // => the response payload 
            //console.log(error.response.data.message)
      });
    }
    else{
      await axios
        .post(SERVER_URL, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datosFormulario,
        })
        .then((response) => {       
          if(!isNaN(response.data)){
            if(parseInt(response.data)){                       
                setOpenAlert(true)
                setTimeout(function () {
                  history.push({
                  pathname: `/servicio/maquinarias/maquinaria/${response.data}/W`,
                  //action: action + Math.floor(Math.random() * (9999))
                });  
              }, 2000);
            }
          }
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
      });
    }
}
  
const handleTipoFormulario = (valor) => {
  setTipoFormulario(valor)
}

const handleCloseAlert = (event, reason) => {  
  if (reason === 'clickaway') {
    return;
  }  
  setOpenAlert(false);
};

return (
<>    
  {!isLoading && (
  <>
    {/* FORMULARIO */}
    <div className={classes.root}>
        <FormProvider {...methods}>
          <form
            id='formulario-maquinaria'
            onSubmit={handleSubmit((data) => {
              
            handleSubmitPost(data);
            })}
            className=""
          >

          <Grid className="borderFormulario" container spacing={1}> 
            <FormFieldCombo propiedades={campos[0]} control={control}/>
            <FormFieldText propiedades={campos[1]} control={control}/>
            <FormFieldDate propiedades={campos[2]} control={control} />
            <FormFieldCombo propiedades={campos[3]} control={control}/>
            <FormFieldComboArray propiedades={campos[4]} control={control}/>
            <FormFieldCombo propiedades={campos[5]} control={control}/>               
            <FormFieldTextArea propiedades={campos[6]} control={control}/>
            <FormFieldFiles propiedades={campos[8]} control={control} ficheros={handleFicheros}/>
            <FormFieldFileUpload propiedades={campos[7]} control={control} handleFicheros={handleFicheros}/>
            
          </Grid>

          <Grid className="borderFormulario" container spacing={1}>
              <BotonesMantenimiento tipoFormulario={tipoFormulario} changeTipoFormulario={handleTipoFormulario} maquinaria={false} />
          </Grid>

          {tipoFormulario === "empleado" && (      
            <Grid className="borderFormulario" container spacing={1}>
            <FormularioMantenimientoEmpleados listadotabla= {handleListadoTabla}/>   
            </Grid>               
          )}         
          {tipoFormulario === "materiales" && (
            <Grid className="borderFormulario" container spacing={1}>
            <FormularioMantenimientoMateriales listadotabla= {handleListadoTabla} />      
            </Grid>      
          )}

          {tipoFormulario === "proveedores" && (
            <Grid className="borderFormulario" container spacing={1}>
            <FormularioMantenimientoProveedor listadotabla= {handleListadoTabla} />      
            </Grid>      
          )}

          </form>
          </FormProvider>          
          <FormularioMantenimientoInicial listadoMateriales={listadoMateriales} ListadoMaterialesFinal={handleRemoveElement} />
          {openAlert && 
          <AlertaMensaje 
            mensaje={"¡Parte guardado correctamente!"} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
          }  
          
    </div>
    
  </>
  )}
    <div>
      {isLoading && 
        <div className={classes.linear}>
              <LinearProgress />
        </div>
      }
    </div>
        </>
    )
};

export default FormularioMantenimiento;