import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaUniforme = () => {
    let { id } = useParams();
    

    const [uniforme, setUniforme] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respUniforme = await axios(`${process.env.REACT_APP_API_URL}rrhhuniformes/${id}`);    
          setUniforme(respUniforme.data)  
        };
        fetchData();  
    }, [id]);

    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(uniforme).length > 0){  
            setCampos([
              {
                classsLabel: "",
                label: "Trabajdor:",
                value: uniforme.Trabajador,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Categoria:",
                value: uniforme.Fk_Categoria_Uniforme.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Elemento:",
                value: uniforme.Fk_Elemento_Uniforme.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Fecha:",
                value: uniforme.Fecha,
                classText: classText,
                xs:4
            },
            {
              classsLabel: "",
              label: "Activo:",
              value: uniforme.Activo === 1 ? "Activo" : "Inactivo",
              classText: classText,
              xs:4
          },
          {
            classsLabel: "",
            label: "Unidades:",
            value: uniforme.Cantidad,
            classText: classText,
            xs:4
        },
            
            {
                classsLabel: "",
                label: "Observaciones:",
                value: uniforme.Observaciones,
                classText: classText,
                xs:12
            },
            
            {                
              classsLabel: "",
              label: "Archivos adjuntos:",
              value: uniforme.ficheros,
              classText: classText,
              type: "file",
              xs:12
             },
        ])
    }
},[uniforme]) 


return(

    <>
      <h6 className="texto-comercial" id="title">          
      </h6>
      <FichaEntidad
        campos = {campos}
        urlClick = {`/rrhh/talento/uniforme/${id}/W`}
      /> 
    </>
   
  )
}
export default FichaUniforme;