import React, { useState, useEffect, useRef } from "react";
import axios from "axios"
import Grid from '@material-ui/core/Grid';
import { v4 as uuidv4 } from 'uuid';
import {useFacturaVenta} from './facturaventa-context'

import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import FormFieldSelectUncontrolled from '../../../../../components/Common/FormFieldSelectUncontrolled'
import FormFieldLineSeparator from '../../../../../components/Common/FormFieldLineSeparator'

let url="";
let etiqueta="";
let required = true
let disabled = true

const FormularioFacturaUnicaDatos = ({datosFactura, changeCliente, changeFicheros, control}) => {  
    const { setListadoProductosFacturaVenta, setOperacion } = useFacturaVenta();
    const [IdOrigen, setIdOrigen]=useState("Operativa");
    const opcionesOrigen = [{Id:'Operativa', Etiqueta: 'Operativa'}, {Id:'Salida', Etiqueta: 'Tique de salida'}, {Id:'Otros', Etiqueta: 'Otros'}]
    const hayCliente = useRef(false);
    const Cliente = useRef(Object.keys(datosFactura).length > 0 ? datosFactura.Cliente : {});

    const handleChangeCliente=(valor)=>{  
        hayCliente.current = valor === null ? false : true   
        Cliente.current = valor     
        changeCliente(valor)   
    }   
      
    const handleFicheros = (files, filenames) => {  
        changeFicheros(files, filenames)        
    }      

    const handleOperacion = (idO) =>{   
        campos[0].value = ''
        setIdOrigen(idO)  
    }
   
    if (IdOrigen==="Operativa") {
        url= hayCliente.current ? `cuentas/listadooperaciones/${Cliente.current.Id}` : "cuentas/listadooperaciones/0"
        etiqueta="Codigo"
        required = true
        disabled = false
    }

    if (IdOrigen==="Otros") {
        url="almacenamientos/tiqueentrada"
        etiqueta="Codigo_Entrada"
        required = false
        disabled = true
    }

    if (IdOrigen==="Salida") {
        url= hayCliente ? `almacenamientos/tiquesalida?idCliente=${Cliente.current.Id}` : "almacenamientos/tiquesalida";
        etiqueta="Codigo"
        required = true
        disabled = false
    }

    const handleChange=(valor) => {  
        if(valor){
            if(valor.Codigo.substring(0,3) === "COP"){
                const fetchData = async () => {              
                    const respListadoOperaciones = await axios(process.env.REACT_APP_API_URL + url + "/" + valor.Id) 
                    
                    if (respListadoOperaciones.data) {  
                        const operacion = respListadoOperaciones.data.find(element => element.Id === valor.Id)
                        
                        if(operacion){
                            
                            setOperacion(operacion)
                            
                            const listadoProductos = operacion.listadoProductos 
                            
                            if(listadoProductos)   { 
                                
                                await listadoProductos.forEach(async producto => { 
                                    await axios(process.env.REACT_APP_API_URL + "cuentas/gettarifaproducto/" + producto.idProducto + "/" + Cliente.current.Id) 
                                    .then(                             
                                        (response) => {     
                                            const datosProducto =  response.data
                                            
                                            let cantidad = parseFloat(producto.cantidad)
                                            let precioUnidad = parseFloat(datosProducto.Tarifa)
                                            
                                            let descuento = 0;
                                            if(Cliente.current.Descuento)
                                                descuento = parseFloat(Cliente.current.Descuento);
                                            
                                            let baseImponible = cantidad * precioUnidad - ((cantidad * precioUnidad) * (descuento/100))
                                            let total = cantidad * precioUnidad - ((cantidad * precioUnidad) * (descuento/100))
                                            
                                            let jornadaSplit = []
                                            if(producto.jornada)
                                                jornadaSplit = producto.jornada.split(" ")
                                            let jornada = "J1"
                                            if(jornadaSplit.length > 0)
                                                jornada = jornadaSplit[0];

                                            let extraCoste3 = datosProducto.extraCoste ? datosProducto.extracostesCliente[0]["Porcentaje"] : 0//J3
                                            let extraCoste8 = datosProducto.extraCoste ? datosProducto.extracostesCliente[1]["Porcentaje"] : 0//J8
                                            let extraCoste7 = datosProducto.extraCoste ? datosProducto.extracostesCliente[2]["Porcentaje"] : 0//J7
                                            let extraCoste4 = datosProducto.extraCoste ? datosProducto.extracostesCliente[3]["Porcentaje"] : 0//J4
                                            let extraCoste5 = datosProducto.extraCoste ? datosProducto.extracostesCliente[4]["Porcentaje"] : 0//J5
                                            let extraCoste6 = datosProducto.extraCoste ? datosProducto.extracostesCliente[5]["Porcentaje"] : 0//J6
                                            let extraCoste9 = datosProducto.extraCoste ? datosProducto.extracostesCliente[6]["Porcentaje"] : 0//J9
                                            let extraCoste10 = datosProducto.extraCoste ? datosProducto.extracostesCliente[7]["Porcentaje"] : 0//J10

                                            let extraCostes =  {
                                                'J3': extraCoste3,
                                                'J4': extraCoste4,
                                                'J5': extraCoste5,
                                                'J6': extraCoste6,
                                                'J7': extraCoste7,
                                                'J8': extraCoste8,
                                                'J9': extraCoste9,
                                                'J10': extraCoste10}
                                             
                                            let extraCoste = parseFloat(extraCostes[jornada])
                                            let resultadoExtraCoste = extraCoste * cantidad * precioUnidad / 100
                                            resultadoExtraCoste = isNaN(resultadoExtraCoste) ? 0 : resultadoExtraCoste.toFixed(2)
                
                                            let nuevoProducto = {
                                                id:uuidv4(), 
                                                idOperativa: producto.idOperativa,
                                                operativa: producto.operativa,
                                                idCategoria: datosProducto.Fk_Categoria,
                                                categoria: producto.categoria,
                                                idSubcategoria: datosProducto.Fk_Subcategoria,
                                                subcategoria: producto.subcategoria,
        
                                                idProducto: producto.idProducto,
                                                codigo: datosProducto.Codigo,
                                                descripcion: producto.producto,
                                                producto: datosProducto.Nombre,
        
                                                idIgic: null,
                                                Igic: 0,
        
                                                idIrpf: null,
                                                Irpf: 0,
        
                                                cantidad: cantidad,
                                                precioUnidad: precioUnidad, //Se debe obtener la del cliente
                                                baseImponible: baseImponible,
                                                descuento: descuento,
                                                precioTotal: total,

                                                jornada: producto.jornada,
                                                importeExtraCoste: resultadoExtraCoste,
                                                operador : producto.operador,

                                                extraCoste3: datosProducto.extraCoste ? datosProducto.extracostesCliente[0]["Porcentaje"] : 0,//J3
                                                extraCoste8: datosProducto.extraCoste ? datosProducto.extracostesCliente[1]["Porcentaje"] : 0,//J8
                                                extraCoste7: datosProducto.extraCoste ? datosProducto.extracostesCliente[2]["Porcentaje"] : 0,//J7
                                                extraCoste4: datosProducto.extraCoste ? datosProducto.extracostesCliente[3]["Porcentaje"] : 0,//J4
                                                extraCoste5: datosProducto.extraCoste ? datosProducto.extracostesCliente[4]["Porcentaje"] : 0,//J5
                                                extraCoste6: datosProducto.extraCoste ? datosProducto.extracostesCliente[5]["Porcentaje"] : 0,//J6
                                                extraCoste9: datosProducto.extraCoste ? datosProducto.extracostesCliente[6]["Porcentaje"] : 0,//J9
                                                extraCoste10: datosProducto.extraCoste ? datosProducto.extracostesCliente[7]["Porcentaje"] : 0,//J10
        
                                            } 
                                            
                                            setListadoProductosFacturaVenta((prevState) => (                                        
                                                [...prevState, nuevoProducto]
                                            )); 
                                                                              
                                        },
                                        (error) => { console.log(error) }
                                    );  
                                })
                                /*
                                setListadoProductosFacturaVenta((prevState) => (                                        
                                    [...prevState, productosArray]
                                ));  
                                */                
                            }  
                        }             
                    }
                };          
                fetchData(); 
            }

            if(valor.Codigo.substring(0,3) === "TSA"){
                
            } 

        } else {
            setListadoProductosFacturaVenta([])
            setOperacion([])
        }
    }

    useEffect(() => {
        
        if(datosFactura.OrigenCO){
            setIdOrigen("Operativa")
            handleChange(datosFactura.OrigenCO)
            hayCliente.current = datosFactura.Cliente === null ? false : true   
            Cliente.current = datosFactura.Cliente     
            changeCliente(datosFactura.Cliente) 
           
        }
        if(datosFactura.OrigenTS){
            setIdOrigen("Salida")
            hayCliente.current = datosFactura.Cliente === null ? false : true   
            Cliente.current = datosFactura.Cliente     
            changeCliente(datosFactura.Cliente) 
           // handleChange(datosFactura.OrigenTS)
        }
        if(!datosFactura.OrigenCO && !datosFactura.OrigenTS){
            setIdOrigen("Otros")
        }


        if(datosFactura.Cliente){
            //hayCliente.current = datosFactura.Cliente === null ? false : true   
            //Cliente.current = datosFactura.Cliente     
            //changeCliente(datosFactura.Cliente) 
        }
        
        return () => {
            
        }
    }, [datosFactura])

    useEffect(() => {
        return () => {
            
        }
    }, [IdOrigen])

    const valoresFactura = Object.keys(datosFactura).length > 0 || (Cliente.current && Object.keys(Cliente.current).length > 0)        
    hayCliente.current = valoresFactura ? true : false    
    const urlEstadoFactura = Object.keys(datosFactura).length > 0 && datosFactura?.Estado?.Id ? "dic/diccionarios/facturaventasestados" : "dic/diccionarios/facturaventasunicasestados"
    
    let campos = [
        // 0 - ORIGEN
        {
            urlData: url,
            campoEtiqueta: etiqueta,
            required: required,
            name: "Origen",
            disabled: !hayCliente.current || disabled,
            label: "Origen",
            type:'combo',
            value: '',
            multiple: false,
            xs: 3,
            onChangeValue: handleChange
        }, 
        // 1  - FECHA FACTURA   
        {
            required: true,
            label: "Fecha Factura",
            name: "fechaFactura",
            type: "date",    
            disabled: !hayCliente.current,
            value: valoresFactura ? datosFactura.Fecha_Factura : "",
            xs: 3
        },  
        // 2 - CLIENTE       
        {
              urlData: "cuentas/clientes",
              campoEtiqueta: "Nombre_Comercial",
              required: true,
              name: "Cliente",
              disabled: false,
              label: "Cliente",
              type:'combo',
              value: valoresFactura ? datosFactura.Cliente : "",
              multiple: false,
              xs: 6,
              onChangeValue: handleChangeCliente
        }, 
        // 3  - ESTADO    
        {
            urlData: urlEstadoFactura,
            campoEtiqueta: "Etiqueta",
            required: true,
            name: "Estado",
            disabled: !hayCliente.current,
            type:'combo',
            label: "Estado",
            value: Object.keys(datosFactura).length > 0 ? datosFactura.Estado : "",
            multiple: false,
            xs: 3
        },
        // 4 - OBSERVACIONES
        {
            required: false,
            name: "observaciones",
            disabled: !hayCliente.current,
            label: "Observaciones (uso interno)",
            value: valoresFactura ? datosFactura.Observaciones : "",
            type: 'textarea',
            lineas:2,
            xs: 6
        },  
        // 5 - FICHEROS ADJUNTOS     
        {
            required: false,
            label: "Documentacion",
            name: "ficherosadjuntos",
            type: "filesupload",   
            tabla: "mtms_hoja_pedido_adjuntos",
            ficheros: valoresFactura ? datosFactura.ficheros : "",
            xs:6
        },
        // 6 - FICHEROS
        {
            required: false,
            label: "Documentacion",
            name: "ficheros",
            type: "files", 
            ficheros: valoresFactura ? datosFactura.ficheros : "",   
            tabla: "mtms_hoja_pedido_adjuntos",
            modelo: "api\\modules\\v1\\models\\facturaventa\\FacturaUnicaAdjuntos",
            carpeta: "facturas_unicas",
            xs:12
        },  
        // 7 - TIPO ORIGEN    
        {
          required: true,
          name: "tipoOrigen",
          disabled: !hayCliente.current,
          label: "Tipo de origen",
          value: "",
          multiple: false,
          xs: 3,
          classes: '',
          opciones: opcionesOrigen,
          type:"select",
          onChangeValue: handleOperacion
        },
        // 8 - TEXTO FACTURA
        {
            required: false,
            name: "textoFactura",
            disabled: !hayCliente.current,
            label: "Texto para factura (sustituye a las líneas de producto)",
            value: valoresFactura ? datosFactura.textoFactura : "",
            type: 'textarea',
            lineas:2,
            xs: 6
        },
        // 9 - TEXTO FACTURA
        {
            required: false,
            name: "Notas",
            disabled: !hayCliente.current,
            label: "Notas para factura (NO sustituye a las líneas de producto)",
            value: valoresFactura ? datosFactura.Notas : "",
            type: 'textarea',
            lineas:2,
            xs: 12
        },
    ]
    
    const numCampos = Object.keys(campos).length 
    if(numCampos > 1){
        campos[0].urlData = url;
        campos[0].campoEtiqueta = etiqueta;
        if(datosFactura.OrigenCO)
            campos[0].value = datosFactura.OrigenCO;
        if(datosFactura.OrigenTE)
            campos[0].value = datosFactura.OrigenTE;
        if(datosFactura.OrigenTS)
            campos[0].value = datosFactura.OrigenTS;
        campos[7].value = IdOrigen
    }

    return (
    <>
    { numCampos > 0 && (
        <Grid className="borderFormulario" container spacing={1}>    
            <FormFieldCombo propiedades={campos[2]} control={control}/>
            <FormFieldDate propiedades={campos[1]} control={control}/>
            <FormFieldCombo propiedades={campos[3]} control={control}/> 
            
            <FormFieldSelectUncontrolled propiedades={campos[7]} onChangeValue={handleOperacion}/>                         
            <FormFieldCombo propiedades={campos[0]} control={control}/>              
              
            <FormFieldLineSeparator propiedades={{xs:6}}/>
            
            <FormFieldTextArea propiedades={campos[4]} control={control}/>
            <FormFieldTextArea propiedades={campos[8]} control={control}/>
            <FormFieldTextArea propiedades={campos[9]} control={control}/>

            <FormFieldFiles propiedades={campos[6]} control={control} ficheros={handleFicheros}/>
            <FormFieldFileUpload propiedades={campos[5]} control={control} handleFicheros={handleFicheros}/>              
          </Grid>  
    )}
    </>
    )
}

export default FormularioFacturaUnicaDatos;