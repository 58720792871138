import React, { useState, useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";

import axios from "axios";

import LinearProgress from "@material-ui/core/LinearProgress";

import { StateContext } from '../../../../../context'
import FormularioOperacionCPE from './FormularioOperacionCPE'
import FormularioOperacionGesport from './FormularioOperacionGesport'
import FormularioOperacionProveedor from './FormularioOperacionProveedor'
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import { useStyles } from "../../../../../styles/styles";

const FormularioOperacionMano = ({index, tipoForm}) => { 
  const methods = useForm();  
  const classes = useStyles();
  let { id } = useParams();
  const { control, handleSubmit} = useForm();   
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch } = useContext(StateContext)
  const { operacion } = state 

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }  
    setOpenAlert(false);
  };    


const handleSubmitPost = async (datos) => {
  //setGrabandoFormulario(true);  
  let datosFormulario = {};
  datosFormulario.clientes = datos.clientes
  datosFormulario.buque = datos.buques ? datos.buques.Id : null
  datosFormulario.muelle = datos.muelles ? datos.muelles.Id : null
  datosFormulario.operativas = datos.operativas
  datosFormulario.fechasoperativa = datos.fechasoperativa
  datosFormulario.manoplanificada = datos.manoplanificada
  switch(tipoForm){
    case "cpe":
      datosFormulario.jornada = datos.jornadas.Id      
      datosFormulario.pedidoCPE = operacion.pedidoCPE
      datosFormulario.listadoMercancias = operacion.listadoMercancias
      datosFormulario.listadoPersonal = operacion.listadoPersonal
      break;
    case "gesport":     
    
      datosFormulario.numparte = operacion.pedidoGesport ? operacion.pedidoGesport.numParte : null
      datosFormulario.fechaparte = operacion.pedidoGesport ? operacion.pedidoGesport.fechaParte : null
      datosFormulario.listadoGesport = operacion.listadoGesport
      break;
    case "proveedor":        
      datosFormulario.listadoProveedor = operacion.listadoProveedor
      break;
    default:
  }
  let user = JSON.parse(localStorage.getItem("user"));
  const AuthStr = "Bearer ".concat(user.access_token);
  const SERVER_URL = `${process.env.REACT_APP_API_URL}operaciones/${id}`

  await axios
    .put(SERVER_URL, {
      headers: {        
        "Content-Type": "application/json",
        Authorization: AuthStr,
      },
      datosFormulario,
    })
    .then((response) => {       
      if(!isNaN(response.data.Id)){
        if(parseInt(response.data.Id) === parseInt(id)){      
          operacion.actualizarDetalle = true                 
            dispatch({type: "UPDATE_OPERACION", 
              payload: tipoForm,
            })
            setOpenAlert(true)
        }
      }
      })
      .catch((error) => {
        console.log("error " + error);
  });
}

return (
<>    
  {!isLoading && (
  <>
    {/* FORMULARIO */}
    <div className={classes.root}>
        <FormProvider {...methods}>
          <form
            id='formulario-operacion-mano'
            onSubmit={handleSubmit((data) => {
              handleSubmitPost(data);
            })}
            className=""
          >
          
          {tipoForm === "cpe" && (            
            <FormularioOperacionCPE control={control} />            
          )}
          

          {tipoForm === "gesport" && (
            <FormularioOperacionGesport />            
          )}
          {tipoForm === "proveedor" && (            
            <FormularioOperacionProveedor/>
          )}
          </form>
          </FormProvider>

          {openAlert && 
          <AlertaMensaje 
            mensaje={"¡Parte guardado correctamente!"} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
          }  
    </div>  
  </>
  )}
    <div>
      {isLoading && 
        <div className={classes.linear}>
              <LinearProgress />
        </div>
      }
    </div>
</>
);    
};

export default FormularioOperacionMano;