import React, { useState, useEffect } from "react";
import axios from "axios";

import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import { useHistory } from "react-router";
import { useLocation } from 'react-router-dom';

import { logout } from "../../../actions/auth";

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  
}));

const ResetPassword = ({reducerApi, logout}) => {
    const { user } = reducerApi;
    const location = useLocation();
    const history = useHistory(); 
    const [grabandoFormulario, setGrabandoFormulario] = useState(false);
    const [mensajeError, setMensajeError] = useState("");
    const [values, setValues] = useState({
        password: '',
        password2: '',
        showPassword: false,
    });
    const [token, setToken] = useState(null)

    const { control, handleSubmit } = useForm();
    const classes = useStyles();

    useEffect(() => {
        setToken(location.search.substr(7))
    }, [location]);

    const handleSubmitPost = async (datos) => {

        
        if(values.password !== values.password2){
          alert("Las contraseñas no coinciden");
          return;
        }
        
        datos.password=values.password
        datos.token = token
         
        setGrabandoFormulario(true); 
        
          
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}rrhhdatospersonales/resetpasswordfromtoken`;
          
        await axios
        .post(SERVER_URL, {
            headers: {
                "Content-Type": "application/json",
                Authorization: AuthStr,
            },
            datos,
        })
        .then((response) => {   
            if(response.data === true){
                alert("Contraseña modificada correctamente");
                setTimeout(() => {
                    history.push({
                        //pathname: `${urlDoubleClick}${item[0]}/R`,   
                        pathname: '/login' ,    
                    }); 
                }, 1000);
            }
        })
        .catch((error) => {
            console.log("error " + error);
            console.log(error?.response?.data); // => the response payload 
            console.log(error?.response?.data?.message)
            console.log({'data': '', 'error': error.message, 'loaded': true})
            alert(error?.response?.data?.message)
        });
            
        setGrabandoFormulario(false);
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    return(
        <>  
        <div className="container ">
            <div className="row align-items-center max-vh-height">
                <div className="col-6 offset-3 mr-auto bg-white p-4 rounded">
                    <span className="font-weight-bolder">Reestablecer contraseña</span>
                    <hr></hr>
                    <div className="">
                        <form
                        onSubmit={handleSubmit((datos) => {
                            handleSubmitPost(datos);
                        })}
                        className="row"
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={8}  >
                                    <FormControl className={clsx(classes.margin, classes.textField)}>
                                        <InputLabel htmlFor="standard-adornment-password">Contraseña nueva</InputLabel>
                                        <Input
                                            id="standard-adornment-password"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={handleChange('password')}
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                >
                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={8}  >
                                    <FormControl className={clsx(classes.margin, classes.textField)}>
                                        <InputLabel htmlFor="standard-adornment-password2">Repetir contraseña nueva</InputLabel>
                                        <Input
                                            id="standard-adornment-password2"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.password2}
                                            onChange={handleChange('password2')}
                                            helperText={mensajeError}
                                            error = {mensajeError.length > 0}
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                >
                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                
                                {/* BOTON */}
                                <div className="col-12 pt-4">
                                    {!grabandoFormulario && (
                                    <>
                                        <button className="float-right MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation">
                                        MODIFICAR CONTRASEÑA
                                        </button>                      
                                    </>
                                    )}
                                </div>
                            </Grid>
                        </form>
                        {grabandoFormulario && (
                            <div className={(classes.linear, "mt-4")}>
                                <LinearProgress />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
        </>
        )
}

export default connect(({ reducerApi }) => ({ reducerApi }), {
    logout,
})(ResetPassword);