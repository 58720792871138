import React, { useState } from "react";
import axios from "axios";

import Chip from '@material-ui/core/Chip';
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
//import Grid from '@material-ui/core/Grid';
import AlertaMensaje from "./AlertaMensaje";
import { useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FormFieldFiles = ({ propiedades }) => { 
     
    const [open, setOpen] = React.useState(false);
    const [ficheroAEliminar, setFicheroAEliminar] = React.useState({});
    const { modo } = useParams();
    const [openAlert,setOpenAlert] = useState({
        open : false,
        tipoMensaje : "success",
        mensaje : ""
      });  

    const deshabilitado = (
        typeof(propiedades.disabled) === "undefined" ? 
        modo==="R" ?  true : false : propiedades.disabled
    )

    const handleDelete = (idFichero) => {  
      if(!deshabilitado){
        setFicheroAEliminar({
            idFichero: idFichero.Id,
            tabla: propiedades.tabla,
            modelo: propiedades.modelo,
            carpeta: propiedades.carpeta,
        })        
        setOpen(true);
      } else {
        setOpenAlert({
          open : true,
          tipoMensaje : "error",
          mensaje : "Debe entrar en modo edición para poder eliminar el fichero"
        })
      }
    }    

    const handleCloseAlert = (event, reason)=>{
        if(reason === "clickaway"){
          return
        }
        setOpenAlert({...openAlert,open:false});
      }

    const handleCloseEliminar = () => {      
        const fetchData = async () => {     
            let datos = {}
            const user = JSON.parse(localStorage.getItem("user"));
            const AuthStr = "Bearer ".concat(user.access_token);
            const SERVER_URL = `${process.env.REACT_APP_API_URL}dic/diccionarios/eliminaficheroadjunto`;
            
            datos.idFichero = ficheroAEliminar.idFichero
            datos.carpeta = ficheroAEliminar.carpeta
            datos.modelo = ficheroAEliminar.modelo
            datos.username=user.username
            datos.userid=user.id
          
          axios
          .post(SERVER_URL, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {
            
            if(!isNaN(response.data)){
                
              setOpenAlert({
                open : true,
                tipoMensaje : "success",
                mensaje : "Fichero eliminado correctamente"
              })
              
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
              
            }else{
                
              setOpenAlert({
                open : true,
                tipoMensaje : "error",
                mensaje : "Error al eliminar el fichero"
              })
              
            }
            return;
            
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          });
        }
        fetchData();
        setOpen(false);
      };
  
    const handleCloseCancelar = () => {      
        setOpen(false);
    };

    const handleClick = (fichero) => {
        window.open(fichero.Ubicacion, "_blank")
    };

  return (  
    <>      
    <div className="col-12  mt-2 mb-2  ">     
    {/*<Grid item xs={propiedades.xs} className="" >*/}
    { 
        propiedades.ficheros && 
        propiedades.ficheros.map((option, index) => ( 
        <MenuItem key={index} value={option.Id} className="no-gutters float-left">
            <div className="col mt-1">                 
                <Chip 
                    key={index}
                    className="m-1"
                    variant="outlined" 
                    color="primary" 
                    size="small" 
                    onDelete={() => handleDelete(option)} 
                    onClick={() => handleClick(option)}
                    label={option.Nombre}
                />
            </div>
        </MenuItem>  
    ))}         
    {/*</Grid> */} 
    </div>   
    <div className="col-12  mt-2 mb-2  ">
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleCloseCancelar()}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"¿Desea eliminar el fichero?"}</DialogTitle>
                        
            <DialogActions>
                <Button onClick={handleCloseEliminar} color="primary">
                    Eliminar
                </Button>
                <Button onClick={handleCloseCancelar} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    </div> 
    {
        openAlert.open && (
        <AlertaMensaje 
            mensaje={openAlert.mensaje} 
            isOpen={openAlert.open} 
            tipoMensaje={openAlert.tipoMensaje}
            cerrar={handleCloseAlert}
        />
                  
              )
            }
    </>           
    )
}
export default FormFieldFiles;