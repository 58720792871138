import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const FormularioCajas = ({ index, tipoForm }) => {

  const [CajasBancos, setCajasBancos] = useState([])
  const [campos, setCampos] = useState([])
  const { id, modo } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    setCampos([
      {
        required: true,
        name: "Entidad",
        disabled: false,
        label: "Entidad",
        type: 'text',
        value: '',
        xs: 6,
      },
      {
        required: true,
        name: "Direccion",
        disabled: false,
        label: "Direccion",
        type: 'text',
        value: '',
        xs: 6,
      },
      {
        required: true,
        name: "Cuenta",
        disabled: false,
        label: "Nº Cuenta",
        type: 'text',
        value: '',
        xs: 6,
      },
      {
        required: true,
        name: "Iban",
        disabled: false,
        label: "IBAN",
        type: 'text',
        value: '',
        xs: 6,
      },


    ])

    const fetchData = async () => {
      if (modo === "W" || modo === "R") {
        setIsLoading(true)
        const resCajasBancos = await axios(
          `${process.env.REACT_APP_API_URL}tesoreriacajas/${id}`
        );
        setCajasBancos(resCajasBancos.data)
        setIsLoading(false);
      }
    };
    fetchData();


  }, []);

  useEffect(() => {
    if (typeof CajasBancos !== "undefined") {
      if (Object.keys(CajasBancos).length > 0) {
        setIsLoading(true)
        campos[0].value = CajasBancos.Entidad;
        campos[1].value = CajasBancos.Direccion;
        campos[2].value = CajasBancos.Cuenta;
        campos[3].value = CajasBancos.IBAN;
        if (modo === "R") {
          campos[0].disabled = true;
          campos[1].disabled = true;
          campos[2].disabled = true;
          campos[3].disabled = true;
        }
        setIsLoading(false)
      }
    }
  }, [CajasBancos])
  return (
    <>
      {!isLoading && (
        <>
          <FormularioEntidad
            urlForm="tesoreriacajas"
            urlGoto="/finanzas/tesoreria/cajasbancos/"
            campos={campos}
            type={modo === 'R' ? "read" : modo === 'W' ? "edit" : "add"}
          />
        </>
      )}
    </>
  )
};

export default FormularioCajas;