import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioPersonalCPE = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [seleccion, setseleccion] = useState([]) 

    useEffect(() => { 
        
        setCampos([  
            //0 Censo
            {
                required: false,
                label: "Censo",
                name: "censo",
                type: "text",    
                disabled: modo === "R" ? true : false, 
                value: "",                
                xs:3
            },   
            //1 Antigüedad  
            {
                required: true,
                label: "Antigüedad",
                name: "antiguedad",
                type: "date",    
                disabled: modo === "R" ? true : false,
                value: "",                
                xs:3
            },
            //2 Situación
            {   
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Situación:",
                name: "situacion",
                disabled: modo === "R" ? true : false,
                type: "comboArray",
                options:[{Id: 0, Etiqueta: 'No adscrito'}, {Id: 1, Etiqueta: 'Adscrito'},{Id: 2, Etiqueta: 'Eventual'},], 
                multiple:false,   
                value: {Id: 0, Etiqueta: 'No adscrito'},
                xs:3
            }, 
            //3 Activo
            {   
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Activo:",
                name: "activo",
                disabled: modo === "R" ? true : false,
                type: "comboArray",
                options:[{Id: 0, Etiqueta: 'Inactivo'}, {Id: 1, Etiqueta: 'Activo'},], 
                multiple:false,   
                value: {Id: 1, Etiqueta: 'Activo'},
                xs:3
            }, 
            //4 Nombre
            {
                required: false,
                label: "Nombre",
                name: "nombre",
                type: "text",    
                disabled: modo === "R" ? true : false,
                value: "",                
                xs:3
            }, 
            //5 Apellido 1
            {
                required: false,
                label: "Apellido 1",
                name: "apellido1",
                type: "text",    
                disabled: modo === "R" ? true : false,
                value: "",                
                xs:3
            }, 
            //6 Apellido 2
            {
                required: false,
                label: "Apellido 2",
                name: "apellido2",
                type: "text",    
                disabled: modo === "R" ? true : false,
                value: "",                
                xs:3
            }, 
            //7 Categoría
            {
                urlData: "dic/diccionarios/categoriascpe",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Categoría:",
                name: "categoria",
                disabled: false,
                type: "combo",
                value: "",
                multiple:false,                 
                xs:6
            },
            //8 Especialidades
            {
                urlData: "dic/diccionarios/especialidadescpe",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Especialidades:",
                name: "especialidad",
                disabled: false,
                type: "combo",
                value: [],   
                multiple:true,             
                xs:6
            },
        ])

        const fetchData = async () => { 
            if (modo === "W") {
                const respseleccion = await axios(
                    `${process.env.REACT_APP_API_URL}censocpes/${id}`
                );                
                setseleccion(respseleccion.data)  
            }
            setDatosCargados(true);
    
        };    
        fetchData(); 

    },[]);

    useEffect(() => {            
    if (typeof seleccion !== "undefined") {        
      if(Object.keys(seleccion).length > 0){ 
        setDatosCargados(false)
        campos[0].value = seleccion.Censo;
        campos[1].value = seleccion.Fecha;
        campos[2].value = seleccion.Situacion;
        campos[3].value = seleccion.Activo ? {Id: 1, Etiqueta: "Activo"} : {Id: 0, Etiqueta: "Inactivo"} ;
        campos[4].value = seleccion.Nombre;
        campos[5].value = seleccion.Apellido1;            
        campos[6].value = seleccion.Apellido2;
        campos[7].value = seleccion.Categoria;
        campos[8].value = seleccion.Especialidades;
        setDatosCargados(true)  
      }
  }
    },[seleccion])

    if (datoscargados){
        return ( 
            <FormularioEntidad 
            urlForm = "censocpes"
            urlGoto = "/rrhh/gestioncpe/personalcpe/"
            campos={campos}
            />        
        )
    }
    else {
        return (              
            <div className={classes.linear}>
                <CircularProgress />
            </div>
        );
    }

}


export default FormularioPersonalCPE;