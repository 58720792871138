import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    float: "right",
  },
}));

const CrearContactoForm = ({ idContacto, onSubmitFormContacto }) => {
  const classes = useStyles();
  const { control, handleSubmit, setValue } = useForm();
  const [grabandoFormulario] = React.useState(false);
  const [pais, setPais] = React.useState("");
  const [paises, setPaises] = React.useState("");
  const [fichero, setFichero] = React.useState("");
  const [datoscargados, setDatosCargados] = useState(false);

  useEffect(() => {
    if (!datoscargados) {
      const fetchData = async () => {
        const respPaises = await axios(process.env.REACT_APP_API_URL + "countries");

        setPaises(respPaises.data);
        if(idContacto > 0){

          const respContacto = await axios(
            `${process.env.REACT_APP_API_URL}contactos/${idContacto}`
          );

          if (idContacto > 0) {
            setValue("foto", respContacto.data.Foto);
            setValue("nombre", respContacto.data.Nombre);
            setValue("apellidos", respContacto.data.Apellidos);
            setValue("departamento", respContacto.data.Departamento);
            setValue("cargo", respContacto.data.Cargo);
            setValue("movil", respContacto.data.Movil);
            setValue("telefono", respContacto.data.Telefono);
            setValue("email", respContacto.data.Email);
            setValue("linkedin", respContacto.data.Linkedin);
            setValue("fechanacimiento", respContacto.data.FechaNacimiento);

            setFichero(respContacto.data.Foto);

            if (respContacto.data.direccion.length > 0) {
              setValue("calle", respContacto.data.direccion["Calle"]);
              setValue("numero", respContacto.data.direccion["Numero"]);
              setValue("piso", respContacto.data.direccion["Piso"]);
              setValue("letra", respContacto.data.direccion["Letra"]);
              setValue(
                "codigopostal",
                respContacto.data.direccion["CodigoPosta"]
              );
              setValue("ciudad", respContacto.data.direccion["Ciudad"]);
              setValue("provincia", respContacto.data.direccion["Provincia"]);
              setPais(respContacto.data.direccion["Fk_Pais"]);
            }
          }
        }


        setDatosCargados(true);
      };
      fetchData();
    }
  }, [datoscargados, idContacto, setValue]);

  const handleChange = (event) => {    
    switch (event.target.name) {
      case "Fk_Pais":
        setPais(event.target.value);
        break;

      default:
        break;
    }
  };

  const handleCapture = ({ target }) => {   
    const fileReader = new FileReader();
    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      setFichero(e.target.result);
    };
  };

  const handleSubmitPost = async (datos) => {
    let user = JSON.parse(localStorage.getItem("user"));

    const ficheroPorDefecto = fichero.search("no-profile-pic.jpg");
    datos["fichero"] = "";
    if (ficheroPorDefecto > 0) {
      datos["fichero"] = fichero;
    }
    

    const AuthStr = "Bearer ".concat(user.access_token);
    const SERVER_URL = process.env.REACT_APP_API_URL + "contactos";

    if (idContacto > 0) {
      await axios
        .put(`SERVER_URL/${idContacto}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {
                 
        })
        .catch((error) => {
          console.log("error " + error);
        });
    } else {
      await axios
        .post(SERVER_URL, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {
                 
        })
        .catch((error) => {
          console.log("error " + error);
        });
    }
  };

  if (datoscargados) {
    return (
      <>
        <h6 className="texto-comercial mt-3" id="title">
          Contacto
        </h6>
        <div className="rounded border border-formulario p1-form">
          <div className="p-2 bg-white mb-2  m-0">
            <div className="p-1 bg-white mb-2 p3-form m-0">
              <form
                onSubmit={handleSubmit((datos) => {
                  handleSubmitPost(datos);
                })}
                className="row"
              >
                <div className="col-12 pt-4">
                  <Avatar className={classes.large} alt="NF" src={fichero} />

                  <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file"
                    onChange={handleCapture}
                    type="file"
                    name="foto"
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      Foto
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </div>

                {/* Identificación */}
                <div className="col-6 mt-3 mb-2  ">
                  <InputLabel className="">Identificación</InputLabel>
                  <hr className="m-0 bg-comercial" />
                </div>
                {/* Dirección */}
                <div className="col-6 mt-3 mb-2  ">
                  <InputLabel className="">Dirección</InputLabel>
                  <hr className="m-0 bg-comercial" />
                </div>
                {/* Nombre */}
                <div className="col-6 mt-2 mb-3">
                  <Controller
                    as={TextField}
                    control={control}
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* apellidos */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="apellidos"
                    name="apellidos"
                    label="Apellidos"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* departamento */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="departamento"
                    name="departamento"
                    label="Departamento"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* cargo */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="cargo"
                    name="cargo"
                    label="Cargo"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* movil */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="movil"
                    name="movil"
                    label="Movil"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* telefono */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="telefono"
                    name="telefono"
                    label="Teléfono"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* email */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* linkedin */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="linkedin"
                    name="linkedin"
                    label="Linkedin"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* fechanacimiento */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="fechanacimiento"
                    name="fechanacimiento"
                    type="date"
                    label="Fecha de Nacimiento"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <div className="col-6 mt-2 mb-3">
                  {/* Calle */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="calle"
                    name="calle"
                    label="Calle"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* numero */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="numero"
                    name="numero"
                    label="Número"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* piso */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="piso"
                    name="piso"
                    label="Piso"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* letra */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="letra"
                    name="letra"
                    label="Letra"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* codigo postal */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="codigopostal"
                    name="codigopostal"
                    label="Código Postal"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* ciudad */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="ciudad"
                    name="ciudad"
                    label="Ciudad"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />
                  {/* provincia */}
                  <Controller
                    as={TextField}
                    control={control}
                    id="provincia"
                    name="provincia"
                    label="Provincia"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue=""
                    className="mt-1 mb-1"
                  />

                  {/* País */}
                  <TextField
                    id="Fk_Pais"
                    name="Fk_Pais"
                    select
                    label="País"
                    value={pais}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                  >
                    {paises.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.etiqueta}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                {/* BOTON */}
                <div className="col-12 pt-4">
                  {!grabandoFormulario && (
                    <>
                      <button className="float-right MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation">
                        GUARDAR
                      </button>

                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          onSubmitFormContacto(idContacto, "R");
                        }}
                        className="float-right MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation mr-2"
                      >
                        CANCELAR
                      </button>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={classes.linear}>
          <LinearProgress />
        </div>
      </>
    );
  }
};

export default CrearContactoForm;
