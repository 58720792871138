import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";

const FormFieldDate = ({ propiedades, control }) => { 
    const { modo } = useParams(); 
    const [selectedDate, setSelectedDate] = useState(propiedades.value || '');
    
    useEffect(() => {   
        setSelectedDate(propiedades.value)
    }, [propiedades.value])

    const handleChange = (v) => {  
        if(propiedades.onChangeValue)
          propiedades.onChangeValue(v)     
          
          setSelectedDate(v)
    }  

    const deshabilitado = (
        typeof(propiedades.disabled) === "undefined" ? 
        modo==="R" ?  true : false : propiedades.disabled
    )
    return (
        <Grid item xs={propiedades.xs} className={propiedades.classes} >
        <Controller    
        control={control}
        name={propiedades.name}
        defaultValue={selectedDate}
        render={({onChange, ...props}) => (            
            <TextField
            required={propiedades.required}
            id={propiedades.name}
            name={propiedades.name}
            disabled={deshabilitado} //{modo==="R"?true:false}
            type="date"
            label={propiedades.label}
            variant="outlined"
            size="small"
            fullWidth
            className=""
            InputLabelProps={{
                shrink: true,
            }}
            onChange={(e, data) => {
              handleChange(e.target.value)              
              onChange(e.target.value)
              }}
            {...props}

            
            
            />
        )}
        />

        </Grid>
    )
    return (
    <>
    {/* Campo Fecha */}
    <Grid item xs={propiedades.xs} className={propiedades.classes} >
        <Controller                        
            as={TextField}
            required={propiedades.required}
            control={control}
            id={propiedades.name}
            name={propiedades.name}
            disabled={deshabilitado} //{modo==="R"?true:false}
            type="date"
            label={propiedades.label}
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={propiedades.value}
            onChange={e => {
                console(e.target.value);
            }}
            className=""
            InputLabelProps={{
                shrink: true,
            }}
        />
    </Grid>
    </>
    )
}

export default FormFieldDate;
