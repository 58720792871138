import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";
import PasosOperacion from "./PasosOperacion"
import axios from "axios";
import { useHistory } from "react-router";
import { StateContext } from '../../../../../context'
import AlertaMensaje from "../../../../../components/Common/AlertaMensaje";
import MigasDePan  from "../../../../../components/Common/MigasDePan";
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Operacion = () => {  
    let { id, modo } = useParams(); 
    const {state, dispatch} = useContext(StateContext)
    const [ codigoOperacion, setCodigoOperacion] = useState('')
    const { operacion } = state
    const history = useHistory(); 
    const [openAlert,setOpenAlert] = useState({
      open : false,
      tipoMensaje : "success",
      mensaje : ""
    });  
    const [open, setOpen] = React.useState(false);
    const [abrirDialogo, setAbrirDialogo] = useState({
      afacturar: false,
      facturada: false,
      cerrar: false
    })


    const botoneraAcciones = [  
        /*  
        {
          etiqueta: modo === "R" ? "Editar Operación" : "Cancelar Edición",
          url: 
          typeof id === "undefined" ? `/operativas/operaciones/` :
          modo === "R" ? 
            `/operativas/operaciones/operacion/${id}/W` : 
            `/operativas/operaciones/operacion/${id}/R`,          
          activo: true,
        },
        
        {
          etiqueta: "Eliminar",          
          function: () => handleClickEliminarOperacion(id),
          activo: true
        }, */
        {
          etiqueta: modo === "R" ? "Editar Operación" : "Cancelar Edición",
          url: 
          typeof id === "undefined" ? `/operativas/operaciones/` :
          modo === "R" ? 
            `/operativas/operaciones/operacion/${id}/W` : 
            `/operativas/operaciones`,          
          activo: true,
        }, 
        /*
        {
          etiqueta: "Guardar",
          form:'formulario-operacion-mano',
          activo: modo === "R" ? false : true,
        },
        */
        {
          etiqueta: "A Facturar",
          function: () => carpetaAFacturar(), //handleFacturarCarpeta(id),
          activo: modo === "R" ? false : true,
        },
        {
          etiqueta: "Facturada",
          function: () => carpetaFacturada(), //handleCarpetaFacturarda(id),
          activo: modo === "R" ? false : true,
        },
        {
          etiqueta: "Cerrar",
          function: () => handleCerrarCarpeta(id),
          activo: modo === "R" ? false : true,
        },
        
    ];     

    const carpetaAFacturar = () => {
      setAbrirDialogo({
        afacturar: true,
        facturada: false,
        cerrar: false
      })
    }

    const carpetaFacturada = () => {
      setAbrirDialogo({
        afacturar: false,
        facturada: true,
        cerrar: false
      })
    }

    /*
    const carpetaCerrada = () => {
      setAbrirDialogo({
        afacturar: false,
        facturada: false,
        cerrar: true
      })
    }
    */

    const handleFacturarCarpeta = async (idOperacion) => {  
      const datos = {"Id": idOperacion}
      const user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}operaciones/facturarcarpeta`;
      await axios
        .post(SERVER_URL, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos
          })
          .then((response) => {            
            
            
            if(!isNaN(response.data)){
              setOpenAlert({
                open : true,
                tipoMensaje : "success",
                mensaje : "La carpeta está lista para facturar"
              })
              setTimeout(function () {
                history.push({
                pathname: `/operativas/operaciones`,
                //action: action + Math.floor(Math.random() * (9999))
              });  
            }, 2000);
              
              //handleClick(response.data);
              //action();
            }else{
              setOpenAlert({
                open : true,
                tipoMensaje : "error",
                mensaje : "No se ha podido establecer para facturar la carpeta"
              })
            }
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          });
    }

    const handleCarpetaFacturarda = async (idOperacion) => {  
      const datos = {"Id": idOperacion}
      const user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}operaciones/carpetafacturada`;
      await axios
        .post(SERVER_URL, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos
          })
          .then((response) => {            
            
            
            if(!isNaN(response.data)){
              setOpenAlert({
                open : true,
                tipoMensaje : "success",
                mensaje : "La carpeta está clasificada como facturada"
              })
              setTimeout(function () {
                history.push({
                pathname: `/operativas/operaciones`,
                //action: action + Math.floor(Math.random() * (9999))
              });  
            }, 2000);
              
              //handleClick(response.data);
              //action();
            }else{
              setOpenAlert({
                open : true,
                tipoMensaje : "error",
                mensaje : "No se ha podido clasificar como facturada la carpeta"
              })
            }
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          });
    }

    const handleCerrarCarpeta = async (idOperacion) => {
      setOpen(true)
    }

    const handleCloseEliminar = async () => {   
      const datos = {"Id": id}
      const user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}operaciones/cerrarcarpeta`;
      await axios
        .post(SERVER_URL, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos
          })
          .then((response) => {            
            
            if(!isNaN(response.data)){
              setOpenAlert({
                open : true,
                tipoMensaje : "success",
                mensaje : "La carpeta ha sido cerrada"
              })
              setTimeout(function () {
                history.push({
                pathname: `/operativas/operaciones`,
                //action: action + Math.floor(Math.random() * (9999))
              });  
            }, 2000);
              
              //handleClick(response.data);
              //action();
            }else{
              setOpenAlert({
                open : true,
                tipoMensaje : "error",
                mensaje : "No se ha podido cerrar la carpeta"
              })
            }
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          });
      setOpen(false);
    };

    const handleCloseCancelar = () => {      
        setOpen(false);
    };

    useEffect(() => {
      
      const fetchDataNumManos = async () => { 
        const respManosOperacion = await axios(
          `${process.env.REACT_APP_API_URL}operaciones/manosplanificacion/${id}`
        )    
        if (Object.keys(respManosOperacion.data).length > 0) {
          dispatch({type: "SET_MANOS_OPERACION", payload: respManosOperacion.data})       
        }
      }
      fetchDataNumManos();

      if( (modo === "R" || modo === "W") && id > 0 ){
        const fetchData = async () => {  
          const respPedido = await axios(
            `${process.env.REACT_APP_API_URL}operaciones/${id}`
          );  
          
          if (Object.keys(respPedido.data).length > 0) {
            dispatch({type: "SET_OPERACION", payload: respPedido.data})
            setCodigoOperacion(`${respPedido.data.Codigo} (${respPedido.data.EstadoTXT})`)
          }
        }
        fetchData(); 
      }
    },[]) 

    useEffect(() => {
     if(operacion && typeof(operacion) !== 'undefined' && 
      typeof(operacion.actualizarDetalle) !== 'undefined'){
        if(operacion.actualizarDetalle){
          const fetchData = async () => {  
            const respPedido = await axios(
              `${process.env.REACT_APP_API_URL}operaciones/${id}`
            );              
            
            if (Object.keys(respPedido.data).length > 0) {
              dispatch({type: "SET_OPERACION", payload: respPedido.data})
              setCodigoOperacion(respPedido.data.Codigo)
              dispatch({type: "RELOAD_DETALLE", payload: false})
            }
          }
          fetchData(); 
        }
      }
      return () => {
        // Anything in here is fired on component unmount.
        //dispatch({type: "DELETE_OPERACION", payload: null})
        
      }

    },[operacion]) 


    const handleCloseAlert = (event, reason)=>{
      if(reason === "clickaway"){
        return
      }
      setOpenAlert({...openAlert,open:false});
    }

    const handleCerrarDialogoAfacturar = (valor) => {      
      setAbrirDialogo({
        afacturar: false,
        facturada: false,
        cerrar: false
      })
      if(valor){
        handleFacturarCarpeta(id)
      }
    }

    const handleCerrarDialogoFacturada = (valor) => {      
      setAbrirDialogo({
        afacturar: false,
        facturada: false,
        cerrar: false
      })
      if(valor){
        handleCarpetaFacturarda(id)
      }
    }

    const handleCerrarDialogoCerrar = (valor) => {      
      /*
      setAbrirDialogo({
        afacturar: false,
        facturada: false,
        cerrar: false
      })
      */
      if(valor){
        //handleCerrarCarpeta(id)
      }

    }

    
    const urlsMigasdepan = [
      {titulo: "Operaciones", colorTexto: "texto-comercial", urlTo: "/operativas/operaciones"},
      {titulo: "Operación", colorTexto: "texto-comercial", urlTo: `/operativas/operaciones/operacion/${id}/${modo}`},
    ]

    return (
    <>        
      <div className="col-8 col-main-home ">            
        <SearchBar
          titulo="Operativas"
          claseCabecera = "bg-produccion"
          buscar={false}
          imagenCabecera={"5-2-2-operaciones.png"}
        />   
        <>
        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">
            <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigoOperacion}/>       
            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
            { abrirDialogo.afacturar && (<AlertaDialogo titulo={"¿Desea enviar la operación a facturar?"}         abrir={abrirDialogo.afacturar}  cerrar={handleCerrarDialogoAfacturar} textoBoton2={"A Facturar"}/>)}
            { abrirDialogo.facturada && (<AlertaDialogo titulo={"¿Desea clasificar la operación como facturada?"} abrir={abrirDialogo.facturada}  cerrar={handleCerrarDialogoFacturada} textoBoton2={"Facturada"}/>)}
            { /*abrirDialogo.cerrar    && (<AlertaDialogo titulo={"¿Desea cerrar la operación?"}                    abrir={abrirDialogo.cerrar}     cerrar={handleCerrarDialogoCerrar}    textoBoton2={"Cerrar"}/>) */}

              <PasosOperacion />
            </div>
          </div>
          <TableAcciones
            filaSeleccionada={-1}
            botonaccion={botoneraAcciones}
          />
        </div>
        <div className="col-12  mt-2 mb-2  ">
          <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => handleCloseCancelar()}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
          >
              <DialogTitle id="alert-dialog-slide-title">{"Al cerrar la carpeta se archivará y no estará disponible para facturar ¿Desea continuar?"}</DialogTitle>
                          
              <DialogActions>
                  <Button onClick={handleCloseCancelar} color="primary">
                      Cancelar
                  </Button>
                  <Button onClick={handleCloseEliminar} color="primary">
                      Cerrar
                  </Button>
              </DialogActions>
          </Dialog>
        </div>

        {
          openAlert.open && (
            <AlertaMensaje 
              mensaje={openAlert.mensaje} 
              isOpen={openAlert.open} 
              tipoMensaje={openAlert.tipoMensaje}
              cerrar={handleCloseAlert}
            />   
          )
        }
        </>       
      </div>     
    </>
  );
  

}

export default Operacion;

/*
const handleClickEliminarOperacion = (idOperacion) => {
      
    }
    */
     /*
      const datos = {"Id": idOperacion}

      const user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}operaciones/cerrarcarpeta`;
      await axios
        .post(SERVER_URL, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos
          })
          .then((response) => {            
            
            
            if(!isNaN(response.data)){
              setOpenAlert({
                open : true,
                tipoMensaje : "success",
                mensaje : "La carpeta ha sido cerrada"
              })
              setTimeout(function () {
                history.push({
                pathname: `/operativas/operaciones`,
                //action: action + Math.floor(Math.random() * (9999))
              });  
            }, 2000);
              
              //handleClick(response.data);
              //action();
            }else{
              setOpenAlert({
                open : true,
                tipoMensaje : "error",
                mensaje : "No se ha podido cerrar la carpeta"
              })
            }
          })
          .catch((error) => {
           
          });
      */

