import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { StateContext } from '../../../../../context'
import { v4 as uuidv4 } from 'uuid';

const propiedadesUsuarios = {
    urlData: "dic/diccionarios/listadoempleadosoperativas",
    campoEtiqueta: "nombre",
    required: true,
    name: "combo-usuarios",
    disabled: false,
    label: "Empleados",
    value: "",
    multiple: false,
    xs:6,
}

const propiedadesHoraInicioGesport = {
    xs:2,
    required: false,
    label: "Hora inicio",
    name: "horainiciogesport",
    type: "time",    
    value: ''
}
  
  const propiedadesHoraFinGesport = {
    xs:2,
    required: false,
    label: "Hora fin",
    name: "horafingesport",
    type: "time",    
    value: ''
}

const FormularioOperacionGesportEmpleados = () => {
    const {dispatch} = useContext(StateContext)

    const [open, setOpen] = useState(false)
    const [selectedEmpleado, setSelectedEmpleado] = useState(null)
    const [empleados, setEmpleados] = useState([]);
    const [horaInicio, setHoraInicio] = useState()
    const [horaFin, setHoraFin] = useState()
    const loading = open && empleados.length === 0;

    useEffect(() => {   

        (async () => {
            const respEmpleados = await axios(process.env.REACT_APP_API_URL + propiedadesUsuarios.urlData);
            setEmpleados(Object.keys(respEmpleados.data).map((key) => respEmpleados.data[key]))           
          })();

          return () => {
            setEmpleados([]); // This worked for me
          };     
          
    }, [])

    const getOptionLabelEmpleado = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesUsuarios.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionSelectedEmpleado = (option, value) => {
        if( option.Id === value.Id ) 
        {
            setSelectedEmpleado(value)
        }
        return option.Id === value.Id
    }

    const handleChangeEmpleado = (e, v) => { 
        setSelectedEmpleado(v)
    }

    const handleChangeHoraInicio = (event) => {         
        setHoraInicio(event.target.value)
    }

    const handleChangeHoraFin = (event) => { 
        setHoraFin(event.target.value)
    }

    const renderInputEmpleado = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesUsuarios.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }

    const handleClick = () => { 

        if(selectedEmpleado){
           
            let fechaInicio = new Date("1970-01-01 " + horaInicio);
            let fechaFin = new Date("1970-01-01 " + horaFin);
            const horas = Math.abs(fechaFin - fechaInicio) / 36e5;
            const horasJornada = 6;
            
            const costeHora = selectedEmpleado.Coste / horasJornada;
            
            dispatch({type: "ADD_LISTADO_GESPORT", 
            payload: {
                recurso:'empleado',
                nombre: selectedEmpleado.nombre,
                id: uuidv4(),
                cantidad: (Math.round(horas * 100) / 100).toFixed(2),
                horainicio: horaInicio,
                horafin: horaFin,
                coste: (Math.round(costeHora * 100) / 100).toFixed(2),
                total: (Math.round(costeHora * horas * 100) / 100).toFixed(2),
            }})

            setSelectedEmpleado(null)
            setHoraInicio('')
            setHoraFin('')
        }
    }

    return (
    <>
    <Grid container  spacing={1} className="mt-2">
    <Grid item xs={propiedadesUsuarios.xs} className="">
        <Autocomplete
            id={propiedadesUsuarios.name}
            name={propiedadesUsuarios.name}
            getOptionLabel= { getOptionLabelEmpleado }
            getOptionSelected={(option, value) => getOptionSelectedEmpleado(option, value)}
            open={open}
            loadingText="Cargando..."
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}   
            loading={loading}
            label={propiedadesUsuarios.label}        
            renderInput={renderInputEmpleado}
            onChange={(e, v, r) => handleChangeEmpleado(e, v, r)}  
            options={empleados}
            value={selectedEmpleado}
            style={{  marginLeft: 0 }}
        />            
    </Grid>

    <Grid item xs={propiedadesHoraInicioGesport.xs} className="" >
    <TextField                 
        required = {propiedadesHoraInicioGesport.required}                
        id={propiedadesHoraInicioGesport.name}
        name={propiedadesHoraInicioGesport.name}
        disabled = {propiedadesHoraInicioGesport.disabled}
        label={propiedadesHoraInicioGesport.label}        
        value={horaInicio} 
        type="time"
        onChange={handleChangeHoraInicio} 
        variant="outlined"
        size="small"        
        InputLabelProps={{
            shrink: true,
        }}
        keyboardbuttonprops={{
            'aria-label': 'change time',
          }}
        fullWidth
    >
    </TextField>  
    </Grid>

    <Grid item xs={propiedadesHoraFinGesport.xs} className="" >
    <TextField                 
        required = {propiedadesHoraFinGesport.required}                
        id={propiedadesHoraFinGesport.name}
        name={propiedadesHoraFinGesport.name}
        disabled = {propiedadesHoraFinGesport.disabled}
        label={propiedadesHoraFinGesport.label}        
        value={horaFin} 
        type="time"
        onChange={handleChangeHoraFin} 
        variant="outlined"
        size="small"        
        InputLabelProps={{
            shrink: true,
        }}
        fullWidth
    >
    </TextField>      
    </Grid>

    <Grid item xs={2} className="" >
    <Button className="" variant="contained" color="primary" size="large" onClick={handleClick}>
        AÑADIR
    </Button> 
    </Grid>
    </Grid>
    </>
    )
}
export default FormularioOperacionGesportEmpleados;