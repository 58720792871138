import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import SearchBar from "./SearchBar";
import { Button } from '@material-ui/core';
import TableAcciones from "./TableAcciones";
import "semantic-ui-css/semantic.min.css";
import {TEXT_LABELS} from "../../configuraciones/conf"
import CuerpoTablaDatos from './CuerpoTablaDatos'
import AlertaMensaje from "./AlertaMensaje"

import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] }
    }),
    {}
);

const CuerpoTabla = ({
    tituloSearchBar, 
    claseBGSearchBar,
    titulo, 
    columnas, 
    botonera,
    urlDatos,
    urlDoubleClick,
    dobleclick = true,
    columnId,
    imagenCabecera = ""
    }) => {   

    const history = useHistory(); 
    const [estado, setEstado] = useState({datos: [], isLoading: false, error: {activo: false, mensaje: ""}, botones: botonera})
    const [filaSeleccionada, setFilaSeleccionada] = useState(-1)  
    const [filtros, setFiltros] = useState([]);
    const [datosChanged, setDatosChanged] = useState([]);
    //const myRef = React.createRef();  

    const onRowsSelect = (curRowSelected, allRowsSelected) => { 
      let filaS = -1      
      if(allRowsSelected.length > 0) {filaS = estado.datos[allRowsSelected[0].dataIndex][columnId]}
        setFilaSeleccionada(filaS)        
    }
    
    const handleRowDoubleClick = (item, index) => { 
        history.push({
          //pathname: `${urlDoubleClick}${item[0]}/R`,   
          pathname: dobleclick ? `${urlDoubleClick}${item[0]}/R`: `${urlDoubleClick}${item[1]}` ,    
        });
    }

    /*
    const handleFilterSubmit = (applyFilters)  => {    
      let filterList = applyFilters();
      setFiltros(filterList);
    };
    */

    function handleSearchChange(searchText) {          
      let arrayKeys = [];
      if(estado.datos.length > 0)
        arrayKeys = Object.keys(estado.datos[0]);

      //let arrayKeys = Object.keys(estado.datos[0]);      

      const result = estado.datos.filter(function(obj) {
       
        let isResultInArray = false;
        arrayKeys.forEach((element) => {
          
          if(obj[element]?.toString()?.includes(searchText))
            isResultInArray = true;
        }
        );
        if(isResultInArray) return true;
        else return false;  
      })
     
     
      setDatosChanged(result)     
    }

    const OPTIONS_COLUMNS = {
        filterType: 'checkbox',
        print: false,
        download: true,
        pagination: true,
        selectableRows: 'single',  
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        elevation: 0,  
        tableBodyMaxHeight: '470px',
        onRowSelectionChange: onRowsSelect,
        responsive: "vertical",
        setRowProps: (item, idx) => ({
          onDoubleClick: () => {
            handleRowDoubleClick(item, idx)      
          }
        }),
        onSearchChange: handleSearchChange,
        /*
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
          return (
            <div style={{ marginTop: '40px' }}>
              <Button variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>Aplicar Filtro</Button>
            </div>
          );
        },
        */
        
        onFilterChange: (column, filterList, type, columnIndex, data) => {
                    
          if (type === 'checkbox' || type === 'chip') {            
            let datosFiltrados = []
            data.forEach( dato => {              
              datosFiltrados.push(dato.data)
            })            
            setDatosChanged(datosFiltrados)
          }
        },
        onDownload: (buildHead, buildBody, columns, values) => {          
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          
          let nombresColumnas = [];
          columns.forEach( column => {            
            nombresColumnas.push(column.label)
          })
          
          let datosFiltrados = [];          

          if(datosChanged.length > 0){
            datosFiltrados =  datosChanged.slice();            
          } else{
            datosFiltrados =  estado.datos.slice();
          }

          let datosFiltradosFinal = [];
          datosFiltrados.forEach( datoFiltrado => {            
            datosFiltradosFinal.push(renameKeys(nombresColumnas, datoFiltrado));
          })
        
          const fileName = `Datos_Clientes`;
          const ws = utils.json_to_sheet(datosFiltradosFinal);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
          const data = new Blob([excelBuffer], { type: fileType });
          saveAs(data, fileName + fileExtension);
         
          return false;
        },
        textLabels: TEXT_LABELS
    };    

    useEffect(() => {
      setEstado({...estado, isLoading: true, error: "", botones: botonera})     
      const getData = async () => {
        const fetchData = async () => {
          let user = JSON.parse(localStorage.getItem("user"));

          const config = {
            headers: { Authorization: `Bearer ${user.access_token}` }
          };
        
          const bodyParameters = {
            key: null
          };       
          
          await axios(process.env.REACT_APP_API_URL + urlDatos, 
            bodyParameters,
            config                                 
          )
          .then(response => {           
            setEstado({...estado, datos: dobleclick ? response.data : response.data[0], isLoading: false, error: {activo: false, mensaje: ""}, botones: botonera})
          })
          .catch(function (error) {
            if (error.response) {                   
              let botoneraAux = JSON.parse(JSON.stringify(botonera));              
              const nuevaBotonera = botoneraAux.map((boton) => {
                boton.activo=false
                return boton
              })              
              setEstado({
                  ...estado, 
                  datos: [], 
                  isLoading: false, 
                  error: {activo: true, mensaje: error.response.data.message}, 
                  botones: nuevaBotonera
                })              
            }
          });        
        };
        fetchData();
      };
      getData();
    }, [urlDatos]);

    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      //setOpenAlert(false);
      setEstado({...estado, datos: [], isLoading: false, error: {activo: false, mensaje: ""}})
    }; 
    
    return (      
      <div  className="col-main-home">

          <SearchBar
            titulo={tituloSearchBar}
            claseCabecera={claseBGSearchBar}
            buscar={false}  
            imagenCabecera={imagenCabecera}       
          />

          <div className="bg-cuerpo p-10px">

            <CuerpoTablaDatos 
              titulo={titulo}
              datos={estado.datos}
              columnas={columnas}
              OPTIONS_COLUMNS = {OPTIONS_COLUMNS}
              isLoading = {estado.isLoading}
            />  

            <AlertaMensaje 
              mensaje={estado.error.mensaje} 
              isOpen={estado.error.activo} 
              tipoMensaje="error"
              cerrar={handleCloseAlert}
            />         

            <TableAcciones                
                filaSeleccionada={filaSeleccionada}                
                botonaccion={estado.botones}
            />
          </div>

      </div>
    )    
}

export default CuerpoTabla;