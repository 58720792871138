import React from "react";
import axios from "axios";

import IconButton from "@material-ui/core/IconButton";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const handleClickOpen = async (idEpi) => { 
  let user = JSON.parse(localStorage.getItem("user"));
  const urlGenerarFicha="rrhhuniformes/generapdf/"            

  const AuthStr = "Bearer ".concat(user.access_token);
  const SERVER_URL = process.env.REACT_APP_API_URL + urlGenerarFicha + idEpi;
  const res = await axios.get(
      SERVER_URL 
          /*, 
          { headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
          }              
          },
          {params: { estado: ''}} 
          */
      );
  window.open(res.data, "_blank")
  //window.open(fichero, "_blank")
};

const columnsSeleccion = 
[
    {
      name: "Id",
      label: "Nº",
      options: {
       filter: true,
       sort: true,
      }
    },  
    {
     name: "fechaRecepcion",
     label: "Fecha Recepción",
     options: {
        filter: true,
        sort: true,
      }
    },
       {
        name: "nombre",
        label: "Nombre",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "apellidos",
        label: "Apellidos",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "puesto",
        label: "Puesto",
        options: {
         filter: true,
         sort: true,
        }
       },  
       {
        name: "departamento",
        label: "Departamento",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "candidatura",
        label: "Candidatura",
        options: {
         filter: true,
         sort: true,
         
        }   
       },  
         {
          name: "codigoCandidatura",
          label: "Cod. Cand.",
          options: {
           filter: true,
           sort: true,
           
          }   
         },
         {
          name: "estado",
          label: "Estado",
          options: {
           filter: true,
           sort: true,
           
          }   
         },   
];
  
export {columnsSeleccion};

const columnsFormacion = [
        {
          name: "Id",
          label: "Nº",
          options: {
           filter: true,
           sort: true,
          }
         },  
        {
            name: "Fecha_Inicio",
            label: "Fecha Inicio",
            options: {
             filter: true,
             sort: true,
            }
        },
        {
            name: "Fecha_Fin",
            label: "Fecha Fin",
            options: {
             filter: true,
             sort: true,
            }
        },
        {
            name: "Nombre_Curso",
            label: "Curso Formación",
            options: {
             filter: true,
             sort: true,
            }
        },
        {
            name: "Asistentes",
            label: "Asistentes",
            options: {
             filter: true,
             sort: true,
            }
        },  
        {
            name: "Entidad",
            label: "Entidad",
            options: {
             filter: true,
             sort: true,
            }
        },
        {
            name: "Horario",
            label: "Horario",
            options: {
             filter: true,
             sort: true,
             
            }   
        },  
        {
              name: "Lugar",
              label: "Lugar",
              options: {
               filter: true,
               sort: true,
               
              }   
         },
        {
              name: "Estado",
              label: "Estado",
              options: {
               filter: true,
               sort: true,
               
              }   
        },   
];
      
export {columnsFormacion};

const columnsRecMedico = [
            {
              name: "Id",
              label: "Nº",
              options: {
               filter: true,
               sort: true,
              }
             }, 
             {
                name: "Fecha",
                label: "Fecha",
                options: {
                 filter: true,
                 sort: true,
                }
               }, 
               {
                name: "Tipologia",
                label: "Tipologia",
                options: {
                 filter: true,
                 sort: true,
                }
               }, 
               {
                name: "Trabajador",
                label: "Trabajador",
                options: {
                 filter: true,
                 sort: true,
                }
               }, 
               {
                name: "Realizada",
                label: "Realizada",
                options: {
                 filter: true,
                 sort: true,
                }
               },  
];
      
export {columnsRecMedico};

const columnsEnfermedad = [
                {
                  name: "Id",
                  label: "Nº",
                  options: {
                   filter: true,
                   sort: true,
                  }
                 }, 
                 {
                    name: "Fecha",
                    label: "Fecha de baja",
                    options: {
                     filter: true,
                     sort: true,
                    }
                   }, 
                   {
                    name: "FechaAlta",
                    label: "Fecha de alta",
                    options: {
                     filter: true,
                     sort: true,
                    }
                   }, 
                   /*
                   {
                    name: "Tipologia",
                    label: "Tipologia",
                    options: {
                     filter: true,
                     sort: true,
                    }
                   }, 
                   */
                   {
                    name: "Trabajador",
                    label: "Trabajador",
                    options: {
                     filter: true,
                     sort: true,
                    }
                   }, 
                   {
                    name: "Dias",
                    label: "Dias",
                    options: {
                     filter: true,
                     sort: true,
                    }
                   },  
];
          
export {columnsEnfermedad};

const columnsAccidente = [
                    {
                      name: "Id",
                      label: "Nº",
                      options: {
                       filter: true,
                       sort: true,
                      }
                     }, 
                     {
                        name: "Fecha",
                        label: "Fecha",
                        options: {
                         filter: true,
                         sort: true,
                        }
                       }, 
                       {
                        name: "Tipologia",
                        label: "Tipologia",
                        options: {
                         filter: true,
                         sort: true,
                        }
                       }, 
                       {
                        name: "Trabajador",
                        label: "Trabajador",
                        options: {
                         filter: true,
                         sort: true,
                        }
                       }, 
                       {
                        name: "Baja",
                        label: "Baja",
                        options: {
                         filter: true,
                         sort: true,
                        }
                       },  
];
              
export {columnsAccidente};


const columnsDocumentacion = [
                        {
                          name: "Id",
                          label: "Nº",
                          options: {
                           filter: true,
                           sort: true,
                          }
                         }, 
                         {
                            name: "Codigo",
                            label: "Código",
                            options: {
                             filter: true,
                             sort: true,
                            }
                           }, 
                           {
                            name: "Fecha_Creacion",
                            label: "Fecha creación",
                            options: {
                             filter: true,
                             sort: true,
                            }
                           }, 
                           {
                            name: "Fecha_Ultima_Modificacion",
                            label: "Fecha última modificación",
                            options: {
                             filter: true,
                             sort: true,
                            }
                           },
                           {
                            name: "Nombre",
                            label: "Nombre",
                            options: {
                             filter: true,
                             sort: true,
                            }
                           }, 
                           {
                            name: "Descripcion",
                            label: "Descripción",
                            options: {
                             filter: true,
                             sort: true,
                            }
                           },  
];
                  
export {columnsDocumentacion};

const columnsEPI = [
                            {
                              name: "Id",
                              label: "Id",
                              options: {
                              filter: false,
                                sort: true,
                                }
                            },
                            {
                                name: "Fk_Categoria_Uniforme",
                                label: "Categoria",
                                options: {
                                 filter: true,
                                 sort: true,
                                }
                               },
                               {
                                name: "Fk_Elemento_Uniforme",
                                label: "Elemento",
                                options: {
                                 filter: true,
                                 sort: true,
                                }
                               },
                               {
                                name: "Cantidad",
                                label: "Unidades",
                                options: {
                                 filter: false,
                                 sort: true,
                                }
                               },
                               {
                                name: "Fecha",
                                label: "Fecha",
                                options: {
                                 filter: false,
                                 sort: true,
                                }
                               },
                               {
                                name: "Trabajador",
                                label: "Trabajador",
                                options: {
                                 filter: true,
                                 sort: true,
                                }
                               },
                               {
                                name: "Observaciones",
                                label: "Observaciones",
                                options: {
                                 filter: false,
                                 sort: true,
                                }
                               },
                               {
                                name: "Activo",
                                label: "Activo",
                                options: {
                                 filter: true,
                                 sort: true,
                                }
                               },
                               {
                                name: "",
                                label: "PDF",
                                options: {
                                 filter: false,
                                 sort: true,
                                 customBodyRender: (value, tableMeta, updateValue) => {   
                                    
                                  return (
                                    <IconButton className="" onClick={() => handleClickOpen(tableMeta.rowData[0])}>
                                    <PictureAsPdfIcon className=""/>
                                    </IconButton>
                                    
                                  );
                                  }
                                }
                            },

                               
                            
];
export {columnsEPI};