import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormFieldCombo from "../../../../../components/Common/FormFieldCombo";
import FormFieldNumber from "../../../../../components/Common/FormFieldNumber";
import FormFieldText from "../../../../../components/Common/FormFieldText";
import { useForm } from "react-hook-form";

const propiedadesArticulo = {
    urlData: "materials/listadomateriales",
    campoEtiqueta: "Nombre",
    required: false,
    name: "descripcion",
    disabled: false,
    label: "Materiales",
    value: "",
    multiple: false,
    xs:3,
}

const propiedadesCpe = {
    xs:3,
    required: false,
    label: "Descripción",
    name: "servicio",
    type: "rext",    
    value: '', 
    disabled: false,
    multiple: false,
}

const propiedadesServicio = {
    xs:3,
    required: false,
    label: "Servicio",
    name: "servicio",
    type: "rext",    
    value: ''
}

const propiedadesProducto = {
    xs:3,
    required: false,
    label: "Producto",
    name: "servicio",
    type: "rext",    
    value: ''
}

const propiedadesCantidad = {
    xs:1,
    required: false,
    label: "Cantidad",
    name: "cantidad",
    type: "number",    
    value: ''
}

const propiedadesPrecioUnidad = {
    xs:1,
    required: false,
    label: "Precio .Ud",
    name: "precio",
    type: "number",    
    value: ''
}

const propiedadesIgic = {
    urlData: "dic/diccionarios/igic",
    campoEtiqueta: "Etiqueta,Valor",
    campoId: "Id",
    required: false,
    name: "igic",
    disabled: false,
    label: "IGIC",
    value: "",
    multiple: false,
    xs:1,
}

const propiedadesIrpf = {
    urlData: "dic/diccionarios/irpf",
    campoEtiqueta: "Etiqueta,Valor",
    campoId: "Id",
    required: false,
    name: "irpf",
    disabled: false,
    label: "IRPF",
    value: "",
    multiple: false,
    xs:1,
}

const propiedadesDeposito = {
    urlData: "dic/diccionarios/getcentroscoste",
    campoEtiqueta: "Etiqueta",
    campoId: "Id",
    required: false,
    name: "centroCoste",
    disabled: false,
    label: "Centro de coste",
    value: "",
    multiple: false,
    xs:2,
}

const propiedadesInversion = {
    xs:3,
    required: false,
    label: "Concepto inversión",
    name: "servicio",
    type: "rext",    
    value: ''
}

const propiedadesRecibo = {
    xs:3,
    required: false,
    label: "Concepto recibo",
    name: "servicio",
    type: "rext",    
    value: ''
}
/*
urlData: "dic/diccionarios/operativas",
        campoEtiqueta: "Etiqueta",
        required: false,
        name: "operativa",      
        label: "Operativa",
        value: "",
        multiple: false,
        xs:4,
*/
const propiedadesTipoOperativas = {
    urlData: "dic/diccionarios/operativas",
    campoEtiqueta: "Etiqueta",
    campoId: "Id",
    required: false,
    name: "tipoOperativa",
    disabled: false,
    label: "Categoría",
    value: "",
    multiple: false,
    xs:2,
}


const FormularioTablaFactura = ({listadotabla, tipoFactura, proveedor, desactivaCuentas}) => { 

    const { control, setValue, getValues} = useForm();

    /*
    const propiedadesCuentasAnaliticas =
    {
        urlData: proveedor ? `dic/diccionarios/cuentasanaliticas/${proveedor.Id}` : "dic/diccionarios/cuentasanaliticas/0",
        campoEtiqueta: "Codigo,Etiqueta",
        campoId: "Id",
        campoDesactivar: "Mostrar",
        required: false,
        name: "cuentaAnalitica",
        disabled: desactivaCuentas,
        label: "Cuentas analíticas",
        type:'combo',
        value: '',
        multiple: false,
        xs: 3
    }
    */

    /*
    const propiedadesCuentasPyG =
    {
        urlData: proveedor ? `dic/diccionarios/cuentaspyg/${proveedor.Id}` : "dic/diccionarios/cuentaspyg/0",
        campoEtiqueta: "Codigo,Etiqueta",
        campoId: "Id",
        campoDesactivar: "Mostrar",
        required: false,
        name: "cuentaPyG",
        disabled: desactivaCuentas,
        label: "Cuentas pérdidas y ganancias",
        type:'combo',
        value: '',
        multiple: false,
        xs: 3
    }
    */
    
    const handleClick = () => {  

        const material = getValues("descripcion")
        const servicio = getValues("servicio")        
        const cantidad = getValues("cantidad")
        const precio = getValues("precio")
        const igic = getValues("igic")
        const irpf = getValues("irpf")
        const deposito = getValues("centroCoste") 
        const categoria = getValues("tipoOperativa")

       
        const igicImporte = cantidad * precio * igic.Valor / 100
        const irpfImporte = cantidad * precio * irpf.Valor / 100

        const esMaterial = (typeof material === "undefined" || material.Id === -1 )? false : true;
        const idMaterial = esMaterial === true ? material.id : -1
        const eanMaterial = esMaterial === true ? material.ean: (tipoFactura.Etiqueta === "Servicios" ? 'servicio' : 'cpe')

        const nombreMaterial = esMaterial === true ? material.Nombre : servicio
        const unidadMaterial = esMaterial === true ? material.tipoUnidad : ''

        let errores = 0;
        let erroresMsg = "";

        if(!tipoFactura) {errores++; erroresMsg += "Debe seleccionar un tipo de factura\n";}
        if(!nombreMaterial) {errores++; erroresMsg += "Debe seleccionar un material o servicio\n";}
        if(!cantidad) {errores++; erroresMsg += "Debe intriducir una cantidad\n";}
        if(!precio) {errores++; erroresMsg += "Debe intriducir un precio unitario\n";}
        if(!igic) {errores++; erroresMsg += "Debe seleccionar un tipo de IGIC\n";}        
        if(!irpf) {errores++; erroresMsg += "Debe seleccionar un tipo de IRPF\n";}        
        if(!deposito) {errores++; erroresMsg += "Debe seleccionar un centro de coste\n";}
        //propiedadesTipoOperativas
        //if(!desactivaCuentas && !cuentaAnalitica) {errores++; erroresMsg += "Debe seleccionar una cuenta analítica\n";}
        //if(!desactivaCuentas && !cuentaPyG) {errores++; erroresMsg += "Debe seleccionar una cuenta de perdidas y ganancias\n";}
        
        if(errores > 0){
            alert(erroresMsg)
            return;
        }
        
        
        /*
        let campoCuentaAnalitica = ""
        if(cuentaAnalitica){
            campoCuentaAnalitica = cuentaAnalitica.Codigo + ': ' + cuentaAnalitica.Etiqueta
        }
        let campoCuentaPyG = ""
        if(cuentaPyG){
            campoCuentaPyG = cuentaPyG.Codigo + ': ' + cuentaPyG.Etiqueta
        }
        */

        listadotabla({
            id:uuidv4(),
            idArticulo:idMaterial,
            idCentroCoste:deposito.Id,
            idIgic: igic ? igic.Id : null,
            idIrpf: irpf ? irpf.Id : null,
            //idCuentaAnalitica: cuentaAnalitica.Id,
            //idCuentaPyG: cuentaPyG.Id,
            codigo:eanMaterial,
            descripcion:nombreMaterial,
            unidad:unidadMaterial,
            cantidad:cantidad,
            precioUnidad:precio,     
            centroCoste:deposito.Etiqueta,
            igic: igic ? igic.Valor : null,
            irpf: irpf ? irpf.Valor : null,
            igicImporte: igicImporte,
            baseImponible: cantidad*precio,
            precioTotal:cantidad*precio + igicImporte - irpfImporte,
            //cuentaAnalitica: campoCuentaAnalitica,
            //cuentaPyG: campoCuentaPyG,
            idCategoria: categoria.Id,
            categoria: categoria.Etiqueta,
        })   

        setValue("descripcion", "")
        setValue("cantidad", "")
        setValue("precio", "")
        setValue("igic", "")    
        setValue("irpf", "")
        setValue("centroCoste", "")   
        setValue("servicio", "")    
        setValue("cuentaAnalitica", "")   
        setValue("cuentaPyG", "")     
        setValue("tipoOperativa", "") 
    }

    //const mostrarMateriales = tipoFactura.Id !== "Servicios" ? true : false;
    const esMaterial = tipoFactura.Tipo && tipoFactura.Tipo.toUpperCase() === "MATERIALES"
    const esServicio = tipoFactura.Tipo && tipoFactura.Tipo.toUpperCase() === "SERVICIOS"
    const esCpe = tipoFactura.Tipo && tipoFactura.Tipo.toUpperCase() === "CPE"
    const esProducto = tipoFactura.Tipo && tipoFactura.Tipo.toUpperCase() === "PRODUCTOS"
    const esInversion = tipoFactura.Tipo && tipoFactura.Tipo.toUpperCase() === "INVERSIÓN"
    const esRecibo = tipoFactura.Tipo && tipoFactura.Tipo.toUpperCase() === "RECIBOS"

    return (
    <>
    <Grid container  spacing={1} className="mt-1 borderFormulario">
        { esMaterial && (
        <FormFieldCombo propiedades={propiedadesArticulo} control={control}/>)}
        { esServicio && (
        <FormFieldText propiedades={propiedadesServicio} control={control}/>)}
        { esProducto && (
        <FormFieldText propiedades={propiedadesProducto} control={control}/>)}
        { esCpe && (
        <FormFieldText propiedades={propiedadesCpe} control={control}/>)}
        { esInversion && (
        <FormFieldText propiedades={propiedadesInversion} control={control}/>)}
        { esRecibo && (
        <FormFieldText propiedades={propiedadesRecibo} control={control}/>)}

        <FormFieldNumber propiedades={propiedadesCantidad} control={control}/> 
        <FormFieldNumber propiedades={propiedadesPrecioUnidad} control={control}/>
        <FormFieldCombo propiedades={propiedadesIgic} control={control}/>
        <FormFieldCombo propiedades={propiedadesIrpf} control={control}/>
        <FormFieldCombo propiedades={propiedadesDeposito} control={control}/>  
        <FormFieldCombo propiedades={propiedadesTipoOperativas} control={control}/>
        {/* 
        <FormFieldCombo propiedades={propiedadesCuentasAnaliticas} control={control}/>
        <FormFieldCombo propiedades={propiedadesCuentasPyG} control={control}/>   
        */}
    
        <Grid item xs={1} className="" >
        <Button className="" variant="contained" color="primary" component="span" onClick={handleClick}>
            AÑADIR
        </Button> 
        </Grid>

    </Grid>
    </>
    )
}
export default FormularioTablaFactura;