import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import FichaEntidad from '../../../../../components/Common/FichaEntidad'


const FichaPedido = ({getCodigo}) => {
    let { id } = useParams();

    const [pedido, setPedido] = useState([])    
    const [campos, setCampos] = useState([])  
    
    useEffect(() => {        
        const fetchData = async () => {
          const respPedido = await axios(`${process.env.REACT_APP_API_URL}pedidoclientes/${id}`);    
          setPedido(respPedido.data);          
          getCodigo(respPedido.data.Codigo)
        };
        fetchData();  
    }, [id]);
   
    const classText= "Texto14"

    useEffect(() => {      
      if(Object.keys(pedido).length > 0){
        const resultado = pedido.Resultado === 0 ? "No confirmado" : "Confirmado"
        setCampos([
          {
            classsLabel: "",
            label: "Fecha de solicitud:",
            value: convertDate(pedido.Fecha_Solicitud),
            classText: classText,
            xs:4
          },
          {
            classsLabel: "",
            label: "Canal de Solicitud:",
            value: pedido.Fuente ? pedido.Fuente.Etiqueta : "",
            classText: classText,
            xs:4
          },
          {
            classsLabel: "",
            label: "Presupuesto:",
            value: pedido.Presupuesto ? (pedido.Presupuesto === 0) ? "No" : "Sí" : "No",
            classText: classText,
            xs:4
          },
          {
            classsLabel: "",
            label: "",
            value: "",
            classText: classText,
            xs:12
          },
          {
            classsLabel: "",
            label: "Cliente:",
            value: pedido.Cuenta.Razon_Social,
            classText: classText,
            xs:4
          },
          {
            classsLabel: "",
            label: "Contacto:",
            value: pedido.Contacto ? (pedido.Contacto.Apellidos.concat(", " , pedido.Contacto.Nombre)) : "",
            classText: classText,
            xs:4
          },
          {
            classsLabel: "",
            label: "Referencia cliente:",
            value: pedido.Referencia_Cliente ? pedido.Referencia_Cliente : "",
            classText: classText,
            xs:4
          },
          {
            classsLabel: "",
            label: "",
            value: "",
            classText: classText,
            xs:12
          },
          {
            classsLabel: "",
            label: "Fecha de operativa:",
            value: pedido.Fecha_Servicio ? convertDate(pedido.Fecha_Servicio) : "",
            classText: classText,
            xs:4
          },
          {
            classsLabel: "",
            label: "Muelle:",
            value: pedido.Localizacion ? pedido.Localizacion.Etiqueta : "",
            classText: classText,
            xs:4
          }, 
          {
            classsLabel: "",
            label: "Buque:",
            value: pedido.Buque ? pedido.Buque.Nombre : "",
            classText: classText,
            xs:4
          }, 
          {
            classsLabel: "",
            label: "",
            value: "",
            classText: classText,
            xs:12
          },   
          {
            classsLabel: "",
            label: "Resultado:",
            value: resultado,
            classText: classText,
            xs:5
          },       
          {
            classsLabel: "",
            label: "Descripción:",
            value: pedido.Descripcion ? pedido.Descripcion : "",
            classText: classText,
            xs:5
          },
          {
            classsLabel: "",
            label: "Archivos adjuntos:",
            value: pedido.ficheros,
            classText: classText,
            type: "file",
            xs:7
          },  
        ])
      }
    }, [pedido])   
       

    const convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    }
    
    return(
      <FichaEntidad
      campos = {campos}
      urlClick = {`/ventas/pedidos/pedido/${id}/W`}
      />
    )
    
  };
  export default FichaPedido;