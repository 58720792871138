import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAxios } from '../../../../../hooks/useAxios'
import FichaEntidad from '../../../../../components/Common/FichaEntidad'


const FichaCuentaCondiciones = () => {
    let { id } = useParams();

    const { response } = useAxios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}cuentas/${id}`,
        /*
        headers: { // no need to stringify
          accept: '* / *'
        },        
        data: {  // no need to stringify
            userId: 1,
            id: 19392,
            title: 'title',
            body: 'Sample text',
        },
        */
    });
   
    const [campos, setCampos] = useState([])      
    
    const classText= "Texto14"

    useEffect(() => {     
        if(!response) {return}     

        if(Object.keys(response).length > 0){  
          if(Object.keys(response).length > 1){

            let etiquetasCA = [];  
            let etiquetasPyG = [];  
            let etiquetasTI = [];

            etiquetasCA = response.CuentaCuentasAnaliticas.map((s) => {
                return s.Codigo + "-" + s.Etiqueta;
            }); 

            etiquetasPyG = response.CuentaCuentasPyG.map((s) => {
                return s.Codigo + "-" + s.Etiqueta;
            }); 

            etiquetasTI = response.CuentaTiposImpositivos.map((s) => {
                return s.Etiqueta;
            }); 
            
            setCampos([
                {
                    classsLabel: "",
                    label: "Cuenta Contable Cliente:",
                    value: response.Cuenta_contable_cliente,
                    classText: classText,
                    xs:2
                },
                {
                    classsLabel: "",
                    label: "Cuenta Contable Proveedor:",
                    value: response.Cuenta_contable_proveedor,
                    classText: classText,
                    xs:2
                },
                {
                    classsLabel: "",
                    label: "Cuenta Contable Acreedor:",
                    value: response.Cuenta_contable_acreedor,
                    classText: classText,
                    xs:2
                },
                {
                    classsLabel: "",
                    label: "Día de pago:",
                    value: response.Dia_Pago,
                    classText: classText,
                    xs:2
                },
                {
                    classsLabel: "",
                    label: "Descuento %:",
                    value: response.Descuento,
                    classText: classText,
                    xs:2
                },
                {
                    classsLabel: "",
                    label: "Retención %:",
                    value: response.Retencion,
                    classText: classText,
                    xs:2
                },
                {
                    classsLabel: "",
                    label: "Cuentas analíticas:",
                    value: etiquetasCA.join(),
                    classText: classText,
                    xs:6
                },
                {
                    classsLabel: "",
                    label: "Cuentas pérdidas y ganancias:",
                    value: etiquetasPyG.join(),
                    classText: classText,
                    xs:6
                },

                {
                    classsLabel: "",
                    label: "Forma de pago:",
                    value: response.FormaPago ? response.FormaPago.Etiqueta : "",
                    classText: classText,
                    xs:3
                },
                {
                    classsLabel: "",
                    label: "Condiciones de pago:",
                    value: response.CondicionesPago ? response.CondicionesPago.Etiqueta : "",
                    classText: classText,
                    xs:3
                },  
                
                {
                    classsLabel: "",
                    label: "Tipos Imositivos:",
                    value: etiquetasTI.join(),
                    classText: classText,
                    xs:3
                },
                {
                    classsLabel: "",
                    label: "Idioma:",
                    value: response.Idioma ? response.Idioma.Etiqueta : "",
                    classText: classText,  
                    xs:3            
                },
                
                   
                {
                    classsLabel: "",
                    label: "Servicios recurrentes",
                    value: response.Servicios_Recurrentes ? "Sí" : "No",
                    classText: classText,
                    xs:6
                },


                {
                    classsLabel: "",
                    label: "Establecimiento permanente en Canarias",
                    value: response.EstPermCanarias ? "Sí" : "No",
                    classText: classText,
                    xs:6
                },    
            ])
        }
        }
    },[response])      

    return(
        <>
        <h6 className="texto-comercial mt-4" id="title">
          Condiciones compra/venta
        </h6>
        <FichaEntidad
        campos = {campos}
        urlClick = {`/crm/cuentas/cuenta/${id}/W`}
        />
        </>
      )
}
export default FichaCuentaCondiciones;