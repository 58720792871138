import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'


const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioDatLaborales = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [datos, setLaboral] = useState([]) 


    
    useEffect(() => { 

        setCampos([
            {
                required: false,
                label: "Fecha alta empresa",
                name: "fechaAlta",
                disabled: modo === "R" ? true : false, 
                type: "date",           
                multiple:false,   
                value: "",
                xs:4
            },  
            {
                urlData: "dic/diccionarios/rrhhtiposcontratos",
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Tipo contrato",
                name: "tipoContrato",
                disabled: modo === "R" ? true : false, 
                type: "combo",
                value: "",              
                xs:4
            },
            {
                urlData: "dic/diccionarios/getdepartamentos",
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Departamento",
                name: "departamento",
                disabled: modo === "R" ? true : false, 
                type: "combo",
                value: "",              
                xs:4
            },
            {
                urlData: "dic/diccionarios/puestostrabajo",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Puesto de trabajo",
                name: "puestoTrabajo",
                disabled: modo === "R" ? true : false, 
                type: "combo",
                value: "",              
                xs:4
            },
            {
                required: false,
                label: "IBAN",
                name: "iban",
                type: "text", 
                disabled: modo === "R" ? true : false,              
                value: "" ,
                xs:4
            },
            {
                required: false,
                label: "SWIFT / BIC",
                name: "swift",
                type: "text", 
                disabled: modo === "R" ? true : false,              
                value: "" ,
                xs:4
            },
            {
                required: false,
                label: "Retención IRPF",
                name: "retencion",
                type: "number",
                disabled: modo === "R" ? true : false,               
                value: "" ,
                xs:4
            },
            {
                required: false,
                label: "Nº Seguridad Social",
                name: "numeroSeguridadSocial",
                type: "text",
                disabled: modo === "R" ? true : false,               
                value: "" ,
                xs:4
            },
            {
                required: false,
                label: "Nivel de estudios",
                name: "nivelEstudios",
                type: "text",  
                disabled: modo === "R" ? true : false,             
                value: "" ,
                xs:4
            },
            {
                required: false,
                label: "Título",
                name: "titulo",
                type: "text",
                disabled: modo === "R" ? true : false,               
                value: "" ,
                xs:4
            }, 
            {
                required: false,
                label: "Banco",
                name: "banco",
                type: "text",
                disabled: modo === "R" ? true : false,               
                value: "" ,
                xs:4
            },
            {
                required: false,
                label: "Número de cuenta",
                name: "numeroCuenta",
                type: "text",
                disabled: modo === "R" ? true : false,               
                value: "" ,
                xs:4
            }, 
            {
                required: false,
                label: "Talla zapatos",
                name: "tallaZapatos",
                type: "text", 
                disabled: modo === "R" ? true : false,              
                value: "" ,
                xs:4
            },
            {
                required: false,
                label: "Talla Camisa/Blusa",
                name: "tallaCamisa",
                type: "text",  
                disabled: modo === "R" ? true : false,             
                value: "" ,
                xs:4
            },
            {
                required: false,
                label: "Talla pantalón",
                name: "tallaPantalon",
                type: "text", 
                disabled: modo === "R" ? true : false,              
                value: "" ,
                xs:4
            },
            {
                required: false,
                label: "¿Entrega DNI?",
                name: "entregaDNI",
                type: "switch",  
                disabled: modo === "R" ? true : false,             
                value: "" ,
                xs:3
            },
            {
                required: false,
                label: "¿Entrega Carnet B1?",
                name: "entregaB1",
                type: "switch",  
                disabled: modo === "R" ? true : false,             
                value: "" ,
                xs:3
            },
            {
                required: false,
                label: "¿Entrega Títulos?",
                name: "entregaTitulo",
                type: "switch", 
                disabled: modo === "R" ? true : false,              
                value: "" ,
                xs:3
            },  
            
            {
                urlData: "dic/diccionarios/puestostrabajoapp",
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Permisos App",
                name: "permisosApp",
                disabled: modo === "R" ? true : false, 
                type: "combo",
                value: "",              
                xs:3
            },

            {
                required: false,
                label: "Observaciones:",
                name: "observaciones",
                type: "textArea",
                lineas: 4,    
                disabled: modo === "R" ? true : false,  
                value: "",                
                xs:12
            },
            {
                required: false,
                label: "Id Usuario",
                name: "idUsuario",
                type: "text", 
                disabled: modo === "R" ? true : false,             
                value: localStorage.getItem('idUsuario') ,
                classes: "d-none",
                xs:4
            },              
    ])

    const fetchData = async () => { 
        if (modo === "W" || modo === "R") {
            const respdatos = await axios(
                `${process.env.REACT_APP_API_URL}rrhhdatoslaborales/${localStorage.getItem('idUsuario')}`
            );                
            setLaboral(respdatos.data)  
        }
        setDatosCargados(true);
  
    };    
    fetchData();       
},[modo]);

useEffect(() => {            
    if (typeof datos !== "undefined") {        
      if(Object.keys(datos).length > 0){              
        setDatosCargados(false)
        campos[0].value = datos.Fecha_Alta;
        campos[1].value = datos.Tipo_Contrato;
        campos[2].value = datos.Departamento;
        campos[3].value = datos.Puesto_Trabajo;
        campos[4].value = datos.IBAN;
        campos[5].value = datos.SWIFT_BIC;
        campos[6].value = datos.Retencion_IRPF;
        campos[7].value = datos.Numero_Seguridad_Social;
        campos[8].value = datos.Nivel_Estudios;
        campos[9].value = datos.Titulo;
        campos[10].value = datos.Banco;
        campos[11].value = datos.Numero_Cuenta;
        campos[12].value = datos.Talla_Zapatos;
        campos[13].value = datos.Talla_Camisa;
        campos[14].value = datos.Talla_Pantalon;
        campos[15].value = datos.Entrega_DNI;
        campos[16].value = datos.Entrega_B1;
        campos[17].value = datos.Entrega_Titulo;
        campos[18].value = datos.PermisosApp;
        campos[19].value = datos.Observaciones;
        campos[20].value = localStorage.getItem('idUsuario');

        setDatosCargados(true)  
      }
  }
  },[datos])
  const modoFormulario = modo === "R" ? "view" : ( modo === "W" ? "edit" : "add")
if (datoscargados){
    return (
        <FormularioEntidad 
        urlForm = "rrhhdatoslaborales"
        urlGoto = "/rrhh/gestionpersonal/empleados/"
        campos={campos}
        type= {modoFormulario}
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}


export default FormularioDatLaborales;