import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioCuentaCondiciones = () =>{
    const { id, modo } = useParams();
    const classes = useStyles();

    const [datoscargados, setDatosCargados] = useState(false);
    const [datosDic, setDatosDic] = React.useState([]);
    const [campos, setCampos] = useState([])  
    const [cuenta, setCuenta] = useState()    

    useEffect(() => {
      let isMounted = true;
        if (!datoscargados) {
          const url_diccionarios =
            `${process.env.REACT_APP_API_URL}dic/diccionarios/formulariocondicionescompraventa`;
    
          const fetchData = async () => {
            const respDatos = await axios(url_diccionarios);

            if(isMounted){
              setDatosDic(respDatos.data);            
              setDatosCargados(true);

              if (modo === "W") {
                const respCuenta = await axios(
                    `${process.env.REACT_APP_API_URL}cuentas/${id}`
                );              
                
                setCuenta(respCuenta.data)  
              }
              else{   
                  setCuenta({
                  Presupuesto:false
                  })
              }
            }
          }
          fetchData();
        }
        return () => {
          isMounted = false;
          setCuenta() 
        }
    },[datoscargados, id, modo])

    useEffect(() => {           
      
        if (typeof cuenta !== "undefined") {   

          const diaPago =  cuenta ? cuenta.Dia_Pago ? cuenta.Dia_Pago : "" : ""
          const descuento =  cuenta ? cuenta.Descuento ? cuenta.Descuento : "0" : "0"
          const retencion =  cuenta ? cuenta.Retencion ? cuenta.Retencion : "0" : "0"
          const serviciosRecurrentes =  cuenta ? cuenta.Servicios_Recurrentes ? cuenta.Servicios_Recurrentes : "" : ""
          const estPermCanarias =  cuenta ? cuenta.EstPermCanarias ? cuenta.EstPermCanarias : "" : ""


          const cuenta_contable_cliente = cuenta ? cuenta.Cuenta_contable_cliente ? cuenta.Cuenta_contable_cliente : "" : ""
          const cuenta_contable_proveedor = cuenta ? cuenta.Cuenta_contable_proveedor ? cuenta.Cuenta_contable_proveedor : "" : ""
          const cuenta_contable_acreedor = cuenta ? cuenta.Cuenta_contable_acreedor ? cuenta.Cuenta_contable_acreedor : "" : ""
          
          setCampos([
            {
              required: false,
              label: "C. contable cliente",
              name: "cuenta_contable_cliente",
              type: "text",                 
              value: cuenta_contable_cliente,
              xs:2
            }, 
            {
              required: false,
              label: "C. contable proveedor",
              name: "cuenta_contable_proveedor",
              type: "text",                 
              value: cuenta_contable_proveedor,
              xs:2
            }, 
            {
              required: false,
              label: "C. contable acreedor",
              name: "cuenta_contable_acreedor",
              type: "text",                 
              value: cuenta_contable_acreedor,
              xs:2
            }, 
            {
              required: false,
              label: "Día de pago",
              name: "diapago",
              type: "number",                 
              value: diaPago,
              multiple:true,
              xs:2
            }, 
            {
              required: true,
              label: "Descuento (%)",
              name: "descuento",
              type: "number",              
              value: descuento,
              xs:2
            },
            {
              required: true,
              label: "Retención (%)",
              name: "retencion",
              type: "number",              
              value: retencion,
              xs:2
            },
            {
              campoEtiqueta: "Codigo,Etiqueta",
              campoId: "Id",
              campoDesactivar: "Mostrar",
              required: cuenta.Es_Proveedor === 1 ? true : false,
              label: "Cuentas Analíticas:",
              name: "cuentasanaliticas",
              disabled: false,
              type: "comboArray", 
              options:datosDic.cuentasAnaliticas, 
              multiple:true,   
              value: cuenta.CuentaCuentasAnaliticas,
              xs:6
            }, 
            {
              campoEtiqueta: "Codigo,Etiqueta",
              campoId: "Id",
              campoDesactivar: "Mostrar",
              required: cuenta.Es_Proveedor === 1 ? true : false,
              label: "Cuentas P. y G.:",
              name: "cuentaspyg",
              disabled: false,
              type: "comboArray", 
              options:datosDic.cuentasPyG, 
              multiple:true,   
              value: cuenta.CuentaCuentasPyG,
              xs:6
            },   
            {
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Forma de Pago:",
              name: "formapago",
              disabled: false,
              type: "comboArray", 
              options:datosDic.formasPago, 
              multiple:false,   
              value: cuenta.FormaPago,
              xs:3
            }, 
            {
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Condiciones de Pago:",
              name: "condicionespago",
              disabled: false,
              type: "comboArray", 
              options:datosDic.condicionesPago, 
              multiple:false,   
              value: cuenta.CondicionesPago,
              xs:3
            },        
            {
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Tipos Impositivos:",
              name: "tiposimpositivos",
              disabled: false,
              type: "comboArray", 
              options: datosDic.tiposImpositivos, 
              multiple:true,   
              value:  cuenta.CuentaTiposImpositivos,
              xs:3
            },              
            {
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Idioma:",
              name: "idioma",
              disabled: false,
              type: "comboArray", 
              options: datosDic.idiomas, 
              multiple:false,   
              value:  cuenta.Idioma,
              xs:3
            },               
            {
              required: true,
              label: "Servicios recurrentes",
              name: "serviciosRecurrentes",
              type: "switch",              
              value: serviciosRecurrentes,
              xs:6
            },
            {
              required: false,
              label: "Establecimiento permanente en Canarias",
              name: "estPermCanarias",
              type: "switch",              
              value: estPermCanarias,
              xs:6
            },
            {
              type: "hidden",              
              value: "formulariocondiciones"
            },
          ])
          
      }
      },[cuenta, datosDic])
     
      
    if (datoscargados){
        return (  
          <>
            <h6 className="texto-comercial mt-4" id="title">
              Condiciones compra/venta
            </h6>
            <FormularioEntidad 
              urlForm = "cuenta/condiciones"
              urlGoto = "/crm/cuentas/cuenta/"
              campos={campos}
            /> 
          </>
        )
          }
        else {
        return (              
            <div className={classes.linear}>
                <CircularProgress />
            </div>
        );
        }
}
export default FormularioCuentaCondiciones;